import * as React from 'react';

const menu = (props: any) => {
  const { colorPrimary } = props;

  return (
    <>
      <path
        d="M80.826 71.823a3.715 3.715 0 013.71 3.711 3.715 3.715 0 01-3.513 3.706l-.197.005H18.71A3.716 3.716 0 0115 75.535c0-1.98 1.56-3.604 3.514-3.707l.197-.005h62.115zm0-25.533a3.715 3.715 0 013.71 3.711 3.714 3.714 0 01-3.513 3.705l-.197.005H18.71A3.715 3.715 0 0115 50.001c0-1.98 1.56-3.603 3.514-3.706l.197-.005h62.115zm0-25.29a3.715 3.715 0 013.71 3.711 3.714 3.714 0 01-3.513 3.705l-.197.005H18.71A3.715 3.715 0 0115 24.711c0-1.98 1.56-3.603 3.514-3.706l.197-.005h62.115z"
        fill={colorPrimary}
        fillRule="evenodd"
      />
    </>
  );
};
export default menu;
