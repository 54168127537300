import Loadable from 'react-loadable';
import { Loader } from '../components';

// CONDITIONAL PAGES
export const Landing = Loadable({
  loader: () => import('../pages/Landing'),
  loading: Loader,
});

export const Consumption = Loadable({
  loader: () => import('../pages/PageConsumption'),
  loading: Loader,
});

export const VideoLibrary = Loadable({
  loader: () => import('../pages/VideoLibrary'),
  loading: Loader,
});

export const EasyWins = Loadable({
  loader: () => import('../pages/PageEasyWins'),
  loading: Loader,
});

export const Profile = Loadable({
  loader: () => import('../pages/Preferences'),
  loading: Loader,
});

export const Login = Loadable({
  loader: () => import('../pages/Login'),
  loading: Loader,
});
export const GenericMarkdown = Loadable({
  loader: () => import('../pages/GenericMarkdown'),
  loading: Loader,
});
export const PageOverview = Loadable({
  loader: () => import('../pages/PageOverview'),
  loading: Loader,
});
