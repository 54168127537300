import * as React from 'react';

const product = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M49.984 7.054l.124.052 40.1 20.157a.995.995 0 01.23.161l.015.018c.08.078.142.17.191.27l.028.038.01.047a.994.994 0 01.057.202l.02.152v47.096a1 1 0 01-.47.847l-.114.061-40.099 18.42-.013.002a.952.952 0 01-.718.034l-.097-.039-.02-.004L9.57 75.628a1 1 0 01-.56-.776L9 74.725V28.156l.014-.104a.991.991 0 01.075-.285l.012-.049.021-.027a.981.981 0 01.213-.278l.046-.033.08-.062.086-.053 39.66-20.157c.203-.103.431-.132.648-.089l.13.035zm-23.64 13.918l-14.1 7.166 28.16 13.735a1 1 0 01-.77 1.842l-.108-.045L11 29.757v44.337L48.66 92.08V47.835l-4.044-2.079a1 1 0 01.807-1.825l.107.047 4.16 2.138 9.515-4.257a1 1 0 01.92 1.771l-.104.054-9.362 4.19v44.232l38.1-17.5v-44.91l-12.034 5.351v16.22c0 .333-.167.643-.44.827l-.106.063-10.873 5.522a.988.988 0 01-.976-.039 1.002 1.002 0 01-.468-.723l-.009-.129V39.851L26.344 20.972zM14.04 66.736a.998.998 0 011.222-.522l.109.043 6.342 2.99a1.001 1.001 0 01-.749 1.852l-.105-.043-6.341-2.989a1.001 1.001 0 01-.478-1.33zm0-6.183a1 1 0 011.222-.522l.11.044 11.396 5.37a1.001 1.001 0 01-.75 1.853l-.104-.043-11.396-5.37a1.001 1.001 0 01-.478-1.331zm60.685-24.615l-8.872 3.946v15.274l8.872-4.506V35.938zM36.736 15.69l-8.18 4.158L64.88 38.128l8.663-3.853L36.736 15.69zM49.662 9.12l-10.715 5.445 36.955 18.661 11.52-5.125-37.76-18.98z"
          fill={colorPrimary}
        />
        <path
          d="M74.725 35.938v14.714l-8.871 4.506V39.884l8.871-3.946zM36.736 15.69l36.807 18.585-8.663 3.854-36.325-18.282 8.181-4.157z"
          fill={colorSecondary}
        />
      </g>
    </>
  );
};
export default product;
