import React from 'react';
import Styled from './CompanyPage.style';
import { Col } from 'react-styled-flexboxgrid';
import { Icon, Popup, Typography } from '../../../components';
interface CredentialsProps {
  text: string;

  title: string;
  isDesktop: boolean;
}
const Credentials: React.FC<CredentialsProps> = (props) => {
  const { text, title, isDesktop } = props;
  const [showPopup, setPopup] = React.useState(false);

  return (
    <Col md={3} sm={6} xs={12}>
      <Styled.Credential onClick={() => setPopup(!showPopup)}>
        <Styled.TitleWrap>
          <Typography
            size="5"
            text={title}
            style={{ fontSize: '18px', fontWeight: 600 }}
          />
          <Icon icon="leaf" size={22} colorPrimary="#86B049" />
        </Styled.TitleWrap>
        <Typography size="p" text={text} style={{ fontWeight: 400 }} />
      </Styled.Credential>
      <Popup
        style={{ width: isDesktop ? '700px' : '100%' }}
        open={showPopup}
        handleClick={() => setPopup(!showPopup)}
        isDesktop={true}
      >
        <Styled.TitleWrap>
          <Typography
            size="5"
            text={title}
            style={{ fontSize: '18px', paddingRight: '0px', fontWeight: 600 }}
          />
        </Styled.TitleWrap>

        <Typography size="p" text={text} style={{ fontWeight: 400 }} />
      </Popup>
    </Col>
  );
};
export default Credentials;
