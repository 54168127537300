/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppWrap from './components/AppWrap';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { setupInterceptors } from './helpers/auth';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import WebFont from 'webfontloader';
import 'react-tooltip/dist/react-tooltip.css';

setupInterceptors();

WebFont.load({
  google: {
    families: ['Open Sans:400,500,600,700', 'sans-serif'],
  },
});

const prodUrl =
  'https://cdn.files.getwaterfit.com/static/files/translations/prod/{{lng}}/translations.json';
const devUrl = `https://cdn.files.getwaterfit.com/static/files/translations/dev/{{lng}}/translations-test.json`;
const stageurl = `https://cdn.files.getwaterfit.com/static/files/translations/stage/{{lng}}/translations.json`;
let filePath;
const url = window.location.host;
console.log(url);
if (url === 'localhost:3000' || url === 'dev.business.getwaterfit.co.uk') {
  filePath = devUrl;
} else if (url === 'stage.business.getwaterfit.co.uk') {
  filePath = stageurl;
} else {
  filePath = prodUrl;
}

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en-GB',
    lng: 'en-GB',
    load: 'currentOnly',
    backend: {
      loadPath: filePath,
    },
    react: {
      useSuspense: true,
    },
    detection: {
      order: [
        'localStorage',
        'cookie',
        'querystring',
        'sessionStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
      caches: ['sessionStorage'],
    },
  })
  .then(() => {})
  .catch((err) => {
    console.log('i18 Error', err);
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <HashRouter>
        <AppWrap />
      </HashRouter>
    </I18nextProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
