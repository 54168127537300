import { logOut } from '../../actions/';
import { connect } from 'react-redux';
import Navigation from './Navigation';

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => dispatch(logOut()),
  };
};

export default connect(null, mapDispatchToProps)(Navigation);
