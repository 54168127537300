import styled, { css } from 'styled-components';
import { colors } from '../../assets/variables';

const Wrap = styled.div`
  background-color: #d7f1ff;
  display: flex;
  margin-bottom: 15px;
  border-radius: 10px;
  overflow: hidden;
  font-family: 'Open Sans', sans-serif;
  flex-direction: column;

  ${(props) => {
    if (props.completed) {
      return css`
        background-color: #e7f1e8;
      `;
    }
    if (props.inProgress) {
      return css`
        background-color: #e5f2f9;
      `;
    }
  }}

  ${(props) => {
    if (props.isDesktop) {
      return desktopStyles;
    }
  }}
`;

const TextWrap = styled.div`
  display: flex;
  flex-direction: row;
`;
const CheckWrap = styled.div`
  display: flex;
  flex-direction: row;
`;
const Check = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 8px 8px 0;
  align-items: flex-start;
`;
const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
const ArrowWrap = styled.div`
  flex-basis: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1 100%;
  max-width: 72px;
  background-color: #98b235;

  svg {
    align-items: center;
    justify-content: center;
    display: flex;
  }
`;
const Title = styled.h3`
  margin: 0;
  font-weight: 600;
  max-width: 300px;
  font-size: 14px;
  text-transform: none;
  cursor: pointer;
  color: ${colors.primary};
  transition: 0.3s;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    ~ ${ArrowWrap} {
      transition: 0.3s;
      background-color: ${colors.primary};
    }
  }
`;
const TitleWrap = styled.div`
  width: 50%;
  flex: 1;
  display: flex;
  align-items: center;
  &:hover {
    ~ ${ArrowWrap} {
      transition: 0.3s;
      background-color: ${colors.primary};
    }
  }
`;
const PopupTitle = styled.h3`
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 18px;
  color: ${colors.primary};
  font-family: 'Open Sans', sans-serif;
`;
const TaskText = styled.p`
  margin: 8px 8px 0 0;
  color: #0b2641;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
`;
const Description = styled.p`
  color: ${colors.primary};
  margin-bottom: 24px;
  margin-top: 0;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
`;
const DaysChallenge = styled.div`
  padding: 10px 20px;
  color: ${colors.primary};
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
`;

const Text = styled.p`
  margin: 16px 0px;
  color: ${colors.primary};
  max-width: 310px;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
`;
const Savings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #98b235;
  border-bottom: 1px solid #98b235;
  font-family: 'Open Sans', sans-serif;
`;
const SavingItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${colors.primary};
  flex-basis: 100%;
  min-height: 50px;
  width: 250px;
  font-family: 'Open Sans', sans-serif;
`;
const CtaWrap = styled.div`
  min-width: 230px;
  margin-top: 24px;
`;
const IconWrap = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${colors.baseLight};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;
const Icon = styled.div`
  flex-basis: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 8px;
  cursor: pointer;
  &:hover {
    ~ ${ArrowWrap} {
      transition: 0.3s;
      background-color: ${colors.primary};
    }
  }
  svg {
    align-items: center;
    justify-content: center;
    display: flex;
  }
`;

const Arrow = styled.div`
  flex-basis: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-right: 20px;
  svg {
    align-items: center;
    justify-content: center;
    display: flex;
  }
`;

const Loading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 200ms;
  visibility: hidden;
  opacity: 0;
  z-index: 5;
  &.show {
    transition: 200ms;
    opacity: 1;
    visibility: visible;
  }
`;

const InfoWrap = styled.div`
  height: 100%;
  overflow-y: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 16;
  font-family: 'Open Sans', sans-serif;
`;

const desktopStyles = css`
  ${Title} {
    font-size: 18px;
    padding: 24px 0px;
  }
  ${TaskText} {
    font-size: 12px;
  }
  ${ContentWrap} {
    flex-direction: row;
  }
  ${SavingItem} {
    flex-basis: 50%;
    border-right: 1px solid #98b235;
    &:last-child {
      border-right: 0;
    }
  }
  ${Check} {
    align-items: flex-end;
    margin: 8px 8px 8px 0;
  }
  ${PopupTitle} {
    font-size: 20px;
    font-weight: 600;
  }

  ${Description} {
    font-size: 16px;
    margin-bottom: 24px;
    max-width: 416px;
  }
  ${Savings} {
    width: 416px;
    flex-direction: row;
    border-top: 1px solid #98b235;
    border-bottom: 1px solid #98b235;
  }
`;

export default {
  Wrap,
  Title,
  PopupTitle,
  Description,
  Savings,
  SavingItem,
  CtaWrap,
  Icon,
  IconWrap,
  Arrow,
  TextWrap,
  CheckWrap,
  Check,
  ContentWrap,
  Text,
  Loading,
  ArrowWrap,
  TaskText,
  DaysChallenge,
  InfoWrap,
  TitleWrap,
};
