import { WholesalerReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

const initialState: WholesalerReducer = {
  status: {
    loading: false,
    error: false,
    done: false,
  },
  data: null,
};

const wholesalerReducer = createReducer(initialState, {
  GET_WHOLESALER_LIST_PENDING: (state) => {
    state.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  GET_WHOLESALER_LIST_FULFILLED: (state, action) => {
    state.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.data = action.payload.data;
  },

  GET_WHOLESALER_LIST_REJECTED: (state, action) => {
    state.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.data = null;
  },
});

export default wholesalerReducer;
