import axios from 'axios';
import { getMicroserviceGWF } from '../helpers/helpers';

export const getCompanyDetails = (id) => {
  return {
    type: 'GET_COMPANY_DETAILS',
    payload: axios.get(
      `${getMicroserviceGWF('sponsor-portal')}public/business/${id}?type=B2B`
    ),
  };
};
