import styled from 'styled-components';
import { colors } from '../../assets/variables';
const Wrap = styled.div`
  flex-basis: 100%;
  margin: 32px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Card = styled.div`
  border-radius: 10px;
  border: 4px solid ${colors.tertiary};
  padding: 32px;
  height: auto;
  max-width: 660px;
  margin: auto;
  width: 100%;
`;

export default { Wrap, Card };
