import PageEasyWins from './PageEasyWins';
import { connect } from 'react-redux';
import { getChallengeCategory } from '../../actions/challenges';
import { AppState } from '../../interfaces';
import { setMeta } from '../../actions';

const mapStateToProps = (state: AppState) => ({
  challenges: state.challengesReducer,
  listLocation: state.listLocationReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getChallengeCategory: (id, cat) => dispatch(getChallengeCategory(id, cat)),
  setMeta: (propertyId) => dispatch(setMeta(propertyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageEasyWins);
