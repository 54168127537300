import styled, { css } from 'styled-components';

const Wrap = styled.div`
  background: #0b2641;
  color: white;
  padding: 30px 0;
  font-size: 16px;

  ${(props) => {
    if (props.layout === 'desktop') {
      return desktopStyles;
    } else if (props.layout === 'mobile' && props.isNotLanding) {
      return css`
        margin-bottom: 80px;
      `;
    }
  }}
`;
const Line = styled.hr`
  border: 0.5px solid rgba(255, 255, 255, 0.2);
`;
const FooterTop = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  a {
    color: white;
    text-decoration: none;
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    &:hover {
      text-decoration: underline;
    }
  }
`;
const Image = styled.div`
  order: 1;
  margin: 0 auto 10px auto;
  padding-top: 30px;
  padding-bottom: 30px;
`;
const TextWrap = styled.div`
  flex-direction: column;
  flex-grow: 1;

  padding-bottom: 100px;
`;
const TextFirst = styled.div`
  font-size: 12px;
`;
const FooterBottom = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;
const IconWrap = styled.div`
  width: 30px;
  height: 30px;
  border: 2px solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  transition: all 300ms ease-in-out;

  &:hover {
    background: transparent;
    svg {
      path {
        fill: white;
      }
    }
  }
`;
const SocialMedia = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-basis: 0px;
  margin-bottom: 45px;
  a {
    &:first-child {
      margin-right: 8px;
    }
  }
`;
const desktopStyles = css`
  ${FooterTop} {
    flex-direction: row;
    margin-right: 200px;
  }

  ${FooterBottom} {
    flex-direction: row;
  }
`;
export default {
  Wrap,
  Line,
  FooterTop,
  FooterBottom,
  Image,
  TextWrap,
  TextFirst,
  IconWrap,
  SocialMedia,
};
