import * as React from 'react';

const useWhite = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M32.4521179,14.2318351 L32.4521179,83.2318351 L13,83.2318351 L13,14.2318351 L32.4521179,14.2318351 Z M59.7548135,29.7318351 L59.7548135,83.2318351 L40.3026955,83.2318351 L40.3026955,29.7318351 L59.7548135,29.7318351 Z M86.0824127,41.352503 L86.0824127,83.2318351 L66.6302948,83.2318351 L66.6302948,41.352503 L86.0824127,41.352503 Z"
          stroke={colorSecondary}
          strokeWidth="2"
          fill={colorPrimary}
        />
      </g>
    </>
  );
};
export default useWhite;
