import React from 'react';
import * as _ from 'lodash';
import Styled from './Filter.style';
import { capitalizeFirstLetter } from '../../helpers/helpers';
interface FilterProps {
  data: any;
  setItemValue: Function;
}

const Filter: React.FC<FilterProps> = (props) => {
  const { data, setItemValue } = props;
  const menuItems = data.map((Val) => Val.type);
  const [value, setValue] = React.useState('all');
  const combineItems = menuItems.flat(1);

  const filterMenu = combineItems.filter(
    (item, index) => combineItems.indexOf(item) === index
  );

  return (
    <div>
      <Styled.Button
        onClick={() => {
          setValue('all');
          setItemValue('all');
        }}
        className={value === 'all' ? 'active' : ''}
      >
        All
      </Styled.Button>
      {filterMenu.map((Val, id) => {
        return (
          <Styled.Button
            onClick={() => {
              setValue(Val);
              setItemValue(Val);
            }}
            key={id}
            className={value === Val ? 'active' : ''}
          >
            {/* {Val.replace('-', ' ')} */}
            {capitalizeFirstLetter(Val.replace(/-/g, ' '))}
          </Styled.Button>
        );
      })}
    </div>
  );
};

export default Filter;
