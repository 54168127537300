import React from 'react';
import { prettifyIfNumber } from '../../helpers/formatters';
import * as _ from 'lodash';
import { Typography, Icon } from '../index';
import Slider from 'rc-slider';
import Styled from './Sliders.style';
import { useResponsivity } from '../../helpers/helpers';
interface SlidersProps {
  title: string;
  onValueChange: Function;
  litres: number;
  money: number;
  className: string;
}

export const Sliders: React.FC<SlidersProps> = (props) => {
  const { title, onValueChange, className, litres, money } = props;
  const [value, setValue] = React.useState();
  const isMobile = useResponsivity();
  const onSliderChange = (value) => {
    setValue(value);
    onValueChange(value);
  };

  return (
    <Styled.Wrap id={title} className={className}>
      {isMobile ? (
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography style={{ marginBottom: '0px' }} size="p" text={title} />
            <Styled.Number>{value ? value : 0}</Styled.Number>
          </div>
          <Slider
            ariaLabelForHandle="Slider handle"
            min={0}
            max={20}
            value={value}
            onChange={onSliderChange}
          />
          <Styled.ValueWrap>
            <Styled.Item>
              {value ? prettifyIfNumber(value * litres) : 0}{' '}
              <Icon
                icon="droplet"
                size={24}
                style={{ marginLeft: isMobile ? '0px' : '8px' }}
              />
            </Styled.Item>
            <Styled.Item>
              {value ? prettifyIfNumber(value * money) : 0}{' '}
              <Icon
                icon="pound"
                size={24}
                style={{ marginLeft: isMobile ? '0px' : '8px' }}
              />
            </Styled.Item>
          </Styled.ValueWrap>
        </div>
      ) : (
        <>
          <Typography style={{ marginBottom: '0px' }} size="p" text={title} />
          <Styled.Number>{value ? value : 0}</Styled.Number>
          <Slider
            ariaLabelForHandle="Slider handle"
            min={0}
            max={20}
            value={value}
            onChange={onSliderChange}
          />
          <Styled.ValueWrap>
            <Styled.Item>
              {value ? prettifyIfNumber(value * litres) : 0}{' '}
              <Icon icon="droplet" size={24} style={{ marginLeft: '8px' }} />
            </Styled.Item>
            <Styled.Item>
              {value ? prettifyIfNumber(value * money) : 0}{' '}
              <Icon icon="pound" size={24} style={{ marginLeft: '8px' }} />
            </Styled.Item>
          </Styled.ValueWrap>
        </>
      )}
    </Styled.Wrap>
  );
};

export default Sliders;
