import _ from 'lodash';
import React from 'react';
import Styled from './VieoLibrary.style';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Wrap, Typography, Filter } from '../../components/index';
import { useTranslation } from 'react-i18next';

export const VideoLibrary: React.FC = () => {
  const [t] = useTranslation('common');
  const pageTitle = t('portal.video_library_title');
  const pageDescription = t('portal.video_library_description');
  const [item, setItem] = React.useState('all');
  const setItemValue = (message) => {
    setItem(message);
  };
  const getVideo = [
    {
      id: 1,
      title: 'SHOWERHEAD',
      url: 'vNtk9pu-4gk',
      type: ['bathroom-shower'],
    },
    {
      id: 2,
      title: 'TAP INSERTS',
      url: 'C_xV51AcNCQ',
      type: ['kitchen-tap', 'bathroom-tap'],
    },
    {
      id: 3,
      title: 'KITCHENSTREAM',
      url: 'YZn1fAIHtxQ',
      type: ['kitchen-tap'],
    },
    {
      id: 4,
      title: 'SHOWER REGULATOR',
      url: 'NZ7GD8s1p3c',
      type: ['bathroom-shower'],
    },
    { id: 5, title: 'LEAKYLOO', url: 'j8rgez1h-fE', type: ['bathroom-toilet'] },
    { id: 6, title: 'BUFFALOO', url: 'dYXORmJozU4', type: ['bathroom-toilet'] },
    {
      id: 7,
      title: 'WATER SAVING GEL FOR PLANTS',
      url: 'gaBzEucv2vg',
      type: ['outdoor'],
    },
    {
      id: 8,
      title: 'TOOTHY TIMER',
      url: 'dWohV6uhdBc',
      type: ['bathroom-shower'],
    },
    {
      id: 9,
      title: 'SHOWER TIMER',
      url: 'hPF0Z2Vbg8o',
      type: ['bathroom-shower'],
    },
    {
      id: 10,
      title: 'HOSE TRIGGER GUN',
      url: 'e3k1AGfjE_Q',
      type: ['outdoor'],
    },
    {
      id: 11,
      title: 'BIN DON\u0027T FLUSH \u0026 THINK AT THE SINK',
      url: 'WhHbyGy7OyY',
      type: ['bathroom-toilet'],
    },
    {
      id: 12,
      title: 'DUAL FLUSH TOILETS',
      url: 'Ki1Z_-3najM',
      type: ['bathroom-toilet'],
    },
  ];
  const filterMenu =
    item === 'all' ? getVideo : getVideo.filter((el) => el.type.includes(item));

  const renderVideo = () => {
    return _.map(filterMenu, (el, index) => {
      return (
        <Col md={4} sm={12} xs={12} key={index}>
          <Styled.Box>
            <Typography
              size="4"
              text={el.title}
              style={{ textAlign: 'center', minHeight: '52px' }}
            />
            <iframe
              title={el.title}
              width="100%"
              height="300"
              src={`https://www.youtube.com/embed/${el.url}`}
            ></iframe>
          </Styled.Box>
        </Col>
      );
    });
  };
  return (
    <Wrap>
      <Styled.Wrap>
        <Row style={{ marginBottom: '16px' }}>
          <Col md={12} sm={12} xs={12}>
            <Typography
              size="1"
              text={pageTitle}
              style={{ fontSize: '40px' }}
            />
            <Typography
              size="p"
              style={{ fontSize: '18px', fontWeight: 700, maxWidth: '760px' }}
              text={pageDescription}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '16px' }}>
          <Col md={12} sm={12} xs={12}>
            <Typography size="p" text="Filter:" />
          </Col>
          <Col md={12} sm={12} xs={12}>
            <Filter data={getVideo} setItemValue={setItemValue} />
          </Col>
        </Row>
        <Row>{renderVideo()}</Row>
      </Styled.Wrap>
    </Wrap>
  );
};

export default VideoLibrary;
