import * as React from 'react';

const typeSlider = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M12.47 58.26A3.47 3.47 0 019 54.8v-8.34c0-1.91 1.56-3.47 3.47-3.47h5.12a19.63 19.63 0 00-1.54 7.64c0 2.71.55 5.28 1.54 7.63h-5.12z"
          fill={colorSecondary}
        />
        <path
          d="M53.29 50.63c0 2.73-.63 5.32-1.74 7.63-.34.69-.71 1.36-1.13 2-3.15 4.81-8.59 7.99-14.75 7.99s-11.59-3.18-14.74-7.99c-.42-.64-.8-1.31-1.14-2a17.629 17.629 0 010-15.27c.34-.69.72-1.36 1.14-2C24.08 36.18 29.51 33 35.67 33s11.6 3.18 14.75 7.99c.42.64.79 1.31 1.13 2a17.629 17.629 0 011.74 7.64"
          fill={colorSecondary}
        />
        <path
          d="M35.67 31c7.187 0 13.478 3.885 16.894 9.667l.187.323h34.99c2.936 0 5.339 2.332 5.454 5.25l.005.22v8.34a5.467 5.467 0 01-5.24 5.456l-.22.004H52.76c-3.37 5.96-9.77 9.99-17.09 9.99-7.187 0-13.487-3.885-16.903-9.667l-.186-.323h-6.11c-2.937 0-5.35-2.332-5.466-5.24L7 54.8v-8.34a5.478 5.478 0 015.251-5.466l.22-.004h6.12C21.96 35.03 28.348 31 35.67 31zm0 2c-6.16 0-11.59 3.18-14.74 7.99-.42.64-.8 1.31-1.14 2a17.615 17.615 0 000 15.27c.34.69.72 1.36 1.14 2 3.15 4.81 8.58 7.99 14.74 7.99s11.6-3.18 14.75-7.99c.42-.64.79-1.31 1.13-2a17.629 17.629 0 000-15.27c-.34-.69-.71-1.36-1.13-2C47.27 36.18 41.83 33 35.67 33zm52.07 9.99H53.75a19.63 19.63 0 011.54 7.64c0 2.53-.479 4.937-1.347 7.157l-.192.473h33.99a3.461 3.461 0 003.453-3.27l.005-.19v-8.34a3.47 3.47 0 00-3.269-3.465l-.19-.005zm-70.15 0h-5.12a3.478 3.478 0 00-3.464 3.28L9 46.46v8.34a3.469 3.469 0 003.28 3.455l.19.005h5.12a19.561 19.561 0 01-1.54-7.63c0-2.53.478-4.946 1.347-7.167l.192-.473z"
          fill={colorPrimary}
        />
      </g>
    </>
  );
};
export default typeSlider;
