import React from 'react';
import Slider from 'rc-slider';
import { SliderControlProps } from '../../interfaces';
import Styled from './SliderControl.style';
import * as _ from 'lodash';
import i18next from 'i18next';
import { Row, Col } from 'react-styled-flexboxgrid';
class SliderControl extends React.Component<SliderControlProps, any> {
  constructor(props) {
    super(props);
    const currentValue =
      props.value != null ? props.value : parseInt(props.defaultValue, 10);
    this.updateDebounced = _.debounce(this.updateDebounced.bind(this), 300);
    this.state = {
      localValue: currentValue,
    };
  }

  updateDebounced = (val) => {
    const {
      answerKey,
      updateAnswerKey = () => void 0,
      required,
      validateRequiredKeys,
    } = this.props;
    if (required) {
      validateRequiredKeys();
    }
    updateAnswerKey(answerKey, val);
  };

  render() {
    const { answerKey, min, max, sliderIndicatorText, help_image_url } =
      this.props;
    const maxValue = max.toString();
    const onSliderChange = (value) => {
      this.setState({ localValue: value });
      this.updateDebounced(value);
    };

    return (
      <Styled.Wraper>
        <Row>
          <Col xs={8} md={4} sm={6}>
            <Styled.Slide id={answerKey} style={{ padding: '10px' }}>
              <Slider
                ariaLabelForHandle="Slider handle"
                min={min}
                max={Number(maxValue)}
                value={this.state.localValue}
                onChange={onSliderChange}
              />
            </Styled.Slide>
          </Col>
          <Col xs={4} md={6} sm={6}>
            <Styled.Desc>
              <strong>{this.state.localValue}</strong>{' '}
              {i18next.t(sliderIndicatorText)}
            </Styled.Desc>
          </Col>
        </Row>
      </Styled.Wraper>
    );
  }
}

export default SliderControl;
