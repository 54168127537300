import React from 'react';
import * as _ from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import {
  Wrap,
  Typography,
  Input,
  Button,
  Notifications,
} from '../../components/index';
import Styled from './Login.style';
import { Formik } from 'formik';
import { Row, Col } from 'react-styled-flexboxgrid';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

interface LoginProps {
  logIn: Function;
  auth: any;
}

export const Login: React.FC<LoginProps> = (props) => {
  const { logIn, auth } = props;
  const [t] = useTranslation('common');
  const { error, done, loading } = auth.status;

  const navigate = useNavigate();
  const LoginSchema = Yup.object().shape({
    password: Yup.string()
      .min(2, `${t('portal.general_form_errors.too_short')}`)
      .max(50, `${t('portal.general_form_errors.too_long')}`)
      .required(`${t('portal.general_form_errors.required')}`),
    email: Yup.string()
      .email(`${t('portal.general_form_errors.email_not_valid')}`)
      .required(`${t('portal.general_form_errors.required')}`),
  });
  React.useEffect(() => {
    if (!loading && !error && done) {
      navigate('/portal');
    }
  }, [loading, error, done]);
  const [hidden, setHidden] = React.useState(false);
  const showVisibility = () => setHidden(!hidden);

  const text_translation = {
    sign_in: t('portal.sign_in.title'),
    sign_in_content: t('portal.sign_in.content'),
    email_label: t('portal.sign_in.email_label'),
    password_label: t('portal.sign_in.password_label'),
    submit_button: t('portal.sign_in.submit_button'),
    forgot_password: t('portal.sign_in.forgot_password'),
    take_me_to_gwf: t('portal.sign_in.take_me_to_gwf'),
    dont_have_account: t('portal.sign_in.dont_have_account'),
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={LoginSchema}
        onSubmit={(values, { setSubmitting }) => {
          logIn(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <Input
                  id="email"
                  name="email"
                  value={values.email}
                  type="text"
                  placeholder="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.email && touched.email ? errors.email : undefined
                  }
                  aria-label="Email"
                  aria-describedby={
                    errors.email && touched.email ? errors.email : undefined
                  }
                />
              </Col>
              <Col xs={12} sm={12} md={12}>
                <Input
                  id="password"
                  name="password"
                  value={values.password}
                  icon={hidden ? 'visible' : 'hidden'}
                  type={hidden ? 'text' : 'password'}
                  iconClick={showVisibility}
                  placeholder="Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.password && touched.password
                      ? errors.password
                      : undefined
                  }
                  aria-label="Password"
                  aria-describedby={
                    errors.password && touched.password
                      ? errors.password
                      : undefined
                  }
                />
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginBottom: '16px',
                }}
              >
                <Link className="decorated" to="/resetpassword">
                  {text_translation.forgot_password}
                </Link>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  isLoading={loading}
                  disabled={isSubmitting}
                  type="submit"
                >
                  {t('portal.sign_in.submit_button')}
                </Button>
              </Col>
              {error ? (
                <Col md={12} sm={12} xs={12}>
                  <Notifications type="ERROR">{error}</Notifications>
                </Col>
              ) : null}
            </Row>
          </form>
        )}
      </Formik>
    );
  };
  return (
    <Wrap>
      <Styled.Wrap>
        <Styled.Card>
          <Typography size="1" text={text_translation.sign_in} />
          <Typography
            size="p"
            style={{ marginBottom: '16px' }}
            text="Sign in to reduce your business water use"
          />
          {renderForm()}
          <Styled.Line />
          <Typography
            size="3"
            style={{ marginTop: '24px' }}
            text={text_translation.dont_have_account}
          />
          <Link
            className="decorated"
            to="/"
            aria-label={text_translation.take_me_to_gwf}
          >
            Take me to GetWaterFit for Business
          </Link>
        </Styled.Card>
      </Styled.Wrap>
    </Wrap>
  );
};

export default Login;
