import styled from 'styled-components';

const Icon = styled.div`
  ${(props) => `
      width: ${props.size}em;
      height: ${props.size}em;
    `}

  position: relative;
`;
export default { Icon };
