import * as React from 'react';
import * as _ from 'lodash';
import Styled from './Tile.style';
import { Icon, Button, Popup } from '../index';

interface TileProps {
  title?: string;
  description?: string;
  buttonText?: string;
  icon?: string;
  onButtonClick?: Function;
  children?: React.ReactNode;
  isDesktop: boolean;
  imageUrl?: string;
  theme?: string;
  bgPosition?: string;
  isPopup?: boolean;
  disabled?: boolean;
  disclaimer?: boolean;
}
export const Tile: React.FC<TileProps> = (props) => {
  const [showPopup, setPopup] = React.useState(false);

  const {
    title,
    icon,
    description,
    buttonText,
    onButtonClick,
    isDesktop,
    imageUrl,
    isPopup,
    children,
    bgPosition,
    theme,
    disabled,
    disclaimer,
  } = props;
  const setButton = () => {
    if (isPopup) {
      return setPopup(!showPopup);
    }
    if (onButtonClick) {
      onButtonClick();
    }
  };
  return (
    <Styled.Wrap isDesktop={isDesktop} theme={theme}>
      <Styled.ImgWrap bgPosition={bgPosition || null} imageUrl={imageUrl} />
      <Styled.Content>
        <Styled.Title
          style={{
            fontSize: '26px',
          }}
        >
          {theme === 'greenTheme' ? (
            <span>
              MySh
              <Icon icon="leaf" size={18} colorPrimary="#86B049" />p
            </span>
          ) : (
            title
          )}
        </Styled.Title>
        <Styled.Description
          style={{
            color: theme === 'darkTheme' ? 'white' : 'auto',
          }}
        >
          {description}
        </Styled.Description>
        {buttonText ? (
          <Styled.Button disabled={disabled} onClick={() => setButton()}>
            {icon ? (
              <Icon icon={icon} size={40} style={{ marginRight: '8px' }} />
            ) : null}
            {buttonText}
          </Styled.Button>
        ) : null}
        {disclaimer ? (
          <Styled.Disclaimer>
            <i>{children}</i>
          </Styled.Disclaimer>
        ) : null}
      </Styled.Content>
      {isPopup ? (
        <Popup open={showPopup} isDesktop={isDesktop} handleClick={setPopup}>
          {children}
        </Popup>
      ) : null}
    </Styled.Wrap>
  );
};
export default Tile;
