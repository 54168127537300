import styled, { css } from 'styled-components';
import { colors } from '../../assets/variables';

const disabled = css`
  background-color: ${colors.disabled};
  color: ${colors.darkGray};
  cursor: not-allowed;
  box-shadow: none;

  :hover,
  :focus {
    box-shadow: none;
    background-color: ${colors.disabled};
    color: ${colors.darkGray};
  }
`;

const outlinedStyle = css`
  box-shadow: inset 0 0 0 2px #d7f1ff;
  background-color: ${colors.baseLight};
  color: ${colors.primary};
  :hover,
  :focus {
    color: ${colors.primary};
    box-shadow: inset 0 0 0 1px ${colors.action};
    background-color: ${colors.action};
  }
`;

const transparentStyle = css`
  box-shadow: none;
  background-color: transparent;
  color: ${(props) => props.colorPrimary};

  :hover,
  :focus {
    color: ${(props) => props.colorPrimary};
    box-shadow: none;
    background-color: transparent;
  }
`;
const Loading = styled.button`
  font-family: 'Open Sans', sans-serif;
  transition: all 300ms;
  font-size: 16px;
  font-weight: 600;
  min-width: 165px;
  height: 50px;
  color: ${colors.baseLight};
  background-color: ${colors.primary};
  line-height: 1.15;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 16px 20px;
  text-decoration: none;
  border-radius: 10px;
  text-align: left;
  cursor: not-allowed;
  border: none;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  text-transform: uppercase;
  margin: 0;
`;
const Default = styled.button`
  font-family: 'Open Sans', sans-serif;
  transition: all 300ms;
  font-size: 16px;
  font-weight: 600;
  min-width: 165px;
  height: 50px;
  color: ${colors.baseLight};
  background-color: ${colors.primary};
  line-height: 1.15;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 16px 20px;
  text-decoration: none;
  border-radius: 10px;
  text-align: left;
  cursor: pointer;
  border: none;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  text-transform: uppercase;
  margin: 0;

  ${(props) => {
    if (props.full) {
      return css`
        display: block;
        width: 100%;
        text-align: center;
      `;
    }
    return css`
      display: flex;
    `;
  }};

  :hover,
  :focus {
    color: ${colors.primary};
    background-color: ${colors.action};
    ${(props) => (props.disabled ? disabled : '')}
    svg {
      path {
        transition: all 300ms;
        fill: ${colors.action};
      }
    }
  }
  :active {
    color: ${colors.primary};
    background-color: ${colors.action};
    box-shadow: inset 0 0 0 2px ${colors.action};
    ${(props) => (props.disabled ? disabled : '')}
  }

  ${(props) => {
    switch (props.appearance) {
      case 'OUTLINED':
        return outlinedStyle;
      case 'TRANSPARENT':
        return transparentStyle;
    }
  }}
  ${(props) => (props.disabled ? disabled : '')};
`;

const IconWrap = styled.div`
  background-color: white;
  border-radius: 50%;
  margin-left: 16px;
  width: 24px;
  height: 24px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Loader = styled.div`
  z-index: 20;
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 21px;
    height: 21px;
    margin: 6px;
    border: 2px solid ${colors.baseLight};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${colors.baseLight} transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;
export default { Default, IconWrap, Loading, Loader };
