import * as React from 'react';

const home = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path fill="#D7F1FF" d="M45.55 85.414h17.487v-30.72H45.55z" />
        <path
          d="M54.542 13.001l.118.015a.996.996 0 01.437.193l.094.083 39 39c.36.36.388.927.083 1.32l-.084.094-5.788 5.788a.997.997 0 01-1.311.09l-.103-.09-6.94-6.94v27.305a6.645 6.645 0 015.512 5.305l.043.251h1.081a1 1 0 01.117 1.993l-.117.007h-2.08a1 1 0 01-1-1c0-.098.03-.187.055-.276-.146-2.427-2.148-4.363-4.61-4.363a4.644 4.644 0 00-4.64 4.639 1 1 0 01-.884.993l-.117.007H15.924l-.07.005-.034-.005H7a1 1 0 01-.116-1.993L7 85.415h7.78a6.648 6.648 0 015.68-6.504 6.647 6.647 0 016.595-7.407c.48 0 .958.056 1.424.16l.347.086v-6.696a1 1 0 011.994-.117l.006.117v20.361H43.55V53.694a1 1 0 01.884-.993l.116-.007h19.487a1 1 0 01.994.883l.006.117v31.721h7.455a6.645 6.645 0 015.306-5.513l.25-.043V50.554L54.481 26.987 21.973 59.494a1 1 0 01-1.32.083l-.094-.083-5.788-5.788a.999.999 0 01-.083-1.32l.083-.094 39-39c.18-.18.414-.277.652-.29h.12zM93.7 85.415a1 1 0 01.116 1.993l-.116.007h-2.408a1 1 0 01-.117-1.994l.117-.007H93.7zM27.054 73.504a4.644 4.644 0 00-4.42 6.049.998.998 0 01-.878 1.299l-.14.001-.2-.011A4.64 4.64 0 0016.79 85.2l-.008.215h12.043V73.857a4.645 4.645 0 00-1.772-.353zm35.983-18.81H45.55v30.72h17.487v-30.72zM50.631 69.348a3.16 3.16 0 013.157 3.156 3.16 3.16 0 01-3.157 3.157 3.16 3.16 0 01-3.157-3.157 3.16 3.16 0 013.157-3.156zm0 2c-.638 0-1.157.52-1.157 1.156 0 .638.519 1.157 1.157 1.157s1.157-.519 1.157-1.157c0-.637-.52-1.156-1.157-1.156zm3.85-55.937L16.891 52.999l4.374 4.374 32.508-32.507a.993.993 0 01.707-.293c.214 0 .426.067.605.203l.102.09 32.507 32.507 4.374-4.374L54.48 15.411z"
          fill={colorPrimary}
        />
        <path
          d="M87.695 57.373L55.188 24.866a.995.995 0 00-.707-.293.993.993 0 00-.707.293L21.266 57.373l-4.374-4.374L54.48 15.411l37.59 37.588-4.375 4.374z"
          fill={colorSecondary}
        />
      </g>
    </>
  );
};
export default home;
