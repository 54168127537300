import store from 'store2';
import * as _ from 'lodash';
import axios from 'axios';
import JWTDecode from 'jwt-decode';
import { logOut, refreshAccessToken } from '../actions/index';
import reduxStore from '../store';
import replaceEndpoints from '../replaceEndpoints';

let fails = 0;

export const isLoggedIn = () => {
  const { authReducer } = reduxStore.getState();
  const accessToken = _.get(authReducer, 'data.accessToken', false);

  return accessToken;
};

export const setupInterceptors = () => {
  const updateToken = (accessToken) => {
    store.transact('swsmUser', (obj) => {
      obj.data.accessToken = accessToken;
    });
  };

  let isFetchingToken = false;
  let tokenSubscribers: any = [];

  function subscribeTokenRefresh(cb) {
    tokenSubscribers.push(cb);
  }

  function onTokenRefreshed(errRefreshing, token) {
    tokenSubscribers.map((cb) => cb(errRefreshing, token));
  }

  function forceLogout() {
    isFetchingToken = false;
    reduxStore.dispatch(logOut());
  }

  axios.interceptors.request.use(
    (reqConfig) => {
      const provideRefresh =
        _.includes(reqConfig.url, '/logout') ||
        _.includes(reqConfig.url, '/token');
      const swsmAPI = _.includes(reqConfig.url, 'getwaterfit.co.uk');

      if (!swsmAPI) {
        return reqConfig;
      }

      const injectRedirections = replaceEndpoints(reqConfig);
      reqConfig = injectRedirections;

      const accessToken = _.get(store('swsmUser'), 'data.accessToken');
      const refreshToken = _.get(store('swsmUser'), 'data.refreshToken');
      reqConfig.headers = reqConfig.headers ?? {};

      reqConfig.headers.authorization = 'Bearer ' + accessToken;

      if (provideRefresh) {
        reqConfig.headers['x-refresh-token'] = refreshToken;
      }

      return reqConfig;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (res) => {
      if (!_.includes(res.config.url, '/token')) {
        fails = 0;
      }
      return res;
    },
    (err) => {
      if (err.response === undefined) {
        return Promise.reject(err);
      } else if (
        _.includes(err.response.config.url, '/login') ||
        _.includes(err.response.config.url, '/reset')
      ) {
        return Promise.reject(_.get(err, 'response.data.message', 'LogIn'));
      } else if (err.response.status === 403) {
        forceLogout();

        return Promise.reject('Authorization will not help');
      } else if (err.response.status === 404) {
        return Promise.reject(err.response);
      } else if (err.response.status !== 401) {
        return Promise.reject(err.response); //only 401 unauthorized
      }

      fails += 1;
      if (fails >= 3) {
        console.log(`${fails} unsuccessfull token refreshes in row.`);
        forceLogout();
        return Promise.reject(err.response);
      }

      if (!isFetchingToken) {
        const refreshToken = _.get(
          store('swsmUser'),
          'data.refreshToken',
          false
        );

        isFetchingToken = true;

        if (!refreshToken) {
          forceLogout();
          return Promise.reject(err.response);
        }
        try {
          const isRefreshTokenExpired =
            JWTDecode<any>(refreshToken).exp < Date.now() / 1000;

          if (isRefreshTokenExpired) {
            forceLogout();
            return Promise.reject(err.response);
          }
        } catch (error) {
          forceLogout();
          return Promise.reject(err.response);
        }

        reduxStore
          .dispatch(refreshAccessToken())
          .then((res) => {
            isFetchingToken = false;
            const newAccessToken = res.value;
            if (newAccessToken === null) {
              forceLogout();
            } else {
              onTokenRefreshed(null, newAccessToken);
              tokenSubscribers = [];
              updateToken(newAccessToken);
            }
          })
          .catch((err) => {
            onTokenRefreshed(
              Promise.reject(
                _.get(err, 'data.message', 'Unable to refresh token')
              ),
              null
            );
            tokenSubscribers = [];
            forceLogout();
          });
      }

      return new Promise((resolve, reject) => {
        subscribeTokenRefresh((errRefreshing, newToken) => {
          if (errRefreshing) {
            return reject(errRefreshing);
          }
          err.config.headers.authorization = newToken;
          return resolve(axios(err.config));
        });
      });
    }
  );
};
