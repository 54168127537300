import React from 'react';
import { Wrap, Typography, Notifications } from '../../components/index';
import Styled from './AddLocation.style';
import { Formik } from 'formik';
import { Row, Col } from 'react-styled-flexboxgrid';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useResponsivity } from '../../helpers/helpers';
import {
  Input,
  Button,
  Select,
  BackButton,
  SearchPostCode,
} from '../../components/index';
import * as _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import store from 'store2';
interface AddLocationProps {
  addLocation: Function;
  location: any;
  listLocation: any;
  getAddress: Function;
  clearAddress: Function;
  listLocations: Function;
}

const AddLocation: React.FC<AddLocationProps> = (props) => {
  const {
    listLocation,
    listLocations,
    clearAddress,
    addLocation,
    location,
    getAddress,
  } = props;

  const [t] = useTranslation('common');
  const navigate = useNavigate();
  const [success, setSuccess] = React.useState(false);
  const addresses = _.get(location, 'address.data.data.items');
  const isMobile = useResponsivity();
  const addressLength = _.get(addresses, 'length');
  const errorAddLocation = _.get(location, 'addLocation.data.errors');
  const succesAddLocation = _.get(location, 'addLocation.data.message');
  const errorAddress = _.get(location, 'address.status.error');
  const doneAddress = _.get(location, 'address.status.done');
  const data = _.get(listLocation, 'data.data');
  const length = _.get(data, 'length');
  const lastItem = data && data[length - 1];

  React.useEffect(() => {
    clearAddress();
    listLocations();
  }, []);

  // if (error) {
  //   return (
  //     <Wrap>
  //       <ErrorDisplay />
  //     </Wrap>
  //   );
  // }
  // if (loading && !done) {
  //   return (
  //     <Wrap>
  //       <SkeletonLoader />
  //     </Wrap>
  //   );
  // }

  if (success === true) {
    setTimeout(() => {
      setSuccess(false);
    }, 10000);
  }

  const renderAddressSelect = () => {
    if (addresses === null) {
      return [];
    }

    return addresses.map((address) => {
      return {
        value: address.summaryline,
        label: address.summaryline,
      };
    });
  };

  const AddLocationSchema = Yup.object().shape({
    spid: Yup.string().matches(/^\d{10}[SW]\d[\dX]$/, 'SPID is not valid'),
    property_name: Yup.string()
      .min(1, 'Too short')
      .max(16, 'Too long')
      .required('Required'),
    number_of_male_employee: Yup.number()
      .min(1, 'Too short')
      .required('Required'),
    number_of_female_employee: Yup.number()
      .min(1, 'Too short')
      .required('Required'),
    water_heated_with: Yup.string().required('Required'),
    postcode: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
  });

  const heatedOptions = [
    { value: 'gas', label: 'Gas' },
    { value: 'electric', label: 'Electric' },
    { value: 'coal', label: 'Coal' },
    { value: 'renewable', label: 'Renewable' },
    { value: 'oil', label: 'Oil' },
    { value: "i-don't-know", label: "I don't know" },
  ];
  const lastId = _.get(location, 'data.id');

  const renderSuccess = () => {
    return (
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Typography size="3" style={{ textAlign: 'center' }} text="Success!" />
        <Typography
          size="p"
          style={{ textAlign: 'center', marginBottom: '32px' }}
          text="You have successfully added your location. Now add your rooms."
        />
        <Styled.ButtonWrap>
          <Button
            onClick={() => navigate(`/${lastItem.id}/add-room`)}
            style={{ marginRight: isMobile ? '0px' : '16px' }}
          >
            Add room
          </Button>
          <Button onClick={() => setSuccess(false)}>Add new location</Button>
        </Styled.ButtonWrap>
      </div>
    );
  };

  const renderErrors = () => {
    return _.map(errorAddLocation, (el, index) => {
      return <li key={index}>{el}</li>;
    });
  };
  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          spid: '',
          number_of_male_employee: '',
          number_of_female_employee: '',
          water_heated_with: '',
          property_name: '',
          postcode: '',
          address: '',
        }}
        validationSchema={AddLocationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          addLocation(values);
          setSuccess(true);
          setTimeout(() => {
            listLocations();
          }, 500);

          store('property', lastId);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => {
          const renderPostcodeError = () => {
            if (errors.postcode && touched.postcode) {
              return errors.postcode;
            }
            if (touched.postcode && errorAddress) {
              return `${errorAddress}`;
            }
            if (addresses === undefined && touched.postcode) {
              return 'Please click on the search button to find your address';
            }
          };
          return (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <Typography size="3" text="Address" />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <SearchPostCode
                    handleBlur={handleBlur}
                    onSearch={() => getAddress(values.postcode)}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    disabled={false}
                    loading={false}
                    placeholder="Enter your postcode"
                    value={values.postcode}
                    name="postcode"
                    clearAddress={() => clearAddress()}
                    error={renderPostcodeError()}
                  />
                </Col>

                {addresses && addressLength > 0 && (
                  <Col xs={12} sm={12} md={6}>
                    <Select
                      id="address"
                      name="address"
                      value={values.address}
                      type="text"
                      placeholder="Address*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={renderAddressSelect()}
                      error={
                        errors.address && touched.address
                          ? errors.address
                          : undefined
                      }
                    />
                  </Col>
                )}

                <Col xs={12} sm={12} md={12}>
                  <Typography size="3" text="Details" />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Input
                    id="property_name"
                    name="property_name"
                    value={values.property_name}
                    type="text"
                    placeholder="Property name*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.property_name && touched.property_name
                        ? errors.property_name
                        : undefined
                    }
                  />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Select
                    onChange={handleChange}
                    style={{ marginBottom: '16px' }}
                    onBlur={handleBlur}
                    options={heatedOptions}
                    value={values.water_heated_with}
                    name="water_heated_with"
                    placeholder="How is the water heated at your premises?*"
                    error={
                      errors.water_heated_with && touched.water_heated_with
                        ? errors.water_heated_with
                        : undefined
                    }
                  />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Input
                    id="spid"
                    name="spid"
                    value={values.spid}
                    type="text"
                    placeholder="SPID (optional)"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.spid && touched.spid ? errors.spid : undefined
                    }
                  />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Input
                    id="number_of_male_employee"
                    name="number_of_male_employee"
                    value={values.number_of_male_employee}
                    type="number"
                    placeholder="Number of male employees*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.number_of_male_employee &&
                      touched.number_of_male_employee
                        ? errors.number_of_male_employee
                        : undefined
                    }
                  />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Input
                    id="number_of_female_employee"
                    name="number_of_female_employee"
                    value={values.number_of_female_employee}
                    type="number"
                    placeholder="Number of female employees*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.number_of_female_employee &&
                      touched.number_of_female_employee
                        ? errors.number_of_female_employee
                        : undefined
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button disabled={isSubmitting} type="submit">
                    Add location
                  </Button>
                </Col>
                {errorAddLocation ? (
                  <Col md={12} sm={12} xs={12}>
                    <Notifications type="ERROR">{renderErrors()}</Notifications>
                  </Col>
                ) : null}
              </Row>
            </form>
          );
        }}
      </Formik>
    );
  };

  return (
    <Wrap>
      <Row>
        <Col xs={12} sm={12} md={12}>
          <Typography size="1" text="Add location" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} style={{ marginBottom: '32px' }}>
          <BackButton />
        </Col>
      </Row>

      <Styled.Card>
        {succesAddLocation && success ? renderSuccess() : renderForm()}
      </Styled.Card>
    </Wrap>
  );
};

export default AddLocation;
