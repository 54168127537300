/* eslint-disable react/jsx-filename-extension */
import * as React from 'react';

const bed = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M77.366 16c3.995 0 7.262 3.186 7.391 7.151l.004.244v21.31c3.278.179 5.908 2.836 6.026 6.13l.004.231v32.35a1 1 0 01-1.993.117l-.007-.117v-7.081H11v7.081a1 1 0 01-1.993.117L9 83.416v-32.35c0-3.217 2.394-5.884 5.494-6.317l.228-.028V23.395c0-3.996 3.186-7.262 7.15-7.391l.244-.004h55.25zm11.425 43.901H11v14.434h77.791V59.901zm-4.379-13.214H15.38a4.385 4.385 0 00-4.375 4.167l-.005.212v6.835h77.791v-6.835a4.385 4.385 0 00-4.167-4.374l-.212-.005zM45.46 28.885H25.845v15.802H45.46V28.885zm28.574 0H54.418v15.802h19.616V28.885zM77.366 18h-55.25a5.401 5.401 0 00-5.39 5.178l-.004.217v21.292h7.123V27.885a1 1 0 01.883-.993l.117-.007H46.46a1 1 0 01.993.883l.007.117v16.802h4.958V27.885a1 1 0 01.883-.993l.117-.007h21.616a1 1 0 01.993.883l.007.117v16.802h6.727V23.395a5.401 5.401 0 00-5.178-5.39L77.366 18z"
          fill={colorPrimary}
        />
        <path
          fill={colorSecondary}
          d="M25.845 44.687H45.46V28.885H25.845zM54.418 44.687h19.615V28.885H54.418z"
        />
        <path fill="#D7F1FF" d="M11 74.335h77.791V59.901H11z" />
      </g>
    </>
  );
};
export default bed;
