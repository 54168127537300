import axios from 'axios';
import { getMicroServiceUrl } from '../helpers/helpers';

export const getAvailableRewards = () => ({
  type: 'GET_AVAILABLE_REWARDS',
  payload: axios.get(
    `${getMicroServiceUrl('business-user')}campaign/availableRewards`
  ),
});

export const optIn = (campaignId) => ({
  type: 'OPT_IN_REWARDS',
  payload: axios.put(
    `${getMicroServiceUrl('business-user')}campaign/optin/${campaignId}`
  ),
});

export const optOut = (campaignId) => ({
  type: 'OPT_OUT_REWARDS',
  payload: axios.put(
    `${getMicroServiceUrl('business-user')}campaign/optout/${campaignId}`
  ),
});
