import React from 'react';
import Styled from './BackButton.style';
import { useNavigate } from 'react-router-dom';

export const BackButton: React.FC = () => {
  const navigate = useNavigate();

  return <Styled.Btn onClick={() => navigate(-1)}>Back</Styled.Btn>;
};

export default BackButton;
