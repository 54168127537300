import { ChallengesReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

const initialState: ChallengesReducer = {
  status: {
    loading: false,
    error: false,
    done: false,
  },
  data: null,
};

const usageReducer = createReducer(initialState, {
  GET_CHALLENGES_CATEGORY_PENDING: (state) => {
    state.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  GET_CHALLENGES_CATEGORY_FULFILLED: (state, action) => {
    state.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.data = action.payload.data;
  },

  GET_CHALLENGES_CATEGORY_REJECTED: (state, action) => {
    state.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.data = action.payload.data.errors[0];
  },
});

export default usageReducer;
