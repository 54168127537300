import React from 'react';
import * as _ from 'lodash';
import Styled from './Landing.style';
import { Formik } from 'formik';
import { useTranslation, Trans } from 'react-i18next';
import { useResponsivity } from '../../helpers/helpers';
import {
  Wrapper,
  Header,
  Typography,
  Calculator,
  Button,
  Input,
  Checkbox,
  Notifications,
} from '../../components';
import { useNavigate, Link } from 'react-router-dom';
import { Row, Col } from 'react-styled-flexboxgrid';
import * as Yup from 'yup';
import Reaptcha from 'reaptcha';

interface LandingProps {
  register: Function;
  registration: any;
}

export const Landing: React.FC<LandingProps> = (props) => {
  const { register, registration } = props;
  const skipRecaptcha = process.env.REACT_APP_SKIP_RECAPTCHA === 'true';
  const [t] = useTranslation('common');
  const { error, loading, done } = registration.status;
  const isMobile = useResponsivity();
  const RegisterSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Your password should have 8 at least characters')
      .max(50, 'Too long')
      .required('Required'),
    agree_to_terms: Yup.boolean().oneOf([true], 'Required'),
    agree_to_privacy: Yup.boolean().oneOf([true], 'Required'),
    email: Yup.string()
      .email(`${t('portal.general_form_errors.email_not_valid')}`)
      .required(`${t('portal.general_form_errors.required')}`),
    recaptchaResponse: Yup.string().required('reCAPTCHA is required'),
  });
  const isAuth = localStorage.getItem('swsmUser');
  const [hidden, setHidden] = React.useState(false);
  const showVisibility = () => setHidden(!hidden);
  const navigate = useNavigate();
  React.useEffect(() => {
    if (isAuth) {
      navigate('/portal');
    }
  }, []);
  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          email: '',
          password: '',
          company: '',
          agree_to_terms: false,
          agree_to_privacy: false,
          recaptchaResponse: skipRecaptcha ? 'test123' : '',
        }}
        validationSchema={RegisterSchema}
        onSubmit={(values, { setSubmitting }) => {
          register(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <Input
                  id="company"
                  name="company"
                  value={values.company}
                  type="text"
                  placeholder="Company name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Input
                  id="email"
                  name="email"
                  value={values.email}
                  type="text"
                  placeholder="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.email && touched.email ? errors.email : undefined
                  }
                />
              </Col>
              <Col xs={12} sm={12} md={12}>
                <Input
                  id="password"
                  name="password"
                  value={values.password}
                  icon={hidden ? 'visible' : 'hidden'}
                  type={hidden ? 'text' : 'password'}
                  iconClick={showVisibility}
                  placeholder="Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.password && touched.password
                      ? errors.password
                      : undefined
                  }
                />
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  fontSize: '15px',
                  marginTop: '-10px',
                  marginBottom: '16px',
                }}
              >
                <Trans i18nKey="portal.homepage.customer_sign_in" />
                <span style={{ marginLeft: '8px' }}>
                  <Link
                    to="/login"
                    style={{ color: '#0b2641', fontWeight: 600 }}
                  >
                    {t('portal.homepage.customer_sign_in_here')}
                  </Link>
                </span>
              </Col>
              <Col md={12} xs={12} sm={12}>
                <Checkbox
                  name="agree_to_terms"
                  id="agree_to_terms"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.agree_to_terms}
                  checked={values.agree_to_terms}
                >
                  I agree to be bound by the{' '}
                  <Link
                    to="/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Use
                  </Link>
                </Checkbox>
              </Col>
              {errors.agree_to_terms && touched.agree_to_terms && (
                <Styled.ErrorInline>{errors.agree_to_terms}</Styled.ErrorInline>
              )}
              <Col
                md={12}
                xs={12}
                sm={12}
                style={{
                  marginBottom: errors.agree_to_privacy ? '16px' : '0px',
                }}
              >
                <Checkbox
                  name="agree_to_privacy"
                  id="agree_to_privacy"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.agree_to_privacy}
                  checked={values.agree_to_privacy}
                >
                  I have read the{' '}
                  <Link to="/privacy" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                  </Link>
                </Checkbox>
              </Col>
              {errors.agree_to_privacy && touched.agree_to_privacy && (
                <Styled.ErrorInline style={{ marginBottom: '16px' }}>
                  {errors.agree_to_privacy}
                </Styled.ErrorInline>
              )}
              {skipRecaptcha ? null : (
                <Col xs={12} sm={12} md={12} style={{ margin: '16px 0px' }}>
                  <Reaptcha
                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA || ''}
                    onVerify={(response) =>
                      setFieldValue('recaptchaResponse', response)
                    }
                    onExpire={() => setFieldValue('recaptchaResponse', '')}
                  />
                  {errors.recaptchaResponse && touched.recaptchaResponse && (
                    <Styled.ErrorInline>
                      {errors.recaptchaResponse}
                    </Styled.ErrorInline>
                  )}
                </Col>
              )}

              <Col
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: isMobile ? '16px' : '0px',
                }}
              >
                <Button
                  isLoading={loading}
                  disabled={isSubmitting}
                  type="submit"
                >
                  Register
                </Button>
              </Col>
              {error ? (
                <Col xs={12} sm={12} md={12}>
                  <Notifications type="ERROR">{error}</Notifications>
                </Col>
              ) : null}
            </Row>
          </form>
        )}
      </Formik>
    );
  };

  return (
    <Styled.Wrap>
      <Header />
      <Wrapper>
        <Styled.Content>
          <Row style={{ marginBottom: '32px' }}>
            <Col md={7} sm={12} xs={12}>
              <Typography size="1" text={t('portal.homepage.header')} />
              <Typography
                size="4"
                style={{ fontWeight: 400, marginBottom: '16px' }}
                text="Find out how and where your staff use water in the workplace, and discover ways to reduce your business water use – and utility bills."
              />
            </Col>
          </Row>
          <Row>
            <Col md={5} sm={12} xs={12}>
              <Typography
                size="2"
                text="Register account"
                style={{ marginBottom: '32px' }}
              />
              {renderForm()}
            </Col>

            <Col
              md={6}
              mdOffset={1}
              sm={12}
              xs={12}
              style={{ marginTop: isMobile ? '32px' : '0px' }}
            >
              <Calculator />
            </Col>
          </Row>
        </Styled.Content>
      </Wrapper>
    </Styled.Wrap>
  );
};

export default Landing;
