import EditRoom from './EditRoom';
import { connect } from 'react-redux';
import { getQuestions, updateEditKeys } from '../../actions/question';
import { editRoom } from '../../actions/room';
import { clearAnswerKey } from '../../actions/question';
import { AppState } from '../../interfaces';

const mapStateToProps = (state: AppState) => ({
  questions: state.questionReducer,
  listLocation: state.listLocationReducer,
  room: state.roomReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getQuestions: (room) => dispatch(getQuestions(room)),
  editRoom: (id, room, roomName, data) =>
    dispatch(editRoom(id, room, roomName, data)),
  clearAnswerKey: () => dispatch(clearAnswerKey()),
  updateEditKeys: (data) => dispatch(updateEditKeys(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditRoom);
