import * as React from 'react';

const star = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <path
        stroke={colorPrimary}
        fill={colorSecondary}
        d="M75.51 89.967l-4.872-28.405 20.638-20.115L62.755 37.3 50 11.459l-12.755 25.84-28.521 4.148 20.638 20.115-4.872 28.405L50 76.557z"
        fillRule="evenodd"
        strokeOpacity=".2"
      />
    </>
  );
};
export default star;
