import * as React from 'react';

const typeInput = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M88.388 33a4.296 4.296 0 014.29 4.29v26.127a4.296 4.296 0 01-4.29 4.29H12.29A4.296 4.296 0 018 63.417V37.29A4.296 4.296 0 0112.29 33h76.098zm0 2H12.29A2.292 2.292 0 0010 37.29v26.127a2.292 2.292 0 002.29 2.29h76.098a2.293 2.293 0 002.29-2.29V37.29a2.293 2.293 0 00-2.29-2.29z"
          fill={colorPrimary}
        />
        <path
          d="M16.462 62.775a1 1 0 01-1-1V38.932a1 1 0 112 0v22.843a1 1 0 01-1 1"
          fill={colorSecondary}
        />
        <path
          d="M61.217 43.317v8.499c0 1.069.215 1.855.646 2.355.43.5 1.142.75 2.136.75.962 0 1.66-.251 2.094-.754.434-.504.65-1.294.65-2.371v-8.479h2.934V52.3c0 1.026-.23 1.924-.688 2.697-.458.772-1.123 1.364-1.99 1.776-.867.411-1.892.617-3.076.617-1.785 0-3.172-.458-4.16-1.372-.987-.915-1.481-2.167-1.481-3.756v-8.945h2.935zm-34.357 0V57.2h-2.944V43.317h2.944zm23.74 0c1.684 0 2.964.362 3.841 1.087.876.725 1.314 1.806 1.314 3.243 0 1.494-.466 2.637-1.4 3.428-.934.792-2.262 1.187-3.983 1.187H49.11V57.2h-2.944V43.317H50.6zm-16.533 0l6.03 10.399h.067l-.08-1.629a60.296 60.296 0 01-.061-2.043l-.001-6.727h2.649V57.2H38.93l-6.04-10.502h-.085l.065 1.049c.07 1.204.107 2.122.113 2.755V57.2h-2.628V43.317h3.712zm48.136 0v2.45h-3.77V57.2h-2.944V45.767h-3.77v-2.45h10.484zm-31.755 2.412H49.11v4.121h.968c.905 0 1.583-.179 2.032-.536.45-.359.675-.879.675-1.563 0-.69-.189-1.199-.566-1.528-.377-.33-.966-.494-1.77-.494z"
          fill={colorPrimary}
        />
      </g>
    </>
  );
};
export default typeInput;
