import * as React from 'react';

const officeTemp = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M53.23 11a1 1 0 01.994.884l.007.116v28.863h35.948a1 1 0 01.993.884l.007.116v47.81a1 1 0 01-.884.994l-.116.006H8.999a1 1 0 01-.993-.883L8 89.673V12a1 1 0 01.883-.993L9 11h44.23zM89.18 42.863H54.23v45.81h8.186V60.094a1 1 0 01.883-.993l.117-.007h14.865a1 1 0 01.993.884l.007.116v28.58h9.897v-45.81zM77.28 61.094H64.417v27.58H77.28v-27.58zM52.23 13H10v75.673h42.23V13z"
          fill={colorPrimary}
        />
        <path
          d="M28.52 19.833a1 1 0 01.993.883l.007.117V35.69a1 1 0 01-.883.993l-.117.007H13.662a1 1 0 01-.993-.883l-.007-.117V20.833a1 1 0 01.883-.993l.117-.007H28.52zm-1 2H14.663V34.69H27.52V21.833zM48.911 19.833a1 1 0 01.993.883l.007.117V35.69a1 1 0 01-.883.993l-.117.007H34.053a1 1 0 01-.993-.883l-.007-.117V20.833a1 1 0 01.883-.993l.117-.007h14.858zm-1 2H35.053V34.69h12.858V21.833zM28.34 42.478a1 1 0 01.993.883l.007.117v14.858a1 1 0 01-.883.993l-.117.007H13.482a1 1 0 01-.993-.884l-.007-.116V43.478a1 1 0 01.883-.993l.117-.007H28.34zm-1.001 2H14.482v12.857h12.857V44.478zM48.731 42.478a1 1 0 01.993.883l.007.117v14.858a1 1 0 01-.883.993l-.117.007H33.873a1 1 0 01-.993-.884l-.007-.116V43.478a1 1 0 01.883-.993l.117-.007h14.858zm-1 2H34.873v12.857h12.858V44.478zM28.52 64.833a1 1 0 01.993.883l.007.117V80.69a1 1 0 01-.883.993l-.117.007H13.662a1 1 0 01-.993-.883l-.007-.117V65.833a1 1 0 01.883-.993l.117-.007H28.52zm-1 2H14.663V79.69H27.52V66.833zM48.911 64.833a1 1 0 01.993.883l.007.117V80.69a1 1 0 01-.883.993l-.117.007H34.053a1 1 0 01-.993-.883l-.007-.117V65.833a1 1 0 01.883-.993l.117-.007h14.858zm-1 2H35.053V79.69h12.858V66.833z"
          fill={colorPrimary}
        />
        <path
          fill={colorSecondary}
          d="M14.663 34.69H27.52V21.833H14.663zM34.874 57.336h12.857V44.479H34.874zM14.663 79.69H27.52V66.833H14.663z"
        />
        <path
          d="M71.7 11.001a4.77 4.77 0 013.396 1.403 4.772 4.772 0 011.408 3.141l.006.255v20.991a8.906 8.906 0 014.12 7.519c0 4.919-4.006 8.92-8.93 8.92-4.92 0-8.92-4.001-8.92-8.92a8.932 8.932 0 013.867-7.362l.252-.167V15.8a4.806 4.806 0 014.8-4.799zm0 2a2.803 2.803 0 00-2.796 2.629l-.005.17v4.008h1.917a1 1 0 01.117 1.993l-.117.007H68.9v3.496h1.917a1 1 0 01.117 1.993l-.117.007H68.9v3.338h1.917a1 1 0 01.117 1.993l-.117.007H68.9v4.708a1 1 0 01-.519.877A6.928 6.928 0 0071.7 51.23c3.821 0 6.931-3.104 6.931-6.92a6.904 6.904 0 00-3.599-6.071 1 1 0 01-.514-.757l-.007-.121V15.8c0-.745-.294-1.449-.828-1.982a2.783 2.783 0 00-1.983-.817z"
          fill={colorPrimary}
        />
        <path
          d="M71.7 51.23a6.928 6.928 0 01-3.32-13.003 1 1 0 00.52-.877v-4.708h1.916a1 1 0 100-2H68.9v-3.338h1.917a1 1 0 100-2H68.9v-3.496h1.917a1 1 0 100-2H68.9V15.8a2.802 2.802 0 012.8-2.799c.752 0 1.456.29 1.983.817.534.533.828 1.237.828 1.982v21.561a1 1 0 00.521.878 6.904 6.904 0 013.6 6.071c0 3.816-3.11 6.92-6.932 6.92"
          fill="#D7F1FF"
        />
      </g>
    </>
  );
};
export default officeTemp;
