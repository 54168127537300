import * as React from 'react';

const youtube = (props: any) => (
  <g id="youtube-a" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g id="youtube" transform="translate(0.000000, 1.000000)" fill="#E1F0F8" fillRule="nonzero">
      <path
        d="M81.822,46.42 L19.564,46.42 C9.668,46.42 1.646,54.45 1.646,64.344 L1.646,78.746 C1.646,88.64 9.668,96.668 19.564,96.668 L81.822,96.668 C91.716,96.668 99.74,88.64 99.74,78.746 L99.74,64.344 C99.74,54.45 91.716,46.42 81.822,46.42 Z M32.644,57.632 L26.856,57.632 L26.856,86.392 L21.264,86.392 L21.264,57.632 L15.48,57.632 L15.48,52.74 L32.644,52.74 L32.644,57.632 L32.644,57.632 Z M48.994,86.392 L44.034,86.392 L44.034,83.672 C43.112,84.678 42.162,85.446 41.17,85.964 C40.178,86.504 39.22,86.756 38.288,86.756 C37.136,86.756 36.28,86.39 35.694,85.646 C35.118,84.904 34.824,83.804 34.824,82.318 L34.824,61.564 L39.784,61.564 L39.784,80.61 C39.784,81.198 39.894,81.62 40.092,81.882 C40.306,82.152 40.646,82.276 41.112,82.276 C41.472,82.276 41.936,82.104 42.494,81.756 C43.056,81.404 43.568,80.964 44.034,80.42 L44.034,61.562 L48.994,61.562 L48.994,86.392 L48.994,86.392 Z M67.002,81.266 C67.002,83.02 66.612,84.368 65.834,85.308 C65.06,86.248 63.928,86.71 62.462,86.71 C61.486,86.71 60.618,86.534 59.864,86.17 C59.106,85.81 58.392,85.256 57.742,84.488 L57.742,86.39 L52.722,86.39 L52.722,52.74 L57.742,52.74 L57.742,63.578 C58.414,62.828 59.124,62.246 59.866,61.856 C60.624,61.458 61.386,61.266 62.158,61.266 C63.728,61.266 64.924,61.79 65.752,62.848 C66.584,63.906 66.998,65.456 66.998,67.488 L66.998,81.266 L67.002,81.266 Z M84.208,74.576 L74.716,74.576 L74.716,79.254 C74.716,80.558 74.876,81.466 75.2,81.972 C75.532,82.48 76.09,82.728 76.884,82.728 C77.708,82.728 78.278,82.514 78.61,82.086 C78.93,81.654 79.102,80.714 79.102,79.254 L79.102,78.124 L84.208,78.124 L84.208,79.398 C84.208,81.942 83.59,83.86 82.336,85.154 C81.102,86.436 79.242,87.072 76.77,87.072 C74.544,87.072 72.79,86.394 71.516,85.03 C70.246,83.674 69.598,81.792 69.598,79.4 L69.598,68.248 C69.598,66.102 70.306,64.336 71.712,62.982 C73.114,61.628 74.92,60.952 77.142,60.952 C79.416,60.952 81.162,61.578 82.38,62.834 C83.598,64.092 84.208,65.892 84.208,68.246 L84.208,74.576 L84.208,74.576 Z"
        id="Shape"
      />
      <path
        d="M76.932,65.292 C76.14,65.292 75.576,65.514 75.232,65.978 C74.88,66.414 74.716,67.16 74.716,68.202 L74.716,70.712 L79.102,70.712 L79.102,68.202 C79.102,67.16 78.926,66.412 78.578,65.978 C78.244,65.516 77.686,65.292 76.932,65.292 Z"
        id="Path"
      />
      <path
        d="M59.818,65.208 C59.466,65.208 59.11,65.286 58.768,65.448 C58.42,65.606 58.08,65.868 57.744,66.208 L57.744,81.674 C58.142,82.074 58.536,82.382 58.92,82.558 C59.302,82.728 59.698,82.824 60.12,82.824 C60.73,82.824 61.174,82.648 61.46,82.292 C61.742,81.94 61.886,81.378 61.886,80.584 L61.886,67.762 C61.886,66.918 61.718,66.286 61.366,65.852 C61.002,65.424 60.486,65.208 59.818,65.208 Z"
        id="Path"
      />
      <polygon
        id="Path"
        points="27.716 37.796 34.004 37.796 34.004 22.416 41.326 0.72 34.934 0.72 31.042 15.54 30.646 15.54 26.56 0.72 20.226 0.72 27.716 23.108"
      />
      <path
        d="M49.846,38.486 C52.386,38.486 54.378,37.82 55.83,36.488 C57.272,35.15 57.994,33.324 57.994,30.984 L57.994,16.856 C57.994,14.774 57.256,13.06 55.786,11.738 C54.306,10.42 52.41,9.756 50.098,9.756 C47.558,9.756 45.536,10.384 44.032,11.638 C42.532,12.892 41.778,14.574 41.778,16.7 L41.778,30.876 C41.778,33.202 42.512,35.042 43.978,36.422 C45.444,37.796 47.404,38.486 49.846,38.486 Z M47.53,16.492 C47.53,15.898 47.744,15.418 48.162,15.042 C48.596,14.674 49.146,14.496 49.83,14.496 C50.568,14.496 51.166,14.674 51.622,15.042 C52.076,15.418 52.302,15.898 52.302,16.492 L52.302,31.394 C52.302,32.124 52.08,32.704 51.63,33.122 C51.184,33.542 50.584,33.748 49.83,33.748 C49.092,33.748 48.522,33.548 48.122,33.128 C47.728,32.718 47.528,32.142 47.528,31.392 L47.528,16.492 L47.53,16.492 Z"
        id="Shape"
      />
      <path
        d="M66.428,38.196 C67.478,38.196 68.556,37.896 69.666,37.32 C70.788,36.736 71.862,35.882 72.884,34.768 L72.884,37.798 L78.47,37.798 L78.47,10.448 L72.884,10.448 L72.884,31.208 C72.364,31.802 71.786,32.29 71.166,32.678 C70.534,33.062 70.014,33.254 69.604,33.254 C69.08,33.254 68.694,33.11 68.468,32.812 C68.238,32.526 68.108,32.058 68.108,31.416 L68.108,10.448 L62.53,10.448 L62.53,33.306 C62.53,34.936 62.858,36.154 63.506,36.962 C64.166,37.782 65.13,38.196 66.428,38.196 Z"
        id="Path"
      />
    </g>
  </g>
);
export default youtube;
