import * as React from 'react';

const kettle = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M46.277 12a5.251 5.251 0 015.245 5.245 5.223 5.223 0 01-1.494 3.654c6.516.984 12.448 5.24 17.163 11.734l.3.418h12.05c4.859 0 8.827 3.882 8.967 8.707l.004.264v18.387a4.642 4.642 0 01-4.636 4.637 4.642 4.642 0 01-4.631-4.425l-.005-.212V45.134a2.64 2.64 0 00-2.47-2.632l-.167-.005h-3.91c3.393 8.01 5.382 17.685 5.382 28.098 0 6.325-.72 12.49-2.14 18.322-.102.41-.447.71-.859.756l-.12.007-55.767-.363a1 1 0 01-.966-.768c-1.362-5.722-2.054-11.763-2.054-17.954 0-7.568 1.053-14.743 2.93-21.175l.228-.768-7.192-13.86a1.185 1.185 0 01.04-1.17 1.18 1.18 0 01.88-.564l.14-.007h13.56c4.428-6.257 9.965-10.513 16.07-11.887-1.09-.962-1.794-2.353-1.794-3.92A5.251 5.251 0 0146.277 12zM66.52 35.05H27.725c-5.864 8.776-9.556 21.46-9.556 35.545 0 3.574.242 7.094.711 10.53l.148 1.028h31.42a1 1 0 01.117 1.993l-.117.007h-31.09c.127.71.261 1.417.406 2.12l.227 1.05 54.182.352c.202-.871.384-1.75.552-2.635l.164-.887H61.8a1 1 0 01-.117-1.994l.117-.006h13.417c.566-3.76.858-7.625.858-11.558 0-14.085-3.69-26.77-9.555-35.544zm-7.882 47.102a1 1 0 01.117 1.994l-.117.006h-2.443a1 1 0 01-.117-1.993l.117-.007h2.443zm20.903-47.101h-10.71a50.013 50.013 0 012.572 4.625l.394.82h4.806a4.643 4.643 0 014.632 4.426l.005.212v15.275a2.64 2.64 0 002.636 2.637 2.64 2.64 0 002.631-2.47l.005-.167V42.022a6.979 6.979 0 00-6.97-6.971zm-54.127 0H14.522l5.681 10.946c1.422-4.03 3.177-7.714 5.211-10.946zm21.708-12.38c-6.651 0-12.78 3.743-17.675 10.008l-.287.372h35.924c-4.94-6.49-11.179-10.38-17.962-10.38zM46.277 14a3.25 3.25 0 00-3.246 3.245 3.25 3.25 0 003.246 3.246 3.25 3.25 0 003.245-3.246A3.25 3.25 0 0046.277 14z"
          fill={colorPrimary}
        />
        <path
          d="M46.277 39.617c4.351 0 7.906 3.472 8.04 7.792l.003.25V77.7a1 1 0 01-.883.993l-.117.007H39.233a1 1 0 01-.993-.884l-.007-.116V47.659c0-4.435 3.608-8.042 8.044-8.042zm0 2a6.05 6.05 0 00-6.04 5.816l-.004.226V76.7H52.32v-4.022h-5.197a1 1 0 01-.117-1.994l.117-.006h5.197v-5.633h-5.197a1 1 0 01-.117-1.994l.117-.006h5.197v-5.762h-5.197a1 1 0 01-.117-1.994l.117-.006h5.197v-5.762h-5.197a1 1 0 01-.117-1.994l.117-.006h5.19c-.074-3.268-2.751-5.905-6.036-5.905z"
          fill={colorPrimary}
        />
        <path
          d="M40.233 76.7V47.66a6.05 6.05 0 016.044-6.042c3.285 0 5.962 2.637 6.036 5.905h-5.19a1 1 0 100 2h5.197v5.762h-5.197a1 1 0 100 2h5.197v5.762h-5.197a1 1 0 100 2h5.197v5.633h-5.197a1 1 0 100 2h5.197V76.7H40.233z"
          fill={colorSecondary}
        />
        <path
          d="M47.123 22.672c6.782 0 13.02 3.89 17.962 10.378H29.16c4.94-6.488 11.18-10.378 17.962-10.378"
          fill="#D7F1FF"
        />
      </g>
    </>
  );
};
export default kettle;
