import styled from 'styled-components';
import { colors } from '../../assets/variables';
const Wrap = styled.div`
  background-color: #e9eef5;
  height: 72px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;
const Litres = styled.div`
  display: flex;
  align-items: center;
  width: 130px;
  cursor: default;
`;
export default {
  Wrap,
  Litres,
};
