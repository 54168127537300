import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Shared from '../../assets/SharedStyles.style';
import Styled from './Preferences.style';
import { Button, Typography, Input, Popup } from '../../components/index';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import * as _ from 'lodash';
import { useResponsivity } from '../../helpers/helpers';
interface FormUserDetailsProps {
  deleteAccount: Function;
  logOut: Function;
  setPreferences: Function;
  data: any;
  doneMessage: boolean;
  erroMessage: string;
}

const FormUserDetails: React.FC<FormUserDetailsProps> = (props) => {
  const {
    deleteAccount,
    logOut,
    data,
    setPreferences,
    doneMessage,
    erroMessage,
  } = props;

  const [t] = useTranslation('common');
  const [isOpen, setOpen] = React.useState(false);
  const [isClicked, setClicked] = React.useState(false);
  const isMobile = useResponsivity();
  if (isClicked) {
    setTimeout(() => {
      setClicked(false);
    }, 4000);
  }
  const navigate = useNavigate();
  const [visibleOldPassword, setVisibleOldPassword] = React.useState(false);
  const [visibleNewPassword, setVisibleNewPassword] = React.useState(false);
  const [visibleNewPassword2, setVisibleNewPassword2] = React.useState(false);
  const communicationPref = t('portal.my_profile.header_nav_password_reset');

  const emailData = _.get(data, 'data.email');
  const organisationData = _.get(data, 'data.organisation');

  const UserDetailsSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, `${t('portal.general_form_errors.too_short')}`)
      .max(50, `${t('portal.general_form_errors.too_long')}`),
    password2: Yup.string().when('password', {
      is: (password) => password !== undefined,
      then: Yup.string()
        .required(`${t('portal.general_form_errors.password_repeat')}`)
        .oneOf(
          [Yup.ref('password'), null],
          `${t('portal.general_form_errors.password_match')}`
        ),
      otherwise: Yup.string().oneOf(
        [Yup.ref('password'), null],
        `${t('portal.general_form_errors.password_match')}`
      ),
    }),
    organisation: Yup.string(),
    email: Yup.string()
      .email(`${t('portal.general_form_errors.email_not_valid')}`)
      .required(`${t('portal.general_form_errors.required')}`),
  });

  const text_translation = {
    my_profile: {
      communication_prefences_button_text: t(
        'portal.my_profile.communication_prefences_buttontext'
      ),
      communication_prefences_success: t(
        'portal.my_profile.communication_prefences_success'
      ),
      communication_prefences_delete_title: t(
        'portal.my_profile.communication_prefences_delete_title'
      ),
      communication_prefences_delete_description: t(
        'portal.my_profile.communication_prefences_delete_description'
      ),
      communication_prefences_delete_yes: t(
        'portal.my_profile.communication_prefences_delete_yes'
      ),
      communication_prefences_delete_no: t(
        'portal.my_profile.communication_prefences_delete_no'
      ),
      communication_prefences_delete_button: t(
        'portal.my_profile.communication_prefences_deletebutton'
      ),
      communication_prefences_email_title: t(
        'portal.my_profile.communication_prefences_email_title'
      ),
      communication_prefences_messaging: t(
        'portal.my_profile.communication_prefences_messaging'
      ),
      communication_prefences_title: t(
        'portal.my_profile.communication_prefences_title'
      ),
      communication_prefences_update_options: t(
        'portal.my_profile.communication_prefences_update_options'
      ),
      page_title: t('portal.my_profile.page_title'),
      set_langugae: 'set_language',
      contact_us_checkbox: t('portal.my_profile.contact_us_checkbox'),
      preferences_form_elements: {
        first_name: t('portal.my_profile.preferences_form_elements.first_name'),
        organisation: t(
          'portal.my_profile.preferences_form_elements.organisation'
        ),
        last_name: t('portal.my_profile.preferences_form_elements.last_name'),
        email: t('portal.my_profile.preferences_form_elements.email'),
        mobile_number: t(
          'portal.my_profile.preferences_form_elements.mobile_number'
        ),
      },
    },
  };
  if (!data) {
    return null;
  }
  return (
    <Formik
      initialValues={{
        organisation: organisationData || '',
        email: emailData || '',
        set_langugae: i18next.language,
        password: '',
        password2: '',
        passwordOld: '',
      }}
      validationSchema={UserDetailsSchema}
      onSubmit={(values, { setSubmitting }) => {
        setPreferences(
          {
            old_password: values.passwordOld,
            new_password: values.password,
            email: values.email,
            company: values.organisation,
          },
          () => {
            setSubmitting(false);
          }
        );
        setClicked(true);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={6} sm={12} xs={12}>
              <Row>
                <Col md={12} sm={12} xs={12} style={{ marginBottom: '16px' }}>
                  <Typography
                    text={text_translation.my_profile.page_title}
                    size="3"
                  />
                  <Styled.Line />
                </Col>
                <Col md={12} sm={12} xs={12} style={{ marginBottom: '8px' }}>
                  <Shared.FormGroup>
                    <Input
                      type="text"
                      name="organisation"
                      icon="pen"
                      placeholder={
                        text_translation.my_profile.preferences_form_elements
                          .organisation
                      }
                      id="organisation"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.organisation}
                      error={
                        errors.organisation && touched.organisation
                          ? errors.organisation
                          : undefined
                      }
                    />
                  </Shared.FormGroup>
                </Col>
                <Col md={12} sm={12} xs={12} style={{ marginBottom: '8px' }}>
                  <Shared.FormGroup>
                    <Input
                      placeholder={
                        text_translation.my_profile.preferences_form_elements
                          .email
                      }
                      type="email"
                      id="email"
                      icon="pen"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={
                        errors.email && touched.email ? errors.email : undefined
                      }
                    />
                  </Shared.FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md={6} sm={12} xs={12}>
              <Row>
                <Col md={12} sm={12} xs={12} style={{ marginBottom: '16px' }}>
                  <Typography
                    style={{ fontSize: '26px' }}
                    text={communicationPref}
                    size="3"
                  />
                  <Styled.Line />
                </Col>
                <Col sm={12} md={12} xs={12} style={{ marginBottom: '8px' }}>
                  <Shared.FormGroup>
                    <Input
                      type={visibleOldPassword ? 'text' : 'password'}
                      placeholder={t(
                        'portal.my_profile.password_reset_form_elements.old_password'
                      )}
                      name="passwordOld"
                      id="passwordOld"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.passwordOld}
                      autoComplete="off"
                      icon={visibleOldPassword ? 'visible' : 'hidden'}
                      iconClick={() => {
                        setVisibleOldPassword(!visibleOldPassword);
                      }}
                      error={
                        errors.passwordOld && touched.passwordOld
                          ? errors.passwordOld
                          : undefined
                      }
                    />
                  </Shared.FormGroup>
                </Col>
                <Col md={6} xs={12} sm={12}>
                  <Shared.FormGroup>
                    <Input
                      type={visibleNewPassword ? 'text' : 'password'}
                      name="password"
                      id="password"
                      placeholder={t(
                        'portal.my_profile.password_reset_form_elements.new_password'
                      )}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      autoComplete="off"
                      icon={visibleNewPassword ? 'visible' : 'hidden'}
                      iconClick={() => {
                        setVisibleNewPassword(!visibleNewPassword);
                      }}
                      error={
                        errors.password && touched.password
                          ? errors.password
                          : undefined
                      }
                    />
                  </Shared.FormGroup>
                </Col>
                <Col md={6} xs={12} sm={12}>
                  <Shared.FormGroup>
                    <Input
                      placeholder={t(
                        'portal.my_profile.password_reset_form_elements.new_password_repeat'
                      )}
                      type={visibleNewPassword2 ? 'text' : 'password'}
                      name="password2"
                      id="password2"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password2}
                      autoComplete="off"
                      icon={visibleNewPassword2 ? 'visible' : 'hidden'}
                      iconClick={() => {
                        setVisibleNewPassword2(!visibleNewPassword2);
                      }}
                      error={
                        errors.password2 && touched.password2
                          ? errors.password2
                          : undefined
                      }
                    />
                  </Shared.FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={12} sm={12} xs={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '30px',
                  flexDirection: isMobile ? 'column' : 'row',
                }}
              >
                <Styled.Delete
                  style={{
                    marginRight: isMobile ? '0px' : '16px',
                    marginBottom: isMobile ? '16px' : '0px',
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setOpen(!isOpen);
                  }}
                >
                  {
                    text_translation.my_profile
                      .communication_prefences_delete_button
                  }
                </Styled.Delete>
                <Popup
                  open={isOpen}
                  isDesktop
                  handleClick={() => setOpen(!isOpen)}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      size="4"
                      text={
                        text_translation.my_profile
                          .communication_prefences_delete_title
                      }
                    />
                    <Typography
                      size="p"
                      text={
                        text_translation.my_profile
                          .communication_prefences_delete_description
                      }
                    />
                    <div style={{ display: 'flex', margin: '16px 0' }}>
                      <Styled.Delete
                        style={{ minWidth: '100px' }}
                        className="gwf-delete-yes"
                        onClick={(e) => {
                          e.preventDefault();
                          deleteAccount();
                          logOut();
                          navigate('/login');
                        }}
                      >
                        {
                          text_translation.my_profile
                            .communication_prefences_delete_yes
                        }
                      </Styled.Delete>
                      <Button
                        style={{ minWidth: '100px' }}
                        onClick={() => setOpen(!isOpen)}
                      >
                        {
                          text_translation.my_profile
                            .communication_prefences_delete_no
                        }
                      </Button>
                    </div>
                  </div>
                </Popup>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  style={{ textAlign: 'center' }}
                >
                  {
                    text_translation.my_profile
                      .communication_prefences_button_text
                  }
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            {doneMessage && isClicked ? (
              <Col md={12} xs={12} sm={12} style={{ marginTop: '16px' }}>
                <div
                  style={{
                    background: '#56BB00',
                    padding: '8px 16px',
                    borderRadius: '8px',
                    color: 'white',
                  }}
                >
                  {text_translation.my_profile.communication_prefences_success}
                </div>
              </Col>
            ) : null}
            {erroMessage && isClicked ? (
              <Col md={12} xs={12} sm={12} style={{ marginTop: '16px' }}>
                <div
                  style={{
                    background: 'red',
                    padding: '8px 16px',
                    borderRadius: '8px',
                    color: 'white',
                  }}
                >
                  {t(`${erroMessage}`)}
                </div>
              </Col>
            ) : null}
          </Row>
        </form>
      )}
    </Formik>
  );
};

export default FormUserDetails;
