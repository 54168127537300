import styled from 'styled-components';
import { colors } from '../../assets/variables';
const Card = styled.div`
  border: 4px solid ${colors.tertiary};
  border-radius: 10px;
  padding: 32px;
`;
const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    button {
      margin-bottom: 16px;
    }
  }
`;

export default { Card, ButtonWrap };
