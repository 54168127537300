import styled, { css } from 'styled-components';
import { colors } from '../../assets/variables';
const Wrap = styled.ol`
  display: flex;
  width: auto;
  justify-content: flex-end;
  border-radius: 9999px;
  padding-inline-start: 0px;
  background-color: #e9eef5;
  color: #475569;
  ${(props) => {
    switch (props.theme) {
      case 'darkTheme':
        return css`
          li {
            &.active {
              background-color: ${colors.primary};
              &:hover {
                background-color: ${colors.primary};
              }
            }
          }
        `;
      case 'greenTheme':
        return css`
          li {
            &.active {
              background-color: ${colors.green};
              &:hover {
                background-color: ${colors.green};
              }
            }
          }
        `;
      default:
        return css`
          li {
            &.active {
              background-color: #4f46e5;
              &:hover {
                background-color: #4f46e5;
              }
            }
          }
        `;
    }
  }};

  li {
    width: 2rem;
    height: 2rem;
    font-size: 14px;
    border-radius: 9999px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #cbd5e1;
      transition-duration: 0.15s;
      transition-property: color, background-color, border-color,
        text-decoration-color, fill, stroke;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &.disabled {
      cursor: not-allowed;
      svg {
        stroke: #bcc0c5;
      }
    }
    &.active {
      color: #fff;
    }
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;

export default { Wrap };
