import React from 'react';
import { Wrap, Task, EasyWin, Loader } from '../../components/index';
import * as _ from 'lodash';
import { useResponsivity } from '../../helpers/helpers';
import { Col } from 'react-styled-flexboxgrid';
import { useTranslation } from 'react-i18next';
import store from 'store2';

interface ToiletsProps {
  challenges: any;
  listLocation: any;
  getChallengeCategory: Function;
  completeTask: any;
  completeChallenge: Function;
  generalData: Function;
}

const Toilets: React.FC<ToiletsProps> = (props) => {
  const {
    challenges,
    listLocation,
    getChallengeCategory,
    completeTask,
    completeChallenge,
    generalData,
  } = props;
  const getRoomId = store('property');

  const loadingChallenges = _.get(challenges, 'status.loading');
  const data = _.get(challenges, 'data.challenges');
  const isMobile = useResponsivity();
  const [t] = useTranslation('common');
  const { error, loading, done } = _.get(completeTask, 'status');
  React.useEffect(() => {
    getChallengeCategory(getRoomId, 'toilets');
  }, [getRoomId]);

  const pageArray = [
    {
      id: 266,
      title: 'Dual flush toilets',
      poster:
        'https://cdn.getwaterfit.co.uk/static/files/images/gwf-business/posters/koala.png',
      emailCopy:
        'Toilets can use as much as 30% more water than necessary. Don’t be a Kenneth Koala and use the wrong flush button.',
      easyWins: [
        {
          title: 'Introduction',
          theme: 'intro',
          size: 'full',
          text: 'Dual flush toilets can be huge water wasters, with staff pressing the wrong button and leaks that go undetected.',
        },
        {
          title: 'Easy Win',
          theme: 'red',
          icon: null,
          size: 'half',
          buttonText: 'Download now',
          buttonPath:
            'https://cdn.getwaterfit.co.uk/static/files/images/gwf-business/posters/koala.pdf',
          text: 'Print off the poster and place within each toilet cubicle to remind staff to flush correctly. This poster will reduce water use in your business.',
        },
        {
          title: 'Easy Win',
          theme: 'red',
          size: 'half',
          icon: null,
          buttonText: 'See video',
          buttonPath: '/video-library',
          text: 'Even small leaks waste a surprising amount of water so it’s important to check toilets regularly. To look for minor leaks, place a LeakyLoo or sheet of toilet paper at the back of the pan. Any water leaking from the cistern will make the strip or tissue wet.',
        },
        {
          title: 'Easy Win Pro',
          theme: 'blue',
          size: 'full',
          icon: null,

          text: 'Toilets with an evidently constant flow of water into the pan could be wasting as much as 400 litres every day, even at weekends when staff are at home – which will have a huge impact on your water bill.',
        },
      ],
    },
  ];

  const getChallenges = () => {
    return _.map(data, (el, index) => {
      const title = _.get(el, 'title');
      const description = _.get(el, 'description');
      const buttonText = _.get(el, 'buttonText');
      const type = _.get(el, 'internalType');
      const buttonPath = _.get(el, 'buttonPath');
      const energySaving = _.get(el, 'energy_saving');
      const carbonSaving = _.get(el, 'carbon_saving');
      const waterSaving = _.get(el, 'water_saving');
      return (
        <Col xs={12} sm={12} md={6} key={index}>
          <Task
            titleWidth="230px"
            isDesktop={!isMobile}
            icon={_.get(el, 'icon')}
            id={_.get(el, 'id')}
            title={`${t(title)}`}
            description={`${t(description)}`}
            buttonText={`${t(buttonText)}`}
            buttonPath={buttonPath}
            popup={type === 'direct-redirect' ? false : true}
            renderComplete={() => {
              completeChallenge(getRoomId, _.get(el, 'id'));
              setTimeout(() => {
                getChallengeCategory(getRoomId, 'toilets');
                generalData();
              }, 1000);
            }}
            isLoading={loading}
            completed={done}
            energySaving={energySaving}
            carbonSaving={carbonSaving}
            waterSaving={waterSaving}
          />
        </Col>
      );
    });
  };
  if (loadingChallenges) {
    return (
      <Wrap>
        <Loader />
      </Wrap>
    );
  }
  return (
    <Wrap>
      <EasyWin data={pageArray}>{getChallenges()}</EasyWin>
    </Wrap>
  );
};

export default Toilets;
