import axios from 'axios';
import { getMicroServiceUrl } from '../helpers/helpers';

export const deleteAccount = () => ({
  type: 'DELETE_ACCOUNT',
  payload: axios.delete(`${getMicroServiceUrl('business-user')}user/delete`),
});

export const setPreferences = (data, cb) => {
  return (dispatch) => {
    const response = dispatch({
      type: 'SET_PREFERENCES',
      payload: axios.post(
        `${getMicroServiceUrl('business-user')}preferences`,
        data
      ),
    });
    response
      .then(() => {
        cb();
      })
      .catch((err) => {
        cb();
      });
  };
};

export const getPreferences = () => ({
  type: 'GET_PREFERENCES',
  payload: axios.get(`${getMicroServiceUrl('business-user')}user`),
});
