import * as React from 'react';

const urinal = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M64.167 67.133c0 3.385-2.543 6.302-5.915 6.784a52.524 52.524 0 01-3.137.342c-.221-2.49-2.296-4.458-4.844-4.458-2.547 0-4.622 1.967-4.843 4.458a52.558 52.558 0 01-3.138-.342c-3.371-.482-5.914-3.399-5.914-6.784v-29.59a2.754 2.754 0 012.751-2.75h22.289a2.754 2.754 0 012.751 2.75v29.59z"
          fill={colorSecondary}
        />
        <path
          d="M52.743 8c.565 0 1.03.426 1.089.975l.006.12v5.726h.332a1 1 0 01.993.884l.007.116v7.085a1 1 0 01-.883.994l-.117.006h-.427v3.817h12.046a5.76 5.76 0 015.75 5.534l.004.22v35.216c0 8.366-6.72 15.19-15.045 15.36l-.317.002h-2.426v7.665a1 1 0 01-.883.994l-.117.006h-4.967a1 1 0 01-.993-.883l-.007-.117v-7.665h-2.427c-8.364 0-15.188-6.72-15.358-15.045L29 68.693V33.477a5.76 5.76 0 015.534-5.75l.22-.004H46.8v-3.817h-.428a1 1 0 01-.993-.883l-.007-.117v-.432h-7.553a1 1 0 01-.993-.883l-.007-.117V18.06a1 1 0 01.883-.993l.117-.007h7.553v-1.238a1 1 0 01.883-.993l.117-.007h.333V9.094c0-.564.427-1.028.976-1.087L47.8 8h4.943zm-.988 76.055h-2.967v6.665h2.967v-6.665zm14.034-54.332H34.754a3.759 3.759 0 00-3.749 3.555l-.005.2v35.215c0 7.265 5.826 13.193 13.05 13.359l.311.003h11.82c7.264 0 13.193-5.826 13.358-13.051l.004-.31V33.476a3.758 3.758 0 00-3.754-3.754zm-4.373 2.966a4.757 4.757 0 014.746 4.534l.005.217v29.59c0 4.374-3.281 8.14-7.632 8.763a58.48 58.48 0 01-8.264.57 58.48 58.48 0 01-8.264-.57c-4.264-.61-7.5-4.24-7.627-8.502l-.004-.261V37.44a4.757 4.757 0 014.534-4.746l.217-.005h22.289zm0 2H39.127a2.754 2.754 0 00-2.746 2.583l-.005.168v29.59c0 3.385 2.543 6.3 5.914 6.784 1.022.146 2.074.255 3.138.342.221-2.491 2.296-4.46 4.843-4.46 2.548 0 4.623 1.969 4.844 4.46a53.363 53.363 0 003.137-.342c3.292-.472 5.793-3.262 5.91-6.543l.005-.241V37.44a2.754 2.754 0 00-2.751-2.751zM50.271 71.697a2.892 2.892 0 00-2.816 2.251l-.033.17h5.699a2.892 2.892 0 00-2.85-2.421zm1.472-47.79H48.8v3.816h2.943v-3.817zm1.427-7.078c-.087.038-.18.064-.278.077l-.149.01H47.8c-.101 0-.2-.014-.292-.04l-.136-.047v5.077h5.798V16.83zm-7.899 2.23H38.82v1.415h6.451V19.06zm6.378-8.87h-2.754v4.538h2.754v-4.539z"
          fill={colorPrimary}
        />
      </g>
    </>
  );
};
export default urinal;
