import * as React from 'react';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import Styled from './LitresNav.style';
import { Icon, Typography } from '../index';
import * as _ from 'lodash';
import { Tooltip } from 'react-tooltip';
import { useLocation } from 'react-router-dom';
interface LitresNavProps {
  children?: any;
  data: any;
}

export const LitresNav: React.FC<LitresNavProps> = (props) => {
  const { children, data } = props;
  const location = useLocation();

  const litresSaved = _.get(data, 'totalWaterSaved');

  const text = `${litresSaved} litres`;
  return (
    <Styled.Wrap>
      <Grid>
        <Row style={{ display: 'flex', alignItems: 'center' }}>
          {location.pathname === '/myshop' ||
          location.pathname.includes('/myshop') ? null : (
            <Col sm={12} xs={12} md={3}>
              <Styled.Litres
                data-tooltip-id="nav-tooltip"
                data-tooltip-content="This is how much your business is saving each month. Figures increase with each Easy win action completed."
                data-tooltip-place="top"
              >
                <Icon icon="droplet" size={36} />
                <Typography
                  size="p"
                  style={{ marginBottom: '0px', marginLeft: '6px' }}
                  text={text}
                />
              </Styled.Litres>
            </Col>
          )}

          <Tooltip id="nav-tooltip" style={{ maxWidth: '400px' }} />
          {children ? (
            <Col
              sm={4}
              xs={6}
              md={4}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              {children}
            </Col>
          ) : null}
        </Row>
      </Grid>
    </Styled.Wrap>
  );
};

export default LitresNav;
