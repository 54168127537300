import React from 'react';
import * as _ from 'lodash';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  ReferenceLine,
  Cell,
} from 'recharts';
import {
  themeToColor,
  CustomBarNegativeLegend,
} from '../../helpers/customChartElements';
import { ChartData } from '../../interfaces';
import { useTranslation } from 'react-i18next';

import Styled from './ChartBarNegative.style';

interface ChartBarNegativeProps {
  id: number;
  chartData: ChartData;
  title: string;
  theme?: string;
  displayMoney?: string;
}

const ChartBarNegative: React.FC<ChartBarNegativeProps> = (props) => {
  const { chartData, displayMoney, theme = 'c1' } = props;
  const transformedData = chartData.dataSets.use?.data;
  const transformedDataCost = chartData.dataSets.cost?.data;
  const { chartTheme } = chartData;
  // const xLabels = displayMoney === 'cost' ? chartData.dataSets.cost?.xLabel : chartData.dataSets.use?.xLabel;
  const barColors = chartTheme
    ? themeToColor(chartTheme.split(','))
    : themeToColor(theme.split(','));
  const [t] = useTranslation('common');

  const data = displayMoney === 'cost' ? transformedDataCost : transformedData;

  return (
    <div>
      <Styled.Wrap>
        <div
          style={{
            width: '100%',
            height: '40px',
            backgroundColor: '#E7EAEC',
            borderLeft: '5px solid black',
            fontWeight: 700,
            color: 'black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            flexDirection: 'column',
            fontFamily: 'Open Sans',
          }}
        >
          <p
            style={{
              width: '150px',
              fontFamily: 'Open Sans',
              marginBottom: '0px',
              marginTop: '0px',
            }}
          >
            {t('portal.my_use.chartbar_above_average')}
          </p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <div
            style={{
              width: '5px',
              height: '440px',
              backgroundColor: 'black',
              borderLeft: '5px solid black',
              fontWeight: 700,
              color: 'black',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              flexDirection: 'column',
            }}
          ></div>

          <ResponsiveContainer height={440} width="100%">
            <BarChart
              data={data}
              height={400}
              margin={{
                top: 100,
                right: 0,
                bottom: 100,
                left: 0,
              }}
              barCategoryGap={30}
            >
              <ReferenceLine
                y={0}
                ifOverflow="extendDomain"
                stroke="#000"
                strokeWidth={30}
              />

              <Bar
                name="value"
                label={{ position: 'top', offset: 10 }}
                maxBarSize={80}
                barSize={80}
                dataKey="value"
                fill={_.get(barColors, theme)}
                radius={[4, 4, 0, 0]}
                minPointSize={2}
              >
                {data?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={_.get(barColors, index, theme)}
                    className={
                      entry.value < 0 ? 'bar-negative' : 'bar-positive'
                    }
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div
          style={{
            width: '100%',
            height: '40px',
            backgroundColor: '#E7EAEC',
            borderLeft: '5px solid black',
            fontWeight: 700,
            color: 'black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            flexDirection: 'column',
            fontFamily: 'Open Sans',
          }}
        >
          <p
            style={{
              width: '150px',
              fontFamily: 'Open Sans',
              marginBottom: '0px',
              marginTop: '0px',
            }}
          >
            {t('portal.my_use.chartbar_below_average')}
          </p>
        </div>
        {CustomBarNegativeLegend({ data, color: barColors })}
      </Styled.Wrap>
    </div>
  );
};

export default ChartBarNegative;
