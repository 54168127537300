import * as React from 'react';
import Styled from './Input.style';
import { Icon } from '../index';

interface InputProps {
  type: 'text' | 'number' | 'password' | 'email' | 'tel';
  placeholder?: any;
  id: string;
  value: string;
  error?: any;
  [anyProp: string]: any;
  icon?: string;
  iconClick?: Function;
  label?: string;
  disabled?: boolean;
}

export const Input: React.FC<InputProps> = (props) => {
  const { icon, disabled, placeholder, label, error, iconClick } = props;

  return (
    <Styled.Wrap label={label}>
      {label ? <Styled.Label>{label}</Styled.Label> : null}
      <Styled.Input disabled={disabled} placeholder={placeholder} {...props} />
      {icon ? (
        <Styled.IconWrap
          onClick={() => {
            if (iconClick) {
              iconClick();
            }
          }}
        >
          <Icon icon={icon} size={35} />
        </Styled.IconWrap>
      ) : null}
      {error ? <Styled.FieldError>{error}</Styled.FieldError> : null}
    </Styled.Wrap>
  );
};

export default Input;
