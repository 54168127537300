import React from 'react';
import Styled from './404.style';
import { Wrap } from '../../components/index';
const Error404: React.FC<{
  someProp?: any;
}> = (props) => {
  const icon = () => {
    return (
      <Styled.Svg
        width="84px"
        height="108px"
        viewBox="0 0 84 108"
        version="1.1"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Group" transform="translate(0.000000, -16.000000)">
            <text
              id="404"
              fontFamily="OpenSans-Bold, Open Sans"
              fontSize="48"
              fontWeight="bold"
              letterSpacing="0.984"
              fill="#21AAE8"
            >
              <tspan x="0.42634375" y="51">
                404
              </tspan>
            </text>
            <g
              id="broken-link"
              transform="translate(15.500000, 69.000000)"
              fill="#0E2239"
              fillRule="nonzero"
            >
              <path
                d="M51.173,3.801 C46.105,-1.267 37.858,-1.265 32.789,3.801 L23.597,12.993 C22.816,13.774 22.816,15.04 23.597,15.821 C24.378,16.602 25.644,16.602 26.425,15.821 L35.617,6.629 C37.308,4.939 39.568,4.007 41.98,4.007 C44.393,4.007 46.653,4.939 48.344,6.63 C50.035,8.321 50.967,10.581 50.967,12.994 C50.967,15.406 50.035,17.666 48.344,19.357 L36.325,31.379 C32.815,34.887 27.106,34.887 23.596,31.379 C22.815,30.598 21.549,30.598 20.768,31.379 C19.987,32.16 19.987,33.427 20.768,34.207 C23.302,36.741 26.631,38.008 29.96,38.008 C33.289,38.008 36.618,36.741 39.152,34.207 L51.173,22.186 C53.62,19.74 54.968,16.475 54.968,12.994 C54.968,9.512 53.62,6.248 51.173,3.801 Z"
                id="Path"
              ></path>
              <path
                d="M27.132,40.57 L19.354,48.348 C17.663,50.039 15.403,50.971 12.99,50.971 C10.578,50.971 8.317,50.039 6.626,48.348 C3.117,44.839 3.117,39.129 6.626,35.62 L17.94,24.306 C19.631,22.616 21.891,21.684 24.304,21.684 C26.716,21.684 28.976,22.616 30.667,24.306 C31.448,25.087 32.714,25.087 33.495,24.306 C34.276,23.525 34.276,22.259 33.495,21.478 C28.428,16.411 20.181,16.41 15.111,21.478 L3.797,32.793 C1.351,35.239 0.003,38.504 0.003,41.985 C0.003,45.465 1.351,48.73 3.798,51.176 C6.244,53.623 9.509,54.971 12.989,54.971 C16.47,54.971 19.735,53.623 22.181,51.176 L29.959,43.398 C30.74,42.617 30.74,41.351 29.959,40.57 C29.178,39.789 27.913,39.789 27.132,40.57 Z"
                id="Path"
              ></path>
              <path
                d="M34.003,44.007 C32.899,44.007 32.003,44.903 32.003,46.007 L32.003,52.007 C32.003,53.111 32.899,54.007 34.003,54.007 C35.107,54.007 36.003,53.111 36.003,52.007 L36.003,46.007 C36.003,44.902 35.108,44.007 34.003,44.007 Z"
                id="Path"
              ></path>
              <path
                d="M41.175,42.593 C40.394,41.812 39.128,41.812 38.347,42.593 C37.566,43.374 37.566,44.64 38.347,45.421 L42.589,49.663 C42.98,50.054 43.491,50.249 44.003,50.249 C44.515,50.249 45.026,50.054 45.417,49.663 C46.198,48.882 46.198,47.616 45.417,46.835 L41.175,42.593 Z"
                id="Path"
              ></path>
              <path
                d="M45.968,36.007 L39.968,36.007 C38.864,36.007 37.968,36.903 37.968,38.007 C37.968,39.111 38.864,40.007 39.968,40.007 L45.968,40.007 C47.072,40.007 47.968,39.111 47.968,38.007 C47.968,36.903 47.073,36.007 45.968,36.007 Z"
                id="Path"
              ></path>
              <path
                d="M18.003,13.007 C19.107,13.007 20.003,12.111 20.003,11.007 L20.003,5.007 C20.003,3.903 19.107,3.007 18.003,3.007 C16.899,3.007 16.003,3.903 16.003,5.007 L16.003,11.007 C16.003,12.111 16.899,13.007 18.003,13.007 Z"
                id="Path"
              ></path>
              <path
                d="M10.589,14.421 C10.98,14.812 11.491,15.007 12.003,15.007 C12.515,15.007 13.026,14.812 13.417,14.421 C14.198,13.64 14.198,12.374 13.417,11.593 L9.175,7.35 C8.394,6.569 7.128,6.569 6.347,7.35 C5.566,8.131 5.566,9.397 6.347,10.178 L10.589,14.421 Z"
                id="Path"
              ></path>
              <path
                d="M5.968,21.007 L11.968,21.007 C13.072,21.007 13.968,20.111 13.968,19.007 C13.968,17.903 13.072,17.007 11.968,17.007 L5.968,17.007 C4.864,17.007 3.968,17.903 3.968,19.007 C3.968,20.111 4.864,21.007 5.968,21.007 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </Styled.Svg>
    );
  };
  return (
    <Wrap>
      <Styled.Wrap>
        <Styled.Title>Page not found</Styled.Title>
        {icon()}
        <Styled.Title>
          Looks like the page you are looking for does not exist
        </Styled.Title>
      </Styled.Wrap>
    </Wrap>
  );
};

export default Error404;
