import * as React from 'react';

const clock = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M54.643 50.897a4.396 4.396 0 01-4.39 4.39 4.384 4.384 0 01-4.33-3.71 4.096 4.096 0 01.151-2 4.383 4.383 0 015.849-2.74c.63.26 1.18.66 1.63 1.17a4.344 4.344 0 011.09 2.89"
          fill={colorSecondary}
        />
        <path
          d="M50.252 7c23.85 0 43.252 19.402 43.252 43.25 0 23.85-19.402 43.253-43.252 43.253S7 74.1 7 50.25 26.402 7 50.252 7zm0 2C27.506 9 9 27.505 9 50.25c0 22.747 18.506 41.253 41.252 41.253 22.746 0 41.252-18.506 41.252-41.252C91.504 27.505 72.998 9 50.252 9zm0 67.097a1 1 0 01.993.883l.007.117v9.309a1 1 0 01-1.993.116l-.007-.116v-9.31a1 1 0 011-1zm13.14-43.56c.407.295.533.855.297 1.293l-.066.107-8.89 12.41a6.393 6.393 0 011.91 4.55c0 3.52-2.87 6.39-6.39 6.39a6.403 6.403 0 01-6.347-5.633l-.023-.228-12.07-.91a.99.99 0 01-.92-1.069.99.99 0 01.951-.921l.119.002 12.08.898c.66-2.809 3.19-4.92 6.21-4.92.907 0 1.774.19 2.56.542l.29.139 8.89-12.42c.32-.45.95-.55 1.4-.23zm-13.14 13.97c-1.96 0-3.62 1.29-4.178 3.07a4.096 4.096 0 00-.151 2 4.384 4.384 0 004.33 3.71c2.42 0 4.39-1.97 4.39-4.39a4.344 4.344 0 00-1.09-2.89c-.45-.51-1-.91-1.63-1.17a4.436 4.436 0 00-1.67-.33zM23.407 49.25a1 1 0 01.117 1.993l-.117.007h-9.309a1 1 0 01-.116-1.993l.116-.007h9.31zm63 0a1 1 0 01.116 1.993l-.117.007h-9.308a1 1 0 01-.116-1.993l.116-.007h9.308zM50.251 13.097a1 1 0 01.993.884l.007.116v9.308a1 1 0 01-1.993.117l-.007-.117v-9.308a1 1 0 011-1z"
          fill={colorPrimary}
        />
      </g>
    </>
  );
};
export default clock;
