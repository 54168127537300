import React from 'react';
import Styled from './RadioControl.style';
import { Radio } from '../index';
import i18next from 'i18next';

interface RadioControlProps {
  answerKey: string;
  options: Array<any>;
  key: string;
  updateAnswerKey: Function;
  value: string;
  text: string;
}

const RadioControl: React.FC<RadioControlProps> = (props) => {
  const { answerKey, updateAnswerKey = () => void 0 } = props;
  const handleAnswer = (value) => {
    updateAnswerKey(answerKey, value);
  };

  const renderOptions = props.options.map((option, index) => {
    return (
      <Styled.Row key={index}>
        {option.button_image_url ? (
          <Styled.Image src={option.button_image_url} alt={option.text} />
        ) : null}
        <Radio
          id={answerKey + '--' + option.value}
          name={answerKey + '--' + option.value}
          onChange={() => handleAnswer(option.value)}
          aria-label={option.value}
          title={i18next.t(option.text)}
          checked={props.value === option.value}
          value={option.value}
        />
      </Styled.Row>
    );
  });

  return (
    <Styled.Wraper role="group" aria-labelledby="my-radio-group">
      {renderOptions}
    </Styled.Wraper>
  );
};
export default RadioControl;
