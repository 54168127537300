import { updateAnswerKey } from '../../actions/question';
import { connect } from 'react-redux';
import Questions from './Questions';

const mapDispatchToProps = (dispatch) => {
  return {
    updateAnswerKey: (key, value) => dispatch(updateAnswerKey(key, value)),
  };
};

export default connect(null, mapDispatchToProps)(Questions);
