import styled from 'styled-components';
import { colors } from '../../assets/variables';
const Wrap = styled.div`
  display: flex;
  justify-content: center;
  flex-basis: 100%;
  margin: 32px 0px;
`;
const Card = styled.div`
  border-radius: 10px;
  border: 4px solid ${colors.tertiary};
  align-items: center;
  justify-content: center;
  padding: 32px;
  max-width: 660px;
  width: 100%;
`;
const Line = styled.hr`
  margin-top: 32px;
  border-top: 1px solid ${colors.baseDark};
`;
export default { Wrap, Card, Line };
