import * as React from 'react';

const checkRounded = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M43.57 66.456L28.328 51.213l5.658-5.656 8.877 8.878a.999.999 0 001.414 0l21.314-21.314 5.657 5.656-27.678 27.68z"
          fill={colorSecondary}
        />
        <path
          d="M49.788 12c20.836 0 37.788 16.952 37.788 37.788 0 20.836-16.952 37.788-37.788 37.788C28.952 87.576 12 70.624 12 49.788 12 28.952 28.952 12 49.788 12zm0 2C30.055 14 14 30.055 14 49.788c0 19.733 16.055 35.788 35.788 35.788 19.733 0 35.788-16.055 35.788-35.788C85.576 30.055 69.521 14 49.788 14zm16.417 16.917l.094.083 7.07 7.07c.36.361.388.928.083 1.32l-.083.094-29.092 29.093a.996.996 0 01-1.315.087l-.099-.087L26.207 51.92a1 1 0 01-.087-1.315l.087-.099 7.072-7.07a1 1 0 011.32-.084l.094.083 8.877 8.878L64.884 31a1 1 0 011.321-.083zm-.614 2.204L44.277 54.435a.999.999 0 01-1.315.087l-.099-.087-8.877-8.878-5.658 5.656L43.57 66.456l27.678-27.679-5.657-5.656z"
          fill={colorPrimary}
        />
      </g>
    </>
  );
};
export default checkRounded;
