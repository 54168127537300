import axios from 'axios';
import { getMicroServiceUrl } from '../helpers/helpers';

export const getAvailableRewards = () => ({
  type: 'GET_AVAILABLE_REWARDS',
  payload: axios.get(
    `${getMicroServiceUrl('business-user')}mktPlaceBanner/optinStatus`
  ),
});

export const optIn = (bannerId) => ({
  type: 'OPT_IN_REWARDS',
  payload: axios.put(
    `${getMicroServiceUrl('business-user')}mktPlaceBanner/optin/${bannerId}`
  ),
});
