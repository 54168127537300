import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import * as _ from 'lodash';
import { Row, Col } from 'react-styled-flexboxgrid';

import {
  Typography,
  Button,
  Select,
  ErrorDisplay,
  Loader,
  NothingToDisplay,
  Search,
  Wrap,
} from '../../../components';
import { useNavigate } from 'react-router-dom';
import Card from '../Card';
import { useTranslation } from 'react-i18next';
import { scrollToTop, useResponsivity } from '../../../helpers/helpers';
import Credentials from './Credentials';
import ReactPaginate from 'react-paginate';
import Styled from './CompanyPage.style';

interface CompanyPageProps {
  getCompanyDetails: Function;
  companyDetails: any;
  search: any;
  getSearchData: Function;
  adAnalytics: Function;
  optIn: Function;
  optedInData: any;
  getAvailableRewards: Function;
}

const CompanyPage: React.FC<CompanyPageProps> = (props) => {
  const {
    getCompanyDetails,
    companyDetails,

    search,
    getSearchData,
    adAnalytics,
    optIn,
    optedInData,
    getAvailableRewards,
  } = props;
  const [t] = useTranslation('common');
  const navigate = useNavigate();
  const optedData = _.get(optedInData, 'data.bannerIds');
  const loadingSearch = _.get(search, 'status.loading');
  const errorSearch = _.get(search, 'status.error');
  const doneSearch = _.get(search, 'status.done');
  const dataSearch = _.get(search, 'data.hits');
  const resultData = _.get(search, 'data.total');
  const isMobile = useResponsivity();
  const routeParams = useParams();
  const id = _.get(routeParams, 'companyId');
  const intId: number = id ? +id : 0;
  const [message, setMessage] = React.useState('');
  const [isSorting, setSorting] = React.useState('new');
  const [pageNumber, setPageNumber] = React.useState(0);

  const ItemsPerPage = 6;
  const pagesVisited = pageNumber * ItemsPerPage;

  const setData = (message) => {
    setMessage(message);
  };
  const [savedMessage, setSavedMessage] = React.useState('');
  const setSavedData = (message) => {
    setSavedMessage(message);
  };
  React.useEffect(() => {
    getCompanyDetails(id);
    scrollToTop();
    getAvailableRewards();
  }, [getAvailableRewards, getCompanyDetails, id]);

  const data = _.get(companyDetails, 'data');
  const { error, loading, done } = _.get(companyDetails, 'status');
  const credentials = _.get(data, 'credentials');
  const water = _.get(credentials, 'water');
  const energy = _.get(credentials, 'energy');
  const carbon = _.get(credentials, 'carbon');
  const environment = _.get(credentials, 'environment');
  const companyName = _.get(data, 'name');
  const banners = _.get(data, 'banners');
  const length = _.get(banners, 'length');

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    if (message) {
      getSearchData({
        type: 'B2C',
        term: message,
        start: ItemsPerPage * selected,
        size: ItemsPerPage,
        businessId: intId,
        sortField:
          isSorting === 'new' || isSorting === 'old' ? 'createdAt' : 'title',
        sortOrder: isSorting === 'new' || isSorting === 'za' ? 'desc' : 'asc',
      });
    }
  };

  const renderData = dataSearch && message !== '' ? dataSearch : banners;
  const pageCount = Math.ceil(
    resultData
      ? resultData / ItemsPerPage
      : renderData && renderData.length / ItemsPerPage
  );

  const sortedZA = _.orderBy(
    renderData && renderData,
    [(user) => user.title.toLowerCase()],
    ['desc']
  );
  const sortedAZ = _.orderBy(
    renderData && renderData,
    [(user) => user.title.toLowerCase()],
    ['asc']
  );
  const sortedNew = _.orderBy(
    renderData && renderData,
    [(user) => user.createdAt],
    ['desc']
  );
  const sortedOld = _.orderBy(
    renderData && renderData,
    [(user) => user.createdAt],
    ['asc']
  );
  const renderSorted = useCallback(() => {
    switch (isSorting) {
      case 'za':
        return sortedZA;
      case 'az':
        return sortedAZ;
      case 'new':
        return sortedNew;
      case 'old':
        return sortedOld;
      default:
        return renderData;
    }
  }, [isSorting, renderData, sortedAZ, sortedNew, sortedOld, sortedZA]);
  const displayUsers =
    renderSorted() &&
    renderSorted().slice(pagesVisited, pagesVisited + ItemsPerPage);
  const sorting = [
    { label: 'Newest to oldest', value: 'new' },
    { label: 'Oldest to newest', value: 'old' },
    { label: 'Alphabetical (A - Z)', value: 'az' },
    { label: 'Alphabetical (Z - A)', value: 'za' },
  ];

  const renderBanner = () => {
    return _.map(dataSearch ? dataSearch : displayUsers, (el, index) => {
      return (
        <Col md={4} sm={6} xs={12} style={{ display: 'flex' }}>
          <Card
            key={index}
            title={el.title}
            description={el.description}
            buttonPath={el.buttonPath}
            buttonText={el.buttonText}
            productUniqueness={el.productUniqueness}
            image={el.imageURL}
            companyName={el.companyName}
            isDesktop={!isMobile}
            id={el.businessId}
            adId={el.id}
            isOffer={el.isOffer}
            optIn={optIn}
            optedInData={optedData}
            getAvailableRewards={getAvailableRewards}
          />
        </Col>
      );
    });
  };

  if (error) {
    return (
      <Wrap>
        <ErrorDisplay />
      </Wrap>
    );
  }
  if (loading) {
    return (
      <Wrap>
        <Loader type="leaf" />
      </Wrap>
    );
  }

  const handleSorting = (e) => {
    if (e.currentTarget !== undefined) {
      setSorting(e.currentTarget.value);
      if (message) {
        getSearchData({
          type: 'B2C',
          term: message,
          start: ItemsPerPage * pageNumber,
          size: ItemsPerPage,
          businessId: intId,
          sortField:
            e.currentTarget.value === 'new' || e.currentTarget.value === 'old'
              ? 'createdAt'
              : 'title',
          sortOrder:
            e.currentTarget.value === 'new' || e.currentTarget.value === 'za'
              ? 'desc'
              : 'asc',
        });
      }
    }
  };

  const renderNoBanners = () => {
    return (
      <Col md={12} sm={12} xs={12}>
        <NothingToDisplay
          icon={'product'}
          title="There are no available offers for you right now"
          subtitle="Please come back later"
        />
      </Col>
    );
  };

  const renderErrorSearch = () => {
    return (
      <Col md={12} sm={12} xs={12}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '4px solid #d7f1ff',
            padding: '16px',
            borderRadius: '6px',
          }}
        >
          <Typography size="3" text="Oops..." />
          <p style={{ fontWeight: 500 }}>{t(errorSearch)}</p>
          <Typography
            size="p"
            style={{ fontSize: '15px' }}
            text="Please try a different search term."
          />
        </div>
      </Col>
    );
  };
  const renderNoResults = () => {
    return (
      <Col md={12} sm={12} xs={12}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '4px solid #d7f1ff',
            padding: '16px',
            borderRadius: '6px',
          }}
        >
          <Typography size="3" text="Hmm..." />
          <p>
            We couldn&apos;t find any matches for &ldquo;<b>{savedMessage}</b>
            &rdquo;
          </p>
          <Typography
            size="p"
            style={{ fontSize: '15px' }}
            text="Double check your search for any typos or spelling errors - or try a different search term."
          />
        </div>
      </Col>
    );
  };
  const renderTypeCard = () => {
    if (loadingSearch && !doneSearch) {
      return (
        <Col md={12} xs={12} sm={12}>
          <Loader type="leaf" />
        </Col>
      );
    } else if (errorSearch) {
      return renderErrorSearch();
    } else if (resultData === 0 && message !== '') {
      return renderNoResults();
    } else if (done && length === 0) {
      return renderNoBanners();
    }
    return renderBanner();
  };
  const renderPagination = () => {
    if (length > 6) {
      return (
        <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={'paginationBttns'}
          previousLinkClassName={'previousBttn'}
          nextLinkClassName={'nextBttn'}
          disabledClassName={'paginationDisabled'}
          activeClassName={'paginationActive'}
          forcePage={pageNumber}
        />
      );
    }
  };
  return (
    <Wrap>
      <Styled.Wrap>
        <Row>
          <Col md={12} sm={12} xs={12}>
            <Typography size="1" text={companyName} />
          </Col>
        </Row>
        <Row>
          <Col md={12} sm={12} xs={12} style={{ marginBottom: '16px' }}>
            <Button onClick={() => navigate(-1)}>Back</Button>
          </Col>
        </Row>
        <Row style={{ margin: '16px 0px' }}>
          <Col md={12} xs={12} sm={12}>
            <Typography
              size="3"
              text="Offers and rewards"
              style={{ fontWeight: 600 }}
            />
          </Col>
        </Row>
        {length > 6 ? (
          <Row>
            <Col
              md={4}
              xs={12}
              sm={12}
              style={{ marginBottom: !isMobile ? '0px' : '16px' }}
            >
              <Search
                setData={setData}
                result={resultData}
                getSearchData={() => {
                  getSearchData({
                    type: 'B2C',
                    term: message,
                    start: 0,
                    size: ItemsPerPage,
                    businessId: intId,
                    sortField: 'createdAt',
                    sortOrder: 'desc',
                  });
                  setPageNumber(0);
                }}
                setPreviousData={setSavedData}
              />
            </Col>
            <Col md={3} xs={12} sm={12} mdOffset={5}>
              <Select
                options={sorting}
                placeholder="Sort by"
                onChange={(e) => handleSorting(e)}
                value={isSorting}
                name="category"
                style={{ border: '1px solid #86B049', borderRadius: '6px' }}
              />
            </Col>
          </Row>
        ) : null}
        {renderPagination()}
        <Row>{renderTypeCard()}</Row>
        {renderPagination()}
        {water && energy && carbon && environment && (
          <Row style={{ margin: '16px 0px' }}>
            <Col md={12} xs={12} sm={12}>
              <Typography
                size="3"
                text="Sustainability credentials"
                style={{ fontWeight: 600 }}
              />
            </Col>
          </Row>
        )}
        {water && energy && carbon && environment && (
          <Row>
            {water && (
              <Credentials
                title={'How we consider water'}
                text={water}
                isDesktop={!isMobile}
              />
            )}
            {energy && (
              <Credentials
                title={'How we consider energy'}
                text={energy}
                isDesktop={!isMobile}
              />
            )}
            {carbon && (
              <Credentials
                title={'How we consider carbon'}
                text={carbon}
                isDesktop={!isMobile}
              />
            )}
            {environment && (
              <Credentials
                title={'How we consider environmental impact'}
                text={environment}
                isDesktop={!isMobile}
              />
            )}
          </Row>
        )}
      </Styled.Wrap>
    </Wrap>
  );
};

export default CompanyPage;
