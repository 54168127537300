import * as React from 'react';

const twitter = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M100,18.9552871 C96.3127781,20.5869888 92.3712651,21.6889171 88.2178428,22.1974995 C92.4560288,19.6545878 95.7194321,15.6283111 97.2451791,10.8391608 C93.2824751,13.1913541 88.8959525,14.9078195 84.2127569,15.8190295 C80.4619623,11.8139436 75.1218478,9.31341386 69.2307692,9.31341386 C57.8936215,9.31341386 48.7179487,18.5102776 48.7179487,29.8474253 C48.7179487,31.457936 48.8874762,33.0260648 49.247722,34.5306209 C32.1890231,33.6829837 17.0586989,25.5032846 6.95062513,13.0642085 C5.19177792,16.0945115 4.17461327,19.6333969 4.17461327,23.3841916 C4.17461327,30.5043441 7.79826234,36.7980504 13.3079042,40.4852723 C9.9385463,40.4005086 6.75990676,39.4681076 4.00508582,37.9211697 C4.00508582,38.0059335 4.00508582,38.0906972 4.00508582,38.1754609 C4.00508582,48.1351981 11.0828565,56.4208519 20.4704387,58.3068447 C18.7539733,58.7730451 16.9315533,59.0273363 15.0667514,59.0273363 C13.7529138,59.0273363 12.460267,58.9001907 11.2100021,58.6458996 C13.8164865,66.8044077 21.4028396,72.7378682 30.387794,72.9073956 C23.3735961,78.4170375 14.5157872,81.7016317 4.8951049,81.7016317 C3.24221233,81.7016317 1.6105107,81.5956771 7.10542736e-15,81.4049587 C9.06971816,87.2960373 19.8559017,90.6865861 31.4261496,90.6865861 C69.1883874,90.6865861 89.8283535,59.3875821 89.8283535,32.2420004 C89.8283535,31.3519814 89.8071625,30.4619623 89.7647807,29.5931341 C93.7698665,26.6899767 97.2451791,23.0663276 100,18.9552871 Z"
        id="Path"
        fill={colorPrimary}
        fillRule="nonzero"
      />
    </g>
  );
};
export default twitter;
