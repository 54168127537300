import { combineReducers } from 'redux';
import appReducer from './app';
import authReducer from './auth';
import supportTicket from './supportTicket';
import wholesalerReducer from './wholesaler';
import locationReducer from './location';
import listLocationReducer from './listLocation';
import questionReducer from './question';
import roomReducer from './room';
import registerReducer from './register';
import usageReducer from './usage';
import deleteAccountReducer from './deleteAccount';
import preferencesReducer from './preferences';
import challengesReducer from './challenges';
import generalReducer from './general';
import completeChallengesReducer from './completeChallenge';
import resetPasswordReducer from './resetPassword';
import contactFormReducer from './contact';
import listBannersReducer from './marketplace';
import roiDataReducer from './roi';
import rewardsReducer from './rewards';
import searchReducer from './search';
import companyNameReducer from './companyName';
import adAnalyticsReducer from './adAnalytics';
import availableRewardsReducer from './availableRewards';
import optRewardsReducer from './optRewards';
const rootReducer = (state: any, action: any) => {
  return mainReducer(state, action);
};

const mainReducer = combineReducers({
  appReducer,
  authReducer,
  wholesalerReducer,
  supportTicket,
  locationReducer,
  listLocationReducer,
  questionReducer,
  roomReducer,
  registerReducer,
  usageReducer,
  deleteAccountReducer,
  preferencesReducer,
  challengesReducer,
  generalReducer,
  completeChallengesReducer,
  resetPasswordReducer,
  contactFormReducer,
  listBannersReducer,
  roiDataReducer,
  rewardsReducer,
  searchReducer,
  companyNameReducer,
  adAnalyticsReducer,
  availableRewardsReducer,
  optRewardsReducer,
});

export default rootReducer;
