import styled, { css, keyframes } from 'styled-components';
import { colors } from '../../assets/variables';

const slideIn = keyframes`
from {
  transform: translateX(100%);

}
to{
  transform: translateX(10%);

}
`;

const slideOut = keyframes`
from {
  transform: translateX(10%);

}

to{
  transform: translateX(100%);

}
`;

const Menu = styled.div`
  display: flex;
  margin-left: 0;
  justify-content: flex-end;

  & > a {
    text-decoration: none;
    color: ${colors.primary};

    &:hover {
      text-decoration: none;
      color: #174489;
    }
    &:focus {
      background-color: transparent;
    }
  }
  & > button {
    text-decoration: none;
    color: ${colors.primary};
    padding-left: 16px;
    &:hover {
      text-decoration: none;
      color: #174489;
    }
  }
  ${(props) => {
    if (props.isDesktop) {
      return desktopStyles;
    }
  }}
`;
const Text = styled.span`
  font-weight: bold;
  margin-top: 6px;
  line-height: 1.2;
  margin-right: 4px;
`;

const StyledBurger = styled.div`
  top: 20px;
  right: 21px;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  transition: all 300ms ease-in-out;
  &:focus {
    outline: none;
  }
  div {
    width: 2rem;
    height: 0.25rem;
    background: #0d0c1d;
    border-radius: 10px;
    transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
    position: relative;
    transform-origin: 1px;
  }
  &.active {
    & > div {
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(1) {
        transform: translateY(0) rotate(45deg);
      }
      &:nth-child(3) {
        transform: translateY(0) rotate(-45deg);
      }
    }
  }
`;
const StyledMenu = styled.nav`
  flex-direction: column;
  font-size: 28px;
  background: #fff;
  height: 100vh;
  text-align: left;
  padding-top: 32px;
  padding-left: 16px;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 70px;
  width: 100%;
  display: none;
  animation: ${slideOut} 0.3s ease-in-out forwards;
  z-index: 9;
  a {
    text-decoration: none;
    color: ${colors.primary};
    padding: 0 16px 16px 0;
    transition: all 0.3s linear;
    &:hover,
    &:focus,
    &:active {
      color: #174489;
      background: transparent;
    }
    &.active {
      color: #174489;
    }
  }
  button {
    text-decoration: none;
    color: ${colors.primary};
    &:hover {
      color: #174489;
    }
  }

  &.open {
    animation: ${slideIn} 0.3s ease-in-out forwards;
    display: flex;
  }
  ${(props) => {
    if (!props.open) {
      return css`
        display: none;
      `;
    }
  }}
`;

const Overlay = styled.div`
  background-color: rgba(37, 179, 235, 0.6);
  position: absolute;
  left: 0;
  top: 70px;
  width: 100%;
  height: 100vh;
  z-index: 8;
`;
const desktopStyles = css`
  a {
    text-decoration: none;
    color: ${colors.primary};
    padding: 16px;
    transition: all 300ms ease-in-out;
    &:hover,
    &:focus,
    &:active {
      color: #174489;
      transition: all 300ms ease-in-out;
      &.active {
        box-shadow: 0 6px #174489;
      }
    }
    &.active {
      box-shadow: 0 6px #174489;
    }
  }
  button {
    text-decoration: none;
    color: ${colors.primary};
    padding-left: 16px;
    &:hover {
      color: #174489;
    }
  }
`;
export default {
  Menu,
  Text,
  StyledBurger,
  StyledMenu,
  Overlay,
};
