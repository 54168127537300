import axios from 'axios';
import { getMicroServiceUrl } from '../helpers/helpers';
import * as _ from 'lodash';

export const addLocation = (data) => {
  return {
    type: 'ADD_LOCATION',
    payload: axios.post(
      `${getMicroServiceUrl('business-user')}add_property`,
      data
    ),
  };
};

export const listLocations = () => {
  return {
    type: 'LIST_LOCATION',
    payload: axios.get(
      `${getMicroServiceUrl('business-user')}users_properties_list`
    ),
  };
};

export const deleteLocation = (id) => {
  return {
    type: 'DELETE_LOCATION',
    payload: axios.delete(
      `${getMicroServiceUrl('business-user')}delete_property/${id}`
    ),
  };
};

export const editLocation = (id, data) => {
  return {
    type: 'EDIT_LOCATION',
    payload: axios.post(
      `${getMicroServiceUrl('business-user')}update_property/${id}`,
      data
    ),
  };
};

export const getAddress = (postcode) => {
  return {
    type: 'GET_ADDRESS',
    payload: axios.get(
      `${getMicroServiceUrl('business-user')}address/${postcode.replace(
        /\s+/g,
        ''
      )}`
    ),
  };
};
export const clearAddress = () => ({
  type: 'CLEAR_ADDRESS',
});
