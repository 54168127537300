import React from 'react';
import { Wrap, Task, EasyWin, Loader } from '../../components/index';
import * as _ from 'lodash';
import { useResponsivity } from '../../helpers/helpers';
import { Col } from 'react-styled-flexboxgrid';
import { useTranslation } from 'react-i18next';
import store from 'store2';
interface SingleToiletProps {
  challenges: any;
  listLocation: any;
  getChallengeCategory: Function;
  completeTask: any;
  completeChallenge: Function;
  generalData: Function;
}

const SingleToilet: React.FC<SingleToiletProps> = (props) => {
  const {
    challenges,
    getChallengeCategory,
    completeTask,
    completeChallenge,
    generalData,
  } = props;
  const getRoomId = store('property');

  const loadingChallenges = _.get(challenges, 'status.loading');
  const data = _.get(challenges, 'data.challenges');
  const isMobile = useResponsivity();
  const [t] = useTranslation('common');
  const { error, loading, done } = _.get(completeTask, 'status');
  React.useEffect(() => {
    getChallengeCategory(getRoomId, 'toilets');
  }, [getRoomId]);

  const pageArray = [
    {
      title: 'Single flush toilet',
      poster: '',
      emailCopy: '',
      roi: { noAppliances: 10, savings: 610.57 },
      easyWins: [
        {
          title: 'Introduction',
          theme: 'intro',
          size: 'full',
          text: 'Single flush toilets are big users of water since they use the same volume of water to flush each time.',
        },
        {
          title: 'Easy Win',
          theme: 'red',
          icon: null,
          size: 'half',
          buttonText: 'See video',
          buttonPath: '/video-library',
          text: 'Placing a Toilet cistern bag in single lever and single button toilets lets your business save around 1 litre with every flush – without affecting the performance of the toilet. And they’re easy to fit – you don’t need a plumber.',
        },
        {
          title: 'Easy Win Pro',
          theme: 'blue',
          icon: null,
          size: 'full',
          text: 'The simplest way to save water with single flush toilets is to convert to a dual flush. It’s not as hard as it sounds, takes around 20 minutes without needing to change the flushing mechanism or the flushing lever. This allows the operator to hold down the lever for a big flush and let go for a small flush.#Alternatively, replace the flush valve completely which replaces the lever with a big and small button flushing mechanism.#Whichever way the single lever toilet is converted, each staff member can use up to 13.5 litres less each day.#Find out when you’ll get your money back by converting a single flush toilet to dual flush with our simple Return on Investment calculator.',
        },
      ],
    },
  ];

  const getChallenges = () => {
    return _.map(data, (el, index) => {
      const title = _.get(el, 'title');
      const description = _.get(el, 'description');
      const buttonText = _.get(el, 'buttonText');
      const type = _.get(el, 'internalType');
      const buttonPath = _.get(el, 'buttonPath');
      const energySaving = _.get(el, 'energy_saving');
      const carbonSaving = _.get(el, 'carbon_saving');
      const waterSaving = _.get(el, 'water_saving');
      return (
        <Col xs={12} sm={12} md={6} key={index}>
          <Task
            titleWidth="230px"
            isDesktop={!isMobile}
            icon={_.get(el, 'icon')}
            id={_.get(el, 'id')}
            title={`${t(title)}`}
            description={`${t(description)}`}
            buttonText={`${t(buttonText)}`}
            buttonPath={buttonPath}
            popup={type === 'direct-redirect' ? false : true}
            renderComplete={() => {
              completeChallenge(getRoomId, _.get(el, 'id'));
              setTimeout(() => {
                getChallengeCategory(getRoomId, 'toilets');
                generalData();
              }, 1000);
            }}
            isLoading={loading}
            completed={done}
            energySaving={energySaving}
            carbonSaving={carbonSaving}
            waterSaving={waterSaving}
          />
        </Col>
      );
    });
  };
  if (loadingChallenges) {
    return (
      <Wrap>
        <Loader />
      </Wrap>
    );
  }
  return (
    <Wrap>
      <EasyWin data={pageArray}>{getChallenges()}</EasyWin>
    </Wrap>
  );
};

export default SingleToilet;
