import * as React from 'react';

const returnIcon = (props: any) => {
  const { colorPrimary } = props;

  return (
    <>
      <path
        d="M47.133 86.074c15.837-1.95 29.263-13.345 33.685-28.767 6.096-21.262-6.24-43.518-27.502-49.615-7.865-2.255-13.861-2.314-23.135.32-1.208.344-1.526.74-1.211 1.603.314.862 1.327.46 1.415.433 6.73-2.035 14.763-2.617 22.38-.433 20.2 5.792 31.923 26.94 26.13 47.14-4.166 14.53-16.742 25.291-31.622 27.28l4.664-6.788-20.458 6.6 19.225 9.614-3.571-7.387z"
        fill={colorPrimary}
        fillRule="evenodd"
      />
    </>
  );
};
export default returnIcon;
