import * as React from 'react';

const sink = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M71.976 59.728H27.305C20.52 59.728 15 54.209 15 47.423a6.625 6.625 0 016.618-6.616h56.045a6.625 6.625 0 016.618 6.616c0 6.785-5.52 12.305-12.305 12.305"
          fill="#D7F1FF"
        />
        <path
          d="M32.692 11h16.431v6.517h-3.185v-3.259a1 1 0 00-1-1h-7.06a3.57 3.57 0 00-3.567 3.565v21.985h-3.183V12.564c0-.862.702-1.564 1.564-1.564"
          fill={colorSecondary}
        />
        <path
          d="M50.123 9a1 1 0 01.993.883l.007.117v8.517a1 1 0 01-.884.993l-.116.006h-5.184a1 1 0 01-.994-.883l-.006-.116v-3.26h-6.062c-.815 0-1.487.627-1.559 1.424l-.006.142v21.984h41.35c4.753 0 8.619 3.865 8.619 8.616 0 7.783-6.247 14.134-13.99 14.302l-.315.003h-11.82v22.449c0 4.025-3.21 7.314-7.205 7.444l-.245.004h-6.131a7.457 7.457 0 01-7.446-7.203l-.004-.245V61.728h-11.82C19.417 61.728 13 55.313 13 47.423c0-4.664 3.726-8.475 8.36-8.612l.258-.004h6.947l-6.466-14.013a1 1 0 011.76-.94l.056.104 5.213 11.296v-22.69a3.57 3.57 0 013.376-3.56L32.693 9h17.43zm8.033 52.728H41.125v22.449a5.456 5.456 0 005.23 5.443l.22.004h6.13a5.456 5.456 0 005.446-5.229l.005-.218V61.728zm-8.516 9.688a1 1 0 01.993.883l.007.117v10.919a1 1 0 01-1.994.117l-.006-.117V72.416a1 1 0 011-1zm28.023-30.608H21.618A6.625 6.625 0 0015 47.422c0 6.684 5.356 12.14 12.002 12.302l.303.003h44.67c6.786 0 12.306-5.52 12.306-12.305a6.625 6.625 0 00-6.618-6.616zm-1.11 11.221a1 1 0 01.117 1.994l-.116.006H57.757a1 1 0 01-.117-1.993l.117-.007h18.797zm0-4.746a1 1 0 01.117 1.993l-.116.007H65.879a1 1 0 01-.117-1.993l.117-.007h10.675zM49.124 11h-16.43c-.815 0-1.487.628-1.559 1.423l-.006.143v26.242h3.184V16.823a3.57 3.57 0 013.376-3.56l.189-.005h7.062a1 1 0 01.993.883l.007.117v3.259h3.184v-6.518z"
          fill={colorPrimary}
        />
      </g>
    </>
  );
};
export default sink;
