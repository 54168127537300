import axios from 'axios';
import { getMicroServiceUrl } from '../helpers/helpers';

export const getQuestions = (room) => ({
  type: 'GET_QUESTIONS',
  payload: axios.get(
    `${getMicroServiceUrl('question')}questions/gwf-business-${room}`
  ),
});

export const updateAnswerKey = (key, value) => {
  return {
    type: 'UPDATE_ANSWER_KEY',
    key,
    value,
  };
};

export const updateEditKeys = (data) => {
  return {
    type: 'UPDATE_EDIT_KEYS',
    data,
  };
};

export const clearAnswerKey = () => {
  return {
    type: 'CLEAR_ANSWER_KEY',
  };
};
