import React from 'react';
import Styled from './Box.style';
import { Icon } from '../index';
import { useNavigate } from 'react-router-dom';
interface BoxProps {
  title?: string;
  value?: number;
  icon?: string;
  location: string;
  buttonText?: string;
  children?: any;
}

export const Box: React.FC<BoxProps> = (props) => {
  const { title, value, location, children, buttonText } = props;
  const navigate = useNavigate();

  return (
    <Styled.Wrap>
      {children ? (
        children
      ) : (
        <>
          <Styled.Title>{title}</Styled.Title>
          <Styled.ValueWrap>
            <Styled.Value>{value}</Styled.Value>
            <Styled.Button onClick={() => navigate(location)}>
              {buttonText}
            </Styled.Button>
          </Styled.ValueWrap>
          <Styled.Mask />
        </>
      )}
    </Styled.Wrap>
  );
};

export default Box;
