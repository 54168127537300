import * as React from 'react';
import { Select } from '../index';

interface LocationDropdownProps {
  value: any;
  onChange: Function;
  options: any;
}
export const LocationDropdown: React.FC<LocationDropdownProps> = (props) => {
  const { value, onChange, options } = props;
  return (
    <Select
      value={value}
      placeholder="Select your location"
      onChange={onChange}
      options={options}
      style={{ minHeight: 'auto' }}
    />
  );
};

export default LocationDropdown;
