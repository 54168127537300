import * as React from 'react';

const lock = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(10.000000, 5.789858)">
        <path
          d="M9.21090869,37.0953963 C7.35336424,37.0953963 5.84337921,38.6053813 5.84337921,40.4600051 L5.84337921,79.2114198 C5.84337921,81.0689643 7.35336424,82.5789493 9.21090869,82.5789493 L70.7931802,82.5789493 C72.6507247,82.5789493 74.1577891,81.0689643 74.1577891,79.2114198 L74.1577891,40.4600051 C74.1577891,38.6053813 72.6507247,37.0953963 70.7931802,37.0953963 L9.21090869,37.0953963 Z"
          fill={colorSecondary}
        ></path>
        <path
          d="M74.1586653,79.2114198 C74.1586653,81.0689643 72.6486802,82.5789493 70.7940564,82.5789493 L9.21178489,82.5789493 C7.35424044,82.5789493 5.84133474,81.0689643 5.84133474,79.2114198 L5.84133474,40.4600051 C5.84133474,38.6053813 7.35424044,37.0953963 9.21178489,37.0953963 L20.9178197,37.0953963 L59.0821803,37.0953963 L70.7940564,37.0953963 C72.6486802,37.0953963 74.1586653,38.6053813 74.1586653,40.4600051 L74.1586653,79.2114198 Z M23.8384871,22.004308 C23.8384871,13.0904312 31.0905042,5.84133474 40.0014603,5.84133474 C48.9094958,5.84133474 56.1615129,13.0904312 56.1615129,22.004308 L56.1615129,31.2540616 L23.8384871,31.2540616 L23.8384871,22.004308 Z M70.7940564,31.2540616 L62.0028477,31.2540616 L62.0028477,22.004308 C62.0028477,9.87185572 52.1339126,0 40.0014603,0 C27.8660874,0 17.9971523,9.87185572 17.9971523,22.004308 L17.9971523,31.2540616 L9.21178489,31.2540616 C4.13274433,31.2540616 0,35.3838852 0,40.4600051 L0,79.2114198 C0,84.2875397 4.13274433,88.420284 9.21178489,88.420284 L70.7940564,88.420284 C75.8701763,88.420284 80,84.2875397 80,79.2114198 L80,40.4600051 C80,35.3838852 75.8701763,31.2540616 70.7940564,31.2540616 L70.7940564,31.2540616 Z"
          fill={colorPrimary}
        ></path>
        <path
          d="M46.4321858,53.6298784 C46.4321858,57.461794 43.3275163,60.5664634 39.4926801,60.5664634 C35.6607645,60.5664634 32.5560951,57.461794 32.5560951,53.6298784 C32.5560951,49.7979628 35.6607645,46.6932934 39.4926801,46.6932934 C43.3275163,46.6932934 46.4321858,49.7979628 46.4321858,53.6298784"
          fill={colorPrimary}
        ></path>
        <path
          d="M40.6080829,54.2943303 L47.8396554,74.2687744 C48.0762294,74.9230039 47.59724,75.6181227 46.8992005,75.6298054 L33.1370158,75.8694491 C32.4389763,75.8809828 31.9366215,75.203388 32.1527509,74.5403965 L38.6862838,54.3264576 C38.9812712,53.4035267 40.2780475,53.383082 40.6080829,54.2943303"
          fill={colorPrimary}
        ></path>
      </g>
    </g>
  );
};
export default lock;
