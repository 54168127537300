import * as React from 'react';
import Styled from './Radio.style';

interface RadioProps {
  checked?: any;
  onChange: Function;
  disabled?: boolean;
  name: string;
  id: string;
  value: string;
  title: string;
  [x: string]: any;
}

export const Radio: React.FC<RadioProps> = ({
  checked,
  onChange,
  name,
  children,
  style,
  title,
  value,
  disabled,
  ...props
}) => (
  <Styled.Label
    disabled={disabled}
    name={name}
    checked={checked}
    value={value}
    {...props}
  >
    <Styled.Content>
      <Styled.Title>{title}</Styled.Title>
    </Styled.Content>
    <Styled.HiddenRadio
      disabled={disabled}
      name={name}
      checked={checked}
      onChange={onChange}
      value={value}
      {...props}
    />
    <Styled.Icon viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="6"></circle>
    </Styled.Icon>
  </Styled.Label>
);

export default Radio;
