import * as React from 'react';

const entrance = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          fill={colorSecondary}
          d="M25.269 88.398v-2.85h8.07v2.85zM71.91 85.548h4.47v2.85H59.05z"
        />
        <path
          d="M40.79 51.848c2.28 0 4.13 1.86 4.13 4.13a4.13 4.13 0 01-4.13 4.12c-2.27 0-4.12-1.85-4.12-4.12s1.85-4.13 4.12-4.13zm0 2c-1.17 0-2.12.96-2.12 2.13s.95 2.12 2.12 2.12c1.17 0 2.13-.95 2.13-2.12 0-1.17-.96-2.13-2.13-2.13z"
          fill={colorPrimary}
        />
        <path
          d="M23.82 7c.08 0 .151.027.226.046l.149-.038.078-.008h53.102a1 1 0 01.993.883l.007.117v4.852a1 1 0 01-.883.993l-.117.007H72.78v69.696h4.6c.51 0 .935.388.993.884l.007.116V88.4h12.1a1 1 0 01.116 1.993l-.117.007H75.66l-.012-.002H50.03l-15.48 3.429a.763.763 0 01-.21.03c-.23 0-.45-.08-.63-.22a.983.983 0 01-.362-.652l-.008-.127v-2.46h-9.07a.997.997 0 01-.993-.882l-.007-.118v-4.851c0-.51.388-.935.884-.993l.116-.007h4.6V13.852h-4.596a1 1 0 01-.993-.884l-.007-.116V9H9a1 1 0 01-.116-1.993L9 7h14.82zm46.96 7.098l-35.44 7.86v69.65l35.44-7.86v-69.65zm5.6 71.45h-4.47l-12.86 2.85h17.33v-2.85zm-43.04 0h-8.07v2.85h8.07v-2.85zm29.322-71.696H30.87v69.696h2.47v-62.39c0-.43.27-.811.668-.949l.112-.031 28.543-6.326zM76.375 9H25.273v2.852h4.578l.018-.004h41.92l.034.004h4.552V9z"
          fill={colorPrimary}
        />
        <path
          d="M25.273 9h51.102v2.852h-4.552c-.012-.001-.022-.004-.034-.004H29.869c-.007 0-.012.004-.018.004h-4.578V9z"
          fill={colorSecondary}
        />
      </g>
    </>
  );
};
export default entrance;
