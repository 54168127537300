import * as React from 'react';

const person = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M50.23 9a6.271 6.271 0 00-6.265 6.264 6.27 6.27 0 006.264 6.263 6.27 6.27 0 006.264-6.263A6.271 6.271 0 0050.23 9"
          fill={colorSecondary}
        />
        <path
          d="M47.041 25.043l.342.019c1.237.081 2.51.08 3.763.065l.935-.013c.964-.016 1.925-.03 2.89-.02 5.446.072 9.425 4.099 9.463 9.574.024 3.45.027 6.936.023 10.385l-.016 8.41c-.005 3.301-1.346 5.961-3.882 7.714l-.267.178c-.661.426-.831.769-.825 1.669l.023 5.288c.02 6.027.014 11.579-.016 16.85-.027 4.534-3.201 7.782-7.72 7.899-.323.008-.647.01-.971.01l-1.331-.006c-5.471-.022-8.485-3.082-8.491-8.617l-.001-6.617c0-4.96.002-9.918-.03-14.877-.003-.505-.155-1.021-.254-1.142-2.64-1.594-3.856-4.123-4.613-6.143a.99.99 0 01-.063-.35v-22.55c0-.108.018-.216.053-.32 1.741-5.173 5.447-7.653 10.988-7.406zM38 32.937v22.198c.945 2.455 2.118 4.008 3.786 5.023 1.1.669 1.143 2.569 1.144 2.784.021 3.309.028 6.617.03 9.926v11.579c.005 4.435 2.13 6.6 6.5 6.618l.53.003c.573.004 1.144.006 1.713-.007 3.432-.09 5.75-2.464 5.77-5.91l.02-4.587c.017-5.442.008-11.22-.026-17.53-.007-1.104.18-2.255 1.538-3.223l.202-.137c2.142-1.382 3.23-3.472 3.234-6.214l.016-8.406c.004-3.445 0-6.929-.023-10.372-.031-4.41-3.11-7.53-7.49-7.587a70.393 70.393 0 00-1.888.004l-.943.014c-1.326.022-2.687.043-4.046-.012l-.815-.043c-4.801-.314-7.742 1.554-9.252 5.879zM50.23 7c4.556 0 8.263 3.707 8.263 8.264 0 4.556-3.707 8.263-8.264 8.263-4.557 0-8.264-3.707-8.264-8.263C41.965 10.707 45.672 7 50.23 7zm0 2a6.271 6.271 0 00-6.265 6.264 6.27 6.27 0 006.264 6.263 6.27 6.27 0 006.264-6.263A6.271 6.271 0 0050.23 9z"
          fill={colorPrimary}
        />
      </g>
    </>
  );
};
export default person;
