import * as React from 'react';
import Styled from './Popup.style';
import { Icon } from '../index';

interface PopupProps {
  id?: number;
  handleClick: Function;
  open?: boolean;
  children: React.ReactNode;
  hideCloseButton?: boolean;
  isDesktop: boolean;
  theme?: string;
  style?: React.CSSProperties;
}

export const Popup: React.FC<PopupProps> = (props) => {
  const {
    handleClick,
    theme,
    isDesktop,
    open,
    children,
    hideCloseButton,
    style,
  } = props;

  return (
    <Styled.PopupBackground theme={theme} className={open ? 'open' : undefined}>
      <Styled.Popup
        style={style}
        className={open ? 'open' : null}
        isDesktop={isDesktop}
      >
        <Styled.CloseWrap>
          {!hideCloseButton && (
            <Styled.Close onClick={() => handleClick()}>
              <Icon icon="times" size={25} />
            </Styled.Close>
          )}
        </Styled.CloseWrap>
        <Styled.Container>{children}</Styled.Container>
      </Styled.Popup>
    </Styled.PopupBackground>
  );
};

export default Popup;
