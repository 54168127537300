import styled from 'styled-components';
import { colors } from '../../assets/variables';

const Card = styled.div`
  border: 4px solid ${colors.tertiary};
  border-radius: 10px;
  padding: 32px;
`;

const RoomTypeText = styled.p`
  padding-bottom: 16px;
  text-align: right;

  color: ${colors.baseDark};
  span {
    text-transform: capitalize;
    font-weight: 600;
    color: ${colors.primary};
  }
`;

export default { Card, RoomTypeText };
