import Marketplace from './Marketplace';
import { connect } from 'react-redux';
import { listBanners } from '../../actions/marketplace';
import { getSearchData } from '../../actions/search';
import { adAnalytics } from '../../actions/adAnalytics';
import { optIn, getAvailableRewards } from '../../actions/utmBanners';
import { AppState } from '../../interfaces';
const mapStateToProps = (state: AppState) => ({
  marketplace: state.listBannersReducer,
  search: state.searchReducer,
  optedInData: state.availableRewardsReducer,
});
const mapDispatchToProps = (dispatch) => ({
  listBanners: () => dispatch(listBanners()),
  getSearchData: (data) => dispatch(getSearchData(data)),
  adAnalytics: (data) => dispatch(adAnalytics(data)),
  optIn: (bannerId) => dispatch(optIn(bannerId)),
  getAvailableRewards: () => dispatch(getAvailableRewards()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Marketplace);
