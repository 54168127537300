import styled, { css } from 'styled-components';
import { colors } from '../../assets/variables';

const iconStyle = css`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const CreateRoom = styled.div`
  ${iconStyle}
  background: ${colors.action};
  margin-right: 16px;
  transition: all 300ms ease-in-out;
  &:hover {
    transition: all 300ms ease-in-out;
    background: #274a6e;
  }
`;
const Wrap = styled.div`
  background-image: linear-gradient(
    to right bottom,
    rgb(14, 165, 233),
    rgb(2, 132, 199)
  );
  position: relative;
  border-radius: 10px;
  padding: 14px;
  min-height: 90px;

  overflow: hidden;
  color: white;
  margin-bottom: 16px;
`;
const Mask = styled.div`
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='207' height='207' fill='none'%3E%3Cpath fill='%23000' d='M138.648 181.408C47.268 232.569 1.327 206.607.824 103.52.324.432 46.014-25.148 137.896 26.777c91.882 51.925 92.133 103.469.753 154.631h-.001z'/%3E%3C/svg%3E");
  background-color: hsla(0, 0%, 100%, 0.2);
  position: absolute;
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
  top: 0;
  right: 0;
  width: 4rem;
  margin: -0.75rem;
  height: 4rem;
`;
const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  flex: 1;
  color: rgb(224, 242, 254);
  padding-left: 16px;
`;
const Value = styled.div`
  font-size: 40px;
  font-weight: 700;
  flex: 1;
  color: rgb(224, 242, 254);
  padding: 0px 16px;
`;
const ValueWrap = styled.div`
  display: flex;
`;
const Button = styled.button`
  text-decoration: underline;
  text-underline-offset: 8px;
  text-decoration-color: ${colors.baseLight};
  text-decoration-thickness: 2px;
  border: 0;
  background: transparent;
  min-height: 35px;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  margin-top: 10px;
  text-transform: none;
  color: ${colors.baseLight};
  ${(props) => {
    if (props.disabled) {
      return css`
        text-decoration-color: ${colors.darkGray};
      `;
    }
  }};
`;

export default {
  Wrap,
  Title,
  Value,
  Button,
  CreateRoom,
  Mask,
  ValueWrap,
};
