import React from 'react';
import Styled from './Product.style';
import { Icon, Typography, Popup } from '../index';
import { useResponsivity } from '../../helpers/helpers';
import { Col } from 'react-styled-flexboxgrid';

interface ProductProps {
  addToOrder: Function;
  removeFromOrder: Function;
  title: string;
  id: number;
  image: string;
  count: number;
  maxInGroup: boolean;
  maxQuantity: number;
  description: string;

  stockCheck?: any;
  limitedOffer?: any;
}

const Product: React.FC<ProductProps> = (props) => {
  const {
    addToOrder,
    maxQuantity,
    description,
    image,
    maxInGroup,
    removeFromOrder,
    title,
    id,
    count,

    stockCheck,
    limitedOffer,
  } = props;

  const isMobile = useResponsivity();
  const [isActive, setIsActive] = React.useState(false);
  const [isOpen, setOpen] = React.useState(false);

  const renderPopup = () => {
    return (
      <Popup
        open={isOpen}
        handleClick={() => setOpen(false)}
        isDesktop={!isMobile}
      >
        <Styled.ImageWrap>
          <Styled.Image src={image} alt={title} className="image-popup" />
          {stockCheck === 'COMINGSOON' && (
            <Styled.Image
              style={{
                width: '100px',
                position: 'absolute',
                boxShadow: '0px 0px 0px',
                top: 0,
                right: 0,
              }}
              src={
                'https://cdn.getwaterfit.co.uk/static/files/images/comingsoon.png'
              }
              alt={title}
            />
          )}
          {limitedOffer === true && stockCheck !== 'COMINGSOON' && (
            <Styled.Image
              style={{
                width: '100px',
                position: 'absolute',
                boxShadow: '0px 0px 0px',
                top: 0,
                right: 0,
              }}
              src={
                'https://cdn.getwaterfit.co.uk/static/files/images/limited.png'
              }
              alt={title}
            />
          )}
        </Styled.ImageWrap>
        <Typography
          size="5"
          text={title}
          style={{
            margin: '0 0 8px 0',
            textAlign: 'center',
            minHeight: '52px',
          }}
          data-test="title"
        />
        <Typography
          size="p"
          text={description}
          data-test="description"
          className="description-popup"
        />
        <Styled.CounterWrap className="custom-button custom-buttom-popup">
          {renderButton()}
        </Styled.CounterWrap>
      </Popup>
    );
  };

  const renderButton = () => {
    switch (stockCheck) {
      case 'SUSPENDED':
        return (
          <Styled.Text stockCheck={stockCheck}>
            Currently out of stock
          </Styled.Text>
        );
      case 'COMINGSOON':
        return (
          <Styled.Text stockCheck={stockCheck}>
            Currently out of stock
          </Styled.Text>
        );
      default:
        return (
          <React.Fragment>
            <Styled.Btn
              data-test="remove"
              colorPrimary="#fff"
              aria-label="Remove item"
              disabled={!count}
              style={{
                marginRight: '24px',
              }}
              onClick={() => {
                removeFromOrder(id);
              }}
            >
              <div>
                <Icon icon="minus" size={23} />
              </div>
            </Styled.Btn>
            <Styled.Count>{count}</Styled.Count>
            <Styled.Btn
              data-test="add"
              colorPrimary="#fff"
              aria-label="Add item"
              style={{
                marginLeft: '24px',
              }}
              disabled={maxInGroup || count >= maxQuantity}
              onClick={() => {
                addToOrder(id);
              }}
            >
              <div>
                <Icon icon="plus" size={23} />
              </div>
            </Styled.Btn>
          </React.Fragment>
        );
    }
  };

  return (
    <Col md={4} xs={12} sm={6} style={{ marginBottom: '16px' }}>
      <Styled.Wrap data-test={`product-${id}`} isDesktop={!isMobile}>
        <Styled.ImageWrap>
          <Styled.Image src={image} alt={title} />
          {stockCheck === 'COMINGSOON' && (
            <Styled.Image
              style={{
                width: '100px',
                position: 'absolute',
                boxShadow: '0px 0px 0px',
                top: 0,
                right: 0,
              }}
              src={
                'https://cdn.getwaterfit.co.uk/static/files/images/comingsoon.png'
              }
              alt={title}
            />
          )}
          {limitedOffer === true && stockCheck !== 'COMINGSOON' && (
            <Styled.Image
              style={{
                width: '100px',
                position: 'absolute',
                boxShadow: '0px 0px 0px',
                top: 0,
                right: 0,
              }}
              src={
                'https://cdn.getwaterfit.co.uk/static/files/images/limited.png'
              }
              alt={title}
            />
          )}
        </Styled.ImageWrap>

        <Typography
          size="5"
          text={title}
          style={{
            margin: '0 0 8px 0',
            textAlign: 'center',
            minHeight: '52px',
          }}
          data-test="title"
        />

        <Typography
          size="p"
          className="small"
          text={description}
          data-test="title"
        />
        <p className="read-more" onClick={() => setOpen(true)}>
          Read more
        </p>
        {renderPopup()}
        <Styled.CounterWrap className="custom-button">
          {renderButton()}
        </Styled.CounterWrap>
      </Styled.Wrap>
    </Col>
  );
};

export default Product;
