import PageOverview from './PageOverview';
import { connect } from 'react-redux';
import {
  deleteLocation,
  listLocations,
  editLocation,
  clearAddress,
  getAddress,
} from '../../actions/location';
import { AppState } from '../../interfaces';

const mapStateToProps = (state: AppState) => ({
  listLocation: state.listLocationReducer,
  wholesaler: state.wholesalerReducer,
  location: state.locationReducer,
});

const mapDispatchToProps = (dispatch) => ({
  listLocations: () => dispatch(listLocations()),
  deleteLocation: (id) => dispatch(deleteLocation(id)),
  editLocation: (id, data) => dispatch(editLocation(id, data)),
  getAddress: (postcode) => dispatch(getAddress(postcode)),
  clearAddress: () => dispatch(clearAddress()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageOverview);
