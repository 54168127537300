import * as React from 'react';

const blockedPipe = (props: any) => {
  const { colorSecondary, colorPrimary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <rect fill={colorSecondary} x="27" y="85.5063446" width="45.6" height="5.13311915" rx="2" />
        <path
          d="M49.8,61.3063446 C55.5996614,61.3063446 60.8502693,63.6571219 64.6509619,67.4578145 C68.4516545,71.2585071 70.8024317,76.509115 70.8024317,82.3087764 L70.8024317,82.3087764 L70.8024317,85.2063446 L28.7975683,85.2063446 L28.7975683,82.3087764 C28.7975683,76.509115 31.1483455,71.2585071 34.9490381,67.4578145 C38.7497307,63.6571219 44.0003386,61.3063446 49.8,61.3063446 Z"
          stroke={colorPrimary}
          strokeWidth="2"
        />
        <path
          d="M49.8,9 C50.6015721,9 51.3272594,9.32490129 51.8525538,9.85019561 C52.3778481,10.3754899 52.7027494,11.1011773 52.7027494,11.9027494 L52.7027494,11.9027494 L52.7027494,61.2 L46.8972506,61.2 L46.8972506,11.9027494 C46.8972506,11.1011773 47.2221519,10.3754899 47.7474462,9.85019561 C48.2727406,9.32490129 48.9984279,9 49.8,9 Z"
          stroke={colorPrimary}
          strokeWidth="2"
        />
        <path
          d="M42.5068063,67.9313114 C37.7848053,71.3906335 35.4238047,74.5951096 35.4238047,77.5447395"
          stroke={colorPrimary}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    </>
  );
};
export default blockedPipe;
