import styled from 'styled-components';
import { colors } from '../../assets/variables';

const ErrorList = styled.ul`
  background: #fbb4b0;
  border: 1px solid ${colors.error};
  border-radius: 10px;
  padding: 8px 16px;
  list-style-type: none;
  color: ${colors.error};
`;
const SuccessList = styled.ul`
  background: #daf5c3;
  border-radius: 10px;
  border: 1px solid ${colors.success};
  padding: 8px 16px;
  list-style-type: none;
  color: ${colors.success};
`;

const InfoList = styled.ul`
  background: #f4e0b1;
  border-radius: 10px;
  border: 1px solid ${colors.energy};
  padding: 8px 16px;
  list-style-type: none;
  color: ${colors.energy};
`;

export default { ErrorList, SuccessList, InfoList };
