import React, { ChangeEvent } from 'react';
import {
  Typography,
  Icon,
  BackButton,
  Select,
  Input,
} from '../../components/index';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-styled-flexboxgrid';
import Styled from './EasyWin.style';
import _ from 'lodash';
import { prettifyIfNumber, capitalizeFirstLetter } from '../../helpers/helpers';
import store from 'store2';
interface EasyWinProps {
  data: any;
  children?: any;
  roiData: any;
  getRoiData: Function;
}

const EasyWin: React.FC<EasyWinProps> = (props) => {
  const { data, children, roiData, getRoiData } = props;
  const [t] = useTranslation('common');
  const getLocation = store('property');
  const [input, setInput] = React.useState('');
  const [value, setValue] = React.useState('');
  const [copy, setCopy] = React.useState(false);
  const navigate = useNavigate();
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const limit = 6;
    setInput(e.target.value.slice(0, limit));
  };
  const roi = parseFloat(input) / parseFloat(value);
  if (copy !== false) {
    setTimeout(() => {
      setCopy(false);
    }, 2000);
  }
  React.useEffect(() => {
    getRoiData(getLocation);
  }, []);

  const dataRoi = _.get(roiData, 'data.data');
  const roiLength = _.get(dataRoi, 'length');
  const productOptions2 = _.map(dataRoi, (el, index) => {
    return {
      value: el.value,
      label: capitalizeFirstLetter(el.label).replace(/-/g, ' '),
    };
  });

  const sizeProps = (size) => {
    switch (size) {
      case 'half':
        return {
          xs: 12,
          sm: 12,
          md: 6,
        };
      case 'full':
        return {
          xs: 12,
          sm: 12,
          md: 12,
        };
      case 'quarter':
        return {
          xs: 12,
          sm: 6,
          md: 3,
        };
      default:
        return {
          xs: 12,
          sm: 12,
        };
    }
  };

  const renderPage = () => {
    return _.map(data, (el) => {
      return (
        <div key={el.id}>
          <Row style={{ marginBottom: '32px' }}>
            <Col md={12} sm={12} xs={12}>
              <Typography size="1" text={el.title} />
            </Col>
            <Col md={12} sm={12} xs={12}>
              <BackButton />
            </Col>
          </Row>
          <Row>
            <Col md={8} sm={12} xs={12}>
              <Row>
                {_.map(el.easyWins, (elem, index) => {
                  const arr = elem.text && elem.text.split('#');
                  if (elem.theme === 'tooltip') {
                    return (
                      <Col
                        key={index}
                        {...sizeProps(elem.size)}
                        style={{ marginBottom: '32px' }}
                      >
                        <Styled.Tip>
                          <Styled.TipTitle>
                            {elem.icon ? (
                              <Icon icon={elem.icon} size={32} />
                            ) : null}
                            <Typography size="4" text="Did you know?" />
                          </Styled.TipTitle>
                          <Styled.TipContent>
                            {_.map(arr, (o, index) => {
                              return (
                                <i key={index}>
                                  <Typography size="p" text={o} />
                                </i>
                              );
                            })}
                          </Styled.TipContent>
                        </Styled.Tip>
                      </Col>
                    );
                  }
                  return (
                    <Col
                      {...sizeProps(elem.size)}
                      key={index}
                      style={{ display: 'flex' }}
                    >
                      <Styled.Box theme={elem.theme}>
                        <Styled.BoxTitle>
                          {elem.icon ? (
                            <Icon icon={elem.icon} size={34} />
                          ) : null}
                          <Typography size="4" text={elem.title} />
                        </Styled.BoxTitle>
                        <Styled.BoxContent>
                          {_.map(arr, (o, index) => {
                            return <Typography size="p" key={index} text={o} />;
                          })}
                        </Styled.BoxContent>
                        {elem.buttonText ? (
                          <Styled.BoxFooter>
                            {elem.theme === 'fix-leak' ||
                            elem.theme === 'fit-product' ? null : (
                              <hr />
                            )}
                            <div className="button-wrap">
                              <button
                                onClick={() => {
                                  elem.buttonPath?.substring(0, 1) === '/'
                                    ? navigate(elem.buttonPath)
                                    : window.open(
                                        `${elem.buttonPath}`,
                                        '_blank' // <- This is what makes it open in a new window.
                                      );
                                }}
                              >
                                {elem.buttonText}
                              </button>
                            </div>
                          </Styled.BoxFooter>
                        ) : null}
                      </Styled.Box>
                    </Col>
                  );
                })}
                {children}

                {el.downloadPDF ? (
                  <>
                    <Col
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ marginBottom: '8px' }}
                    >
                      <hr style={{ width: '100%' }} />
                    </Col>
                    <Col
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Styled.Download
                        onClick={() =>
                          window.open(
                            `${el.downloadPDF}`,
                            '_blank' // <- This is what makes it open in a new window.
                          )
                        }
                      >
                        Download PDF
                      </Styled.Download>
                    </Col>
                  </>
                ) : null}
              </Row>
            </Col>
            <Col md={4} sm={12} xs={12}>
              {el.poster === '' ? null : (
                <Styled.ImgWrap>
                  <img src={el.poster} alt="Image" />
                </Styled.ImgWrap>
              )}
              {el.emailCopy === '' ? null : (
                <Styled.EmailWrap>
                  <div className="content">
                    <Typography
                      size="p"
                      style={{ fontWeight: 700 }}
                      text="Email copy:"
                    />
                    <q>{el.emailCopy}</q>
                    <Styled.CopyText>
                      <Styled.CopyTextContent
                        onClick={() => {
                          setCopy(true);
                          navigator.clipboard.writeText(el.emailCopy);
                        }}
                        style={copy ? { border: '1px solid green' } : {}}
                      >
                        <div className="image">
                          <Icon
                            icon={copy ? 'checkRounded' : 'contract'}
                            size={30}
                          />
                        </div>
                        <div
                          className="text"
                          style={
                            copy ? { background: 'green', color: 'white' } : {}
                          }
                        >
                          <p>{copy ? 'Copied text' : 'Copy text'}</p>
                        </div>
                      </Styled.CopyTextContent>
                    </Styled.CopyText>
                  </div>
                </Styled.EmailWrap>
              )}
              {roiLength === 0 ? null : (
                <Styled.CalculatorWrap>
                  <Styled.Calculator>
                    <Typography
                      size="4"
                      text="ROI Calculator"
                      style={{ color: 'white', marginBottom: '16px' }}
                    />
                    <Styled.Mask />
                    <Select
                      onChange={(e) => {
                        setValue(e.target.value);
                      }}
                      style={{ marginBottom: '16px' }}
                      options={productOptions2}
                      value={value}
                      name="products"
                      placeholder="Products"
                    />
                    <p style={{ fontSize: '14px' }}>
                      Estimated payback on water saving products is:{' '}
                      <span style={{ fontSize: '20px', fontWeight: 700 }}>
                        {input !== '' ? prettifyIfNumber(roi, 1) : 0}
                      </span>{' '}
                      weeks.
                    </p>

                    <div style={{ maxWidth: '50%' }}>
                      <Input
                        id={el.id}
                        value={input}
                        label="Product price:"
                        type="number"
                        disabled={!value}
                        pattern="\d*"
                        maxLength={7}
                        onChange={handleChange}
                        placeholder="£ 0.00"
                      />{' '}
                    </div>
                  </Styled.Calculator>
                </Styled.CalculatorWrap>
              )}
              {roiLength === 0 ? null : (
                <Styled.Disclaimer>
                  <p style={{ fontSize: '12px' }}>
                    Savings will vary by business, employee water use and water
                    saving behaviour and strength of leak. The savings presented
                    are estimates and are not guaranteed by either Save Water
                    Save Money, your Water Retailer or your water Wholesaler.
                    Financial values are based on the information submitted via
                    GetWaterFit (number of appliances, staff), potential water
                    and energy saved at the cost of business water supply and
                    sewerage at £2.90 per m3, and energy price of £0.50 per kWh,
                    excluding standing charges.
                  </p>
                </Styled.Disclaimer>
              )}
            </Col>
          </Row>
        </div>
      );
    });
  };

  return <>{renderPage()}</>;
};

export default EasyWin;
