import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { colors } from '../../assets/variables';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 0;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 0;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  position: absolute;
  top: 3px;
  left: 0;
`;

const Title = styled.div`
  font-size: 16px;
  line-height: 1.6em;
  font-weight: bold;
  display: flex;
  align-items: center;
  vertical-align: middle;
  color: ${colors.primary};
`;
const Description = styled.div`
  line-height: 1.6em;
  font-size: 14px;
  color: ${colors.primary};
`;

const checkedStyles = css`
  background-color: ${colors.primary};
`;

const checkedRoundedStyles = css`
  background-color: #174489;
  border: 1px solid #174489;
  stroke: white;
`;

const hoverStyles = css`
  &:hover {
    background-color: #174489;
    border: 1px solid #174489;
    stroke: white;
    transition: all 200ms ease-in-out;
  }
`;
const uncheckedRoundedStyles = css`
  background-color: #25b3eb;
  border: 1px solid #25b3eb;
  stroke: #25b3eb;
  &:hover {
    ${props => !props.disabled && hoverStyles}
  }
`;
const uncheckedStyles = css`
  border: 2px solid ${colors.primary};
  background-color: ${colors.baseLight};
`;

const disabledStyles = css`
  border: 2px solid ${props => (props.checked ? '#25b3eb' : colors.disabled)};
  background-color: ${colors.baseLight};
  cursor: not-allowed;
  stroke: ${props => (props.checked ? '#25b3eb' : colors.disabled)};
`;

const Content = styled.div`
  display: block;
`;

const Label = styled.label`
  min-height: ${props => props.boxSize + 4}px;
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${Content} {
    margin-left: ${props => props.boxSize + 10}px;
    color: ${props => (props.disabled ? colors.disabled : 'inherit')};
    margin-top: 2px;
  }

  ${HiddenCheckbox} {
    ~ ${() => Icon} {
      background-color: ${colors.primary};
      width: ${props => props.boxSize}px;
      height: ${props => props.boxSize}px;
      ${props => (props.checked ? checkedStyles : uncheckedStyles)};
      ${props => (props.disabled ? disabledStyles : '')}
    }
    &:focus {
      ~ ${Icon} {
        box-shadow: 0 0 0 3px ${transparentize(0.5, colors.primary)};
      }
    }
  }
  ${Icon} {
    stroke: ${props => (props.disabled ? colors.disabled : colors.baseLight)};
  }

  ${Title} {
    color: ${props => (props.disabled ? colors.darkGray : colors.primary)};
    height: ${props => props.boxSize}px;
  }
  ${Description} {
    color: ${props => (props.disabled ? colors.darkGray : colors.primary)};
  }

  ${props => {
    if (props.theme === 'rounded') {
      return roundedStyle;
    }
  }};
`;

const FieldError = styled.div`
  font-size: 0.8em;
  color: ${colors.error};
  margin-top: 5px;
`;
const roundedStyle = css`
  ${HiddenCheckbox} {
    ~ ${() => Icon} {
      background-color: ${colors.baseLight};
      width: ${props => props.boxSize}px;
      height: ${props => props.boxSize}px;
      border-radius: 50%;
      stroke: ${props => (props.checked ? '#25b3eb' : 'white')};
      ${props => (props.checked ? checkedRoundedStyles : uncheckedRoundedStyles)};
      ${props => (props.disabled ? disabledStyles : '')}
    }
    &:focus {
      ~ ${Icon} {
        box-shadow: 0 0 0 3px ${transparentize(0.5, colors.primary)};
      }
    }
  }
  ${Icon} {
    stroke: ${props => (props.disabled ? colors.disabled : colors.baseLight)};
  }
`;

export default {
  Content,
  Icon,
  HiddenCheckbox,
  Label,
  Title,
  Description,
  FieldError,
};
