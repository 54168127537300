import styled, { css } from 'styled-components';
import { colors } from '../../assets/variables';
const disabled = css`
  cursor: not-allowed;
  box-shadow: none;
  color: ${colors.darkGray};
  :hover,
  :focus {
    box-shadow: none;
  }
`;

export const ButtonClean = styled.button`
  font-family: 'Open Sans', sans-serif;
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  color: inherit;
  text-decoration: none;
  background: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-transform: uppercase;
  ${(props) => (props.disabled ? disabled : '')}

  &:hover,
  &:focus {
    background: none;
    ${(props) => (props.disabled ? disabled : '')}
  }
`;
export default ButtonClean;
