import React from 'react';
import Styled from './Footer.style';
import { LinkHandler, Icon } from '../index';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import * as _ from 'lodash';
import { useResponsivity, footerItems } from '../../helpers/helpers';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export const Footer: React.FC = () => {
  const getYear = new Date().getFullYear();
  const [t] = useTranslation('common');
  const location = useLocation();
  console.log(location);
  const device = () => {
    if (useResponsivity()) {
      return 'mobile';
    }
    return 'desktop';
  };

  const renderFooterLinks = () => {
    return _.map(footerItems[0].navElements, (el) => {
      return (
        <Col md={4} sm={6} xs={12} key={el.path}>
          <LinkHandler internal path={el.path}>
            {t(el.name)}
          </LinkHandler>
        </Col>
      );
    });
  };
  const renderSocialMedia = () => {
    return _.map(footerItems[0].socialMedia, (el, index) => {
      return (
        <LinkHandler key={index} label={el.name} path={el.url}>
          <Styled.IconWrap>
            <Icon icon={el.name} size={16} />
          </Styled.IconWrap>
        </LinkHandler>
      );
    });
  };
  return (
    <Styled.Wrap isNotLanding={location.pathname !== '/'} layout={device()}>
      <Grid>
        <Styled.Image>
          <img
            src="https://cdn.getwaterfit.co.uk/static/files/logos/www-white.svg"
            alt="GetWaterFit"
          />
        </Styled.Image>
        <Styled.FooterTop>
          <Row>{renderFooterLinks()}</Row>
        </Styled.FooterTop>
        <Styled.Line />
        <Styled.FooterBottom>
          <Styled.TextWrap>
            <Styled.TextFirst>
              {t('portal.footer.copyright_pretext')}
            </Styled.TextFirst>
            <div style={{ fontSize: '12px' }}>
              Copyright © {getYear} Save Water Save Money Ltd.
            </div>
          </Styled.TextWrap>

          <Styled.SocialMedia>{renderSocialMedia()}</Styled.SocialMedia>
        </Styled.FooterBottom>
      </Grid>
    </Styled.Wrap>
  );
};

export default Footer;
