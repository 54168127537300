import styled, { css } from 'styled-components';
import { colors } from '../../assets/variables';
const Wrap = styled.div`
  display: flex;
  width: 100%;
  line-height: 1.3em;
  border-radius: 10px;
  border: 4px solid #d7f1ff;
  flex-direction: column;
  padding: 16px;
  ${(props) => {
    if (props.isDesktop) {
      return desktopStyles;
    }
  }}
  .small {
    color: ${colors.primary};
    text-align: center;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* number of lines to show */
    overflow: hidden;
    word-wrap: break-word;
    height: 62px;
  }
  .read-more {
    padding: 0px;
    margin: 0px 0px 16px 0px;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .description-popup {
    text-align: center;
  }
`;
const Btn = styled.button`
  padding: 6px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: 0.3s;
  border: 2px solid #d7f1ff;
  &:hover {
    background-color: ${(props) => (props.disabled ? '#ffff' : '#d7f1ff')};
    transition: 0.3s;
  }
  &:focus,
  &:active {
    background-color: ${(props) => (props.disabled ? '#ffff' : '#d7f1ff')};
    & div {
      background-color: ${(props) =>
        props.disabled ? '#ffff' : `${colors.primary}`};
      border: 2px solid
        ${(props) => (props.disabled ? '#d7f1ff' : `${colors.primary}`)};
    }
    & svg {
      path {
        fill: ${colors.baseLight};
      }
    }
  }
  & div {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${colors.action};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const ImageWrap = styled.div`
  position: relative;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin: 0 0 16px 0;
`;

const Image = styled.img`
  width: 150px;
  height: auto;
  &.image-popup {
    width: 250px;
    height: auto;
  }
`;

const Title = styled.div`
  margin: 0 0 8px 0;
`;

const Panel = styled.div`
  cursor: pointer;

  margin-bottom: 16px;
`;
const AccordionDesc = styled.div`
  margin-bottom: 6px;
  max-height: 45px;
  overflow: hidden;
  color: ${colors.primary};
  transition: max-height 0.2s ease-in-out;
  &.active {
    max-height: 290px;
    transition: max-height 0.2s ease-in-out;
  }
`;
const Description = styled.div`
  margin-bottom: 16px;
  color: ${colors.primary};
`;
const Count = styled.span`
  font-size: 48px;
  width: 50px;
  text-align: center;
  color: ${colors.primary};
  font-weight: 700;
`;
const CounterWrap = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding-top: 16px;
  width: 100%;
  border-top: 2px solid rgba(37, 179, 235, 0.48);
  &.custom-buttom-popup {
    border-top: 0px;
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

const Text = styled.div`
  font-size: 18px;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  color: ${(props) => {
    if (props.stockCheck === 'SUSPENDED') {
      return colors.error;
    } else if (props.stockCheck === 'COMINGSOON') {
      return colors.error;
    }
  }};
`;

const desktopStyles = css`
  flex-direction: column;
  ${ImageWrap} {
    margin: 0px auto 16px auto;
    width: 148px;
    min-width: 148px;
  }

  ${Title} {
    margin: 0 0 16px 0;
    text-align: left;
  }
  ${Description} {
    text-align: left;
  }
`;

export default {
  Text,
  Image,
  Panel,
  Btn,
  AccordionDesc,
  ImageWrap,
  Wrap,
  CounterWrap,

  Title,
  Description,
  Count,
};
