import * as React from 'react';

const washing = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M34.102 50.469a1 1 0 01.994.883l.007.117v4.572a1 1 0 01-1.994.116l-.007-.116v-4.572a1 1 0 011-1zm5.78 0a1 1 0 01.994.883l.007.117v4.572a1 1 0 01-1.993.116l-.007-.116v-4.572a1 1 0 011-1zm5.35 0a1 1 0 01.994.883l.006.117v4.572a1 1 0 01-1.993.116l-.007-.116v-4.572a1 1 0 011-1z"
          fill={colorPrimary}
        />
        <path
          d="M42.686 24.98a1 1 0 01.993.884l.007.116v3.11h.02c1.183 0 2.156.924 2.235 2.089l.005.153v2.848c.276.171.541.362.791.576l.244.222 5.311 5.114c.51.49.913 1.058 1.221 1.67a25.99 25.99 0 013.731-.292c14.258 0 25.857 11.6 25.857 25.858 0 14.258-11.599 25.858-25.857 25.858-1.66 0-3.32-.164-4.94-.478a6.626 6.626 0 01-3.977 1.566l-.293.007h-17.6a6.613 6.613 0 01-4.875-2.15 6.616 6.616 0 01-1.717-4.758l.02-.29 2.84-31.036c.024-.259.024-.52-.002-.78l-.034-.26-1.493-9.165a6.144 6.144 0 011.522-5.12l.184-.193 5.435-5.46a6.23 6.23 0 01.814-.68l-.015-.062-.01-.063v-2.932c0-1.173.907-2.136 2.055-2.231l.158-.008V26.98h-2.8a1 1 0 01-.118-1.993l.117-.007h10.171zm.041 10.284h-6.066a4.106 4.106 0 00-2.758 1.054l-.172.163-5.434 5.46a4.137 4.137 0 00-1.181 3.349l.031.23 1.493 9.165c.066.41.091.825.075 1.238l-.02.308-2.84 31.033a4.548 4.548 0 001.182 3.52 4.542 4.542 0 003.152 1.49l.244.007h17.601c1.3 0 2.5-.526 3.381-1.48a4.545 4.545 0 001.216-3.234l-.014-.258-2.486-30.482a6.113 6.113 0 01.034-1.316l.053-.326 1.88-9.905a4.118 4.118 0 00-1.03-3.582l-.163-.166-5.31-5.113a4.116 4.116 0 00-2.62-1.148l-.248-.007zm14.517 8.206c-1.05 0-2.099.091-3.137.228.072.534.08 1.08.009 1.627l-.053.328-.96 5.053a17.247 17.247 0 014.824-.693c9.547 0 17.315 7.767 17.315 17.315 0 3.46-1.018 6.8-2.942 9.66a.999.999 0 11-1.659-1.118 15.234 15.234 0 002.601-8.542c0-8.445-6.87-15.315-15.315-15.315-1.647 0-3.251.272-4.79.783l-.46.16-.495 2.602a4.12 4.12 0 00-.071.828l.013.277 2.082 25.518a15.38 15.38 0 003.721.463c2.327 0 4.562-.509 6.641-1.51a1 1 0 01.87 1.801 17.174 17.174 0 01-7.51 1.71c-1.029 0-2.048-.093-3.051-.273l-.5-.097.234 2.872a6.6 6.6 0 01-.842 3.784 24.16 24.16 0 003.475.255c13.155 0 23.857-10.703 23.857-23.858 0-13.155-10.702-23.858-23.857-23.858zM43.705 31.09h-8.36a.244.244 0 00-.234.178l-.009.064v1.932h8.844v-1.932a.243.243 0 00-.177-.233l-.064-.009zm-2.019-4.11h-4.37v2.11h4.37v-2.11z"
          fill={colorPrimary}
        />
        <path
          d="M44.682 61.597H35.36a4.192 4.192 0 00-4.175 3.753l-1.99 18.621a4.203 4.203 0 001.052 3.253 4.203 4.203 0 003.123 1.392h12.598a4.216 4.216 0 003.07-1.333 4.217 4.217 0 001.12-3.155l-1.285-18.621a4.213 4.213 0 00-4.19-3.91"
          fill={colorPrimary}
        />
        <path
          d="M47.575 85.918c-.42.45-.99.698-1.607.698H33.37a2.175 2.175 0 01-1.636-.729 2.174 2.174 0 01-.55-1.703l1.99-18.621a2.195 2.195 0 012.186-1.966h9.323c1.151 0 2.115.9 2.194 2.048l1.284 18.621a2.176 2.176 0 01-.586 1.652"
          fill={colorSecondary}
        />
        <path
          d="M27.658 22.744a5.836 5.836 0 00-5.829-5.829A5.836 5.836 0 0016 22.745a5.836 5.836 0 005.83 5.828 5.836 5.836 0 005.828-5.829"
          fill={colorPrimary}
        />
        <path
          d="M21.83 26.573A3.834 3.834 0 0118 22.744a3.834 3.834 0 013.83-3.829 3.834 3.834 0 013.828 3.83 3.834 3.834 0 01-3.829 3.828"
          fill={colorSecondary}
        />
        <path
          d="M25.682 14.137a3.572 3.572 0 003.568-3.569C29.25 8.601 27.65 7 25.682 7a3.572 3.572 0 00-3.568 3.568c0 1.968 1.6 3.569 3.568 3.569"
          fill={colorPrimary}
        />
        <path d="M25.658 9a1.57 1.57 0 010 3.137 1.57 1.57 0 010-3.137" fill={colorSecondary} />
      </g>
    </>
  );
};
export default washing;
