import * as React from 'react';

const mouse = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M58.38 20.72c.79 0 1.57.05 2.34.15 10.96 1.266 19.525 10.528 19.707 21.782l.003.368v30.3c0 12.177-9.811 22.11-21.942 22.308l-.369.003H45.61c-12.177 0-22.11-9.812-22.308-21.943l-.003-.368v-30.3c0-11.42 8.631-20.87 19.711-22.15.77-.1 1.55-.15 2.34-.15a6.406 6.406 0 00-.131.984l-.01.337v15.05c0 3.669 2.991 6.649 6.651 6.649 3.592 0 6.533-2.855 6.656-6.416l.004-.233V22.04c0-.45-.05-.901-.14-1.321zm2.42 2.45v13.92c0 4.441-3.268 8.143-7.533 8.813l-.26.037v13.358a1.141 1.141 0 01-2.277.124l-.007-.124V45.94c-4.298-.54-7.659-4.175-7.789-8.585l-.004-.265V23.17c-9.666 1.294-17.164 9.524-17.338 19.496l-.003.353v30.3c0 10.925 8.795 19.842 19.677 20.027l.344.003h12.51c10.925 0 19.833-8.804 20.017-19.686l.003-.344v-30.3c0-10.13-7.56-18.54-17.34-19.85zm-8.935 1.752a4.142 4.142 0 014.131 3.93l.005.207v7.551a4.141 4.141 0 01-4.136 4.137 4.141 4.141 0 01-4.131-3.93l-.006-.207v-7.551a4.141 4.141 0 014.136-4.137zm0 2.283c-.974 0-1.774.755-1.848 1.71l-.006.144v7.551a1.855 1.855 0 003.702.145l.005-.145v-7.551a1.856 1.856 0 00-1.854-1.854zM20.15 6c.63 0 1.14.51 1.14 1.14 0 1.05.36 1.91 1.12 2.63 2.933 2.804 10.695 2.849 15.515 2.679l3.112-.135c.382-.015.703-.024.953-.024 2.88 0 5.26.42 7.11 1.25 1.08.48 1.98 1.09 2.71 1.85 1.29 1.34 2.03 3.11 2.21 5.32.03.37.05.75.05 1.14a1.14 1.14 0 11-2.28 0 9.2 9.2 0 00-.038-.866l-.031-.274c-.221-2.1-1.131-3.63-2.741-4.64-.73-.46-1.61-.81-2.63-1.06-1.23-.29-2.69-.44-4.36-.44l-.361.005c-.2.006-.427.014-.68.025l-2.386.109c-5.278.212-14.01.263-17.733-3.289C19.62 10.26 19 8.82 19 7.14c0-.63.51-1.14 1.15-1.14z"
          fill={colorPrimary}
        />
        <path
          d="M53.72 29.059v7.551a1.854 1.854 0 01-3.707 0v-7.551c0-1.022.83-1.854 1.852-1.854 1.023 0 1.854.832 1.854 1.854"
          fill={colorSecondary}
        />
      </g>
    </>
  );
};
export default mouse;
