import { connect } from 'react-redux';
import ContactUs from './Contactus';
import { AppState } from '../../interfaces';
import { setCustomerService } from '../../actions/contact';

const mapStateToProps = (state: AppState) => ({
  contactForm: state.contactFormReducer,
});

const mapDispatchToProps = (dispatch) => ({
  setCustomerService: (data, onSucess, onFailed) =>
    dispatch(setCustomerService(data, onSucess, onFailed)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
