import * as React from 'react';

const faceSad = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M50.113 12c21.017 0 38.114 17.098 38.114 38.114S71.13 88.228 50.113 88.228C29.097 88.228 12 71.13 12 50.114S29.097 12 50.113 12zm0 2C30.2 14 14 30.201 14 50.114c0 19.913 16.2 36.114 36.113 36.114s36.114-16.201 36.114-36.114C86.227 30.201 70.027 14 50.113 14zm.776 42.817a.83.83 0 01.206.025c9.786.459 15.972 7.128 17.803 13.74a.853.853 0 11-1.644.455c-1.699-6.133-7.54-12.321-16.802-12.51-9.26.189-15.103 6.377-16.8 12.51a.854.854 0 01-1.644-.455c1.83-6.612 8.016-13.281 17.802-13.74a.83.83 0 01.206-.025h.873zM36.23 36.015a5.957 5.957 0 015.95 5.95 5.957 5.957 0 01-5.95 5.95 5.958 5.958 0 01-5.95-5.95c0-3.281 2.67-5.95 5.95-5.95zm27.767 0a5.957 5.957 0 015.95 5.95 5.957 5.957 0 01-5.95 5.95 5.958 5.958 0 01-5.95-5.95c0-3.281 2.67-5.95 5.95-5.95zm-27.767 2a3.955 3.955 0 00-3.95 3.95 3.956 3.956 0 003.95 3.95 3.955 3.955 0 003.95-3.95 3.955 3.955 0 00-3.95-3.95zm27.767 0a3.955 3.955 0 00-3.95 3.95 3.956 3.956 0 003.95 3.95 3.955 3.955 0 003.95-3.95 3.955 3.955 0 00-3.95-3.95z"
          fill={colorPrimary}
        />
        <path
          d="M36.23 38.015a3.955 3.955 0 013.95 3.95 3.955 3.955 0 01-3.95 3.95 3.956 3.956 0 01-3.95-3.95 3.955 3.955 0 013.95-3.95zm27.767 0a3.955 3.955 0 013.95 3.95 3.955 3.955 0 01-3.95 3.95 3.956 3.956 0 01-3.95-3.95 3.955 3.955 0 013.95-3.95z"
          fill={colorSecondary}
        />
      </g>
    </>
  );
};
export default faceSad;
