import { QuestionReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

const initialState: QuestionReducer = {
  status: {
    loading: false,
    error: false,
    done: false,
  },
  data: null,
  answers: {},
};

const questionReducer = createReducer(initialState, {
  GET_QUESTIONS_PENDING: (state) => {
    state.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  GET_QUESTIONS_FULFILLED: (state, action) => {
    state.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.data = action.payload.data;
  },

  GET_QUESTIONS_REJECTED: (state) => {
    state.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.data = null;
  },
  UPDATE_ANSWER_KEY: (state, action) => {
    state.answers = {
      ...state.answers,
      [action.key]: action.value,
    };
  },
  UPDATE_EDIT_KEYS: (state, action) => {
    state.answers = action.data;
  },
  CLEAR_ANSWER_KEY: (state) => {
    state.answers = {};
  },
});

export default questionReducer;
