import styled, { keyframes } from 'styled-components';
import { colors } from '../../assets/variables';

const skeletonLoading = keyframes`
  0% {
      background-color: hsl(201, 28.571428571428577%, 86.27450980392157%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
`;

const Box = styled.div`
  animation: ${skeletonLoading} 1s linear infinite alternate;
  height: 300px;
  border-radius: 10px;
`;

export default { Box };
