import React from 'react';
import {
  Typography,
  Wrap,
  Select,
  Questions,
  Button,
  Icon,
  Input,
  BackButton,
  Loader,
} from '../../components/index';
import { Row, Col } from 'react-styled-flexboxgrid';
import Styled from './AddRoom.style';
import { useParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { scrollToTop, useResponsivity } from '../../helpers/helpers';
interface AddRoomProps {
  questions: any;
  getQuestions: Function;
  addRoom: Function;
  clearAnswerKey: Function;
  room: any;
}

const AddRoom: React.FC<AddRoomProps> = (props) => {
  const { questions, room, getQuestions, addRoom, clearAnswerKey } = props;
  const { loading, done, error } = props.questions.status;

  const { locationId } = useParams();
  const [refresh, setRefresh] = React.useState(false);
  const navigate = useNavigate();
  const addRoomDone = _.get(room, 'addRoom.status.done');
  const addRoomLoading = _.get(room, 'addRoom.status.loading');
  const addRoomError = _.get(room, 'addRoom.status.error');
  const roomData = _.get(room, 'addRoom.data');
  const data = props.questions.answers;
  const isMobile = useResponsivity();
  React.useEffect(() => {
    // when the component is mounted, the alert is displayed for 3 seconds
    const timeId = setTimeout(() => {
      setRefresh(false);
      setRoom('');
      clearAnswerKey();
    }, 3000);
    return () => {
      clearTimeout(timeId);
    };
  }, []);
  const roomType = [
    { label: 'Washroom', value: 'washroom' },
    { label: 'Kitchen', value: 'kitchen' },
  ];
  const [selectRoom, setRoom] = React.useState('');
  const url =
    selectRoom === 'washroom'
      ? 'https://cdn.getwaterfit.co.uk/static/files/pdf/GWF-Washroom.pdf'
      : 'https://cdn.getwaterfit.co.uk/static/files/pdf/GWF-Kitchen.pdf';
  const handleRoomType = (e) => {
    if (e.currentTarget !== undefined) {
      setRoom(e.currentTarget.value);
      getQuestions(e.currentTarget.value);
      clearAnswerKey();
    }
  };
  React.useEffect(() => {
    if (selectRoom) {
      getQuestions(selectRoom);
    }
    scrollToTop();
  }, [getQuestions]);

  const renderMessage = () => {
    return (
      <Col md={12} sm={12} xs={12}>
        <Typography
          size="p"
          style={{ textAlign: 'center', marginTop: '32px', fontWeight: 600 }}
          text="You have not selected any room. Please select a room in order to see the questions."
        />
      </Col>
    );
  };
  const AddRoomSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
  });
  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          name: '',
        }}
        validationSchema={AddRoomSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          addRoom(locationId, selectRoom, values.name, data);
          setRefresh(true);
          scrollToTop();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => {
          return (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <Row>
                <Col xs={12} sm={12} md={6}>
                  <Input
                    id="name"
                    name="name"
                    value={values.name}
                    type="text"
                    placeholder="Room name*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.name && touched.name ? errors.name : undefined
                    }
                  />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Select
                    onChange={(e) => handleRoomType(e)}
                    value={selectRoom}
                    options={roomType}
                    name="room"
                    placeholder="Choose the type of your room"
                  />
                </Col>
                <Col xs={12} sm={12} md={12}>
                  <hr />
                </Col>
                {loading && <Loader />}
                {error && (
                  <Typography
                    size="p"
                    style={{
                      textAlign: 'center',
                      marginTop: '32px',
                      fontWeight: 600,
                    }}
                    text="There was a problem with"
                  />
                )}
                {questions.data && selectRoom !== '' && !loading ? (
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    style={{
                      marginTop: '16px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Styled.Download
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon icon="download" size={16} /> Download PDF
                    </Styled.Download>
                  </Col>
                ) : null}
                {questions.data && selectRoom !== '' && !loading ? (
                  <Col xs={12} md={12} sm={12}>
                    <Questions data={questions} />
                  </Col>
                ) : (
                  renderMessage()
                )}
                {selectRoom === '' || loading ? null : (
                  <Col
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flexDirection: isMobile ? 'column' : 'row',
                    }}
                  >
                    <Styled.Download
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        marginRight: isMobile ? '0px' : '16px',
                        marginBottom: isMobile ? '16px' : '0px',
                      }}
                    >
                      <Icon icon="download" size={16} /> Download PDF
                    </Styled.Download>

                    <Button
                      disabled={isSubmitting}
                      isLoading={addRoomLoading}
                      type="submit"
                    >
                      Create room
                    </Button>
                  </Col>
                )}
              </Row>
            </form>
          );
        }}
      </Formik>
    );
  };

  return (
    <Wrap>
      <Row>
        <Col md={12} sm={12} xs={12}>
          <Typography
            size="1"
            text="Add room"
            style={{ marginBottom: '32px' }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} sm={12} xs={12} style={{ marginBottom: '32px' }}>
          <BackButton />
        </Col>
      </Row>
      <Row>
        {addRoomDone && refresh ? (
          <Col md={12} sm={12} xs={12}>
            <Styled.Card>
              <Typography
                size="4"
                style={{
                  textAlign: 'center',
                  marginTop: '32px',
                  fontWeight: 600,
                }}
                text="Success!"
              />
              <Typography
                size="p"
                style={{
                  textAlign: 'center',
                  marginTop: '32px',
                  fontWeight: 400,
                }}
                text="New room created"
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '32px',
                  flexDirection: isMobile ? 'column' : 'row',
                }}
              >
                <Button
                  style={{
                    marginRight: '16px',
                    marginBottom: isMobile ? '16px' : '0px',
                  }}
                  onClick={() => navigate('/portal')}
                >
                  Home
                </Button>
                <Button
                  onClick={() => {
                    setRefresh(false);
                    clearAnswerKey();
                    setRoom('');
                  }}
                  style={{ marginRight: '16px' }}
                >
                  Create new room
                </Button>
              </div>
            </Styled.Card>
          </Col>
        ) : (
          <Col md={12} sm={12} xs={12}>
            <Styled.Card>{renderForm()}</Styled.Card>
          </Col>
        )}
      </Row>
    </Wrap>
  );
};

export default AddRoom;
