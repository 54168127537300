import * as React from 'react';

const employees = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <defs>
        <path id="employees" d="M0 .94h88.983V68H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(6 16.06)">
          <mask id="employees-1" fill="#fff">
            <use xlinkHref="#employees" />
          </mask>
          <path
            d="M70.22 64.353A1.65 1.65 0 0168.573 66h-48.67a1.65 1.65 0 01-1.648-1.647v-9.02a24.843 24.843 0 0118.808-24.142 15.86 15.86 0 007.175 1.72c2.576 0 5.003-.627 7.16-1.713 11.096 2.779 18.822 12.65 18.822 24.136v9.019zM30.25 16.926c0-7.712 6.276-13.986 13.988-13.986 7.71 0 13.986 6.274 13.986 13.986 0 7.712-6.275 13.986-13.986 13.986-7.712 0-13.987-6.274-13.987-13.986zm44.62 7.445a12.61 12.61 0 004.803-9.906c0-6.965-5.667-12.633-12.632-12.633a12.57 12.57 0 00-6.985 2.107 1 1 0 101.107 1.665 10.582 10.582 0 015.878-1.772c5.863 0 10.632 4.77 10.632 10.633 0 3.927-2.152 7.52-5.618 9.377l-.03.013c-.008.005-.02.008-.03.014-2.52 1.33-5.602 1.584-8.297.69a1 1 0 00-.63 1.898 12.6 12.6 0 003.973.639c1.938 0 3.85-.466 5.577-1.317a19.013 19.013 0 0114.366 18.496v7c0 .58-.473 1.055-1.055 1.055H72.035c-1.157-10.474-8.272-19.285-18.325-22.557 3.942-2.915 6.514-7.58 6.514-12.848C60.224 8.11 53.052.94 44.238.94 35.423.94 28.25 8.11 28.25 16.925c0 5.273 2.577 9.942 6.525 12.855a26.809 26.809 0 00-18.334 22.55H3.055A1.056 1.056 0 012 51.275v-7A19.012 19.012 0 0116.366 25.78c1.727.85 3.64 1.316 5.577 1.316 1.358 0 2.694-.215 3.972-.639a.999.999 0 10-.63-1.898c-2.695.893-5.78.64-8.3-.692l-.016-.007c-.013-.006-.027-.011-.04-.018a10.624 10.624 0 01-5.617-9.377c0-5.863 4.768-10.633 10.63-10.633 2.103 0 4.136.614 5.88 1.772a1 1 0 001.107-1.666 12.583 12.583 0 00-6.986-2.106c-6.965 0-12.631 5.667-12.631 12.633 0 3.909 1.8 7.534 4.803 9.905C5.707 27.299 0 35.188 0 44.276v7a3.059 3.059 0 003.055 3.055h13.242c-.012.334-.042.666-.042 1.004v9.019A3.652 3.652 0 0019.903 68h48.67a3.652 3.652 0 003.647-3.647v-9.02c0-.337-.03-.669-.043-1.003h13.752a3.059 3.059 0 003.055-3.055v-7c0-9.087-5.707-16.976-14.114-19.904z"
            fill={colorPrimary}
            mask="url(#employees-1)"
          />
        </g>
        <path
          d="M36.251 32.986C36.251 25.274 42.525 19 50.237 19c7.712 0 13.987 6.274 13.987 13.986 0 7.712-6.275 13.986-13.987 13.986s-13.986-6.274-13.986-13.986"
          fill="#D7F1FF"
        />
        <path
          d="M76.22 80.413a1.65 1.65 0 01-1.647 1.647h-48.67a1.65 1.65 0 01-1.648-1.647v-9.019a24.843 24.843 0 0118.808-24.143 15.86 15.86 0 007.175 1.721 15.86 15.86 0 007.16-1.714C68.495 50.037 76.22 59.909 76.22 71.394v9.019z"
          fill={colorSecondary}
        />
      </g>
    </>
  );
};
export default employees;
