import * as React from 'react';

const office = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M53.23 11a1 1 0 01.994.883l.007.117v28.863h35.948a1 1 0 01.993.883l.007.117v47.81a1 1 0 01-.884.993l-.116.007H8.999a1 1 0 01-.993-.883L8 89.673V12a1 1 0 01.883-.993L9 11h44.23zM89.18 42.863H54.23v45.81h8.186v-28.58a1 1 0 01.883-.992l.117-.007h14.865a1 1 0 01.993.883l.007.117v28.579h9.897v-45.81zM77.28 61.093H64.417v27.58H77.28v-27.58zM52.23 13H10v75.673h42.23V13z"
          fill={colorPrimary}
        />
        <path
          d="M28.52 19.832a1 1 0 01.993.883l.007.117V35.69a1 1 0 01-.883.993l-.117.007H13.662a1 1 0 01-.993-.884l-.007-.116V20.832a1 1 0 01.883-.993l.117-.007H28.52zm-1 2H14.663V34.69H27.52V21.833zM48.911 19.832a1 1 0 01.993.883l.007.117V35.69a1 1 0 01-.883.993l-.117.007H34.053a1 1 0 01-.993-.884l-.007-.116V20.832a1 1 0 01.883-.993l.117-.007h14.858zm-1 2H35.053V34.69h12.858V21.833zM28.34 42.478a1 1 0 01.993.883l.007.117v14.857a1 1 0 01-.883.993l-.117.007H13.482a1 1 0 01-.993-.883l-.007-.117V43.478a1 1 0 01.883-.993l.117-.007H28.34zm-1.001 2H14.482v12.857h12.857V44.478zM48.731 42.478a1 1 0 01.993.883l.007.117v14.857a1 1 0 01-.883.993l-.117.007H33.873a1 1 0 01-.993-.883l-.007-.117V43.478a1 1 0 01.883-.993l.117-.007h14.858zm-1 2H34.873v12.857h12.858V44.478zM28.52 64.832a1 1 0 01.993.883l.007.117V80.69a1 1 0 01-.883.993l-.117.007H13.662a1 1 0 01-.993-.884l-.007-.116V65.832a1 1 0 01.883-.993l.117-.007H28.52zm-1 2H14.663V79.69H27.52V66.833zM48.911 64.832a1 1 0 01.993.883l.007.117V80.69a1 1 0 01-.883.993l-.117.007H34.053a1 1 0 01-.993-.884l-.007-.116V65.832a1 1 0 01.883-.993l.117-.007h14.858zm-1 2H35.053V79.69h12.858V66.833z"
          fill={colorPrimary}
        />
        <path
          d="M27.52 66.833V79.69H14.663V66.833H27.52zm20.211-22.355v12.857H34.874V44.478h12.857zM27.52 21.833V34.69H14.663V21.833H27.52z"
          fill={colorSecondary}
        />
      </g>
    </>
  );
};
export default office;
