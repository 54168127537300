import React from 'react';
import Styled from './NothingToDisplay.style';
import { Button } from '../index';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
interface NothingToDisplay {
  title?: string;
  subtitle?: string;
  buttonText?: string;
  location?: string;
}

const NothingToDisplay = (props) => {
  const { title, subtitle, buttonText, location } = props;
  const [t] = useTranslation('common');
  const navigate = useNavigate();
  const noDatatitle = t('portal.no_data.we_have_problem');
  const noDatadesc2 = t('portal.no_data.we_have_problem_desc');
  return (
    <Styled.Wrap>
      <h3>{title ? title : noDatatitle}</h3>
      <div>{subtitle ? subtitle : noDatadesc2}</div>
      {location ? (
        <Styled.Button onClick={() => navigate(location)}>
          {buttonText}
        </Styled.Button>
      ) : null}
      <Styled.Mask />
    </Styled.Wrap>
  );
};

export default NothingToDisplay;
