import * as React from 'react';

const chart = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="nonzero">
        <path
          d="M45.444 16.304v36.16l25.91 25.91a36.313 36.313 0 01-25.91 10.819C25.324 89.193 9 72.869 9 52.749s16.324-36.445 36.444-36.445zM91 52.75a36.313 36.313 0 01-10.82 25.91l-25.862-25.91H91zM51.519 10.23c20.12 0 36.444 16.324 36.444 36.444H51.519V10.23z"
          fill={colorPrimary}
        />
        <path
          d="M44.457 17.54V52.75l25.695 25.603c-2.486 2.522-5.496 4.515-8.657 6.13-4.816 2.46-10.163 3.903-15.968 3.903-10.747 0-20.96-5.106-27.46-13.042-5.074-6.196-8.02-14.203-8.02-22.594 0-8.23 2.766-15.768 7.414-21.72 6.162-7.893 16.086-13.488 26.996-13.488zm45.43 35.88c0 9.615-3.49 17.744-9.855 24.019L56.397 53.577l33.49-.157zM57.674 11.75c1.777.302 3.293.844 4.717 1.251l.47.13c6.178 1.637 10.41 5.24 14.49 9.377 5.781 5.861 9.504 13.868 9.504 23.256H52.482V11.437c1.45 0 3.482.023 5.193.313z"
          fill={colorSecondary}
        />
      </g>
    </>
  );
};
export default chart;
