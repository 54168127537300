import { isFinite as _isFinite } from 'lodash';
import React from 'react';
import { range, colors } from '../assets/variables';
export function colorRange(index: number, colors: string[]) {
  if (colors) {
    const rangeLengthIndexed = colors.length;
    return colors[index % rangeLengthIndexed];
  }

  const rangeLengthIndexed = range.length;
  return range[index % rangeLengthIndexed];
}

export const numberFormatter = (
  number,
  fractionDigits = 0,
  thousandSeperator = ',',
  fractionSeperator = '.'
) => {
  if ((number !== 0 && !number) || !_isFinite(number)) return number;
  const frDigits = _isFinite(fractionDigits)
    ? Math.min(Math.max(fractionDigits, 0), 7)
    : 0;
  const num = number.toFixed(frDigits).toString();

  const parts = num.split('.');
  const digits = parts[0].split('').reverse();
  let sign = '';
  if (num < 0) {
    sign = digits.pop();
  }
  const final: any = [];
  let pos = 0;

  while (digits.length > 1) {
    final.push(digits.shift());
    pos++;
    if (pos % 3 === 0) {
      final.push(thousandSeperator);
    }
  }
  final.push(digits.shift());
  return `${sign}${final.reverse().join('')}${
    frDigits > 0 ? fractionSeperator : ''
  }${frDigits > 0 && parts[1] ? parts[1] : ''}`;
};

export const prettifyIfNumber = (
  stringOrNumber: string | number
): string | number => {
  if (typeof stringOrNumber === 'number') {
    return numberFormatter(stringOrNumber);
  }
  return stringOrNumber;
};

export const capitalizeFirstLetter = (string: any): any => {
  if (typeof string === 'string' || string instanceof String) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string;
};

export const pluralize = (count, noun, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

export const renderParameter = (parameter) => {
  const url_string = window.location.href;
  const myUrl = new URL(url_string.replace('#/', ''));
  const param = myUrl.searchParams.get(parameter);

  return param && param !== '' ? param : undefined;
};

export function useTypewriter(words) {
  const [wordIndex, setWordIndex] = React.useState(0);
  const [text, setText] = React.useState('');
  const [isDeleting, setIsDeleting] = React.useState(false);

  const type = React.useCallback(() => {
    // Current word
    const currentWord = words[wordIndex];
    // Determine the function to be performed
    const shouldDelete = isDeleting ? 1 : -1;
    // Create the new text
    setText((current) =>
      currentWord.substring(0, current.length - shouldDelete)
    );
    // Determine if this word is complete
    if (!isDeleting && text === currentWord) {
      // Make a pause at the end
      setTimeout(() => setIsDeleting(true), 1000);
    } else if (isDeleting && text === '') {
      setIsDeleting(false);
      // Move to the next word
      setWordIndex((current) => (current + 1) % words.length);
    }
  }, [isDeleting, text, wordIndex, words]);

  React.useEffect(() => {
    const timer = setTimeout(type, isDeleting ? 30 : 90);
    // Cleanup function to clear the timeout
    return () => clearTimeout(timer);
    // Add dependencies to the dependency array
  }, [wordIndex, isDeleting, text, type]);

  return text;
}
