import React from 'react';
import { Icon } from '../../components/index';
import Styled from './CounterControl.style';
import { CounterControlProps } from '../../interfaces';
const CounterControl: React.FC<CounterControlProps> = (props) => {
  const {
    answerKey,
    value,
    defaultValue,
    max,
    min,
    maxInGroup,
    updateAnswerKey = () => void 0,
  } = props;

  const currentValue = value != null ? value : parseInt(defaultValue, 10);

  const minusDisabled = currentValue === min;
  const plusDisabled = currentValue === max;

  const pmcNumberIncrease = (e) => {
    e && e.preventDefault(); //IOS double click fix
    if (currentValue < max) {
      updateAnswerKey(answerKey, currentValue + 1);
    }
  };

  const pmcNumberDecrease = (e) => {
    e && e.preventDefault(); //IOS double click fix
    if (currentValue > min) {
      updateAnswerKey(answerKey, currentValue - 1);
    }
  };

  return (
    <Styled.Wraper>
      <Styled.Indicator>
        <Styled.Button
          aria-label="Minus counter"
          type="button"
          disabled={minusDisabled}
          onClick={pmcNumberDecrease}
          data-test="pmcMinus"
        >
          <Styled.CenterCore>
            <Icon icon="minus" size={12} />
          </Styled.CenterCore>
        </Styled.Button>
        <Styled.IndicatorNumber className="pmcNumber">
          {currentValue}
        </Styled.IndicatorNumber>
        <Styled.Button
          aria-label="Plus counter"
          type="button"
          disabled={plusDisabled || maxInGroup}
          onClick={pmcNumberIncrease}
          data-test="pmcPlus"
        >
          <Styled.CenterCore>
            <Icon icon="plus" size={12} colorPrimary="#000" />
          </Styled.CenterCore>
        </Styled.Button>
      </Styled.Indicator>
    </Styled.Wraper>
  );
};

export default CounterControl;
