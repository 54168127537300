import * as React from 'react';

const plus = (props: any) => {
  const { colorPrimary } = props;

  return (
    <>
      <path
        d="M88.763 47.131H52.13v-36.63a2.5 2.5 0 10-5 0v36.63H10.5a2.5 2.5 0 100 5h36.63v36.631a2.5 2.5 0 105 0v-36.63h36.633a2.5 2.5 0 100-5"
        fill={colorPrimary}
        fillRule="evenodd"
      />
    </>
  );
};
export default plus;
