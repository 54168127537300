import * as React from 'react';

const droplet = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M56.63 90.102c-14.281 0-25.9-11.62-25.9-25.901 0-12.93 21.378-46.457 25.9-53.405 4.524 6.948 25.902 40.473 25.902 53.405 0 14.282-11.62 25.9-25.901 25.9"
          fill={colorSecondary}
        />
        <path
          d="M57.463 8.418l1.026 1.56C63.502 17.662 84.532 50.71 84.532 64.2c0 15.384-12.516 27.9-27.901 27.9S28.73 79.585 28.73 64.202c0-14.988 25.964-54.124 27.069-55.783.37-.557 1.293-.557 1.664 0zm-.832 2.378l-1.603 2.487C48.433 23.612 30.73 52.435 30.73 64.201c0 14.282 11.619 25.9 25.9 25.9 14.283 0 25.902-11.618 25.902-25.9 0-12.931-21.378-46.457-25.901-53.405zM19.2 73.785a1 1 0 01.993.884l.006.116v5.2h5.2a1 1 0 01.117 1.994l-.117.006h-5.2v5.2a1 1 0 01-1.993.117l-.007-.117v-5.2H13a1 1 0 01-.116-1.993l.116-.007h5.2v-5.2a1 1 0 011-1zm54.744-10.684a1 1 0 01.988.853l.01.114c.011.318.195 7.831-4.88 13.076-3.11 3.212-7.485 4.841-13.003 4.841a1 1 0 110-2c4.956 0 8.846-1.424 11.564-4.232 4.048-4.18 4.312-10.198 4.322-11.404v-.215a.999.999 0 01.85-1.021l.15-.012zm6.202-53.098a1 1 0 01.993.884l.007.116v5.2h5.2a1 1 0 01.116 1.993l-.116.007h-5.2v5.2a1 1 0 01-1.994.117l-.006-.117v-5.2h-5.2a1 1 0 01-.117-1.993l.117-.007h5.2v-5.2a1 1 0 011-1z"
          fill={colorPrimary}
        />
      </g>
    </>
  );
};
export default droplet;
