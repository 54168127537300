import styled from 'styled-components';
import { Col } from 'react-styled-flexboxgrid';

const Wrap = styled.div`
  display: block;
  margin-bottom: 32px;
  font-family: 'Open sans', sans-serif;
`;
const ToggleWrap = styled.div`
  height: 38px;
  width: 140px;
  border: 2px solid #174489;
  border-radius: 20px;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-bottom: 16px;
`;
const Item = styled.div`
  padding: 8px;
  width: 90px;
  cursor: pointer;
  text-align: center;
  border-right: 1px solid #174489;
  &:last-child {
    border-right: 0px;
  }
  &.active {
    background: #174489;
    color: white;
  }
  &.inactive {
    color: #174489;
    background: white;
  }
`;
const Column = styled(Col)`
  margin-bottom: 32px;

  @media screen and (max-width: 700px) {
    padding: 0;
  }
`;

const Box = styled.div`
  width: 100%;
  line-height: 1.4em;
  border-radius: 10px;
  border: 4px solid #d7f1ff;
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  flex-direction: column;
  height: auto;
  padding: 20px 20px 40px;
  margin-bottom: 16px;
  margin-top: 32px;
`;

const DropdownWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
`;

export default { Wrap, Column, Box, ToggleWrap, Item, DropdownWrap };
