import * as React from 'react';

const glassWater = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M70.017 12a3.62 3.62 0 013.614 3.764l-.016.217-7.166 70.607a3.605 3.605 0 01-3.406 3.247l-.192.005H37.05a3.598 3.598 0 01-3.58-3.097l-.022-.193-6.433-70.606a3.627 3.627 0 01.931-2.767 3.628 3.628 0 012.455-1.17l.216-.007h39.401zm-7.995 20.375c-1.476 0-2.222.671-3.095 1.548l-.404.406c-.974.968-2.218 2.046-4.425 2.046-2.3 0-3.555-1.174-4.548-2.173l-.43-.433c-.809-.8-1.551-1.394-2.94-1.394-1.477 0-2.222.671-3.095 1.548l-.404.406c-.973.968-2.216 2.046-4.422 2.046-2.014 0-3.226-.899-4.162-1.793l-.842-.837c-.712-.699-1.382-1.231-2.488-1.342l-.246-.017L35.44 86.37a1.607 1.607 0 001.463 1.464l.147.006h25.802a1.61 1.61 0 001.588-1.31l.02-.144 5.078-50.039c-1.934-.125-3.089-1.103-3.998-2.001l-.574-.576c-.81-.8-1.552-1.395-2.943-1.395zm-.832 36.149a3.409 3.409 0 013.404 3.405 3.408 3.408 0 01-3.404 3.404 3.408 3.408 0 01-3.405-3.404 3.409 3.409 0 013.405-3.405zm0 2c-.774 0-1.405.63-1.405 1.405 0 .774.63 1.404 1.405 1.404.774 0 1.404-.63 1.404-1.404 0-.775-.63-1.405-1.404-1.405zM40.7 51.692a4.964 4.964 0 014.957 4.958 4.963 4.963 0 01-4.957 4.957 4.964 4.964 0 01-4.958-4.957 4.965 4.965 0 014.958-4.958zm0 2a2.961 2.961 0 00-2.958 2.958 2.96 2.96 0 002.958 2.957 2.96 2.96 0 002.957-2.957 2.96 2.96 0 00-2.957-2.958zm20.889-4.264a3.008 3.008 0 013.004 3.004 3.008 3.008 0 01-3.004 3.003 3.008 3.008 0 01-3.004-3.004 3.008 3.008 0 013.004-3.003zm0 2a1.005 1.005 0 10.001 2.009 1.005 1.005 0 00-.001-2.01zM70.017 14h-39.4c-.46 0-.885.186-1.194.526A1.59 1.59 0 0029 15.592l.007.17 1.331 14.613c2.11 0 3.34.986 4.295 1.92l.685.685c.81.801 1.552 1.395 2.941 1.395 1.389 0 2.131-.594 2.941-1.394l.557-.558c.973-.97 2.217-2.048 4.422-2.048 2.11 0 3.34.986 4.294 1.92l.685.685c.81.801 1.552 1.395 2.94 1.395 1.478 0 2.224-.67 3.096-1.547l.404-.406c.974-.97 2.218-2.047 4.424-2.047 2.112 0 3.34.986 4.296 1.92l.71.708c.708.695 1.375 1.226 2.468 1.341l.243.018 1.886-18.583a1.597 1.597 0 00-.407-1.246 1.6 1.6 0 00-1.2-.533z"
          fill={colorPrimary}
        />
        <path
          d="M61.19 70.524c.774 0 1.404.63 1.404 1.405 0 .774-.63 1.404-1.404 1.404-.774 0-1.405-.63-1.405-1.404 0-.775.63-1.405 1.405-1.405zM40.7 53.692a2.96 2.96 0 012.957 2.958 2.96 2.96 0 01-2.957 2.957 2.96 2.96 0 01-2.958-2.957 2.961 2.961 0 012.958-2.958zm20.889-2.264a1.005 1.005 0 11-.001 2.009 1.005 1.005 0 01.001-2.01z"
          fill={colorSecondary}
        />
      </g>
    </>
  );
};
export default glassWater;
