import React from 'react';
import App from '../App';

const AppWrap: React.FC = () => {
  const componentRef = React.createRef<HTMLDivElement>();
  return (
    <div ref={componentRef}>
      <App />
    </div>
  );
};

export default AppWrap;
