import * as React from 'react';

const dRotate = (props: any) => {
  const { colorPrimary } = props;

  return (
    <>
      <path
        d="M87.224 49.797c.516 0 .935.418.935.935C88.159 71.773 71.04 88.89 50 88.89c-7.354 0-14.416-2.07-20.55-6.003l-.482-.314L27.065 90l-7.932-18.456 18.587 7.622-7.43 2.037c5.871 3.81 12.648 5.819 19.71 5.819 20.01 0 36.29-16.28 36.29-36.29 0-.517.418-.935.934-.935zM70.124 10l9.232 17.84-19.084-6.271 7.299-2.576A36.208 36.208 0 0050 14.442c-20.01 0-36.29 16.28-36.29 36.29a.934.934 0 11-1.869 0c0-21.042 17.118-38.16 38.159-38.16 6.399 0 12.65 1.616 18.224 4.652l.537.298L70.124 10z"
        fill={colorPrimary}
        fillRule="evenodd"
      />
    </>
  );
};
export default dRotate;
