import React from 'react';
import {
  Wrap,
  Typography,
  Questions,
  Button,
  Input,
  BackButton,
  Notifications,
} from '../../components/index';
import { useParams } from 'react-router-dom';
import * as _ from 'lodash';
import Styled from './EditRoom.style';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { scrollToTop } from '../../helpers/helpers';
interface EditRoomProps {
  getQuestions: Function;
  listLocation: any;
  questions: any;
  updateEditKeys: Function;
  editRoom: Function;
  room: any;
}

const EditRoom: React.FC<EditRoomProps> = (props) => {
  const {
    getQuestions,
    room,
    listLocation,
    questions,
    editRoom,
    updateEditKeys,
  } = props;
  const { roomId, locationId } = useParams();

  const data = _.get(listLocation, 'data.data');
  const findLocation = _.find(data, function (o) {
    return o.id == locationId;
  });
  const [refresh, setRefresh] = React.useState(false);
  const getRooms = _.get(findLocation, 'rooms');
  const findRoom = _.find(getRooms, function (o) {
    return o.id == roomId;
  });
  const { error, done, loading } = _.get(room, 'editRoom.status');

  const editMessage = _.get(room, 'editRoom.data.message');

  const getRoomType = _.get(findRoom, 'type');
  const getRoomData = _.get(findRoom, 'data');
  const getRoomName = _.get(findRoom, 'name');

  if (refresh === true) {
    setTimeout(() => {
      setRefresh(false);
    }, 10000);
  }
  React.useEffect(() => {
    if (getRoomType) {
      getQuestions(getRoomType);
      updateEditKeys(getRoomData);
    }
    scrollToTop();
  }, [getQuestions, getRoomType]);
  const answers = _.get(questions, 'answers');
  const EditRoomSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
  });
  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          name: getRoomName ? getRoomName : '',
        }}
        validationSchema={EditRoomSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          editRoom(roomId, getRoomType, values.name, answers);
          setRefresh(true);
          scrollToTop();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => {
          return (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <Row>
                <Col xs={12} sm={12} md={6}>
                  <Input
                    id="name"
                    name="name"
                    value={values.name}
                    type="text"
                    placeholder="Room name*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.name && touched.name ? errors.name : undefined
                    }
                  />
                </Col>
                <Col md={6} xs={12} sm={12}>
                  <Styled.RoomTypeText>
                    Room type: <span>{getRoomType}</span>
                  </Styled.RoomTypeText>
                </Col>
                <Col xs={12} sm={12} md={12}>
                  <hr />
                </Col>
                <Col md={12} sm={12} xs={12}>
                  <Questions data={questions} />
                </Col>
                <Col
                  md={12}
                  xs={12}
                  sm={12}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Button
                    disabled={isSubmitting}
                    isLoading={loading}
                    type="submit"
                  >
                    Edit room
                  </Button>
                </Col>
                {errors.name ? (
                  <Col md={12} sm={12} xs={12}>
                    <Notifications type="ERROR">
                      Room name: {errors.name}
                    </Notifications>
                  </Col>
                ) : null}
              </Row>
            </form>
          );
        }}
      </Formik>
    );
  };

  return (
    <Wrap>
      <Row>
        <Col md={12} sm={12} xs={12}>
          <Typography
            size="1"
            text="Edit room"
            style={{ marginBottom: '32px' }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} sm={12} xs={12} style={{ marginBottom: '32px' }}>
          <BackButton />
        </Col>
      </Row>
      <Row>
        {done && refresh ? (
          <Col md={12} sm={12} xs={12}>
            <Styled.Card>
              <div style={{ textAlign: 'center' }}>
                <Typography text="Success" size="3" />{' '}
                <Typography text={editMessage} size="p" />
              </div>
            </Styled.Card>
          </Col>
        ) : (
          <Col md={12} sm={12} xs={12}>
            <Styled.Card>{renderForm()}</Styled.Card>
          </Col>
        )}
      </Row>
    </Wrap>
  );
};

export default EditRoom;
