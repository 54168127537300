import * as React from 'react';

const refresh = (props: any) => {
  const { colorPrimary } = props;

  return (
    <>
      <path
        d="M85.05 34.45C78.81 19.766 64.156 10 48.112 10 25.994 10 8 27.994 8 50.112 8 72.23 25.994 90.224 48.112 90.224a1 1 0 100-2C27.097 88.224 10 71.127 10 50.112S27.097 12 48.112 12c15.115 0 28.926 9.123 34.939 22.878l-7.81-2.612 11.983 17.846 3.942-21.13-6.116 5.469z"
        fill={colorPrimary}
        fillRule="evenodd"
      />
    </>
  );
};
export default refresh;
