import * as React from 'react';

const results = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M22.726059,12.3770953 L50,28.4540956 M47.8226094,27.055798 L77.682171,39.4897496"
        stroke={colorPrimary}
        strokeWidth="2"
      />
      <path
        d="M32.4521179,27.2304236 L32.4521179,90.6667384 L13,90.6667384 L13,27.2304236 L32.4521179,27.2304236 Z M59.7548135,41.5158163 L59.7548135,90.6667384 L40.3026955,90.6667384 L40.3026955,41.5158163 L59.7548135,41.5158163 Z M76.3563538,34.1120677 C77.6173952,34.1120677 78.759054,34.6232058 79.5854523,35.4496041 C80.4118507,36.2760025 80.9229888,37.4176613 80.9229888,38.6787027 C80.9229888,39.9397442 80.4118507,41.0814029 79.5854523,41.9078013 C78.759054,42.7341997 77.6173952,43.2453378 76.3563538,43.2453378 C75.0953123,43.2453378 73.9536536,42.7341997 73.1272552,41.9078013 C72.3008568,41.0814029 71.7897187,39.9397442 71.7897187,38.6787027 C71.7897187,37.4176613 72.3008568,36.2760025 73.1272552,35.4496041 C73.9536536,34.6232058 75.0953123,34.1120677 76.3563538,34.1120677 Z"
        stroke={colorPrimary}
        strokeWidth="2"
        fill="#EEF3FB"
      />
      <path
        d="M86.0824127,52.2258681 L86.0824127,90.6667384 L66.6302948,90.6667384 L66.6302948,52.2258681 L86.0824127,52.2258681 Z M50,22.9787977 C51.2610414,22.9787977 52.4027002,23.4899357 53.2290986,24.3163341 C54.055497,25.1427325 54.566635,26.2843913 54.566635,27.5454327 C54.566635,28.8064741 54.055497,29.9481329 53.2290986,30.7745313 C52.4027002,31.6009297 51.2610414,32.1120677 50,32.1120677 C48.7389586,32.1120677 47.5972998,31.6009297 46.7709014,30.7745313 C45.944503,29.9481329 45.433365,28.8064741 45.433365,27.5454327 C45.433365,26.2843913 45.944503,25.1427325 46.7709014,24.3163341 C47.5972998,23.4899357 48.7389586,22.9787977 50,22.9787977 Z M22.726059,7.81046024 C23.9871004,7.81046024 25.1287592,8.32159828 25.9551576,9.14799668 C26.7815559,9.97439507 27.292694,11.1160538 27.292694,12.3770953 C27.292694,13.6381367 26.7815559,14.7797955 25.9551576,15.6061939 C25.1287592,16.4325923 23.9871004,16.9437303 22.726059,16.9437303 C21.4650175,16.9437303 20.3233588,16.4325923 19.4969604,15.6061939 C18.670562,14.7797955 18.1594239,13.6381367 18.1594239,12.3770953 C18.1594239,11.1160538 18.670562,9.97439507 19.4969604,9.14799668 C20.3233588,8.32159828 21.4650175,7.81046024 22.726059,7.81046024 Z"
        stroke={colorPrimary}
        strokeWidth="2"
        fill={colorSecondary}
      />
    </g>
  );
};
export default results;
