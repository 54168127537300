import * as React from 'react';

const play = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path fill="#D7F1FF" d="M77.168 72.272H11V28.307h78.666v43.965z" />
        <path
          d="M90.667 20a1 1 0 01.993.884l.007.116v58.58a1 1 0 01-.883.993l-.117.007H10a1 1 0 01-.993-.883L9 79.58V21a1 1 0 01.883-.993L10 20h80.667zm-1 54.272H78.169v4.308h11.498v-4.308zm-13.499 0H64.671v4.308h11.497v-4.308zm-13.497 0H51.174v4.308h11.497v-4.308zm-26.993 0h-11.18v4.308h11.18v-4.308zm-13.18 0H11v4.308h11.498v-4.308zm26.677 0h-11.18v4.308h11.18v-4.308zm40.492-45.964H11v43.964h78.667V28.308zM22.498 22H11v4.308h11.498V22zm26.676 0h-11.18v4.308h11.18V22zm13.497 0H51.174v4.308h11.497V22zm13.497 0H64.671v4.308h11.497V22zm13.499 0H78.169v4.308h11.498V22zm-53.99 0h-11.18v4.308h11.18V22z"
          fill={colorPrimary}
        />
        <path
          d="M42.925 39.683c.27-.156.597-.175.881-.059l.12.059 16.871 9.74a1 1 0 01.1 1.668l-.1.065-16.872 9.74a.99.99 0 01-.5.135 1 1 0 01-.993-.882l-.007-.118V40.549a1 1 0 01.5-.866zm1.5 2.598v16.018l13.872-8.01-13.872-8.008z"
          fill={colorPrimary}
        />
        <path fill={colorSecondary} d="M44.425 58.299V42.28l13.872 8.009z" />
      </g>
    </>
  );
};
export default play;
