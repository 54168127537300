import styled, { css } from 'styled-components';
import { colors } from '../../assets/variables';
const ImgWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 32px;
  img {
    width: 300px;
  }
  @media screen and (max-width: 500px) {
    justify-content: flex-start;
  }
`;
const Tip = styled.div`
  border: 4px solid ${colors.tertiary};
  border-radius: 10px;
  position: relative;
`;
const TipTitle = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -16px;
  left: 16px;
  background: rgb(248, 250, 252);
  padding: 0px 16px;
  h4 {
    margin-bottom: 0px;
    padding-bottom: 0px;
    margin-left: 8px;
  }
`;
const TipContent = styled.div`
  padding: 32px 32px 16px;
`;
const CalculatorWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;
const Calculator = styled.div`
  width: 300px;
  background-image: linear-gradient(
    to right bottom,
    rgb(14, 165, 233),
    rgb(2, 132, 199)
  );
  position: relative;
  border-radius: 10px;
  padding: 14px;
  min-height: 90px;
  overflow: hidden;
  color: white;
  margin-bottom: 16px;
`;

const Mask = styled.div`
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='207' height='207' fill='none'%3E%3Cpath fill='%23000' d='M138.648 181.408C47.268 232.569 1.327 206.607.824 103.52.324.432 46.014-25.148 137.896 26.777c91.882 51.925 92.133 103.469.753 154.631h-.001z'/%3E%3C/svg%3E");
  background-color: hsla(0, 0%, 100%, 0.2);
  position: absolute;
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
  top: 0;
  right: 0;
  width: 4rem;
  margin: -0.75rem;
  height: 4rem;
`;
const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  button {
    background: white;
    color: ${colors.primary};
    border: 1px solid ${colors.primary};
    &:hover {
      background: ${colors.primary};
      color: white;
      border: 1px solid ${colors.primary};
    }
  }
`;

const EmailWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  .content {
    width: 300px;
    border: 4px solid ${colors.tertiary};
    border-radius: 10px;
    padding: 16px;
  }
`;

const CopyText = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;
const CopyTextContent = styled.div`
  max-width: 180px;
  align-items: center;
  border: 1px solid ${colors.action};
  border-radius: 6px;
  display: flex;
  cursor: pointer;
  background: white;
  .image {
    margin: 0px 8px;
  }
  .text {
    background: ${colors.action};
    text-transform: uppercase;
    p {
      margin: 8px 16px;
      min-width: 98px;
      text-align: center;
    }
  }
`;

const Disclaimer = styled.div`
  display: flex;
  justify-content: flex-end;
  p {
    max-width: 300px;
  }
`;
const Download = styled.a`
  font-family: 'Open Sans', sans-serif;
  transition: all 300ms;
  font-size: 16px;
  font-weight: 600;
  color: ${colors.primary};
  background-color: ${colors.baseLight};
  line-height: 1.15;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 16px 20px;
  text-decoration: none;
  border-radius: 10px;
  border: 2px solid ${colors.primary};
  text-align: left;
  cursor: pointer;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  text-transform: uppercase;
  margin: 0;
  :hover,
  :focus {
    color: ${colors.action};
    border: 2px solid ${colors.action};
    transition: all 300ms;
    svg {
      path {
        transition: all 300ms;
        fill: ${colors.action};
      }
    }
  }
`;
const Box = styled.div`
  background: white;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(48, 46, 56, 0.06) 0px 3px 10px 0px;
  ${(props) => {
    switch (props.theme) {
      case 'blue':
        return css`
          border: 2px solid ${colors.action};
        `;
      case 'intro':
        return css`
          background: transparent;
          padding: 0px;
          box-shadow: none;
          ${BoxContent} {
            min-height: auto;
            padding-bottom: 0px;
          }
        `;
      case 'fix-leak':
        return css`
          background-color: rgba(16, 185, 129, 0.15);
          border: 2px solid rgb(16, 185, 129);
          padding: 16px;
          box-shadow: none;
          ${BoxContent} {
            min-height: auto;
            padding-bottom: 0px;
          }
          ${BoxTitle} {
            text-decoration: none;
          }
          ${BoxFooter} {
            .button-wrap {
              button {
                text-transform: capitalize;
                color: ${colors.baseLight};
                background: rgb(16, 185, 129);
                border-radius: 14px;
                padding: 4px 16px;

                &:hover {
                  background: rgba(16, 185, 129, 0.8);
                }
              }
            }
          }
        `;
      case 'fit-product':
        return css`
          background-color: rgba(14, 165, 233, 0.15);
          border: 2px solid rgb(14, 165, 233);
          padding: 16px;
          box-shadow: none;
          ${BoxContent} {
            min-height: auto;
            padding-bottom: 0px;
          }
          ${BoxTitle} {
            text-decoration: none;
          }
          ${BoxFooter} {
            .button-wrap {
              button {
                text-transform: capitalize;
                color: ${colors.baseLight};
                background: rgb(14, 165, 233);
                border-radius: 14px;
                padding: 4px 16px;

                &:hover {
                  background: rgba(14, 165, 233, 0.8);
                }
              }
            }
          }
        `;
      default:
        return css`
          background: ${colors.tertiary};
          padding: 0px;
          overflow: hidden;

          ${BoxTitle} {
            text-decoration: none;
            padding: 16px;
          }
          ${BoxContent} {
            background: white;
            padding: 16px;
            border-radius: 16px 16px 0px 0px;
          }
          ${BoxFooter} {
            background: white;
            padding: 0px 16px 16px;
          }
        `;
    }
  }}
`;
const BoxTitle = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  text-decoration: underline;
  text-decoration-color: ${colors.action};
  svg {
    margin-right: 8px;
  }
  h4 {
    margin: 0px;
    padding: 0px;
  }
`;
const BoxContent = styled.div`
  min-height: 150px;
  padding-bottom: 16px;
  flex-grow: 1;
`;
const BoxFooter = styled.div`
  hr {
    border-top: 0.5px solid ${colors.tertiary};
    margin-block-start: 0px;
    margin-block-end: 0px;
  }
  .button-wrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    button {
      background: transparent;
      border: 0px;
      text-transform: uppercase;
      font-size: 14px;
      color: ${colors.primary};
      font-weight: 600;
      transition: all 300ms;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
export default {
  ImgWrap,
  Tip,
  TipTitle,
  CalculatorWrap,
  Calculator,
  TipContent,
  ButtonWrap,
  Mask,
  EmailWrap,
  CopyText,
  CopyTextContent,
  Disclaimer,
  Download,
  Box,
  BoxTitle,
  BoxContent,
  BoxFooter,
};
