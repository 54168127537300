import * as React from 'react';

const bulb = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M49.315 20.447c13.107 0 23.77 10.664 23.77 23.771 0 10.671-3.92 15.256-7.648 19.033l-1.455 1.465c-2.383 2.419-4.356 4.735-4.66 8.702 1.713.157 3.064 1.586 3.064 3.34a3.357 3.357 0 01-1.721 2.921 3.2 3.2 0 01.362 1.236l.009.224v.25a3.16 3.16 0 01-.833 2.128c.465.515.773 1.175.825 1.907l.008.222v.25a3.182 3.182 0 01-3.043 3.17c-.518 4.336-4.213 7.712-8.686 7.712-4.474 0-8.17-3.376-8.686-7.712a3.184 3.184 0 01-3.038-2.99l-.005-.18v-.25c0-.82.32-1.563.833-2.129a3.165 3.165 0 01-.825-1.906l-.008-.222v-.25c0-.528.142-1.02.37-1.46a3.357 3.357 0 01-1.72-2.92c0-1.714 1.289-3.118 2.945-3.329-.287-4.479-2.373-6.979-4.891-9.59l-1.635-1.685c-3.502-3.653-7.103-8.134-7.103-17.937 0-13.107 10.664-23.77 23.77-23.77zm6.54 68.633H42.758c.509 3.16 3.248 5.585 6.55 5.585 3.218 0 5.903-2.305 6.506-5.35l.043-.235zm1.997-4.507h-17.09c-.552 0-1.008.42-1.066.956l-.006.117v.25c0 .552.419 1.008.955 1.066l.117.006h17.09c.551 0 1.006-.419 1.064-.956l.007-.117v-.249c0-.552-.418-1.008-.955-1.066l-.116-.007zm.303-4.444H40.459a1.065 1.065 0 00-.763.891l-.006.12v.25c0 .55.419 1.007.955 1.065l.117.006h17.09c.551 0 1.006-.419 1.064-.955l.007-.117v-.25c0-.443-.275-.81-.66-.971l-.108-.039zm.86-4.507H39.598a1.136 1.136 0 00-.124 2.264l.124.007h19.417c.626 0 1.136-.509 1.136-1.135 0-.584-.445-1.067-1.013-1.13l-.123-.006zm-9.7-53.175c-12.004 0-21.771 9.767-21.771 21.771 0 9.181 3.291 13.177 6.706 16.719l1.64 1.689c2.58 2.691 4.858 5.485 5.255 10.34l.03.421h7.084V55.904c-3.747-.514-6.645-3.728-6.645-7.613a1.056 1.056 0 012.113 0 5.586 5.586 0 005.58 5.58 5.586 5.586 0 005.58-5.58 1.056 1.056 0 012.113 0c0 3.795-2.767 6.95-6.387 7.572l-.242.038v17.486h6.944c.335-5.03 2.94-7.767 5.743-10.58l.79-.794c3.65-3.68 7.238-7.762 7.238-17.795 0-12.004-9.766-21.77-21.771-21.77z"
          fill={colorPrimary}
        />
        <path
          d="M63.292 62.572c-2.891 2.897-5.632 5.646-5.977 10.815H50.37V55.901c3.738-.52 6.629-3.73 6.629-7.61a1.057 1.057 0 00-2.113 0 5.586 5.586 0 01-5.58 5.58 5.586 5.586 0 01-5.58-5.58 1.057 1.057 0 00-2.113 0c0 3.885 2.898 7.1 6.645 7.613v17.483h-7.084c-.33-5.685-3.175-8.605-6.18-11.683-3.663-3.755-7.451-7.637-7.451-17.486 0-12.004 9.767-21.77 21.77-21.77 12.006 0 21.772 9.766 21.772 21.77 0 10.543-3.962 14.514-7.794 18.354"
          fill="#D7F1FF"
        />
        <path
          d="M15.134 26.3a.998.998 0 011.262-.418l.104.053 6.561 3.788a1 1 0 01-.879 1.79l-.12-.058-6.562-3.788a1 1 0 01-.366-1.366zm67.522-.365a1 1 0 011.098 1.668l-.098.064-6.561 3.788a.998.998 0 01-1.366-.366 1 1 0 01.268-1.302l.098-.064 6.56-3.788zM70.252 9.88a1 1 0 011.785.896l-.052.104-3.788 6.561a.998.998 0 01-1.366.366 1 1 0 01-.419-1.262l.053-.104 3.787-6.56zm-42.715-.366a1 1 0 011.302.268l.064.098 3.788 6.56a1 1 0 01-1.655 1.115l-.077-.114-3.788-6.561a1 1 0 01.366-1.366zM50.101 4a1 1 0 01.993.884l.007.116v7.576a1 1 0 01-1.993.117l-.007-.117V5a1 1 0 011-1z"
          fill={colorPrimary}
        />
        <path
          d="M60.159 76.758c0 .626-.51 1.135-1.136 1.135H39.606a1.136 1.136 0 010-2.27h19.417c.626 0 1.136.51 1.136 1.135M58.932 81.14v.25c0 .59-.48 1.072-1.072 1.072H40.77A1.073 1.073 0 0139.7 81.39v-.25c0-.483.327-.877.768-1.011h17.697c.44.134.768.528.768 1.01M58.932 85.895c0 .592-.48 1.073-1.072 1.073H40.77a1.073 1.073 0 01-1.071-1.073v-.25c0-.59.48-1.072 1.072-1.072H57.86c.592 0 1.072.482 1.072 1.073v.25z"
          fill={colorSecondary}
        />
      </g>
    </>
  );
};
export default bulb;
