import { AppState } from '../../interfaces';
import { connect } from 'react-redux';
import ViewLocation from './ViewLocation';
import { listLocations } from '../../actions/location';
import { deleteRoom } from '../../actions/room';

const mapStateToProps = (state: AppState) => ({
  listLocation: state.listLocationReducer,
  room: state.roomReducer,
});

const mapDispatchToProps = (dispatch) => ({
  deleteRoom: (id) => dispatch(deleteRoom(id)),
  listLocations: () => dispatch(listLocations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewLocation);
