import styled, { css } from 'styled-components';
import { colors } from '../../assets/variables';

const Wrap = styled.div`
  & > .paginationBttns {
    display: flex;
    width: auto;
    justify-content: flex-end;
    border-radius: 9999px;
    padding-inline-start: 0px;
    background-color: #e9eef5;
    color: #475569;
  }

  li {
    width: 2rem;
    height: 2rem;
    font-size: 14px;
    border-radius: 9999px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    & > a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
    }
    &:hover {
      background-color: #cbd5e1;
      transition-duration: 0.15s;
      transition-property: color, background-color, border-color,
        text-decoration-color, fill, stroke;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
    &.paginationDisabled {
      cursor: not-allowed;
      background-color: #e9eef5;
      color: #bfbebe;
    }
    &.paginationActive {
      color: white;
      background-color: ${colors.green};
      &:hover {
        background-color: ${colors.green};
      }
    }
  }
`;

const Card = styled.div`
  border-radius: 10px;
  background: #e2edd9;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  max-width: 660px;
  width: 100%;
`;
const ShortDescription = styled.p`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
  margin: 0;
  min-height: 86px;
`;
const ImgWrap = styled.div`
  display: flex;
  flex: 1 0 auto;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position-x: ${(props) => props.bgPosition};
  margin: 0;
  height: 250px;
`;
const CopyWrap = styled.div`
  padding: 16px;
`;
const Button = styled.button`
  text-decoration: underline;
  text-underline-offset: 8px;
  text-decoration-color: #86b049;
  text-decoration-thickness: 2px;
  border: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  min-height: 35px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  margin-top: 10px;
  text-transform: none;
  ${(props) => {
    if (props.disabled) {
      return css`
        text-decoration-color: ${colors.darkGray};
      `;
    }
  }}
`;
const CompanyName = styled.div`
  display: flex;
  margin-bottom: 8px;
  p {
    flex: 1;
    flex-basis: 100%;
  }
`;

const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  img {
    margin: 16px 0;
  }
  @media screen and (max-height: 400px) {
    overflow-y: scroll;
    height: 250px;
    position: relative;
  }
`;
export default {
  Wrap,
  Card,
  ShortDescription,
  PopupContent,
  Button,
  ImgWrap,
  CopyWrap,
  CompanyName,
};
