import AddLocation from './AddLocation';
import { connect } from 'react-redux';

import {
  addLocation,
  getAddress,
  clearAddress,
  listLocations,
} from '../../actions/location';
import { AppState } from '../../interfaces';

const mapStateToProps = (state: AppState) => ({
  location: state.locationReducer,
  listLocation: state.listLocationReducer,
});

const mapDispatchToProps = (dispatch) => ({
  addLocation: (data) => dispatch(addLocation(data)),
  getAddress: (postcode) => dispatch(getAddress(postcode)),
  clearAddress: () => dispatch(clearAddress()),
  listLocations: () => dispatch(listLocations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddLocation);
