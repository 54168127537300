import * as React from 'react';

const cart = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M42.526 75.061a4.699 4.699 0 014.693 4.693 4.698 4.698 0 01-4.693 4.693 4.698 4.698 0 01-4.692-4.693 4.698 4.698 0 014.692-4.693zm28.357 0a4.699 4.699 0 014.693 4.693 4.698 4.698 0 01-4.693 4.693 4.698 4.698 0 01-4.692-4.693 4.698 4.698 0 014.692-4.693z"
          fill={colorSecondary}
        />
        <path
          d="M42.526 73.061a6.7 6.7 0 016.693 6.693 6.701 6.701 0 01-6.693 6.693 6.7 6.7 0 01-6.692-6.693 6.7 6.7 0 016.692-6.693zm28.358 0a6.7 6.7 0 016.692 6.693 6.7 6.7 0 01-6.692 6.693 6.701 6.701 0 01-6.693-6.693 6.7 6.7 0 016.693-6.693zm-28.358 2a4.698 4.698 0 00-4.692 4.693 4.698 4.698 0 004.692 4.693 4.698 4.698 0 004.693-4.693 4.699 4.699 0 00-4.693-4.693zm28.358 0a4.699 4.699 0 00-4.693 4.693 4.698 4.698 0 004.693 4.693 4.698 4.698 0 004.692-4.693 4.698 4.698 0 00-4.692-4.693zm-45.639-60.06a1 1 0 01.968.746l.022.111 2.25 15.75 62.033-.543c.311.022.606.14.798.385.165.211.236.476.204.737l-.025.13-9.265 35.514a1 1 0 01-.845.74l-.123.008H32.615a1 1 0 01-.968-.747l-.022-.11L24.378 17H10a1 1 0 01-.117-1.993L10 15h15.245zM89.23 33.076l-60.46.527 4.712 32.975H80.49l8.74-33.502zM54.04 57.086a1 1 0 01.117 1.993l-.117.007H39.113a1 1 0 01-.116-1.993l.116-.007H54.04zm12.493-7.36a1 1 0 01.117 1.994l-.117.007H47.545a1 1 0 01-.117-1.994l.117-.006h18.988z"
          fill={colorPrimary}
        />
      </g>
    </>
  );
};
export default cart;
