import { PreferencesReducer } from '../interfaces';
import * as _ from 'lodash';
import { createReducer } from '@reduxjs/toolkit';
import i18next from 'i18next';

const initialState: PreferencesReducer = {
  userData: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  updateData: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
  },
};

const preferencesReducer = createReducer(initialState, {
  GET_PREFERENCES_PENDING: (state) => {
    state.userData.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  GET_PREFERENCES_FULFILLED: (state, action) => {
    state.userData.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.userData.data = _.get(
      action,
      'payload.data.data.items',
      i18next.t('portal.general.error_message')
    );
  },

  GET_PREFERENCES_REJECTED: (state, action) => {
    state.userData.status = {
      loading: false,
      error: _.get(
        action,
        'payload.data.errors',
        i18next.t('portal.general.error_message')
      ),
      done: false,
    };
  },

  SET_PREFERENCES_PENDING: (state) => {
    state.updateData.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  SET_PREFERENCES_FULFILLED: (state) => {
    state.updateData.status = {
      loading: false,
      error: false,
      done: true,
    };
  },

  SET_PREFERENCES_REJECTED: (state, action) => {
    state.updateData.status = {
      loading: false,
      error: _.get(
        action,
        'payload.data.errors',
        i18next.t('portal.general.error_message')
      ),
      done: false,
    };
  },
});

export default preferencesReducer;
