import styled, { css } from 'styled-components';
import { colors } from '../../assets/variables';

const Wrap = styled.div`
  border-radius: 0;
  display: flex;
  margin-bottom: 60px;
  flex-direction: column;
  ${(props) => {
    if (props.isDesktop) {
      return desktopStyles;
    }
  }}
`;

const Delete = styled.button`
  font-family: 'Open Sans', sans-serif;
  -webkit-transition: all 300ms;
  transition: all 300ms;
  font-size: 16px;
  font-weight: 600;
  color: ${colors.primary};
  background-color: #cbcbcb;
  line-height: 1.15;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 16px 20px;
  text-decoration: none;
  border-radius: 10px;
  text-align: left;
  cursor: pointer;
  border: none;
  vertical-align: middle;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  display: flex;
  &:hover {
    background: #9d9d9d;
  }
  .gwf-delete-yes {
    background-color: ${colors.error};
    &:hover {
      background: #b8332b;
    }
  }
`;
const SectionContent = styled.div`
  display: flex;
  flex: 1 1 100%;
  height: auto;
  padding: 16px;
`;
const Line = styled.hr`
  border-top: 1px solid ${colors.baseDark};
`;
const SectionMenu = styled.ul`
  padding: 0;
  margin: 0 0 20px 0;
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid ${colors.primary};
`;

const SectionMenuElement = styled.li`
  margin: 0 0 1px 0;
  color: ${colors.primary};
  padding: 5px;
  line-height: 22px;
  font-size: 16px;
  font-weight: 600;
  padding: 16px 48px;
  background-color: #d7f1ff;
  text-transform: uppercase;
  position: relative;
  width: 50%;
  cursor: pointer;
  transition: all 300ms;
  &:first-child {
    border-top-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 0;
    margin: 0;
  }
  &:hover {
    color: ${colors.primary};
  }

  svg {
    position: absolute;
    left: -20px;
    top: 8px;
    opacity: 0;
    transition: all 300ms;
  }

  ${(props) => {
    if (props.active) {
      return css`
        background-color: #174489;
        color: ${colors.baseLight};
        padding: 16px 16px;
        &:hover {
          background-color: #174489;
          color: ${colors.baseLight};
        }
      `;
    }
  }}
  &:before {
    content: none !important;
  }
`;

export const IconWrap = styled.div`
  position: absolute;
  right: 15px;
  top: 33px;
  .eye {
    transition: transform 300ms;
    &:hover {
      transform: scale(1.2);
    }
  }
`;

export const Error = styled.span`
  color: red;
`;
export const Contact = styled.button`
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: ${colors.primary};
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-decoration-color: #25b3eb;
  text-underline-offset: 2px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 16px;
`;

const desktopStyles = css`
  ${SectionContent} {
    height: 600px;
    max-height: 600px;
    overflow-y: hidden;
    overflow-x: hidden;
    margin-top: 0;
  }
  ${SectionMenu} {
    flex-direction: row;
  }

  ${SectionMenuElement} {
    margin: 0;
    padding: 16px 48px;
    &:first-child {
      border-top-left-radius: 10px;
    }
    &:last-child {
      border-top-right-radius: 10px;
    }
  }
`;

export default {
  Wrap,
  Error,
  Delete,
  SectionContent,
  SectionMenu,
  SectionMenuElement,
  Contact,
  Line,
};
