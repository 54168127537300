import { RewardsReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

const initialState: RewardsReducer = {
  rewards: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  optIn: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  optOut: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
};

const rewardsReducer = createReducer(initialState, {
  GET_AVAILABLE_REWARDS_PENDING: (state) => {
    state.rewards.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  GET_AVAILABLE_REWARDS_FULFILLED: (state, action) => {
    state.rewards.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.rewards.data = action.payload.data;
  },

  GET_AVAILABLE_REWARDS_REJECTED: (state, action) => {
    state.rewards.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.rewards.data = action.payload.data;
  },
  OPT_IN_REWARDS_PENDING: (state) => {
    state.optIn.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  OPT_IN_REWARDS_FULFILLED: (state, action) => {
    state.optIn.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.optIn.data = action.payload.data;
  },
  OPT_IN_REWARDS_REJECTED: (state, action) => {
    state.optIn.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.optIn.data = action.payload.data;
  },
  OPT_OUT_REWARDS_PENDING: (state) => {
    state.optOut.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  OPT_OUT_REWARDS_FULFILLED: (state, action) => {
    state.optOut.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.optOut.data = action.payload.data;
  },
  OPT_OUT_REWARDS_REJECTED: (state, action) => {
    state.optOut.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.optOut.data = action.payload.data;
  },
});

export default rewardsReducer;
