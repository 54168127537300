import * as React from 'react';

const chartEqual = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(2.000000, 18.531500)"
        fill={colorPrimary}
        fillRule="nonzero"
      >
        <path d="M96,20.4375 C96,22.923 93.9855,24.9375 91.5,24.9375 L4.5,24.9375 C2.0145,24.9375 0,22.923 0,20.4375 L0,4.5 C0,2.0145 2.0145,0 4.5,0 L91.5,0 C93.9855,0 96,2.0145 96,4.5 L96,20.4375 Z"></path>
        <path d="M96,58.437 C96,60.924 93.9855,62.937 91.5,62.937 L4.5,62.937 C2.0145,62.937 0,60.924 0,58.437 L0,42.4995 C0,40.0155 2.0145,37.9995 4.5,37.9995 L91.5,37.9995 C93.9855,37.9995 96,40.0155 96,42.4995 L96,58.437 Z"></path>
      </g>
    </g>
  );
};
export default chartEqual;
