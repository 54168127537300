import { AddRoomReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

const initialState: AddRoomReducer = {
  addRoom: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  deleteRoom: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  editRoom: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
};

const roomReducer = createReducer(initialState, {
  ADD_ROOM_PENDING: (state) => {
    state.addRoom.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  ADD_ROOM_FULFILLED: (state, action) => {
    state.addRoom.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.addRoom.data = action.payload.data;
  },

  ADD_ROOM_REJECTED: (state, action) => {
    state.addRoom.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.addRoom.data = action.payload.data;
  },
  DELETE_ROOM_PENDING: (state) => {
    state.deleteRoom.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  DELETE_ROOM_FULFILLED: (state, action) => {
    state.deleteRoom.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.deleteRoom.data = action.payload.data;
  },

  DELETE_ROOM_REJECTED: (state, action) => {
    state.deleteRoom.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.deleteRoom.data = action.payload.data;
  },
  EDIT_ROOM_PENDING: (state) => {
    state.editRoom.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  EDIT_ROOM_FULFILLED: (state, action) => {
    state.editRoom.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.editRoom.data = action.payload.data;
  },

  EDIT_ROOM_REJECTED: (state, action) => {
    state.editRoom.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.editRoom.data = action.payload.data;
  },
});

export default roomReducer;
