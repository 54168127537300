import { AppState } from '../../../interfaces';
import { connect } from 'react-redux';
import { getCompanyDetails } from '../../../actions/companyName';
import CompanyPage from './CompanyPage';
import { getSearchData } from '../../../actions/search';
import { adAnalytics } from '../../../actions/adAnalytics';
import { optIn, getAvailableRewards } from '../../../actions/utmBanners';

const mapStateToProps = (state: AppState) => ({
  companyDetails: state.companyNameReducer,
  search: state.searchReducer,
  optedInData: state.availableRewardsReducer,
  optRewards: state.optRewardsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getCompanyDetails: (id) => dispatch(getCompanyDetails(id)),
  getSearchData: (data) => dispatch(getSearchData(data)),
  adAnalytics: (data) => dispatch(adAnalytics(data)),
  optIn: (bannerId) => dispatch(optIn(bannerId)),
  getAvailableRewards: () => dispatch(getAvailableRewards()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPage);
