import Rewards from './Rewards';
import { connect } from 'react-redux';
import { optIn, optOut, getAvailableRewards } from '../../actions/rewards';

import { AppState } from '../../interfaces';
const mapStateToProps = (state: AppState) => ({
  rewards: state.rewardsReducer,
});
const mapDispatchToProps = (dispatch) => ({
  optIn: (campaignId) => dispatch(optIn(campaignId)),
  optOut: (campaignId) => dispatch(optOut(campaignId)),
  getAvailableRewards: () => dispatch(getAvailableRewards()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Rewards);
