import React from 'react';
import { Wrap, Task, EasyWin, Loader } from '../../components/index';
import * as _ from 'lodash';
import { useResponsivity } from '../../helpers/helpers';
import { Col } from 'react-styled-flexboxgrid';
import { useTranslation } from 'react-i18next';
import store from 'store2';
interface ToiletsProps {
  challenges: any;
  listLocation: any;
  getChallengeCategory: Function;
  completeTask: any;
  completeChallenge: Function;
  generalData: Function;
}

const Shower: React.FC<ToiletsProps> = (props) => {
  const {
    challenges,
    getChallengeCategory,
    completeTask,
    completeChallenge,
    generalData,
  } = props;
  const getRoomId = store('property');
  const loadingChallenges = _.get(challenges, 'status.loading');
  const data = _.get(challenges, 'data.challenges');
  const isMobile = useResponsivity();
  const [t] = useTranslation('common');
  const { error, loading, done } = _.get(completeTask, 'status');
  React.useEffect(() => {
    getChallengeCategory(getRoomId, 'bathroom-shower');
  }, [getRoomId]);

  const pageArray = [
    {
      id: 266,
      title: 'Showers',
      poster:
        'https://cdn.getwaterfit.co.uk/static/files/images/gwf-business/posters/monkey.png',
      emailCopy:
        'Don’t be a cheeky monkey – do your bit for the environment and keep showers short.',
      easyWins: [
        {
          title: 'Introduction',
          theme: 'intro',
          size: 'full',
          text: 'Showers can account for high water and energy consumption owing to the amount of water flowing per minute, and length of time of each shower.',
        },
        {
          title: 'Easy Win',
          theme: 'red',
          icon: null,
          size: 'half',
          buttonText: 'Download now',
          buttonPath:
            'https://cdn.getwaterfit.co.uk/static/files/images/gwf-business/posters/monkey.pdf',
          text: 'Print off the poster and place within each shower area to remind staff to take a short shower. This poster will reduce water and energy use in your business.',
        },
        {
          title: 'Easy-to-fit Win',
          theme: 'red',
          size: 'half',
          icon: null,
          buttonText: 'See video',
          buttonPath: '/video-library',
          text: 'If your showers are gravity or mains fed, then fitting a shower regulator or water-saving showerhead will reduce water and energy use - and bills – without users noticing a difference.',
        },
        {
          title: 'Easy-to-fit Win',
          theme: 'red',
          size: 'half',
          icon: null,
          buttonText: 'See video',
          buttonPath: '/video-library',
          text: 'Help users keep an eye on how long they spend in the shower with a four minute sand timer. Simply turn the timer when starting the shower.#Alternatively, fit a water saving showerhead that also changes colour the longer the shower is operating.',
        },
        {
          theme: 'tooltip',
          icon: 'knowledge',
          size: 'full',
          text: 'To measure the flow of water, use a measuring jug and fill for 5 seconds. Multiply the amount of water in the jug by 12 in order to understand the how much water runs out of the tap in one minute. For example, if you have 1 litre in your jug after 5 seconds, then the flow rate of the tap will be 1l x 12 = 12 litres per minute. Find out when you’ll get your money back on a Kitchen Tap Aerator with our simple Return on Investment calculator.',
        },
        {
          title: 'Easy Win',
          theme: 'red',
          icon: null,
          size: 'half',

          text: 'Check your push button showers operate for no more than 30 seconds each time. Percussion showers that run for longer keep showers running after staff have finished their shower. Remember that wasted hot water will impact energy bills, too.#Your Facilities Manager or Plumber will be able to adjust the timing, if required.',
        },
        {
          title: 'Easy Win',
          theme: 'red',
          icon: null,
          size: 'half',

          text: 'Even small leaks waste a surprising amount of water so it’s important to check showers regularly. If a shower is dripping, then it’s worth fixing. Find out when you’ll get your money back on fixing a shower leak with our simple Return on Investment calculator.',
        },
        {
          title: 'Easy Win Pro',
          theme: 'blue',
          icon: 'worker',
          size: 'full',
          buttonPath:
            'https://cdn.getwaterfit.co.uk/static/files/images/gwf-business/posters/monkey.pdf',
          text: 'Reduce cold & hot water use by switching your showers to operate by push button or sensor. Push button operated showers produce a timed flow of water i.e. up to 30 seconds before the shower turns off automatically (the user can push the button as many times as they require). Sensor taps only operate when a user is standing directly in front of or beneath the shower. Both types of showers can also allow water temperature to be pre-set, helping to manage energy used to heat the water.#Unlike conventional showers, which require the user to manually turn off the water flow, push button and sensor operated showers provide only the amount of water required. They also minimise the risk of showers being left on overnight or at weekends. #Find out when you’ll get your money back on buying new shower controls with our simple Return on Investment calculator.',
        },
      ],
    },
  ];

  const getChallenges = () => {
    return _.map(data, (el, index) => {
      const title = _.get(el, 'title');
      const description = _.get(el, 'description');
      const buttonText = _.get(el, 'buttonText');
      const type = _.get(el, 'internalType');
      const buttonPath = _.get(el, 'buttonPath');
      const energySaving = _.get(el, 'energy_saving');
      const carbonSaving = _.get(el, 'carbon_saving');
      const waterSaving = _.get(el, 'water_saving');
      return (
        <Col xs={12} sm={12} md={6} key={index}>
          <Task
            titleWidth="230px"
            isDesktop={!isMobile}
            icon={_.get(el, 'icon')}
            id={_.get(el, 'id')}
            title={`${t(title)}`}
            description={`${t(description)}`}
            buttonText={`${t(buttonText)}`}
            buttonPath={buttonPath}
            popup={type === 'direct-redirect' ? false : true}
            renderComplete={() => {
              completeChallenge(getRoomId, _.get(el, 'id'));
              setTimeout(() => {
                getChallengeCategory(getRoomId, 'bathroom-shower');
                generalData();
              }, 1000);
            }}
            isLoading={loading}
            completed={done}
            energySaving={energySaving}
            carbonSaving={carbonSaving}
            waterSaving={waterSaving}
          />
        </Col>
      );
    });
  };
  if (loadingChallenges) {
    return (
      <Wrap>
        <Loader />
      </Wrap>
    );
  }
  return (
    <Wrap>
      <EasyWin data={pageArray}>{getChallenges()}</EasyWin>
    </Wrap>
  );
};

export default Shower;
