import React from 'react';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
interface WrapperProps {
  children: any;
}

export const Wrapper: React.FC<WrapperProps> = (props) => {
  return (
    <Grid>
      <Row>
        <Col md={12} xs={12} sm={12}>
          {props.children}
        </Col>
      </Row>
    </Grid>
  );
};

export default Wrapper;
