import * as React from 'react';

const faceSmiley = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M50.113 12c21.017 0 38.114 17.098 38.114 38.113 0 21.017-17.097 38.115-38.114 38.115C29.097 88.228 12 71.13 12 50.113 12 29.098 29.097 12 50.113 12zm0 2C30.2 14 14 30.2 14 50.113c0 19.914 16.2 36.115 36.113 36.115s36.114-16.2 36.114-36.115C86.227 30.2 70.027 14 50.113 14z"
          fill={colorPrimary}
        />
        <path
          d="M36.23 36.015a5.957 5.957 0 015.95 5.95 5.957 5.957 0 01-5.95 5.95 5.957 5.957 0 01-5.95-5.95c0-3.281 2.67-5.95 5.95-5.95zm0 2a3.954 3.954 0 00-3.95 3.95 3.955 3.955 0 003.95 3.95 3.955 3.955 0 003.95-3.95 3.954 3.954 0 00-3.95-3.95zM63.997 36.015a5.957 5.957 0 015.95 5.95 5.957 5.957 0 01-5.95 5.95 5.957 5.957 0 01-5.95-5.95c0-3.281 2.67-5.95 5.95-5.95zm0 2a3.954 3.954 0 00-3.95 3.95 3.955 3.955 0 003.95 3.95 3.955 3.955 0 003.95-3.95 3.954 3.954 0 00-3.95-3.95zM68.076 56.818a.853.853 0 00-.822.626c-1.699 6.133-7.54 12.32-16.802 12.51-9.26-.19-15.103-6.377-16.8-12.51a.854.854 0 00-1.644.455c1.83 6.612 8.016 13.28 17.802 13.74a.83.83 0 00.206.025h.873a.83.83 0 00.206-.025c9.786-.46 15.972-7.128 17.803-13.74a.853.853 0 00-.822-1.081"
          fill={colorPrimary}
        />
        <path
          d="M36.23 38.015a3.954 3.954 0 00-3.95 3.95 3.955 3.955 0 003.95 3.95 3.955 3.955 0 003.95-3.95 3.954 3.954 0 00-3.95-3.95M63.997 38.015a3.954 3.954 0 00-3.95 3.95 3.955 3.955 0 003.95 3.95 3.955 3.955 0 003.95-3.95 3.954 3.954 0 00-3.95-3.95"
          fill={colorSecondary}
        />
      </g>
    </>
  );
};
export default faceSmiley;
