import * as React from 'react';

const userSwitch = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M53.7 43.536c-.51.95-1.11 1.84-1.78 2.65-.48-.48-.92-.99-1.32-1.54a13.938 13.938 0 01-2.73-8.29c0-3.39 1.21-6.5 3.23-8.92.43-.52.9-1.009 1.4-1.47 3.61 4.99 4.29 11.79 1.2 17.57"
          fill="#D7F1FF"
        />
        <path
          d="M75.84 36.357c0 7.71-6.27 13.99-13.979 13.99-1.68 0-3.29-.301-4.79-.841-.65-.24-1.28-.53-1.88-.85.79-.99 1.5-2.06 2.11-3.2 3.83-7.15 3.01-15.56-1.4-21.76a14.015 14.015 0 015.96-1.33c7.71 0 13.98 6.28 13.98 13.99"
          fill="#D7F1FF"
        />
        <path
          d="M87.84 74.767v9.02c0 .91-.739 1.64-1.649 1.64h-48.67c-.91 0-1.64-.73-1.64-1.64v-9.02c0-6.311 2.35-12.16 6.33-16.601.84-.95 1.76-1.84 2.75-2.64l.09-.03a20.482 20.482 0 007.74-4.32c.62-.2 1.25-.39 1.89-.55a15.914 15.914 0 007.18 1.721c2.57 0 5-.631 7.16-1.721 11.09 2.78 18.82 12.65 18.82 24.14"
          fill={colorSecondary}
        />
        <path
          d="M61.861 20.366c8.81 0 15.98 7.18 15.98 15.991 0 5.269-2.57 9.929-6.51 12.849 9.911 3.226 16.973 11.84 18.27 22.12l.05.43.15 2 .031.674.008.337v9.019a3.65 3.65 0 01-3.456 3.636l-.193.005h-48.67a3.642 3.642 0 01-3.636-3.448l-.005-.193v-9.019l.008-.337.032-.674.141-2a26.667 26.667 0 015.39-13.38c.55-.729 1.15-1.43 1.78-2.099 1.25-.14 2.5-.39 3.73-.75a22.868 22.868 0 00-2.75 2.639 24.81 24.81 0 00-6.326 16.104l-.005.497v9.019c0 .862.656 1.564 1.499 1.635l.142.006h48.67c.862 0 1.57-.656 1.643-1.499l.006-.142v-9.019c0-11.49-7.729-21.361-18.819-24.141a15.855 15.855 0 01-7.16 1.721 15.914 15.914 0 01-7.18-1.721c-.64.16-1.27.351-1.89.551.87-.761 1.67-1.61 2.4-2.521.6.321 1.23.611 1.88.85 1.5.54 3.11.841 4.79.841 7.71 0 13.98-6.28 13.98-13.99s-6.27-13.991-13.98-13.991a14.015 14.015 0 00-5.96 1.33 17.65 17.65 0 00-1.26-1.599c.62-.32 1.27-.601 1.94-.83 1.65-.58 3.43-.901 5.28-.901zm-10.62 4.05c.46.49.89 1.011 1.26 1.55-.5.461-.97.95-1.4 1.471a13.863 13.863 0 00-3.23 8.92c0 3.099 1.02 5.969 2.73 8.29.4.549.84 1.059 1.32 1.54-.41.519-.86 1.009-1.34 1.46-.48-.47-.92-.97-1.32-1.491a15.861 15.861 0 01-3.39-9.799c0-4.021 1.49-7.701 3.97-10.521.43-.5.9-.979 1.4-1.42z"
          fill={colorPrimary}
        />
        <path
          d="M32.49 13.986a22.525 22.525 0 0117.281 1.71 22.792 22.792 0 016.81 5.571c.45.54.87 1.11 1.26 1.69a22.859 22.859 0 012.94 6.17c1.75 5.8 1.14 11.93-1.72 17.27-.59 1.1-1.26 2.14-1.99 3.11-.43.56-.88 1.09-1.35 1.6a22.445 22.445 0 01-10.09 6.3c-1.13.34-2.27.59-3.42.76-.92.13-1.84.2-2.76.21-.14.01-.28.01-.42.01-2.26 0-4.51-.34-6.68-1.02-.96-.292-1.897-.653-2.813-1.078l-.548-.263-.629-.33c-1.29-.69-2.5-1.49-3.61-2.4-3.5-2.819-6.06-6.63-7.4-11.029-1.75-5.8-1.15-11.94 1.71-17.28a22.517 22.517 0 0113.43-11zm6.6 1.01c-2.01 0-4.04.3-6.02.9-5.29 1.6-9.639 5.161-12.24 10.04-5.389 10.05-1.59 22.61 8.47 27.991 3.71 1.99 7.841 2.79 11.931 2.35a21.757 21.757 0 003.113-.58l.616-.17.09-.03a20.51 20.51 0 007.74-4.32 19.9 19.9 0 002.401-2.52c.79-.99 1.5-2.06 2.11-3.2 3.83-7.15 3.01-15.56-1.4-21.76-.39-.55-.8-1.09-1.26-1.6a20.451 20.451 0 00-5.81-4.64 20.62 20.62 0 00-9.74-2.46z"
          fill={colorPrimary}
        />
        <path
          d="M39.09 14.996c3.36 0 6.69.83 9.74 2.46 2.27 1.221 4.22 2.8 5.81 4.641.46.51.87 1.05 1.26 1.6 4.41 6.2 5.23 14.61 1.4 21.76a21.026 21.026 0 01-2.11 3.2c-.73.91-1.53 1.76-2.4 2.52a20.473 20.473 0 01-7.229 4.158l-.6.191c-1.23.361-2.48.611-3.73.75-4.09.44-8.22-.36-11.93-2.349-10.06-5.38-13.86-17.94-8.47-27.99 2.6-4.88 6.95-8.44 12.24-10.04 1.98-.6 4.01-.9 6.02-.9zm7.81 6.061a16.483 16.483 0 00-12.65-1.25 16.501 16.501 0 00-9.83 8.05c-4.32 8.07-1.27 18.16 6.81 22.48a16.48 16.48 0 007.81 1.96c2.96 0 5.88-.8 8.431-2.29a16.067 16.067 0 003.11-2.36c.48-.45.93-.94 1.34-1.46.67-.81 1.27-1.7 1.78-2.65 3.029-5.667 2.435-12.314-.992-17.275l-.209-.296c-.37-.539-.8-1.06-1.26-1.55a16.47 16.47 0 00-4.34-3.359z"
          fill="#D7F1FF"
        />
        <path
          d="M50.58 47.647l-.36.339c-.851.776-1.77 1.452-2.75 2.02a16.746 16.746 0 01-8.43 2.29c-2.65 0-5.33-.63-7.81-1.96-8.08-4.32-11.13-14.41-6.81-22.48 2.1-3.91 5.59-6.77 9.83-8.05a16.49 16.49 0 0112.65 1.25c1.67.9 3.13 2.04 4.34 3.36.369.393.717.804 1.03 1.229l.23.32c3.61 4.992 4.29 11.79 1.2 17.572a16.186 16.186 0 01-1.451 2.238l-.33.412c-.478-.48-.92-.99-1.318-1.54l.294-.39a14.4 14.4 0 001.046-1.671 14.526 14.526 0 001.729-6.87 14.65 14.65 0 00-2.57-8.28 12.786 12.786 0 00-1.26-1.6 14.364 14.364 0 00-3.88-3.02 14.597 14.597 0 00-6.88-1.73c-1.42 0-2.85.21-4.25.63a14.517 14.517 0 00-8.64 7.09c-3.8 7.09-1.12 15.96 5.98 19.76a14.466 14.466 0 0011.12 1.11 14.503 14.503 0 005.656-3.22l.314-.3c.4.52.84 1.02 1.32 1.49zM26.379 52.368l5.843 3.128a.999.999 0 01.41 1.353L17.995 84.19a3.265 3.265 0 01-1.946 1.595 3.282 3.282 0 01-2.503-.248l-1.81-.97a3.293 3.293 0 01-1.348-4.448l14.638-27.342a.999.999 0 011.353-.41zm-.062 2.235l-14.165 26.46a1.289 1.289 0 00.527 1.742l1.81.969a1.287 1.287 0 001.742-.526l14.166-26.461-4.08-2.184z"
          fill={colorPrimary}
        />
      </g>
    </>
  );
};
export default userSwitch;
