import React from 'react';
import {
  Wrap,
  Typography,
  Task,
  LocationDropdown,
  RewardsBanner,
  Filter,
  NothingToDisplay,
  SkeletonLoader,
} from '../../components/index';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { Row, Col } from 'react-styled-flexboxgrid';
import { useResponsivity } from '../../helpers/helpers';
import Styled from './PageEasyWins.style';
import store from 'store2';
interface PageEasyWinsProps {
  challenges: any;
  getChallengeCategory: Function;
  listLocation: any;
  setMeta: Function;
}

export const PageEasyWins: React.FC<PageEasyWinsProps> = (props) => {
  const { challenges, setMeta, listLocation, getChallengeCategory } = props;
  const [t] = useTranslation('common');
  const dataLocation = _.get(listLocation, 'data.data');
  const dataLength = _.get(dataLocation, 'length');

  const text_page_title = t('portal.easy_wins.page_title');
  const text_page_description =
    'Saving water and energy in the workplace is easier than you think. You’ll find the Easy win actions are presented in order of where your business can make the most savings, starting with simple steps your staff can take.';
  const getValue = store.get('property');

  const isMobile = useResponsivity();
  const getLocationId = _.get(listLocation, 'data.data[0].id');
  const data = _.get(challenges, 'data.challenges');
  const [value, setValue] = React.useState(`${getValue}`);
  const { error, loading, done } = _.get(challenges, 'status');

  const errorMessage = _.get(challenges, 'data');
  const findLocation = _.find(dataLocation, function (o) {
    return o.id == value;
  });

  const roomLength = _.get(findLocation, 'rooms.length');
  const [item, setItem] = React.useState('all');
  const datLength = _.get(data, 'length');
  const setItemValue = (message) => {
    setItem(message);
  };
  const renderMapOptions =
    dataLocation &&
    dataLocation.map((el) => ({
      value: el.id,
      label: el.property_name,
    }));
  React.useEffect(() => {
    if (getValue === 'all') {
      store('property', getLocationId);
    }
  }, [getValue, getLocationId]);

  React.useEffect(() => {
    if (!getValue) {
      store('property', getLocationId);
    } else if (dataLength === 1) {
      getChallengeCategory(getLocationId, 'parent');
    } else if (getValue) {
      getChallengeCategory(getValue, 'parent');
    }
  }, [getValue, getLocationId]);

  const sortedData = _.sortBy(data, [
    function (o) {
      return o.displayOrder;
    },
  ]);

  const filterMenu =
    item === 'all'
      ? sortedData
      : sortedData.filter((el) => el.type.includes(item));

  // if (error && !loading) {
  //   return (
  //     <Wrap>
  //       <ErrorDisplay title={errorMessage} />
  //     </Wrap>
  //   );
  // }
  if (loading && !done) {
    return (
      <Wrap>
        <SkeletonLoader />
      </Wrap>
    );
  }

  const renderNoLocation = () => {
    return (
      <Col xs={12} sm={12} md={12}>
        <NothingToDisplay
          title="You have no locations added"
          location={'/add-location'}
          subtitle="Please add a location"
          buttonText="Add location"
        />
      </Col>
    );
  };

  const renderNoRooms = () => {
    return (
      <Col xs={12} sm={12} md={12}>
        <NothingToDisplay
          title="You have no room(s) added for this location."
          location={`/${value}/add-room`}
          subtitle="Please add a room in order to view your data."
          buttonText="Add room(s)"
        />
      </Col>
    );
  };

  const challengeData = _.map(filterMenu, (el, index) => {
    const title = _.get(el, 'title');
    const description = _.get(el, 'description');
    const buttonText = _.get(el, 'buttonText');
    const type = _.get(el, 'internalType');
    const buttonPath = _.get(el, 'buttonPath');

    return (
      <Col xs={12} sm={12} md={6} key={index}>
        <Task
          titleWidth="300px"
          isDesktop={!isMobile}
          icon={_.get(el, 'icon')}
          id={_.get(el, 'id')}
          title={`${t(title)}`}
          description={`${t(description)}`}
          buttonText={`${t(buttonText)}`}
          buttonPath={buttonPath}
          popup={type === 'direct-redirect' ? false : true}
        />
      </Col>
    );
  });
  const renderNoEasyWins = () => {
    return (
      <Col xs={12} sm={12} md={12}>
        <NothingToDisplay
          title="Oopps!"
          subtitle="There is not enough data to generate easy wins"
        />
      </Col>
    );
  };
  const renderPage = () => {
    if (dataLength === 0) {
      return renderNoLocation();
    } else if (roomLength === 0) {
      return renderNoRooms();
    } else if (datLength === 0) {
      return renderNoEasyWins();
    } else {
      return challengeData;
    }
  };

  return (
    <Wrap>
      <Row style={{ marginBottom: '32px' }}>
        <Col md={8} xs={12} sm={12}>
          <Typography
            size="1"
            weight={700}
            text={text_page_title}
            style={{
              marginBottom: '16px',
              fontSize: '40px',
              color: '#0b2641',
            }}
          />
          <Typography
            size="p"
            style={{ fontSize: '18px', fontWeight: 700, maxWidth: '760px' }}
            text={text_page_description}
          />
        </Col>
      </Row>
      {datLength === 0 || dataLength === 0 || roomLength === 0 ? null : (
        <Row style={{ marginBottom: '32px' }}>
          <Col md={8} xs={12} sm={12}>
            <Typography size="p" text="Priority actions:" />
            <Filter setItemValue={setItemValue} data={sortedData} />
          </Col>
          <Col md={4} xs={12} sm={12}>
            {dataLength <= 1 ? null : (
              <Styled.DropdownWrap>
                <LocationDropdown
                  value={value === getValue ? value : getValue}
                  onChange={(e) => {
                    e.preventDefault();
                    setValue(e.target.value);
                    store('property', e.target.value);
                    setMeta(getValue);
                  }}
                  options={renderMapOptions}
                />
              </Styled.DropdownWrap>
            )}
          </Col>
        </Row>
      )}

      <RewardsBanner />
      <Row>{renderPage()}</Row>
    </Wrap>
  );
};

export default PageEasyWins;
