import * as React from 'react';

const washingMachine = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path d="M78.363 17.732v7.749H42.281V14h32.35a3.737 3.737 0 013.732 3.732" fill="#D7F1FF" />
        <path
          d="M50.2 40.13c7.745 0 14.072 6.114 14.451 13.768-.432-.265-.806-.698-1.279-1.319-.813-1.067-1.823-2.394-3.842-2.394-2.018 0-3.028 1.327-3.84 2.394-.78 1.026-1.27 1.606-2.25 1.606-.978 0-1.468-.58-2.248-1.606-.81-1.067-1.822-2.394-3.839-2.394-2.016 0-3.026 1.328-3.837 2.395-.779 1.025-1.267 1.605-2.244 1.605-.977 0-1.466-.58-2.246-1.605-.637-.838-1.403-1.825-2.675-2.217 1.823-5.919 7.34-10.233 13.85-10.233"
          fill={colorSecondary}
        />
        <path
          d="M63.26 60.849c-.69-.111-1.127-.636-1.762-1.532-.764-1.08-1.717-2.422-3.651-2.422-1.935 0-2.887 1.343-3.651 2.422-.714 1.008-1.16 1.578-2.018 1.578-.857 0-1.303-.57-2.017-1.578-.764-1.08-1.715-2.422-3.65-2.422-1.932 0-2.882 1.344-3.646 2.423-.712 1.008-1.158 1.577-2.013 1.577-.858 0-1.303-.57-2.016-1.577-.66-.933-1.463-2.058-2.915-2.348a14.463 14.463 0 01-.21-2.351c0-.78.08-1.541.2-2.288.549.227.963.724 1.524 1.46.81 1.067 1.82 2.394 3.838 2.394 2.015 0 3.025-1.327 3.836-2.394.779-1.025 1.267-1.606 2.245-1.606.978 0 1.467.58 2.247 1.606.81 1.067 1.822 2.394 3.84 2.394 2.017 0 3.028-1.327 3.84-2.394.78-1.025 1.27-1.606 2.25-1.606.979 0 1.469.58 2.25 1.606.663.87 1.468 1.903 2.834 2.258a14.344 14.344 0 01-1.355 4.8"
          fill={colorSecondary}
        />
        <path
          d="M50.2 69.106c-6.175 0-11.445-3.89-13.527-9.343.167.206.34.439.53.71.765 1.079 1.715 2.422 3.648 2.422s2.883-1.343 3.647-2.422c.713-1.008 1.158-1.578 2.014-1.578.857 0 1.302.57 2.016 1.578.765 1.079 1.716 2.422 3.65 2.422 1.933 0 2.885-1.343 3.65-2.421.714-1.01 1.16-1.58 2.018-1.58.86 0 1.305.57 2.02 1.58.573.809 1.252 1.766 2.371 2.195-2.602 3.878-7.025 6.437-12.037 6.437"
          fill={colorSecondary}
        />
        <path
          d="M74.631 12a5.739 5.739 0 015.728 5.512l.004.22v69.059a1 1 0 01-.883.993l-.117.007H21a1 1 0 01-.993-.883L20 86.79V17.732a5.738 5.738 0 015.512-5.728L25.73 12h48.9zm3.732 15.48H22v58.311h56.363V27.48zM40.281 14h-14.55a3.736 3.736 0 00-3.726 3.534l-.005.198v7.748h18.281V14zm34.35 0h-32.35v11.48h36.082v-7.748a3.737 3.737 0 00-3.534-3.727L74.63 14z"
          fill={colorPrimary}
        />
        <path
          d="M58.673 20.774a1 1 0 01.117 1.993l-.117.007H48.628a1 1 0 01-.117-1.993l.117-.007h10.045zm15.298 0a1 1 0 01.117 1.993l-.117.007H63.926a1 1 0 01-.117-1.993l.117-.007H73.97zm-15.298-4.707a1 1 0 01.117 1.994l-.117.006H48.628a1 1 0 01-.117-1.993l.117-.007h10.045zm15.298 0a1 1 0 01.117 1.994l-.117.006H63.926a1 1 0 01-.117-1.993l.117-.007H73.97zM31.256 14.83a4.916 4.916 0 014.91 4.91 4.915 4.915 0 01-4.91 4.91 4.915 4.915 0 01-4.91-4.91 4.916 4.916 0 014.91-4.91zm0 2a2.914 2.914 0 00-2.91 2.91 2.913 2.913 0 002.91 2.91 2.913 2.913 0 002.91-2.91 2.914 2.914 0 00-2.91-2.91zM50.182 32.41c12.246 0 22.209 9.962 22.209 22.208 0 12.246-9.963 22.21-22.21 22.21-12.245 0-22.208-9.964-22.208-22.21S37.936 32.41 50.182 32.41zm0 2c-11.144 0-20.21 9.065-20.21 20.208 0 11.143 9.066 20.21 20.21 20.21S70.39 65.76 70.39 54.617 61.326 34.41 50.18 34.41z"
          fill={colorPrimary}
        />
        <path
          d="M50.182 38.13c9.09 0 16.487 7.397 16.487 16.488 0 9.092-7.396 16.488-16.487 16.488-9.092 0-16.488-7.396-16.488-16.488 0-9.091 7.396-16.488 16.488-16.488zm7.646 20.765c-.805 0-1.247.5-1.888 1.395l-.327.458c-.734 1.018-1.681 2.147-3.453 2.147-1.693 0-2.632-1.028-3.353-2.008l-.554-.771c-.575-.785-1.01-1.221-1.76-1.221-.748 0-1.183.436-1.757 1.22l-.452.632c-.734 1.02-1.68 2.148-3.451 2.148-1.692 0-2.63-1.028-3.351-2.008l-.297-.414a13.841 13.841 0 00-.53-.71c2.082 5.453 7.35 9.343 13.527 9.343 5.012 0 9.435-2.56 12.037-6.437-1.04-.398-1.7-1.252-2.247-2.02l-.124-.175c-.715-1.01-1.161-1.58-2.02-1.58zm1.684-6.71c-.918 0-1.406.51-2.106 1.419l-.459.596c-.765.968-1.76 1.985-3.525 1.985-1.933 0-2.942-1.22-3.738-2.26l-.383-.498c-.63-.798-1.11-1.242-1.966-1.242-.855 0-1.336.444-1.965 1.242l-.595.773c-.764.968-1.757 1.985-3.521 1.985-1.932 0-2.94-1.22-3.736-2.26l-.102-.134c-.56-.737-.974-1.233-1.524-1.46a14.49 14.49 0 00-.198 2.287c0 .802.082 1.584.208 2.35 1.367.274 2.158 1.287 2.797 2.183l.375.523c.574.784 1.009 1.22 1.759 1.22.749 0 1.183-.436 1.757-1.22l.452-.631c.734-1.02 1.68-2.148 3.452-2.148 1.691 0 2.63 1.028 3.351 2.008l.554.771c.575.784 1.01 1.22 1.76 1.22.751 0 1.186-.436 1.761-1.22l.554-.771c.72-.98 1.661-2.008 3.354-2.008 1.935 0 2.887 1.343 3.652 2.422.635.896 1.072 1.42 1.762 1.532a14.35 14.35 0 001.355-4.801c-1.197-.31-1.962-1.14-2.58-1.925l-.536-.696c-.63-.798-1.112-1.242-1.97-1.242zm-9.33-12.055c-6.51 0-12.026 4.315-13.85 10.234 1.103.339 1.825 1.126 2.413 1.875l.543.704c.63.798 1.11 1.242 1.966 1.242.854 0 1.335-.444 1.964-1.242l.595-.772c.764-.97 1.758-1.986 3.522-1.986 1.764 0 2.76 1.017 3.524 1.985l.315.409c.78 1.026 1.27 1.606 2.248 1.606.857 0 1.339-.444 1.968-1.242l.596-.772c.766-.97 1.76-1.986 3.526-1.986 1.766 0 2.76 1.017 3.526 1.986l.316.409c.473.62.847 1.053 1.279 1.318-.38-7.653-6.706-13.768-14.451-13.768z"
          fill={colorPrimary}
        />
      </g>
    </>
  );
};
export default washingMachine;
