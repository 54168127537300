import * as React from 'react';

const denmark = () => (
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <circle fill="#F0F0F0" fillRule="nonzero" cx="50" cy="50" r="45" />
    <path
      d="M40.2174219,44.1304883 L94.619082,44.1304883 C91.7427734,22.0511328 72.8633398,5 50,5 C46.6399414,5 43.3674219,5.37142578 40.2174219,6.06962891 L40.2174219,44.1304883 L40.2174219,44.1304883 Z"
      fill="#D80027"
      fillRule="nonzero"
    />
    <path
      d="M28.4782227,44.1304883 L28.4782227,10.471543 C16.1220312,17.213457 7.27636719,29.5798438 5.38091797,44.1304883 L28.4782227,44.1304883 L28.4782227,44.1304883 Z"
      fill="#D80027"
      fillRule="nonzero"
    />
    <path
      d="M28.4782227,55.8695117 L5.38091797,55.8695117 C7.27636719,70.4201563 16.1220312,82.786543 28.4782227,89.5282813 L28.4782227,55.8695117 L28.4782227,55.8695117 Z"
      fill="#D80027"
      fillRule="nonzero"
    />
    <path
      d="M40.2174219,55.8695117 L40.2174219,93.9303711 C43.3674219,94.6285742 46.6399414,95 50,95 C72.8633398,95 91.7427734,77.9488672 94.619082,55.8695117 L40.2174219,55.8695117 L40.2174219,55.8695117 Z"
      fill="#D80027"
      fillRule="nonzero"
    />
  </g>
);
export default denmark;
