import * as React from 'react';

const faucet = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M79.91 50.792c-.549.886-3.287 5.396-3.287 7.297a4.056 4.056 0 004.05 4.051 4.056 4.056 0 004.052-4.05c0-1.902-2.739-6.412-3.287-7.298-.328-.528-1.2-.528-1.528 0"
          fill={colorPrimary}
        />
        <path
          d="M80.674 60.343a2.256 2.256 0 01-2.254-2.254c0-.827 1.15-3.141 2.254-5.066 1.103 1.925 2.253 4.238 2.253 5.066a2.256 2.256 0 01-2.253 2.254"
          fill={colorSecondary}
        />
        <path
          d="M34.841 80.905a.898.898 0 011.228-.327c1.13.654 3.181 2.273 3.7 4.19a.9.9 0 01-1.735.47c-.302-1.113-1.702-2.431-2.866-3.105a.898.898 0 01-.327-1.228zm5.468-56.68a.9.9 0 01.674 1.667c-2.056.831-3.824 3.518-4.512 5.122a.899.899 0 11-1.652-.709c.744-1.734 2.736-4.966 5.49-6.08z"
          fill={colorPrimary}
        />
        <path
          d="M83.527 40.544h-.197C82.867 23.622 69.401 10 52.902 10c-16.79 0-30.45 14.104-30.45 31.44v17.881a4.407 4.407 0 00-3.47 4.299v9.746a.9.9 0 001.797 0V63.62a2.609 2.609 0 012.606-2.605h5.058a2.608 2.608 0 012.605 2.605V68h5.706v8.292h-5.706v6.238H20.78v-3.225a.898.898 0 10-1.797 0v3.308c-2.794.45-4.937 2.872-4.937 5.791a.9.9 0 00.899.9h21.582a.899.899 0 00.899-.9c0-2.793-1.962-5.133-4.58-5.725v-4.59h10.902a2.273 2.273 0 002.27-2.269V58.424a.899.899 0 10-1.798 0v7.83a2.272 2.272 0 00-.472-.05H32.846V63.62c0-1.953-1.287-3.594-3.05-4.168V41.539c0-13.587 10.681-24.641 23.812-24.641 12.857 0 23.361 10.56 23.793 23.706a1.661 1.661 0 00-1.244 1.604v4.48c0 .917.746 1.663 1.663 1.663h5.707c.917 0 1.664-.746 1.664-1.663v-4.48c0-.918-.747-1.664-1.664-1.664z"
          fill={colorPrimary}
        />
        <path
          d="M53.659 15.1c-14.122 0-25.612 11.86-25.612 26.44v17.677H24.3V41.44c0-16.344 12.853-29.642 28.652-29.642 15.509 0 28.18 12.814 28.64 28.744l-1.168.002h-1.169C78.791 26.434 67.487 15.1 53.66 15.1"
          fill="#D7F1FF"
        />
        <path
          d="M35.641 87.505H16.057a4.075 4.075 0 013.714-3.154h12.156a4.075 4.075 0 013.714 3.154"
          fill="#EEF3FB"
        />
        <path
          d="M44.22 68.473V75.82c0 .26-.213.472-.473.472h-5.195v-8.29h5.195c.26 0 .472.21.472.471"
          fill={colorSecondary}
        />
        <path fill="#EEF3FB" d="M83.393 46.554h-5.438v-4.21l2.432-.002h3.006z" />
      </g>
    </>
  );
};
export default faucet;
