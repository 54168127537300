import * as React from 'react';

const bathTub = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M83.497 9.545a1 1 0 01.993.884l.007.116V57.19h7.93a1 1 0 01.117 1.993l-.116.007h-2.925v1.578c0 10.863-7.134 20.086-16.961 23.24l-.361.113 2.815 6.712a1 1 0 01-1.79.884l-.055-.11-2.927-6.98c-1.447.31-2.94.496-4.468.537l-.657.01h-30.21c-1.478 0-2.925-.133-4.331-.386l-.6-.116-2.91 6.935a1 1 0 01-1.883-.664l.039-.11 2.793-6.658c-9.99-2.946-17.336-12.122-17.51-23.013l-.003-.394V59.19H8a1 1 0 01-.117-1.993L8 57.19h53.283v-.899a2.984 2.984 0 012.806-2.975l.175-.005h11.515a2.984 2.984 0 012.976 2.805l.005.175v.9h3.737V11.544H66.964v5.218h5.442a1 1 0 01.993.883l.007.117v3.183a1 1 0 01-.883.993l-.117.007H59.523a1 1 0 01-.993-.883l-.007-.117v-3.183a1 1 0 01.883-.993l.117-.007h5.44v-6.218a1 1 0 01.884-.993l.117-.007h17.533zM61.283 59.19h-48.8v1.578c0 12.23 9.852 22.204 22.036 22.402l.37.003h30.21c12.23 0 22.203-9.85 22.4-22.035l.004-.37V59.19H78.76v12.197a2.984 2.984 0 01-2.806 2.976l-.175.005H64.264a2.984 2.984 0 01-2.976-2.806l-.005-.175V59.19zm15.477 7.786H63.283v4.411c0 .502.38.918.867.974l.114.007h11.515c.502 0 .918-.38.974-.867l.007-.114v-4.41zm-.981-11.665H64.264a.982.982 0 00-.974.866l-.007.114v8.685H76.76v-8.685a.981.981 0 00-.867-.973l-.114-.007zm-4.373-36.548H60.522v1.183h10.884v-1.183zM35.142 7a6.618 6.618 0 016.61 6.611 6.618 6.618 0 01-6.61 6.612 6.618 6.618 0 01-6.611-6.612A6.618 6.618 0 0135.14 7zm0 2a4.617 4.617 0 00-4.611 4.611 4.617 4.617 0 004.61 4.612 4.617 4.617 0 004.612-4.612A4.617 4.617 0 0035.142 9zM24.458 25.838a6.079 6.079 0 016.072 6.072 6.079 6.079 0 01-6.072 6.072 6.079 6.079 0 01-6.072-6.072 6.079 6.079 0 016.072-6.072zm0 2a4.077 4.077 0 00-4.072 4.072 4.077 4.077 0 004.072 4.072 4.076 4.076 0 004.072-4.072 4.076 4.076 0 00-4.072-4.072z"
          fill={colorPrimary}
        />
        <path
          d="M44.151 36.313c4.155 0 7.536 3.38 7.536 7.536 0 4.154-3.38 7.535-7.536 7.535-4.155 0-7.535-3.381-7.535-7.535 0-4.156 3.38-7.536 7.535-7.536zm0 2a5.541 5.541 0 00-5.535 5.536 5.541 5.541 0 005.535 5.535 5.542 5.542 0 005.536-5.535 5.542 5.542 0 00-5.536-5.536z"
          fill={colorPrimary}
        />
        <path
          d="M63.283 56.291c0-.541.44-.98.98-.98H75.78c.541 0 .981.439.981.98v8.685H63.283v-8.685zM44.151 38.313a5.542 5.542 0 015.536 5.536 5.542 5.542 0 01-5.536 5.535 5.541 5.541 0 01-5.535-5.535 5.541 5.541 0 015.535-5.536M35.142 9a4.617 4.617 0 014.61 4.611 4.617 4.617 0 01-4.61 4.612 4.617 4.617 0 01-4.611-4.612A4.617 4.617 0 0135.14 9"
          fill={colorSecondary}
        />
        <path
          d="M63.283 66.976H76.76v4.411c0 .541-.44.981-.98.981H64.262c-.54 0-.98-.44-.98-.98v-4.412z"
          fill="#EEF3FB"
        />
      </g>
    </>
  );
};
export default bathTub;
