import * as React from 'react';

const engineer = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M16.372 30.176c0-8.942 7.275-16.215 16.216-16.215 8.942 0 16.217 7.273 16.217 16.215 0 8.941-7.275 16.216-16.217 16.216-8.941 0-16.216-7.275-16.216-16.216"
          fill="#D7F1FF"
        />
        <path
          d="M62.713 85.165c0 1.052-.857 1.91-1.91 1.91H4.374a1.912 1.912 0 01-1.91-1.91V74.708A28.804 28.804 0 0124.27 46.716a18.389 18.389 0 008.319 1.995c2.987 0 5.8-.727 8.303-1.987 12.863 3.22 21.821 14.668 21.821 27.984v10.457z"
          fill={colorSecondary}
        />
        <path
          d="M32.589 11.642c10.22 0 18.534 8.314 18.534 18.534 0 6.107-2.982 11.517-7.553 14.896 11.497 3.743 19.68 13.732 21.187 25.655l.06.498.165 2.319.04.775.01.389v10.457a4.234 4.234 0 01-4.004 4.222l-.225.006H4.374a4.235 4.235 0 01-4.223-4.004l-.006-.224V74.708l.01-.39.039-.774.168-2.32c1.347-12.11 9.614-22.346 21.257-26.143-4.578-3.377-7.566-8.792-7.566-14.905 0-10.22 8.316-18.534 18.536-18.534zM24.27 46.716a28.802 28.802 0 00-21.803 27.54l-.003.452v10.457c0 .997.769 1.818 1.746 1.902l.164.007h56.43c.997 0 1.818-.769 1.902-1.745l.007-.164V74.708c0-13.316-8.958-24.763-21.821-27.984a18.397 18.397 0 01-8.303 1.987c-2.992 0-5.813-.73-8.319-1.995zM32.59 13.96c-8.941 0-16.217 7.273-16.217 16.215 0 8.941 7.276 16.216 16.217 16.216 8.94 0 16.216-7.275 16.216-16.216 0-8.942-7.276-16.215-16.216-16.215zM92.889 10.094c.424-.181.917-.096 1.254.22 3.51 3.303 5.443 7.779 5.443 12.6a17.248 17.248 0 01-10.667 15.962l-.36.144v46.136c0 2.534-1.999 4.61-4.503 4.734l-.236.006h-3.066c-2.534 0-4.61-2-4.733-4.504l-.006-.236V39.02a17.232 17.232 0 01-11.04-16.106c0-4.81 1.93-9.282 5.432-12.587a1.158 1.158 0 011.948.704l.008.138v12.026l9.911 5.72 9.915-5.72V11.158c0-.462.274-.88.7-1.064zM86.24 85.156v-45.4l-.211.039c-.492.107-.99.194-1.494.26l-.455.055a17.59 17.59 0 01-1.807.103c-.6 0-1.195-.042-1.785-.103l-.22-.025-.218-.028c-.497-.064-.99-.15-1.477-.257l-.24-.042v45.398c0 1.276.992 2.326 2.248 2.415l.172.006h3.066a2.424 2.424 0 002.415-2.249l.006-.172zm8.266-70.92v9.63c0 .368-.174.712-.465.93l-.114.075-11.074 6.387c-.314.18-.69.203-1.02.068l-.14-.068-11.07-6.387a1.159 1.159 0 01-.572-.869l-.008-.137v-9.61a14.842 14.842 0 00-2.75 8.66 14.93 14.93 0 0010.244 14.204c.762.258 1.547.452 2.346.58 2.387.383 4.894.186 7.143-.577a14.949 14.949 0 0010.24-14.207c0-3.012-.87-5.867-2.497-8.298l-.263-.38z"
          fill={colorPrimary}
        />
      </g>
    </>
  );
};
export default engineer;
