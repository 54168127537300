import React from 'react';
import Styled from './Notifications.style';

interface NotificationsProps {
  children: any;
  type: 'ERROR' | 'SUCCESS' | 'INFO';
}

const Notifications: React.FC<NotificationsProps> = (props) => {
  const { children, type } = props;
  const renderType = () => {
    if (type === 'SUCCESS') {
      return <Styled.SuccessList>{children}</Styled.SuccessList>;
    } else if (type === 'ERROR') {
      return <Styled.ErrorList>{children}</Styled.ErrorList>;
    } else if (type === 'INFO') {
      return <Styled.InfoList>{children}</Styled.InfoList>;
    }
  };
  return <>{renderType()}</>;
};

export default Notifications;
