import React from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import Styled from './SkeletonLoader.style';

const SkeletonLoader: React.FC = () => {
  return (
    <>
      <Row style={{ marginBottom: '16px' }}>
        <Col md={4}>
          <Styled.Box />
        </Col>
        <Col md={4}>
          <Styled.Box />
        </Col>
        <Col md={4}>
          <Styled.Box />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Styled.Box />
        </Col>
        <Col md={6}>
          <Styled.Box />
        </Col>
      </Row>
    </>
  );
};

export default SkeletonLoader;
