import store from 'store2';
import * as _ from 'lodash';
import { AuthReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
import i18next from 'i18next';

export const initialState: AuthReducer | any = {
  status: {
    loading: false,
    error: null,
    done: false,
  },
  data: null,
};

let stateFromStore = initialState;
if (_.has(store('swsmUser'), 'status')) {
  stateFromStore = store('swsmUser');
}

const authReducer = createReducer(stateFromStore, {
  CLEAR_USERDATA: (state) => {
    state.status = {
      loading: false,
      error: null,
      done: false,
    };
    state.data = null;
  },
  LOG_IN_PENDING: (state) => {
    state.status = {
      loading: true,
      error: null,
      done: false,
    };
  },

  LOG_IN_FULFILLED: (state, action) => {
    state.status = {
      loading: false,
      error: null,
      done: true,
    };
    state.data = action.payload.data;

    store('swsmUser', state);
  },

  LOG_IN_REJECTED: (state, action) => {
    const errorMessage = _.get(action, 'payload');
    state.status = {
      loading: false,
      error: errorMessage
        ? i18next.t(`${errorMessage}`)
        : i18next.t('portal.authentication.error_message'),
      done: false,
    };
  },

  REFRESH_ACCESS_TOKEN_PENDING: (state) => {
    state.status = {
      loading: true,
      error: null,
      done: false,
    };
  },

  REFRESH_ACCESS_TOKEN_FULFILLED: (state, action) => {
    state.status = {
      loading: false,
      error: null,
      done: true,
    };
    state.data.accessToken = _.get(action, 'payload.data.accessToken', null);
    store('swsmUser', state);
  },
});

export default authReducer;
