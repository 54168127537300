import styled from 'styled-components';

const ItemWrap = styled.div`
  border-bottom: 2px solid #e4e4e4;
  padding: 16px 0px;
  position: relative;
  &:last-child {
    border-bottom: 0px;
  }
  span {
    position: absolute;
    background: #dfffdd;
    padding: 8px;
    border-radius: 8px;
    color: #62a55d;
    left: -42px;
    top: 0px;
  }
`;
const Title = styled.div`
  flex: 1;
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 16px;
`;
export default { ItemWrap, Title };
