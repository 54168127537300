import styled from 'styled-components';
import { colors } from '../../assets/variables';

const Box = styled.div`
  background: white;

  border-radius: 8px;
  border: 1px solid #e6e6e6;
  .top {
    border-bottom: 1px solid #e6e6e6;
    padding: 16px 16px;
    display: flex;
    align-items: center;
  }
  .title {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    div {
      &:first-child {
        color: ${colors.primary};
        font-weight: 600;
      }
      &:last-child {
        font-size: 14px;
        color: #626262;
        text-transform: capitalize;
      }
    }
  }
  .content {
    padding: 16px 16px;
    min-height: 80px;
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;
    }
  }
  .bottom {
    border-top: 1px solid #e6e6e6;
    padding: 16px 16px;
    display: flex;
    justify-content: flex-end;
  }
  .button-delete {
    padding: 14px;
    font-size: 13px;
    background: white;

    color: ${colors.primary};
    border: 1px solid ${colors.primary};
    &:hover {
      background: ${colors.action};
      color: ${colors.primary};
    }
  }
`;
const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  button {
    margin: 0 8px;
    min-width: 120px;
    &.delete-yes {
      background-color: ${colors.action};
      color: ${colors.primary};
      border: 1px solid ${colors.action};

      &:hover {
        background-color: ${colors.baseLight};
        color: ${colors.primary};
        border: 1px solid ${colors.primary};
      }
    }
  }
`;
const TopBtnWrap = styled.div`
  display: flex;
  .back-button {
    margin-right: 16px;
    min-width: 100px;
    background: white;
    border: 1px solid ${colors.primary};
    color: ${colors.primary};
  }
`;
export default {
  Box,
  ButtonWrap,
  TopBtnWrap,
};
