import styled, { css } from 'styled-components';
import { Input, Button } from '../index';
import { colors } from '../../assets/variables';

const InputGroup = styled.div`
  position: relative;
  ${(props) => {
    if (props.label) {
      return newStyles;
    }
  }}
`;

const InputSearch = styled(Input)`
  padding-right: 50px;
`;
const Btn = styled(Button)`
  background: ${colors.tertiary};
  min-height: 50px;
  min-width: 50px;
  border: 1px solid ${colors.darkGray};
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  position: absolute;
  top: 0;
  right: 0;
  &:hover {
    background: ${colors.tertiary};
  }
  &:focus {
    background: ${colors.tertiary};
  }
  &:active {
    background: ${colors.tertiary};
  }
`;

const Label = styled.label`
  font-size: 14px;
`;

const newStyles = css`
  ${InputSearch} {
    margin-top: 6px;
  }
  ${Btn} {
    top: 0;
  }
`;
export default { InputSearch, Label, Btn, InputGroup };
