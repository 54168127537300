import React from 'react';
import {
  Wrap,
  Input,
  Button,
  Typography,
  Notifications,
} from '../../components/index';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Styled from './ContactUs.style';
import store from 'store2';
import Reaptcha from 'reaptcha';
import _ from 'lodash';
import { scrollToTop } from '../../helpers/helpers';
interface ContactUsProps {
  setCustomerService: Function;
  contactForm: any;
}

const ContactUs: React.FC<ContactUsProps> = (props) => {
  const { contactForm, setCustomerService } = props;
  const { error, loading, done } = _.get(contactForm, 'status');
  const skipRecaptcha = process.env.REACT_APP_SKIP_RECAPTCHA === 'true';
  const data = store('swsmUser');

  const [t] = useTranslation('common');
  const CommunicationSchema = Yup.object().shape({
    name: Yup.string().required(`${t('portal.general_form_errors.required')}`),
    phone: Yup.string()
      .required(`${t('portal.general_form_errors.required')}`)
      .matches(
        /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/,
        `${t('portal.general_form_errors.contact_not_valid')}`
      ),
  });
  const text_translation = {
    customer_service: {
      required: t('portal.customer_service.required'),
      form_elements: {
        contact_number: t(
          'portal.customer_service.form_elements.contact_number'
        ),
        email: t('portal.customer_service.form_elements.email'),
        name: t('portal.customer_service.form_elements.name'),
        query_freetext: t(
          'portal.customer_service.form_elements.query_freetext'
        ),
      },
    },
  };
  const [refresh, setRefresh] = React.useState(false);
  if (refresh === true) {
    setTimeout(() => {
      setRefresh(false);
    }, 3000);
  }
  React.useEffect(() => {
    scrollToTop();
  }, []);
  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          recaptchaResponse: skipRecaptcha ? 'test123' : '',
          name: '',
          phone: '',
          email: data.data.email,
          freeText: '',
          subject: 'Contact us form GWF Business',
        }}
        validationSchema={CommunicationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setCustomerService(
            values,
            () => {
              setSubmitting(false);
              resetForm();
            },
            () => setSubmitting(false)
          );
          setRefresh(true);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Row>
              <Col xs={12} sm={12} md={4}>
                <Input
                  placeholder={`${text_translation.customer_service.form_elements.name}*`}
                  type="text"
                  id="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  error={errors.name && touched.name ? errors.name : undefined}
                />
              </Col>
              <Col xs={12} sm={12} md={4}>
                <Input
                  placeholder={`${text_translation.customer_service.form_elements.contact_number}*`}
                  type="text"
                  id="phone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  error={
                    errors.phone && touched.phone ? errors.phone : undefined
                  }
                />
              </Col>
              <Col xs={12} sm={12} md={4}>
                <Input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={`${text_translation.customer_service.form_elements.email}*`}
                  type="email"
                  disabled
                  id="email"
                  value={values.email}
                  error={
                    errors.email && touched.email ? errors.email : undefined
                  }
                />
              </Col>
              <Col xs={12} sm={12} md={12}>
                <Styled.Textarea
                  rows={5}
                  cols={49}
                  id="freeText"
                  value={values.freeText}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={
                    text_translation.customer_service.form_elements
                      .query_freetext
                  }
                ></Styled.Textarea>
              </Col>
              {skipRecaptcha ? null : (
                <Col xs={12} sm={12} md={12} style={{ margin: '16px 0px' }}>
                  <Reaptcha
                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA || ''}
                    onVerify={(response) =>
                      setFieldValue('recaptchaResponse', response)
                    }
                    onExpire={() => setFieldValue('recaptchaResponse', '')}
                  />
                  {errors.recaptchaResponse && touched.recaptchaResponse && (
                    <Styled.ErrorInline>
                      {errors.recaptchaResponse}
                    </Styled.ErrorInline>
                  )}
                </Col>
              )}
              <Col xs={12} sm={12} md={12}>
                <i>* -{text_translation.customer_service.required}</i>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '25px',
                }}
              >
                <Button
                  isLoading={loading}
                  disabled={isSubmitting}
                  type="submit"
                >
                  {t('portal.forgot_password.submit_button')}
                </Button>
              </Col>
              {error ? (
                <Col md={12} sm={12} xs={12}>
                  <Notifications type="ERROR">{error}</Notifications>
                </Col>
              ) : null}
              {done && refresh ? (
                <Col md={12} sm={12} xs={12}>
                  <Notifications type="SUCCESS">
                    You have successfully submitted the contact us form.
                  </Notifications>
                </Col>
              ) : null}
            </Row>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <Wrap>
      <Typography size="1" text="Contact us" style={{ marginBottom: '32px' }} />
      {renderForm()}
    </Wrap>
  );
};

export default ContactUs;
