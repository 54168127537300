import React from 'react';
import Styled from './Calculator.style';
import * as _ from 'lodash';
import { Typography, Sliders, Icon } from '../index';
import { prettifyIfNumber } from '../../helpers/formatters';
import { useResponsivity } from '../../helpers/helpers';
export const Calculator: React.FC = () => {
  const items = [
    {
      name: 'No. of taps',
      text: 'Taps',
      litres: 10842,
      money: 33.84,
    },

    { name: 'No. of toilets', text: 'Toilets', litres: 15600, money: 48.7 },
    { name: 'No. of urinals', text: 'Urinals', litres: 4680, money: 14.61 },
    { name: 'No. of showers', text: 'Showers', litres: 6825, money: 21.3 },
  ];
  const isMobile = useResponsivity();
  const [sliderTaps, setSliderTaps] = React.useState(0);
  const [sliderToilet, setSliderToilet] = React.useState(0);
  const [sliderUrinals, setSliderUrinals] = React.useState(0);
  const [sliderShower, setSliderShower] = React.useState(0);

  const totalLitres =
    sliderTaps * items[0].litres +
    sliderToilet * items[1].litres +
    sliderUrinals * items[2].litres +
    sliderShower * items[3].litres;

  const totalMoney =
    sliderTaps * items[0].money +
    sliderToilet * items[1].money +
    sliderUrinals * items[2].money +
    sliderShower * items[3].money;

  const renderItems = () => {
    return _.map(items, (el, index) => {
      return (
        <Sliders
          className="slider"
          onValueChange={(value) => {
            if (el.text === 'Taps') {
              setSliderTaps(value);
            } else if (el.text === 'Toilets') {
              setSliderToilet(value);
            } else if (el.text === 'Showers') {
              setSliderShower(value);
            } else if (el.text === 'Urinals') {
              setSliderUrinals(value);
            }
          }}
          litres={el.litres}
          money={el.money}
          title={el.name}
          key={index}
        />
      );
    });
  };

  return (
    <Styled.Wrap>
      <Typography size="5" text="Quick Sums" />

      {renderItems()}

      <Styled.TotalWrap>
        <div
          style={{
            flex: '1 1 50%',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography
            text="Potential annual savings:"
            size="5"
            style={{ fontSize: isMobile ? '16px' : '18px' }}
          />
          <Typography
            size="p"
            text="Based on 10 employees"
            style={{
              fontSize: isMobile ? '12px' : '14px',
              fontStyle: 'italic',
            }}
          />
        </div>
        <Styled.ItemWrap>
          <Styled.Item>
            {prettifyIfNumber(totalLitres)}
            <Icon icon="droplet" size={30} style={{ marginLeft: '8px' }} />
          </Styled.Item>
          <Styled.Item style={{ marginLeft: '16px' }}>
            {prettifyIfNumber(totalMoney)}
            <Icon icon="pound" size={30} style={{ marginLeft: '8px' }} />
          </Styled.Item>
        </Styled.ItemWrap>
      </Styled.TotalWrap>
    </Styled.Wrap>
  );
};

export default Calculator;
