import * as React from 'react';

const typeCounter = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M46.18 65.71C45.69 56.43 38 49.04 28.6 49.04c-.6 0-1.19.03-1.78.09-.71-2.26-1.09-4.66-1.09-7.15C25.73 28.76 36.49 18 49.71 18 62.94 18 73.7 28.76 73.7 41.98c0 2.49-.38 4.89-1.09 7.15-.59-.06-1.18-.09-1.78-.09-9.4 0-17.09 7.39-17.58 16.66-1.15.18-2.34.27-3.54.27-1.2 0-2.38-.09-3.53-.26"
          fill={colorSecondary}
        />
        <path
          d="M49.71 16C64.04 16 75.7 27.65 75.7 41.98c0 2.59-.38 5.1-1.1 7.47 7.9 1.73 13.83 8.77 13.83 17.18 0 9.71-7.9 17.6-17.6 17.6-9.34 0-17-7.3-17.57-16.51-1.16.17-2.35.25-3.55.25-1.2 0-2.39-.08-3.55-.25-.56 9.21-8.22 16.51-17.56 16.51-9.7 0-17.6-7.89-17.6-17.6 0-8.41 5.93-15.45 13.83-17.18a25.674 25.674 0 01-1.1-7.47C23.73 27.65 35.39 16 49.71 16zm21.12 35.04c-8.15 0-14.87 6.28-15.54 14.27-.04.43-.06.87-.06 1.32 0 .25.01.5.02.74.39 8.27 7.23 14.86 15.58 14.86 8.6 0 15.6-6.99 15.6-15.6 0-7.424-5.207-13.636-12.163-15.2l-.328-.07v-.01a15.63 15.63 0 00-1.622-.238l-.418-.031c-.349-.031-.71-.04-1.069-.04zm-42.23 0c-.24 0-.48.005-.717.017l-.353.024c-.7.04-1.38.139-2.04.269C18.37 52.8 13 59.09 13 66.63c0 8.61 7 15.6 15.6 15.6 8.35 0 15.19-6.59 15.58-14.86a14.263 14.263 0 00-.04-2.061c-.67-7.99-7.39-14.268-15.54-14.268zM49.71 18c-13.22 0-23.98 10.76-23.98 23.98 0 2.49.38 4.89 1.09 7.15.59-.06 1.18-.09 1.78-.09 9.4 0 17.09 7.39 17.58 16.67 1.15.17 2.33.26 3.53.26 1.2 0 2.39-.09 3.54-.27.49-9.27 8.18-16.66 17.58-16.66.6 0 1.19.03 1.78.09.71-2.26 1.09-4.66 1.09-7.15C73.7 28.76 62.94 18 49.71 18z"
          fill={colorPrimary}
        />
        <path
          d="M49.733 25.654c2.387 0 4.312.543 5.773 1.63 1.462 1.085 2.193 2.55 2.193 4.392 0 1.273-.353 2.407-1.058 3.402-.705.995-1.843 1.885-3.412 2.67 1.865 1 3.204 2.044 4.017 3.13.813 1.086 1.22 2.277 1.22 3.573 0 2.047-.802 3.694-2.405 4.94-1.604 1.244-3.713 1.866-6.328 1.866-2.73 0-4.873-.579-6.431-1.739-1.558-1.16-2.337-2.804-2.337-4.93 0-1.422.378-2.684 1.134-3.787.757-1.103 1.97-2.075 3.642-2.917-1.421-.898-2.445-1.859-3.071-2.882a6.328 6.328 0 01-.937-3.36c0-1.787.739-3.23 2.217-4.333 1.478-1.103 3.406-1.655 5.783-1.655zm-.256 14.397c-2.423 1.114-3.634 2.524-3.634 4.23 0 .978.342 1.74 1.024 2.285.682.546 1.615.82 2.797.82 1.308 0 2.286-.283 2.934-.845.648-.563.973-1.305.973-2.227 0-.76-.322-1.474-.964-2.14-.643-.665-1.686-1.373-3.13-2.123zm.222-10.542c-.9 0-1.623.23-2.175.691-.552.46-.827 1.077-.827 1.851 0 .681.218 1.293.656 1.834.437.54 1.23 1.093 2.38 1.662 1.114-.523 1.893-1.057 2.337-1.603a2.918 2.918 0 00.665-1.893c0-.785-.284-1.405-.853-1.86-.57-.455-1.297-.682-2.183-.682zM36.46 65.63H21.15c-.55 0-1 .45-1 1 0 .56.45 1 1 1h15.31c.55 0 1-.44 1-1 0-.55-.45-1-1-1M78.48 65.63h-6.65v-6.65c0-.55-.45-1-1-1s-1 .45-1 1v6.65h-6.66c-.55 0-1 .45-1 1 0 .56.45 1 1 1h6.66v6.66c0 .55.45 1 1 1s1-.45 1-1v-6.66h6.65c.56 0 1-.44 1-1 0-.55-.44-1-1-1"
          fill={colorPrimary}
        />
      </g>
    </>
  );
};
export default typeCounter;
