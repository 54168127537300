import styled from 'styled-components';
import { Button } from '../index';
import { colors } from '../../assets/variables';

const Btn = styled(Button)`
  margin-right: 16px;
  background: white;
  border: 1px solid ${colors.primary};
  color: ${colors.primary};
`;
export default {
  Btn,
};
