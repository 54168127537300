import axios from 'axios';
import { getMicroServiceUrl } from '../helpers/helpers';
import { generalData } from './general';
export const getChallenges = (id) => ({
  type: 'GET_ALL_CHALLENGES',
  payload: axios.get(
    `${getMicroServiceUrl('gamification', '2.0')}orgPropertyId/${id}/challenges`
  ),
});

export const getChallengeCategory = (id, cat) => ({
  type: 'GET_CHALLENGES_CATEGORY',
  payload: axios.get(
    `${getMicroServiceUrl(
      'gamification',
      '2.0'
    )}orgPropertyId/${id}/challenges/category/${cat}`
  ),
});

// export const completeChallenge = (id, challengeId) => ({
//   type: 'COMPLETE_CHALLENGE',
//   payload: axios.post(
//     `${getMicroServiceUrl(
//       'gamification',
//       '2.0'
//     )}orgPropertyId/${id}/challenges/${challengeId}/complete`
//   ),
// });

export const completeChallenge = (id, challengeId) => {
  return (dispatch) => {
    const response = dispatch({
      type: 'COMPELTE_CHALLENGE',
      payload: axios.post(
        `${getMicroServiceUrl(
          'gamification',
          '2.0'
        )}orgPropertyId/${id}/challenges/${challengeId}/complete`
      ),
      meta: {
        id,
      },
    });
    response
      .then((data) => {
        setTimeout(() => {
          dispatch(generalData());
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
