import DefaultLayout from './DefaultLayout';
import { connect } from 'react-redux';
import { listLocations } from '../../actions/location';

import { getPreferences } from '../../actions/preferences';
import { generalData } from '../../actions/general';
import { getAvailableRewards } from '../../actions/rewards';
import { AppState } from '../../interfaces';

const mapStateToProps = (state: AppState) => ({
  listLocation: state.listLocationReducer,
  general: state.generalReducer,
});

const mapDispatchToProps = (dispatch) => ({
  listLocations: () => dispatch(listLocations()),
  getPreferences: () => dispatch(getPreferences()),
  generalData: () => dispatch(generalData()),
  getAvailableRewards: () => dispatch(getAvailableRewards()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
