import { PropertyReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

const initialState: PropertyReducer = {
  addLocation: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  editLocation: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  deleteLocation: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  address: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
};

const locationReducer = createReducer(initialState, {
  ADD_LOCATION_PENDING: (state) => {
    state.addLocation.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  ADD_LOCATION_FULFILLED: (state, action) => {
    state.addLocation.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.addLocation.data = action.payload.data;
  },

  ADD_LOCATION_REJECTED: (state, action) => {
    state.addLocation.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.addLocation.data = action.payload.data;
  },
  EDIT_LOCATION_PENDING: (state) => {
    state.editLocation.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  EDIT_LOCATION_FULFILLED: (state, action) => {
    state.editLocation.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.editLocation.data = action.payload.data;
  },

  EDIT_LOCATION_REJECTED: (state, action) => {
    state.editLocation.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.editLocation.data = action.payload.data;
  },
  DELETE_LOCATION_PENDING: (state) => {
    state.editLocation.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  DELETE_LOCATION_FULFILLED: (state, action) => {
    state.editLocation.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.editLocation.data = action.payload.data;
  },

  DELETE_LOCATION_REJECTED: (state, action) => {
    state.editLocation.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.editLocation.data = action.payload.data;
  },
  GET_ADDRESS_PENDING: (state) => {
    state.address.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  GET_ADDRESS_FULFILLED: (state, action) => {
    state.address.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.address.data = action.payload.data;
  },

  GET_ADDRESS_REJECTED: (state, action) => {
    state.address.status = {
      loading: false,
      error: action.payload.data.error,
      done: false,
    };
  },
  CLEAR_ADDRESS: (state) => {
    state.address.data = null;
  },
});

export default locationReducer;
