import SingleToilet from './SingleToilet';
import { connect } from 'react-redux';
import {
  getChallengeCategory,
  completeChallenge,
} from '../../actions/challenges';
import { AppState } from '../../interfaces';
import { generalData } from '../../actions/general';

const mapStateToProps = (state: AppState) => ({
  challenges: state.challengesReducer,
  listLocation: state.listLocationReducer,
  completeTask: state.completeChallengesReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getChallengeCategory: (id, cat) => dispatch(getChallengeCategory(id, cat)),
  completeChallenge: (id, challengeId) =>
    dispatch(completeChallenge(id, challengeId)),
  generalData: () => dispatch(generalData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleToilet);
