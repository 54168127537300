import EasyWin from './EasyWin';
import { connect } from 'react-redux';

import { getRoiData } from '../../actions/roi';
import { AppState } from '../../interfaces';

const mapStateToProps = (state: AppState) => ({
  roiData: state.roiDataReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getRoiData: (locationId) => dispatch(getRoiData(locationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EasyWin);
