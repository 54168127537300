import * as React from 'react';

const admin = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M93.373 83.466H83.47v3.718h9.902v-3.718zM78.45 87.183h2.701V39.809a13.31 13.31 0 01-2.258.038l-.443-.038v47.374zm1.343-74.864c-6.956 0-12.614 5.658-12.614 12.614 0 5.693 3.78 10.505 8.952 12.081 1.16.36 2.387.545 3.662.545 1.277 0 2.505-.185 3.677-.545 5.17-1.576 8.95-6.388 8.95-12.08 0-6.957-5.657-12.615-12.627-12.615zm0 1.948c5.89 0 10.68 4.788 10.68 10.666 0 5.658-4.419 10.296-9.994 10.656-.222.011-.454.023-.685.023-.231 0-.463-.012-.684-.023-5.566-.36-9.982-4.998-9.982-10.656 0-5.878 4.788-10.666 10.666-10.666zm0 2.319c-4.602 0-8.346 3.745-8.346 8.347 0 4.615 3.744 8.36 8.347 8.36 4.614 0 8.36-3.745 8.36-8.36 0-4.602-3.746-8.347-8.36-8.347zm-3.439 22.886l-.223-.058c-6.47-1.634-11.271-7.501-11.271-14.48C64.86 16.7 71.561 10 79.793 10c8.244 0 14.946 6.701 14.946 14.933 0 6.98-4.8 12.847-11.27 14.481v27.537h11.063c.641 0 1.16.519 1.16 1.16v6.037c0 .641-.519 1.16-1.16 1.16l-11.063-.001v5.84h11.063c.641 0 1.16.519 1.16 1.16v6.037c0 .64-.519 1.16-1.16 1.16h-12.22l-.02-.003H77.29c-.593 0-1.084-.45-1.151-1.024l-.008-.135V39.415l.449.11-.226-.053z"
          fill={colorPrimary}
        />
        <path fill={colorSecondary} d="M83.471 72.988h9.902V69.27H83.47z" />
        <path
          d="M3.764 73.473c-.015.389-.05.774-.05 1.164v10.457a4.234 4.234 0 004.23 4.229h56.429a4.234 4.234 0 004.228-4.229V74.637c0-.391-.035-.775-.048-1.164l-.166-2.318c-1.342-12.144-9.59-22.359-21.247-26.153 4.57-3.379 7.553-8.79 7.553-14.897 0-10.219-8.316-18.534-18.535-18.534-10.22 0-18.535 8.315-18.535 18.534 0 6.113 2.988 11.528 7.566 14.906C13.546 48.808 5.28 59.043 3.931 71.155l-.167 2.318z"
          fill={colorPrimary}
        />
        <path
          d="M66.282 85.094c0 1.053-.856 1.91-1.91 1.91H7.945a1.912 1.912 0 01-1.91-1.91V74.637A28.804 28.804 0 0127.84 46.646a18.394 18.394 0 008.317 1.994c2.987 0 5.8-.727 8.303-1.987 12.864 3.223 21.821 14.669 21.821 27.984v10.457z"
          fill={colorSecondary}
        />
        <path
          d="M19.942 30.106c0-8.942 7.274-16.216 16.216-16.216 8.941 0 16.216 7.274 16.216 16.216 0 8.941-7.275 16.215-16.216 16.215-8.942 0-16.216-7.274-16.216-16.215"
          fill="#D7F1FF"
        />
      </g>
    </>
  );
};
export default admin;
