import * as React from 'react';

const man2 = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M50.238 17c8.814 0 15.986 7.171 15.986 15.986 0 5.267-2.572 9.933-6.514 12.847 9.915 3.228 16.972 11.843 18.274 22.128l.05.429H91.93c.543 0 .991-.413 1.049-.94l.006-.115v-7a19.013 19.013 0 00-14.366-18.496 12.65 12.65 0 01-5.577 1.317c-1.358 0-2.694-.215-3.972-.638a.999.999 0 11.629-1.898c2.587.857 5.532.658 7.993-.536l.364-.182a10.623 10.623 0 005.618-9.377c0-5.862-4.77-10.632-10.632-10.632-2.102 0-4.135.613-5.878 1.772A1 1 0 0166.056 20a12.57 12.57 0 016.985-2.107c6.965 0 12.632 5.667 12.632 12.632 0 3.909-1.8 7.534-4.803 9.906a20.996 20.996 0 0114.11 19.504l.004.4v7a3.059 3.059 0 01-2.876 3.05l-.18.005H78.178l.034.668.009.336v9.019a3.652 3.652 0 01-3.454 3.642l-.193.005h-48.67a3.653 3.653 0 01-3.643-3.454l-.005-.193v-9.019l.008-.336.034-.668H9.055a3.059 3.059 0 01-3.05-2.876L6 67.335v-7c0-9.086 5.707-16.976 14.115-19.904a12.61 12.61 0 01-4.803-9.906c0-6.965 5.666-12.632 12.63-12.632 2.499 0 4.915.729 6.987 2.106a1 1 0 01-1.107 1.666 10.588 10.588 0 00-5.88-1.772c-5.862 0-10.63 4.77-10.63 10.632 0 3.82 2.037 7.326 5.339 9.223l.298.163.037.017c2.52 1.332 5.604 1.585 8.3.692a.999.999 0 11.629 1.898 12.616 12.616 0 01-3.972.638c-1.937 0-3.85-.466-5.577-1.316A19.01 19.01 0 008.003 59.965l-.003.37v7c0 .542.412.991.94 1.049l.115.006h13.387a26.809 26.809 0 0118.334-22.549c-3.948-2.913-6.525-7.583-6.525-12.855C34.25 24.171 41.423 17 50.238 17zm-7.175 30.251a24.841 24.841 0 00-18.805 23.754l-.003.389v9.019c0 .86.663 1.569 1.506 1.64l.142.007h48.67a1.65 1.65 0 001.64-1.505l.007-.142v-9.019c0-11.485-7.726-21.357-18.821-24.136a15.868 15.868 0 01-7.161 1.714 15.86 15.86 0 01-7.175-1.721zM50.238 19c-7.712 0-13.987 6.274-13.987 13.986 0 7.712 6.275 13.986 13.987 13.986 7.71 0 13.986-6.274 13.986-13.986C64.224 25.274 57.949 19 50.238 19z"
          fill={colorPrimary}
        />
        <path
          d="M36.251 32.986C36.251 25.274 42.525 19 50.237 19c7.712 0 13.987 6.274 13.987 13.986 0 7.712-6.275 13.986-13.987 13.986s-13.986-6.274-13.986-13.986"
          fill="#D7F1FF"
        />
        <path
          d="M76.22 80.413a1.65 1.65 0 01-1.647 1.647h-48.67a1.65 1.65 0 01-1.648-1.647v-9.019a24.843 24.843 0 0118.808-24.143 15.86 15.86 0 007.175 1.721c2.576 0 5.003-.627 7.16-1.714C68.495 50.037 76.22 59.909 76.22 71.394v9.019z"
          fill={colorSecondary}
        />
      </g>
    </>
  );
};
export default man2;
