import React from 'react';
import * as _ from 'lodash';
import Styled from './PageConsumption.style';
import { Typography, Card } from '../../components/index';
import { Row } from 'react-styled-flexboxgrid';
import { useTranslation } from 'react-i18next';

const ConsumptionChart: React.FC<any> = (props) => {
  const { data, isDesktop } = props;
  const [display, setDisplay] = React.useState('savings');
  const [t] = useTranslation('common');
  const text_selector_title = t('portal.my_use.chart_selector_title');
  const text_disclaimer = t('portal.my_use.chart_disclaimer');
  const title = t(data.title);

  if (!data) {
    return <p>No data</p>;
  }

  const renderCards = (data) => {
    return (
      <Styled.Column
        key={data.id}
        xs={12}
        sm={12}
        md={12}
        lg={6}
        style={{ marginBottom: '32px' }}
      >
        <Card isDesktop={isDesktop} {...data} displayMoney={display} />
      </Styled.Column>
    );
  };

  const renderDataSet = () => {
    return data.id === 5 || data.id === 6 ? null : (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginBottom: '16px',
        }}
      >
        <Typography
          size="p"
          text={text_selector_title}
          style={{ marginBottom: '8px', color: '#174489', fontSize: '12px' }}
        />
        <Styled.ToggleWrap>
          <Styled.Item
            className={display === 'savings' ? 'active' : 'inactive'}
            onClick={() => setDisplay('savings')}
          >
            {t('portal.my_use.chart_selector_option_litre').toUpperCase()}{' '}
          </Styled.Item>
          <Styled.Item
            className={display === 'cost' ? 'active' : 'inactive'}
            onClick={() => setDisplay('cost')}
          >
            {t('portal.my_use.chart_selector_option_cost').toUpperCase()}
          </Styled.Item>
        </Styled.ToggleWrap>
      </div>
    );
  };

  const renderCharts = () => {
    return (
      <Styled.Wrap key={data.id}>
        <Typography size="2" text={title} weight={700} />
        {data ? renderDataSet() : null}
        <Row>{_.map(data.components, (el) => renderCards(el))}</Row>
      </Styled.Wrap>
    );
  };

  return <React.Fragment>{renderCharts()}</React.Fragment>;
};

export default ConsumptionChart;
