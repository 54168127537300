import * as React from 'react';

const visible = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M51.223 32.025c-10.04 0-18.206 8.166-18.206 18.206 0 10.038 8.167 18.205 18.206 18.205 10.038 0 18.205-8.168 18.205-18.206S61.26 32.026 51.223 32.026"
          fill={colorSecondary}
        />
        <path
          d="M51.222 27c14.996 0 40.203 21.554 41.27 22.472a1.001 1.001 0 010 1.517l-.85.718C86.97 55.611 64.869 73.46 51.222 73.46c-14.979 0-41.72-21.536-42.852-22.452a.997.997 0 010-1.553l1.07-.852C14.733 44.445 37.742 27 51.223 27zm0 2c-12.729 0-35.608 17.32-40.61 21.23l1.303 1.008C18.77 56.48 39.388 71.46 51.222 71.46c12.731 0 34.39-17.358 39.061-21.23l-1.221-1c-6.438-5.216-26-20.23-37.84-20.23z"
          fill={colorPrimary}
        />
        <path
          d="M51.223 30.024c11.142 0 20.205 9.065 20.205 20.206 0 11.143-9.063 20.206-20.205 20.206-11.142 0-20.206-9.064-20.206-20.206 0-11.141 9.064-20.205 20.206-20.205zm0 2c-10.04 0-18.206 8.167-18.206 18.206s8.167 18.206 18.206 18.206c10.038 0 18.205-8.168 18.205-18.206S61.26 32.026 51.223 32.026z"
          fill={colorPrimary}
        />
        <path
          d="M51.223 43.727a6.51 6.51 0 016.503 6.504 6.51 6.51 0 01-6.503 6.503 6.51 6.51 0 01-6.504-6.503 6.512 6.512 0 016.504-6.504zm0 2a4.51 4.51 0 00-4.504 4.504 4.509 4.509 0 004.504 4.503 4.508 4.508 0 004.503-4.503 4.509 4.509 0 00-4.503-4.504z"
          fill={colorPrimary}
        />
      </g>
    </>
  );
};
export default visible;
