import AddRoom from './AddRoom';
import { connect } from 'react-redux';
import { getQuestions } from '../../actions/question';
import { addRoom } from '../../actions/room';
import { clearAnswerKey } from '../../actions/question';
import { AppState } from '../../interfaces';

const mapStateToProps = (state: AppState) => ({
  questions: state.questionReducer,
  room: state.roomReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getQuestions: (room) => dispatch(getQuestions(room)),
  addRoom: (id, room, roomName, data) =>
    dispatch(addRoom(id, room, roomName, data)),
  clearAnswerKey: () => dispatch(clearAnswerKey()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddRoom);
