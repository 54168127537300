import * as React from 'react';

const pin = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M50.49 9c19.57 0 35.49 15.921 35.49 35.49 0 19.299-33.43 47.093-34.853 48.267a.999.999 0 01-1.274 0l-.922-.775C43.295 87.19 15 62.245 15 44.49 15 24.921 30.92 9 50.49 9zm0 2C32.023 11 17 26.023 17 44.49c0 16.959 28.811 42.201 33.49 46.188l1.092-.94C58.522 83.69 83.98 60.426 83.98 44.49 83.98 26.023 68.957 11 50.49 11z"
          fill={colorPrimary}
        />
        <path
          d="M50.49 21.724c10.403 0 18.866 8.463 18.866 18.866 0 10.402-8.463 18.865-18.866 18.865-10.402 0-18.865-8.463-18.865-18.865 0-10.403 8.463-18.866 18.865-18.866zm0 2c-9.3 0-16.865 7.566-16.865 16.866 0 9.299 7.565 16.865 16.865 16.865 9.3 0 16.866-7.566 16.866-16.865 0-9.3-7.566-16.866-16.866-16.866z"
          fill={colorPrimary}
        />
        <path
          d="M50.49 23.724c-9.3 0-16.865 7.566-16.865 16.866 0 9.299 7.565 16.865 16.865 16.865 9.3 0 16.866-7.566 16.866-16.865 0-9.3-7.566-16.866-16.866-16.866"
          fill={colorSecondary}
        />
      </g>
    </>
  );
};
export default pin;
