import Search from './Search';
import { connect } from 'react-redux';
import { clearSearchData, getSearchData } from '../../actions/search';
import { AppState } from '../../interfaces';
const mapStateToProps = (state: AppState) => ({
  search: state.searchReducer,
});
const mapDispatchToProps = (dispatch) => ({
  clearSearchData: () => dispatch(clearSearchData()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Search);
