import * as React from 'react';

const facebook = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M73.0947266,55.8251953 L75.8494141,37.8640625 L58.6164062,37.8640625 L58.6164062,26.2083984 C58.6164062,21.2945313 61.0236328,16.5048828 68.7423828,16.5048828 L76.5777344,16.5048828 L76.5777344,1.21367188 C76.5777344,1.21367188 69.4667969,0 62.66875,0 C48.4753906,0 39.1990234,8.60195312 39.1990234,24.1748047 L39.1990234,37.8640625 L23.4222656,37.8640625 L23.4222656,55.8251953 L39.1990234,55.8251953 L39.1990234,99.2451172 C42.3625,99.7416016 45.6048828,100 48.9078125,100 C52.2107422,100 55.453125,99.7416016 58.6164062,99.2451172 L58.6164062,55.8251953 L73.0947266,55.8251953 Z"
        id="Path"
        fill={colorPrimary}
        fillRule="nonzero"
      />
    </g>
  );
};
export default facebook;
