import * as React from 'react';
import Styled from './Checkbox.style';

interface CheckboxProps {
  boxSize?: number;
  checked?: any;
  onChange: Function;
  disabled?: boolean;
  error?: string;
  name: string;
  id: string;
  theme?: string;
  title?: string;
  required?: boolean;
  [x: string]: any;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  boxSize,
  checked,
  onChange,
  name,
  theme,
  children,
  style,
  title,
  disabled,
  required,
  error,
  ...props
}) => (
  <Styled.Label
    boxSize={boxSize || (title ? 40 : 20)}
    disabled={disabled}
    name={name}
    checked={checked}
    theme={theme}
  >
    <Styled.HiddenCheckbox
      disabled={disabled}
      name={name}
      checked={checked}
      onChange={onChange}
      aria-labelledby={name}
      {...props}
    />
    <Styled.Icon viewBox="0 0 24 24">
      <polyline points="20 6 9 17 4 12" />
    </Styled.Icon>
    <Styled.Content>
      {title ? <Styled.Title>{title}</Styled.Title> : null}
      {children ? (
        <Styled.Description>
          {children}
          {' '}
          {required ? '*' : null}
        </Styled.Description>
      ) : null}
    </Styled.Content>

    {error ? <Styled.FieldError>{error}</Styled.FieldError> : null}
  </Styled.Label>
);

export default Checkbox;
