import * as React from 'react';

const exit = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M33.339 85.548v2.85h-8.07v-2.85h8.07zm43.04 0v2.85H59.05l12.86-2.85h4.47zM76.375 9v2.852h-4.552c-.012 0-.022-.003-.034-.003h-41.92l-.018.003h-4.578V9h51.102z"
          fill={colorSecondary}
        />
        <path
          d="M23.82 7c.08 0 .151.027.226.045l.149-.038.078-.008h53.102a1 1 0 01.993.884l.007.116v4.852a1 1 0 01-.883.994l-.117.006H72.78v69.697h4.6c.51 0 .935.388.993.884l.007.116V88.4h12.1a1 1 0 01.116 1.994l-.117.006H75.66l-.012-.002H50.03l-15.48 3.43a.727.727 0 01-.21.03 1.03 1.03 0 01-.63-.22.987.987 0 01-.362-.652l-.008-.128v-2.46h-9.07a.997.997 0 01-.993-.882l-.007-.118v-4.85c0-.511.388-.935.884-.993l.116-.007h4.6V13.851h-4.596a1 1 0 01-.993-.883l-.007-.117V9H9a1 1 0 01-.116-1.993L9 7h14.82zm46.96 7.098l-35.44 7.86v69.65l35.44-7.86v-69.65zm5.6 71.45h-4.47l-12.86 2.85h17.33v-2.85zm-43.04 0h-8.07v2.85h8.07v-2.85zm29.322-71.697H30.87v69.697h2.47v-62.39c0-.43.27-.812.668-.948l.112-.032 28.543-6.327zM40.79 51.848c2.28 0 4.13 1.86 4.13 4.13a4.13 4.13 0 01-4.13 4.12 4.128 4.128 0 01-4.12-4.12c0-2.27 1.85-4.13 4.12-4.13zm0 2c-1.17 0-2.12.96-2.12 2.13s.95 2.12 2.12 2.12c1.17 0 2.13-.95 2.13-2.12 0-1.17-.96-2.13-2.13-2.13zm-24.011-7.122L15.63 49.43h9.643a1 1 0 01.116 1.993l-.116.007H15.63l1.148 2.703L8 50.43l8.778-3.703zM76.375 9H25.273v2.852h4.578l.018-.003 41.937.002.017.001h4.552V9z"
          fill={colorPrimary}
        />
      </g>
    </>
  );
};
export default exit;
