import React from 'react';
import { Col } from 'react-styled-flexboxgrid';
import * as _ from 'lodash';
import Styled from './Sidebar.style';
import LinkHandler from '../../components/LinkHandler';
import Typography from '../Typography';
import { footerItems } from '../../helpers/helpers';
const Sidebar: React.FC = (props) => {
  const renderFooterLinks = () => {
    const renderLinks = _.map(footerItems[0].navElements, (el) => {
      return (
        <Styled.Wrapper key={el.name}>
          <LinkHandler path={el.path}>{el.title}</LinkHandler>
        </Styled.Wrapper>
      );
    });
    return (
      <Col xs={12} style={{ marginTop: '32px' }}>
        <Typography size="3" style={{ marginBottom: '16px' }} text="Policies" />
        <Styled.Line />
        {renderLinks}
      </Col>
    );
  };

  return <div>{renderFooterLinks()}</div>;
};

export default Sidebar;
