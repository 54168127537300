import React from 'react';
import * as _ from 'lodash';
import Styled from './ViewLocation.style';
import {
  Wrap,
  Button,
  Avatar,
  Popup,
  Icon,
  BackButton,
  NothingToDisplay,
  Typography,
  Loader,
} from '../../components/index';
import { Row, Col } from 'react-styled-flexboxgrid';
import { useParams, useNavigate } from 'react-router-dom';
import { useResponsivity } from '../../helpers/helpers';
import { useTranslation } from 'react-i18next';

interface ViewLocationProps {
  listLocation: any;
  room: any;
  deleteRoom: Function;
  listLocations: Function;
}

const ViewLocation: React.FC<ViewLocationProps> = (props) => {
  const { listLocation, room, deleteRoom, listLocations } = props;
  const { locationId } = useParams();
  const [isId, setId] = React.useState();
  const [popup, setPopup] = React.useState(false);
  const data = _.get(listLocation, 'data.data');
  const isMobile = useResponsivity();
  const navigate = useNavigate();
  const { error, loading, done } = _.get(listLocation, 'status');
  const [t] = useTranslation('common');
  const findElement = _.find(data, function (o) {
    return o.id == locationId;
  });
  const getRooms = _.get(findElement, 'rooms');
  const roomLength = _.get(getRooms, 'length');

  React.useEffect(() => {
    listLocations();
  }, [listLocations]);

  const renderNoRooms = () => {
    return (
      <Col md={12} sm={12} xs={12}>
        <NothingToDisplay
          title="You have no room(s) added for this location."
          location={`/${locationId}/add-room`}
          subtitle="Please add a room in order to view your data."
          buttonText="Add room(s)"
        />
      </Col>
    );
  };

  const renderRooms = () => {
    return _.map(getRooms, (el, index) => {
      const sortedSummary = _.sortBy(el.summary, (o) => o.displayOrder);

      return (
        <Col
          md={6}
          sm={12}
          xs={12}
          key={index}
          style={{ marginBottom: '16px' }}
        >
          <Styled.Box>
            <div className="top">
              <Avatar style={{ width: '38px', height: '38px' }}>
                {index + 1}
              </Avatar>
              <div className="title">
                <div>{el.name !== '' ? el.name : 'Sample room title'}</div>
                <div>{el.type}</div>
              </div>
            </div>
            <Row className="content">
              {sortedSummary.map((elem, index) => {
                return (
                  <Col md={6} xs={12} sm={12} className="item" key={index}>
                    <Typography
                      size="p"
                      text={t(elem.title)}
                      style={{
                        marginBottom: '0px',
                        marginRight: '8px',
                        minWidth: '150px',
                      }}
                    />
                    <Avatar
                      theme="blue"
                      style={{ width: '26px', height: '26px' }}
                    >
                      {elem.count}
                    </Avatar>
                  </Col>
                );
              })}
              {el.summary.length === 0 ? (
                <Typography
                  size="p"
                  text="Not enough data to summarize it"
                  style={{ textAlign: 'center', margin: 'auto' }}
                />
              ) : null}
            </Row>
            <div className="bottom">
              <Button
                onClick={() => {
                  navigate(`/${locationId}/edit-room/${el.id}`);
                }}
                style={{ marginRight: '16px' }}
              >
                Edit
              </Button>
              <Button
                onClick={() => {
                  setPopup(!popup);
                  setId(el.id);
                }}
                className="button-delete"
              >
                Delete
              </Button>
            </div>
          </Styled.Box>
        </Col>
      );
    });
  };

  const renderPage = () => {
    if (roomLength === 0) {
      return renderNoRooms();
    } else {
      return renderRooms();
    }
  };

  return (
    <Wrap>
      <Row>
        <Col md={12} sm={12} xs={12}>
          <Typography
            size="1"
            text="My rooms"
            style={{ marginBottom: '16px' }}
          />
        </Col>
        <Col md={12} sm={12} xs={12} style={{ marginBottom: '32px' }}>
          <Styled.TopBtnWrap>
            <BackButton />
            {roomLength === 0 ? null : (
              <Button onClick={() => navigate(`/${locationId}/add-room`)}>
                Add new room
              </Button>
            )}
          </Styled.TopBtnWrap>
        </Col>
      </Row>
      <Row>
        {loading && !done ? <Loader /> : renderPage()}
        <Popup
          open={popup}
          isDesktop={!isMobile}
          handleClick={() => {
            setPopup(!popup);
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <Typography
              size="3"
              text="Are you sure you want to delete your room?"
            />
            <Typography size="p" text="All your data will be lost" />
            <Styled.ButtonWrap>
              <Button
                className="delete-yes"
                onClick={() => {
                  deleteRoom(isId);
                  setPopup(!popup);
                  setTimeout(() => {
                    listLocations();
                  }, 500);
                }}
              >
                Yes
              </Button>{' '}
              <Button
                onClick={() => {
                  setPopup(!popup);
                }}
              >
                No
              </Button>
            </Styled.ButtonWrap>
          </div>
        </Popup>
      </Row>
    </Wrap>
  );
};

export default ViewLocation;
