import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  .recharts-cartesian-axis-tick-value {
    tspan {
      font-size: 13px;
    }
  }
  .recharts-cartesian-axis-line {
    stroke-width: 4px;
    stroke: black;
  }
  .yAxis .recharts-cartesian-axis-tick {
    display: none;
  }
  .recharts-label {
    font-weight: 700;
    fill: #0b2641;
  }
  .original-values {
    opacity: 0.6;
    transform: translateY(2px);
  }
`;

const VerticalLine = styled.div`
  width: 40px;
  height: 320px;
  background-color: #e7eaec;
  box-shadow: 4px 0 black;
  font-weight: 700;
  color: black;
  p {
    font-family: 'Open Sans', sans-serif;
  }
`;
const HorizontalLine = styled.div`
  width: 100%;
  height: 40px;
  background-color: #e7eaec;
  border-top: 3px solid black;
  font-weight: 700;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonComp = styled.button`
  font-size: 16px;
  font-weight: 700;
  text-transform: none;
  color: #0b2641;
  background: none;
  border: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #25b3eb;
  padding: 8px 16px;
  &:last-child {
    border-bottom: 1px solid #25b3eb;
  }
  p {
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 0;
    margin-top: 0;
  }
`;
export default { Wrap, VerticalLine, HorizontalLine, ButtonWrap, ButtonComp };
