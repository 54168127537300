import React from 'react';
// import { SearchIcon } from "@savewatersavemoney/swsm-ui";
import Button from '../Button';
import SearchIcon from '../SearchIcon';
import Styled from './SearchPostcode.style';

interface SearchPostCodeProps {
  handleChange: Function;
  handleBlur: Function;
  clearAddress: Function;
  setFieldValue: Function;
  value: number | string;
  name: string;
  placeholder?: string;
  disabled: boolean;
  onSearch: Function;
  loading: boolean;
  error?: string;
  label?: string;
  style?: string;
}

const SearchPostCode: React.FC<SearchPostCodeProps> = (props) => {
  const {
    handleChange,
    handleBlur,
    value,
    label,
    disabled,
    onSearch,
    loading,
    setFieldValue,
    clearAddress,
    placeholder,
    style,
    error,
  } = props;

  return (
    <Styled.InputGroup>
      {label ? (
        <Styled.Label htmlFor={props.value}>{label}</Styled.Label>
      ) : null}
      <Styled.InputSearch
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        disabled={loading}
        onKeyDown={(e) => {
          setFieldValue('address', '');
          clearAddress();
          if (e.key === 'Enter') {
            e.preventDefault();
            if (!disabled && !loading) {
              e.target.blur();
              onSearch(value);
              setFieldValue('address', '');
            }
          }
        }}
        name="postcode"
        placeholder={placeholder}
        error={error}
      />
      <Styled.Btn
        disabled={disabled || loading}
        style={{ top: label ? '21.5px' : 0 }}
        onClick={() => {
          onSearch(value);
          setFieldValue('address', '');
        }}
        type="button"
        id="search-postcode"
      >
        <SearchIcon color="#000" size={1} loading={loading} />
      </Styled.Btn>
    </Styled.InputGroup>
  );
};

export default SearchPostCode;
