import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Wrap,
  Typography,
  Icon,
  SkeletonLoader,
  NothingToDisplay,
  LocationDropdown,
  RewardsBanner,
} from '../../components/index';
import * as _ from 'lodash';
import ConsumptionChart from './ConsumptionChart';
import { useResponsivity } from '../../helpers/helpers';
import Styled from './PageConsumption.style';
import store from 'store2';
import { Row, Col } from 'react-styled-flexboxgrid';
interface PageConsumptionProps {
  usage: any;
  getUsage: Function;
  listLocation: any;
  setMeta: Function;
  getGeneralUse: Function;
}

export const PageConsumption: React.FC<PageConsumptionProps> = (props) => {
  const { usage, getUsage, listLocation, setMeta, getGeneralUse } = props;
  const [t] = useTranslation('common');
  const isMobile = useResponsivity();
  const { loading, error, done } = usage.status;
  const getLocationId = _.get(listLocation, 'data.data[0].id');
  const getValue = store.get('property');
  const errorMessage = _.get(usage, 'data.errors[0]');
  const data = usage.data;
  const dataLocation = _.get(listLocation, 'data.data');

  const dataLength = _.get(dataLocation, 'length');

  const [value, setValue] = React.useState('all');

  const findLocation = _.find(dataLocation, function (o) {
    return o.id == getLocationId;
  });
  const roomLength = _.get(findLocation, 'rooms.length');

  React.useEffect(() => {
    if (dataLength === 1) {
      getUsage(getLocationId);
    } else if (dataLength >= 2) {
      if (getValue === 'all') {
        getGeneralUse();
      } else {
        getUsage(getValue);
      }
    }
  }, [getLocationId]);
  const renderMapOptions =
    dataLocation &&
    dataLocation.map((el) => ({
      value: el.id,
      label: el.property_name,
    }));

  if (loading && !done) {
    return (
      <Wrap>
        <SkeletonLoader />
      </Wrap>
    );
  }
  const renderOptions =
    dataLocation &&
    renderMapOptions.unshift({
      value: 'all',
      label: 'All',
    });
  if (error && done) {
    return (
      <Wrap>
        <Styled.Box>
          <Icon icon="alert" style={{ marginBottom: '24px' }} size={100} />
          <Typography size="3" text="No data" />
          <Typography
            size="p"
            style={{ marginTop: '20px' }}
            text={errorMessage}
          />
        </Styled.Box>
      </Wrap>
    );
  }

  const text_page_title = 'Use';
  const text_page_description = t('portal.my_use.page_description');

  const renderData = () => {
    return _.map(data, (el, index) => {
      return <ConsumptionChart key={index} data={el} isDesktop={!isMobile} />;
    });
  };
  const renderNoLocation = () => {
    return (
      <NothingToDisplay
        title="You have no locations added"
        location={'/add-location'}
        subtitle="Please add a location"
        buttonText="Add location"
      />
    );
  };

  const renderNoRooms = () => {
    return (
      <NothingToDisplay
        title="You need to complete the questions for at least one room in order to populate the charts."
        location={`/${value}/add-room`}
        subtitle="Please add a room in order to view your data."
        buttonText="Add room(s)"
      />
    );
  };

  const renderNotAnswer = () => {
    return (
      <NothingToDisplay
        title="Not enough answer to populate the chart"
        location={`/locations/${getLocationId}`}
        subtitle="Please add more rooms to your location or edit the room answers"
        buttonText="Edit room(s)"
      />
    );
  };

  const renderPage = () => {
    if (dataLength === 0) {
      return renderNoLocation();
    } else if (error) {
      return renderNotAnswer();
    } else if (roomLength === 0) {
      return renderNoRooms();
    } else {
      return renderData();
    }
  };

  return (
    <Wrap>
      <Row>
        <Col md={8} xs={12} sm={12}>
          <Typography
            size="1"
            weight={700}
            text={text_page_title}
            style={{ marginBottom: '16px', fontSize: '40px', color: '#0b2641' }}
          />
        </Col>
        <Col md={4} xs={12} sm={12}>
          {dataLength <= 1 ? null : (
            <Styled.DropdownWrap>
              <LocationDropdown
                value={value === getValue ? value : getValue}
                onChange={(e) => {
                  e.preventDefault();
                  setValue(e.target.value);
                  setMeta(e.target.value);
                  if (e.target.value === 'all') {
                    getGeneralUse();
                    store('property', 'all');
                  } else {
                    getUsage(e.target.value);
                    store('property', e.target.value);
                  }
                }}
                options={renderMapOptions}
              />
            </Styled.DropdownWrap>
          )}
        </Col>
      </Row>

      <Typography
        size="p"
        style={{
          fontSize: '18px',
          fontWeight: 700,
          maxWidth: '760px',
          marginBottom: '32px',
        }}
        text="On this page, you can see where your business is using water and where you can make the most savings. To start saving pick an easy win."
      />
      <RewardsBanner />
      {renderPage()}
    </Wrap>
  );
};

export default PageConsumption;
