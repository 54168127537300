import styled from 'styled-components';
import { colors } from '../../assets/variables';

const Wrapper = styled.div`
  a {
    text-decoration: none;
    color: ${colors.primary};
    display: block;
    font-weight: 600;
    margin-bottom: 8px;
    &:hover {
      color: ${colors.action};
    }
  }
`;

const ExternalLink = styled.p`
  a {
    text-decoration: none;
    color: ${colors.action};
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;
const Line = styled.hr`
  clear: both;
  max-width: 75rem;
  height: 0;
  width: 100%;
  margin: 0 auto 20px;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #25b3eb;
  border-left: 0;
`;

export default { Line, ExternalLink, Wrapper };
