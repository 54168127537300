import React from 'react';
import { Typography, Wrap, Button, Popup } from '../../components/index';
import { Row, Col } from 'react-styled-flexboxgrid';
import Styled from './About.style';
import { useResponsivity, scrollToTop } from '../../helpers/helpers';
const About: React.FC = () => {
  const [isOpen, setOpen] = React.useState(false);
  const isMobile = useResponsivity();
  React.useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <Wrap>
      <Row style={{ marginBottom: '16px' }}>
        <Col md={12} xs={12} sm={12}>
          <Typography size="1" text="Make water saving your business" />
          <Typography
            size="p"
            style={{
              fontSize: '18px',
              fontWeight: 700,
              maxWidth: '760px',
              marginBottom: '32px',
            }}
            text="By the end of this decade, global water supply is expected to outstrip supply by 40%. So ensuring there’s enough to go around makes water saving everyone’s business. Here’s how we can help, no matter where you are in the world."
          />
        </Col>
      </Row>
      <Row style={{ margin: '16px 0' }}>
        <Col md={6} sm={12} xs={12} mdOffset={3}>
          <iframe
            title="GetWaterFit"
            width="100%"
            height="300"
            src="https://www.youtube.com/embed/sncngJ0mhQg"
          ></iframe>
        </Col>
      </Row>
      <Row style={{ margin: '16px 0' }}>
        <Col md={6} sm={12} xs={12} style={{ display: 'flex' }}>
          <Styled.Card>
            <div style={{ textAlign: 'left' }}>
              <img
                src="https://cdn.getwaterfit.co.uk/static/files/logos/www-color.svg"
                alt="GetWaterFit"
              />
            </div>
            <Typography size="4" text="water saving community" />
            <Typography
              size="p"
              style={{ flex: '1' }}
              text="Water saving is everyone's business."
            />

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <img
                src="https://cdn.getwaterfit.co.uk/static/files/images/gwf-business/about/GetWaterFit-community-thumbnail.png"
                alt="GWF-why"
                width="49%"
                className="image-popup"
                style={{ cursor: 'pointer', border: '1px solid black' }}
                onClick={() =>
                  window.open(
                    'https://cdn.getwaterfit.co.uk/static/files/images/gwf-business/about/GetWaterFit-community.pdf',
                    '_blank' // <- This is what makes it open in a new window.
                  )
                }
              />
            </div>
          </Styled.Card>
        </Col>
        <Col md={6} sm={12} xs={12} style={{ display: 'flex' }}>
          <Styled.Card>
            <div style={{ textAlign: 'left' }}>
              <img
                src="https://cdn.getwaterfit.co.uk/static/files/logos/www-color.svg"
                alt="GetWaterFit"
              />
            </div>
            <Typography size="4" text="case studies" />
            <Typography
              size="p"
              style={{ flex: '1' }}
              text="Reducing significant volumes of business water & energy use."
            />
            <Typography
              size="p"
              style={{ fontWeight: 600 }}
              text="1. St. Margaret’s sports centre"
            />
            <Typography
              size="p"
              style={{ fontWeight: 600 }}
              text="2. BT call centre"
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <img
                src="https://cdn.getwaterfit.co.uk/static/files/images/gwf-business/about/Case1-thumbnail.png"
                alt="GWF-why"
                width="49%"
                className="image-popup"
                style={{ cursor: 'pointer', border: '1px solid black' }}
                onClick={() =>
                  window.open(
                    'https://cdn.getwaterfit.co.uk/static/files/images/gwf-business/about/Case-study-1.pdf',
                    '_blank' // <- This is what makes it open in a new window.
                  )
                }
              />
              <img
                src="https://cdn.getwaterfit.co.uk/static/files/images/gwf-business/about/Case2-thumbnail.png"
                alt="GWF-why"
                width="49%"
                className="image-popup"
                style={{ cursor: 'pointer', border: '1px solid black' }}
                onClick={() =>
                  window.open(
                    'https://cdn.getwaterfit.co.uk/static/files/images/gwf-business/about/Case-study-2.pdf',
                    '_blank' // <- This is what makes it open in a new window.
                  )
                }
              />
            </div>
          </Styled.Card>
        </Col>
        <Col md={6} sm={12} xs={12} style={{ display: 'flex' }}>
          <Styled.Card>
            <div style={{ textAlign: 'left' }}>
              <img
                src="https://cdn.getwaterfit.co.uk/static/files/images/gwf-business/about/swsm.png"
                alt="GetWaterFit"
                width="200px"
              />
            </div>
            <Typography size="4" text="product brochure" />
            <Typography
              size="p"
              style={{ flex: '1' }}
              text="Good for the environment and good for your customers."
            />
            <img
              src="https://cdn.getwaterfit.co.uk/static/files/images/gwf-business/about/brochure-thumbnail.png"
              alt="GWF-why"
              width="100%"
              className="image-popup"
              style={{ cursor: 'pointer' }}
              onClick={() =>
                window.open(
                  'https://cdn.getwaterfit.co.uk/static/files/images/gwf-business/about/Product-brochure.pdf',
                  '_blank' // <- This is what makes it open in a new window.
                )
              }
            />
          </Styled.Card>
        </Col>
      </Row>
    </Wrap>
  );
};

export default About;
