import * as React from 'react';

const man = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M35.996 32.986C35.996 25.274 42.27 19 49.982 19c7.712 0 13.987 6.274 13.987 13.986 0 7.712-6.275 13.986-13.987 13.986s-13.986-6.274-13.986-13.986"
          fill="#D7F1FF"
        />
        <path
          d="M75.965 80.413a1.65 1.65 0 01-1.647 1.647h-48.67A1.65 1.65 0 0124 80.413v-9.019a24.843 24.843 0 0118.809-24.143 15.865 15.865 0 007.174 1.72c2.576 0 5.003-.627 7.16-1.714 11.096 2.78 18.822 12.652 18.822 24.137v9.02z"
          fill={colorSecondary}
        />
        <path
          d="M49.983 17c8.814 0 15.986 7.171 15.986 15.985 0 5.267-2.572 9.934-6.514 12.848 9.916 3.229 16.972 11.843 18.274 22.128l.05.43.144 2 .033.667.009.336v9.02a3.652 3.652 0 01-3.454 3.641l-.193.005h-48.67a3.653 3.653 0 01-3.643-3.453L22 80.413v-9.019l.008-.335.035-.669.144-2a26.807 26.807 0 0118.335-22.549c-3.95-2.913-6.526-7.584-6.526-12.856C33.996 24.171 41.168 17 49.983 17zM42.809 47.25a24.841 24.841 0 00-18.806 23.754l-.003.39v9.018c0 .86.663 1.57 1.506 1.641l.142.006h48.67a1.65 1.65 0 001.64-1.505l.007-.142v-9.019c0-11.485-7.726-21.357-18.821-24.137a15.868 15.868 0 01-7.161 1.714c-2.581 0-5.014-.629-7.174-1.72zM49.983 19c-7.712 0-13.987 6.274-13.987 13.986 0 7.712 6.275 13.986 13.987 13.986 7.71 0 13.986-6.274 13.986-13.986C63.969 25.273 57.694 19 49.983 19z"
          fill={colorPrimary}
        />
      </g>
    </>
  );
};
export default man;
