import styled from 'styled-components';
import { colors } from '../../assets/variables';
const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  border: 4px solid #d7f1ff;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 16px;

  display: block;
  transition: 0.5s ease;
  backface-visibility: hidden;
  &:hover {
    border: 4px solid ${colors.primary};
    transition: 200ms ease-in-out;
    cursor: pointer;
  }
`;

export default { Wrap, Box };
