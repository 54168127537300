/* eslint-disable react/jsx-filename-extension */
import * as React from 'react';

const bell = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M81.8584071,70.4424779 L75.0884956,62.079646 C74.4247788,61.1504425 74.0265487,60.0884956 74.0265487,58.8938053 L74.0265487,41.6371681 C74.0265487,30.8849558 66.8584071,21.3274336 56.5044248,18.4070796 L56.5044248,11.6371681 C56.5044248,7.92035398 53.4513274,5 49.8672566,5 C46.2831858,5 43.2300885,8.05309735 43.2300885,11.6371681 L43.2300885,18.2743363 C32.7433628,21.3274336 25.5752212,30.8849558 25.7079646,41.6371681 L25.7079646,58.8938053 C25.7079646,60.0884956 25.3097345,61.1504425 24.6460177,62.079646 L17.8761062,70.4424779 C17.3451327,71.1061947 16.9469027,72.0353982 16.9469027,72.9646018 L16.9469027,83.4513274 L83.0530973,83.4513274 L83.0530973,72.9646018 C82.7876106,72.0353982 82.3893805,71.1061947 81.8584071,70.4424779 Z"
        fill={colorPrimary}
        fillRule="nonzero"
      />
      <path
        d="M78.8053097,79.3362832 L20.6637168,79.3362832 L20.6637168,72.9646018 L27.4336283,64.6017699 C28.7610619,63.0088496 29.4247788,61.0176991 29.4247788,58.8938053 L29.4247788,41.6371681 C29.4247788,30.4867257 38.4513274,21.3274336 49.7345133,21.3274336 C61.0176991,21.3274336 70.0442478,30.3539823 70.0442478,41.6371681 L70.0442478,58.8938053 C70.0442478,61.0176991 70.7079646,63.0088496 72.0353982,64.6017699 L78.8053097,72.9646018 L78.8053097,79.3362832 Z"
        fill={colorSecondary}
        fillRule="nonzero"
      />
      <path
        d="M47.079646,11.6371681 C47.079646,10.1769912 48.2743363,8.98230088 49.7345133,8.98230088 C51.1946903,8.98230088 52.3893805,10.1769912 52.3893805,11.6371681 L52.3893805,17.6106195 C50.5309735,17.3451327 48.8053097,17.3451327 46.9469027,17.6106195 L46.9469027,11.6371681 L47.079646,11.6371681 Z"
        fill={colorSecondary}
        fillRule="nonzero"
      />
      <path
        d="M47.079646,25.840708 L47.079646,29.8230088 C55.1769912,29.8230088 61.8141593,36.460177 61.8141593,44.5575221 C61.8141593,44.5575221 61.8141593,44.5575221 61.8141593,44.5575221 L65.7964602,44.5575221 C65.7964602,34.2035398 57.4336283,25.840708 47.079646,25.840708 C47.079646,25.840708 47.079646,25.840708 47.079646,25.840708 Z"
        fill="#FFFFFF"
        fillRule="nonzero"
      />
      <path
        d="M49.7345133,91.0176991 C45.8849558,91.0176991 42.8318584,87.9646018 42.8318584,84.1150442 L38.8495575,84.1150442 C38.8495575,90.0884956 43.7610619,95 49.7345133,95 C55.7079646,95 60.619469,90.0884956 60.619469,84.1150442 L56.6371681,84.1150442 C56.6371681,87.9646018 53.5840708,91.0176991 49.7345133,91.0176991 Z"
        fill={colorPrimary}
        fillRule="nonzero"
      />
    </g>
  );
};
export default bell;
