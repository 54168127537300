import { ListLocationReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
import store from 'store2';

const initialState: ListLocationReducer = {
  status: {
    loading: false,
    error: false,
    done: false,
  },
  data: null,
  propertyId: null,
};

const listLocationReducer = createReducer(initialState, {
  LIST_LOCATION_PENDING: (state) => {
    state.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  LIST_LOCATION_FULFILLED: (state, action) => {
    state.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.data = action.payload.data;

    state.propertyId = store('property');
  },

  SET_META: (state) => {
    state.propertyId = store('property');
  },

  LIST_LOCATION_REJECTED: (state, action) => {
    state.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.data = null;
  },
});

export default listLocationReducer;
