import * as React from 'react';

const calendar = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-2" transform="translate(11.000000, 15.000000)">
        <path
          d="M79,22 L79,66 C79,68.209139 77.209139,70 75,70 L4,70 C1.790861,70 0,68.209139 0,66 L0,22 L79,22 Z M77,24 L2,24 L2,66 C2,67.0543618 2.81587779,67.9181651 3.85073766,67.9945143 L4,68 L75,68 C76.0543618,68 76.9181651,67.1841222 76.9945143,66.1492623 L77,66 L77,24 Z"
          fill={colorPrimary}
          fillRule="nonzero"
        ></path>
        <path
          d="M75,6.42952217 C75.8284271,6.42952217 76.5784271,6.76530861 77.1213203,7.30820182 C77.6642136,7.85109504 78,8.60109504 78,9.42952217 L78,9.42952217 L78,23.0387971 L1,23.0387971 L1,9.42952217 C1,8.60109504 1.33578644,7.85109504 1.87867966,7.30820182 C2.42157288,6.76530861 3.17157288,6.42952217 4,6.42952217 L4,6.42952217 Z"
          stroke={colorPrimary}
          strokeWidth="2"
          fill={colorSecondary}
        ></path>
        <g
          transform="translate(15.000000, 0.000000)"
          fill={colorSecondary}
          stroke={colorPrimary}
          strokeWidth="2"
        >
          <rect x="1" y="1" width="5" height="12.7" rx="2"></rect>
          <rect x="42.9" y="1" width="5" height="12.7" rx="2"></rect>
        </g>
        <polygon
          fill={colorPrimary}
          fillRule="nonzero"
          points="70.788286 34.0157288 70.788286 36.0157288 7 36.0157288 7 34.0157288"
        ></polygon>
        <polygon
          fill={colorPrimary}
          fillRule="nonzero"
          points="70.788286 45.1064658 70.788286 47.1064658 7 47.1064658 7 45.1064658"
        ></polygon>
        <polygon
          fill={colorPrimary}
          fillRule="nonzero"
          points="70.788286 56.1972029 70.788286 58.1972029 7 58.1972029 7 56.1972029"
        ></polygon>
      </g>
    </g>
  );
};
export default calendar;
