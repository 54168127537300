import * as React from 'react';

const swimmingPool = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M72.696 79.774c3.903 0 6.082 1.902 7.91 3.768l1.09 1.123c1.692 1.723 3.338 3.11 6.427 3.11a1 1 0 110 2c-3.778 0-5.94-1.782-7.733-3.587l-1.269-1.305c-1.69-1.722-3.336-3.109-6.425-3.109-3.088 0-4.735 1.387-6.426 3.11l-1.09 1.123c-1.83 1.865-4.007 3.767-7.911 3.767s-6.082-1.902-7.91-3.767l-1.09-1.124c-1.691-1.722-3.337-3.109-6.425-3.109-3.088 0-4.733 1.387-6.424 3.11l-1.09 1.123c-1.83 1.865-4.008 3.767-7.911 3.767-3.901 0-6.079-1.902-7.907-3.767l-1.09-1.124c-1.69-1.722-3.335-3.109-6.422-3.109a1 1 0 110-2c3.902 0 6.08 1.902 7.908 3.768l1.09 1.123c1.69 1.723 3.334 3.11 6.421 3.11 3.089 0 4.734-1.387 6.425-3.11l1.09-1.123c1.828-1.866 4.006-3.768 7.91-3.768 3.903 0 6.082 1.902 7.91 3.768l1.09 1.123c1.691 1.723 3.337 3.11 6.425 3.11 3.088 0 4.735-1.387 6.426-3.11l1.09-1.123c1.83-1.866 4.007-3.768 7.911-3.768zm0-25.101c3.778 0 5.94 1.781 7.732 3.587l1.269 1.304c1.691 1.722 3.337 3.109 6.426 3.109a1 1 0 110 2c-3.778 0-5.94-1.782-7.733-3.587l-1.269-1.304c-1.69-1.722-3.336-3.11-6.425-3.11-3.088 0-4.735 1.388-6.426 3.11l-1.09 1.124c-1.83 1.865-4.007 3.767-7.911 3.767-3.778 0-5.94-1.782-7.732-3.587l-1.268-1.304c-1.691-1.722-3.337-3.11-6.425-3.11-3.088 0-4.733 1.388-6.424 3.11l-1.09 1.124c-1.83 1.865-4.008 3.767-7.911 3.767-3.776 0-5.936-1.782-7.729-3.587l-1.267-1.304c-1.69-1.722-3.336-3.11-6.423-3.11a1 1 0 110-2c3.776 0 5.937 1.782 7.73 3.588l1.455 1.494c1.626 1.633 3.261 2.919 6.234 2.919 3.318 0 4.97-1.6 6.803-3.497l.712-.736c1.828-1.865 4.006-3.767 7.91-3.767 3.777 0 5.94 1.781 7.732 3.587l1.268 1.304c1.692 1.722 3.337 3.109 6.425 3.109 3.317 0 4.97-1.6 6.804-3.497l.712-.736c1.83-1.865 4.007-3.767 7.911-3.767z"
          fill={colorSecondary}
        />
        <path
          d="M72.696 67.224c3.903 0 6.082 1.902 7.91 3.767l1.09 1.124c1.692 1.722 3.338 3.109 6.427 3.109a1 1 0 110 2c-3.778 0-5.94-1.782-7.733-3.587l-1.269-1.304c-1.69-1.723-3.336-3.11-6.425-3.11-3.088 0-4.735 1.387-6.426 3.11l-1.09 1.123c-1.83 1.866-4.007 3.768-7.911 3.768s-6.082-1.902-7.91-3.768l-1.09-1.123c-1.691-1.723-3.337-3.11-6.425-3.11-3.088 0-4.733 1.387-6.424 3.11l-1.09 1.123c-1.83 1.866-4.008 3.768-7.911 3.768-3.901 0-6.079-1.902-7.907-3.768l-1.09-1.123c-1.69-1.723-3.335-3.11-6.422-3.11a1 1 0 110-2c3.902 0 6.08 1.903 7.908 3.768l1.09 1.124c1.69 1.722 3.334 3.109 6.421 3.109 3.089 0 4.734-1.387 6.425-3.11l1.09-1.123c1.828-1.865 4.006-3.767 7.91-3.767 3.903 0 6.082 1.902 7.91 3.767l1.09 1.124c1.691 1.722 3.337 3.109 6.425 3.109 3.088 0 4.735-1.387 6.426-3.11l1.09-1.123c1.83-1.865 4.007-3.767 7.911-3.767zM65.931 11a6.265 6.265 0 016.253 6.028l.004.23v3.153a1 1 0 01-1.993.117l-.007-.117v-3.154A4.262 4.262 0 0065.931 13a4.261 4.261 0 00-4.252 4.051l-.005.206V49.09a1 1 0 01-1.993.117l-.007-.117v-3.38H39.357v3.38a1 1 0 01-1.993.117l-.007-.117V17.257A6.265 6.265 0 0143.615 11a6.265 6.265 0 016.253 6.028l.004.23v3.153a1 1 0 01-1.993.117l-.007-.117v-3.154A4.26 4.26 0 0043.615 13a4.263 4.263 0 00-4.253 4.051l-.005.206v6.248h20.317v-6.248A6.264 6.264 0 0165.931 11zm-6.258 27.975H39.357v4.735h20.316v-4.735zm.001-6.735H39.358v4.735h20.316V32.24zm0-6.734H39.358v4.734h20.316v-4.734z"
          fill={colorPrimary}
        />
      </g>
    </>
  );
};
export default swimmingPool;
