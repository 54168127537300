import axios from 'axios';
import { getMicroServiceUrl } from '../helpers/helpers';

export const getUsage = (id) => ({
  type: 'GET_MY_USE',
  payload: axios.get(
    `${getMicroServiceUrl('data')}organisation/orgPropertyId/${id}/myuse`
  ),
});

export const getGeneralUse = () => ({
  type: 'GET_ALL_MY_USE',
  payload: axios.get(`${getMicroServiceUrl('data')}organisation/myuse`),
});
