import * as React from 'react';

const typeMultiple = (props: any) => {
  const { colorPrimary } = props;

  return (
    <>
      <g fill={colorPrimary} fillRule="evenodd">
        <path d="M88.388 33a4.296 4.296 0 014.29 4.29v26.127a4.296 4.296 0 01-4.29 4.29H12.29A4.296 4.296 0 018 63.417V37.29A4.296 4.296 0 0112.29 33h76.098zm0 2H12.29A2.292 2.292 0 0010 37.29v26.127a2.292 2.292 0 002.29 2.29h76.098a2.293 2.293 0 002.29-2.29V37.29a2.293 2.293 0 00-2.29-2.29z" />
        <path d="M65.61 44.01c1.893 0 3.341.545 4.344 1.635 1.003 1.091 1.505 2.658 1.505 4.704 0 2.04-.506 3.608-1.517 4.704-1.012 1.096-2.46 1.644-4.35 1.644-1.889 0-3.338-.548-4.35-1.644-1.01-1.096-1.517-2.67-1.517-4.72 0-2.052.507-3.619 1.521-4.7 1.015-1.082 2.47-1.623 4.363-1.623zm-35.862.194v7.545c0 .95.192 1.646.574 2.09.382.444 1.014.667 1.896.667.854 0 1.475-.224 1.86-.671.384-.446.577-1.148.577-2.103v-7.528h2.604v7.975c0 .91-.203 1.709-.61 2.394-.409.686-.996 1.21-1.767 1.576-.769.366-1.68.548-2.73.548-1.585 0-2.817-.406-3.693-1.218-.877-.812-1.315-1.923-1.315-3.334v-7.941h2.604zm-10.283 0c1.748 0 3.016.249 3.806.746.789.498 1.184 1.288 1.184 2.373 0 .736-.173 1.34-.52 1.813a2.07 2.07 0 01-1.377.85v.085c.782.175 1.345.5 1.69.979.347.477.519 1.112.519 1.904 0 1.124-.406 2-1.218 2.63-.812.63-1.915.944-3.31.944h-4.61V44.204h3.836zm28.914 0v2.175h-3.347v10.149h-2.613v-10.15h-3.347v-2.174h9.307zm9.998 0v2.175H55.03v10.149h-2.613v-10.15H49.07v-2.174h9.307zm18.933 0l5.353 9.23h.058l-.074-1.536a52.651 52.651 0 01-.052-1.71v-5.984h2.352v12.324h-3.321l-5.362-9.323h-.076l.074 1.216c.053.93.08 1.65.085 2.161v5.946h-2.334V44.204h3.297zm-11.7 1.981c-1.04 0-1.825.35-2.353 1.05-.528.7-.793 1.737-.793 3.114 0 1.377.262 2.414.784 3.11.523.698 1.304 1.046 2.344 1.046 2.085 0 3.127-1.385 3.127-4.156 0-2.776-1.036-4.164-3.11-4.164zM19.86 51.16h-1.618v3.212h1.703c.72 0 1.25-.138 1.594-.413.343-.276.514-.697.514-1.265 0-1.023-.731-1.534-2.192-1.534zm-.243-4.813h-1.375v2.739h1.518c.708 0 1.22-.11 1.539-.328.317-.22.475-.582.475-1.088 0-.472-.173-.811-.518-1.016-.345-.205-.892-.307-1.64-.307z" />
      </g>
    </>
  );
};
export default typeMultiple;
