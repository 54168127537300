import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Col } from 'react-styled-flexboxgrid';
import { Typography, Button, Input } from '../../components/index';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
interface ResetPasswordRequestProps {
  resetPasswordRequest: Function;
  resetPasswordRequestRepeat: Function;
  resetRequest: any;
}

const ResetPasswordRequest: React.FC<ResetPasswordRequestProps> = (props) => {
  const { resetPasswordRequest, resetPasswordRequestRepeat, resetRequest } =
    props;
  const [t] = useTranslation('common');
  const navigate = useNavigate();
  const allDone = t('portal.forgot_password.all_done');
  const tryAgain = t('portal.forgot_password.try_again');
  const emailNotValid = t('portal.general_form_errors.email_not_valid');
  const emailRequired = t('portal.general_form_errors.required');
  const SignupSchema = Yup.object().shape({
    email: Yup.string().email(emailNotValid).required(emailRequired),
  });
  const pageTitle = t('portal.forgot_password.page.title');
  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        resetPasswordRequest(values.email);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <Typography size="1" text={pageTitle} />

          <Col xs={12}>
            <Input
              label={`${t('portal.forgot_password.email_label')}`}
              type="email"
              name="email"
              id="email"
              placeholder="john.doe@gmail.com"
              onChange={handleChange}
              onBlur={handleBlur}
              icon="pen"
              aria-label={t('portal.forgot_password.email_label')}
              value={values.email}
              error={errors.email && touched.email ? errors.email : undefined}
            />
          </Col>

          {resetRequest.status.error ? (
            <Col xs={12}>
              <div>{resetRequest.status.error}</div>
            </Col>
          ) : null}

          <Col xs={12}>
            {resetRequest.status.done ? (
              <p>
                {allDone}{' '}
                <span
                  style={{ fontSize: '16px', textDecoration: 'underline' }}
                  onClick={() => {
                    resetPasswordRequestRepeat();
                  }}
                >
                  {tryAgain}
                </span>
                .
              </p>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '10px',
                }}
              >
                <Button
                  disabled={isSubmitting || resetRequest.status.loading}
                  type="submit"
                >
                  {t('portal.forgot_password.reset_password_button')}
                </Button>
              </div>
            )}
          </Col>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '10px',
            }}
          >
            <div
              style={{
                color: '#0b2641',
                fontSize: '16px',
                textDecoration: 'underline',
                textDecorationThickness: '3px',
                textDecorationColor: '#25b3eb',
              }}
              onClick={() => navigate('/login')}
            >
              {t('portal.forgot_password.take_me_back')}
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default ResetPasswordRequest;
