import * as React from 'react';

const boiler = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M36.027 10a1 1 0 01.993.883l.007.117v4.199H57.62V11a1 1 0 01.883-.993L58.62 10h3.804a1 1 0 01.993.883l.007.117v4.199h8.273a1 1 0 01.993.883l.007.117v48.006c0 9.03-7.262 16.397-16.252 16.567l-.318.003h-2.366a3.37 3.37 0 01-3.261 3.343c.337.567.543 1.221.543 1.928a3.81 3.81 0 01-3.806 3.805 3.81 3.81 0 01-3.806-3.805c0-.707.206-1.361.543-1.928a3.372 3.372 0 01-3.255-3.157l-.006-.186h-2.144c-9.03 0-16.396-7.261-16.566-16.252L22 64.205V16.199a1 1 0 01.883-.993l.117-.007h8.224V11a1 1 0 01.883-.993l.117-.007h3.803zm11.21 74.24c-.996 0-1.806.81-1.806 1.806 0 .995.81 1.805 1.806 1.805s1.806-.81 1.806-1.805c0-.996-.81-1.806-1.806-1.806zm4.526-3.465h-9.052c.01.704.553 1.282 1.244 1.348l.131.006h6.303c.707 0 1.291-.537 1.366-1.224l.008-.13zm5.857-63.576H37.027v61.492c.338.036.68.061 1.023.074l.519.01h17.558c.336 0 .67-.012 1-.034l.493-.042v-61.5zm13.077 0H59.62v61.135c6.243-1.545 10.919-7.111 11.073-13.789l.004-.34V17.199zm-35.67 0H24v47.006c0 6.697 4.548 12.336 10.712 14.033l.315.083V17.199zm0-5.199h-1.803v3.198h1.803V12zm26.397 0H59.62v3.198h1.804V12zM76.586 17.932a1 1 0 00-1 1v36.55a1 1 0 102 0v-36.55a1 1 0 00-1-1"
          fill={colorPrimary}
        />
        <path
          d="M47.258 26.861a6.447 6.447 0 016.44 6.44 6.447 6.447 0 01-6.44 6.441 6.448 6.448 0 01-6.44-6.44c0-3.55 2.89-6.44 6.44-6.44zm1 2.124v4.316a1 1 0 01-1.993.117l-.007-.117v-4.316c-1.967.456-3.44 2.213-3.44 4.316a4.446 4.446 0 004.44 4.441 4.445 4.445 0 004.44-4.44 4.439 4.439 0 00-3.238-4.265l-.202-.052zM52.965 58.927H41.552a1 1 0 100 2h11.413a1 1 0 100-2M53.233 49.09a1 1 0 01.993.884l.007.117v4.152a1 1 0 01-.884.994l-.116.006h-11.95a1 1 0 01-.994-.883l-.006-.117V50.09a1 1 0 01.883-.993l.117-.007h11.95zm-1 2.002h-9.95v2.151h9.95v-2.151z"
          fill={colorPrimary}
        />
        <path
          d="M46.238 28.985v4.316a1 1 0 102 0v-4.316c1.966.456 3.439 2.213 3.439 4.316a4.445 4.445 0 01-4.439 4.441 4.446 4.446 0 01-4.441-4.44c0-2.104 1.474-3.86 3.441-4.317M42.349 53.243h9.95v-2.151h-9.95z"
          fill={colorSecondary}
        />
        <path
          d="M24 64.205V17.199h11.027v61.122C28.703 76.732 24 71.015 24 64.205M70.697 64.205c0 6.828-4.728 12.558-11.077 14.129V17.199h11.077v47.006z"
          fill="#D7F1FF"
        />
      </g>
    </>
  );
};
export default boiler;
