import * as React from 'react';

const uk = (props: any) => (
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g transform="translate(5.000000, 5.000000)" fillRule="nonzero">
      <circle fill="#F0F0F0" cx="45" cy="45" r="45" />
      <g transform="translate(1.550215, 1.550215)" fill="#0052B4">
        <path d="M7.75212891,16.0528711 C4.21734375,20.6518359 1.55197266,25.9525195 -2.49800181e-15,31.7109375 L23.4101953,31.7109375 L7.75212891,16.0528711 Z" />
        <path d="M86.8995703,31.7109375 C85.3475977,25.9526953 82.6820508,20.6520117 79.1474414,16.0530469 L63.4897266,31.7109375 L86.8995703,31.7109375 Z" />
        <path d="M-2.81025203e-15,55.1891602 C1.55214844,60.9474023 4.21751953,66.2480859 7.75212891,70.846875 L23.409668,55.1891602 L-2.81025203e-15,55.1891602 Z" />
        <path d="M70.8466992,7.75230469 C66.2477344,4.21751953 60.9472266,1.55214844 55.1888086,-2.49800181e-15 L55.1888086,23.4100195 L70.8466992,7.75230469 Z" />
        <path d="M16.0528711,79.1472656 C20.6518359,82.6820508 25.9525195,85.3474219 31.7107617,86.8995703 L31.7107617,63.4897266 L16.0528711,79.1472656 Z" />
        <path d="M31.7105859,-2.81025203e-15 C25.9523437,1.55214844 20.6516602,4.21751953 16.0528711,7.75212891 L31.7105859,23.4098437 L31.7105859,-2.81025203e-15 Z" />
        <path d="M55.1889844,86.8995703 C60.9472266,85.3474219 66.2479102,82.6820508 70.8466992,79.1474414 L55.1889844,63.4897266 L55.1889844,86.8995703 Z" />
        <path d="M63.4897266,55.1891602 L79.1474414,70.8470508 C82.6820508,66.2482617 85.3475977,60.9474023 86.8995703,55.1891602 L63.4897266,55.1891602 Z" />
      </g>
      <g transform="translate(0.000000, 0.000000)" fill="#D80027">
        <path d="M89.619082,39.1304883 L50.8698633,39.1304883 L50.8696875,39.1304883 L50.8696875,0.380917969 C48.9482227,0.13078125 46.9893164,3.12250226e-16 45,3.12250226e-16 C43.010332,3.12250226e-16 41.0517773,0.13078125 39.1304883,0.380917969 L39.1304883,39.1301367 L39.1304883,39.1303125 L0.380917969,39.1303125 C0.13078125,41.0517773 5.30825384e-15,43.0106836 5.30825384e-15,45 C5.30825384e-15,46.989668 0.13078125,48.9482227 0.380917969,50.8695117 L39.1301367,50.8695117 L39.1303125,50.8695117 L39.1303125,89.619082 C41.0517773,89.8692188 43.010332,90 45,90 C46.9893164,90 48.9482227,89.8693945 50.8695117,89.619082 L50.8695117,50.8698633 L50.8695117,50.8696875 L89.619082,50.8696875 C89.8692188,48.9482227 90,46.989668 90,45 C90,43.0106836 89.8692188,41.0517773 89.619082,39.1304883 L89.619082,39.1304883 Z" />
        <path d="M56.7391992,56.7391992 L76.8197461,76.8199219 C77.7433008,75.8967188 78.6243164,74.9315039 79.4649023,73.930957 L62.2731445,56.7391992 L56.7391992,56.7391992 L56.7391992,56.7391992 Z" />
        <path d="M33.2608008,56.739375 L33.2604492,56.739375 L13.1800781,76.8197461 C14.1032812,77.7433008 15.0684961,78.6243164 16.069043,79.4649023 L33.2608008,62.272793 L33.2608008,56.739375 Z" />
        <path d="M33.2608008,33.2611523 L33.2608008,33.2608008 L13.1802539,13.1800781 C12.2566992,14.1032812 11.3756836,15.0684961 10.5350977,16.069043 L27.7270312,33.2611523 L33.2608008,33.2611523 L33.2608008,33.2611523 Z" />
        <path d="M56.7391992,33.2611523 L76.8199219,13.1802539 C75.8967188,12.2566992 74.9315039,11.3756836 73.930957,10.5352734 L56.7391992,27.727207 L56.7391992,33.2611523 Z" />
      </g>
    </g>
  </g>
);
export default uk;
