import {
  GenericMarkdown,
  PageOverview,
  EasyWins,
  Profile,
  VideoLibrary,
  Consumption,
} from './pages/lazy';
import { Routes } from './interfaces';

const routes: Routes = {
  '/portal': {
    template: <PageOverview />,
    authRequired: true,
  },
  '/use': {
    template: <Consumption />,
    authRequired: true,
  },
  '/easy-wins': {
    template: <EasyWins />,
    authRequired: true,
  },
  '/video-library': {
    template: <VideoLibrary />,
    authRequired: true,
  },
  '/profile': {
    template: <Profile />,
    authRequired: true,
  },
  '/faq': {
    template: <GenericMarkdown />,
  },
  '/contact-us': {
    template: <GenericMarkdown />,
  },
  '/terms-and-conditions': {
    template: <GenericMarkdown />,
  },
  '/privacy': {
    template: <GenericMarkdown />,
  },
  '/cookies': {
    template: <GenericMarkdown />,
  },
  '/other-policies': {
    template: <GenericMarkdown />,
  },
  '/end-user-license-agreement': {
    template: <GenericMarkdown />,
  },
  '/moderation-policy': {
    template: <GenericMarkdown />,
  },
};

export default routes;
