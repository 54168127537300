import React from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import {
  Tile,
  Wrap,
  Box,
  ErrorDisplay,
  SkeletonLoader,
  Typography,
  Button,
  FooterInfo,
  Avatar,
  Icon,
  Pagination,
  Notifications,
  Popup,
  Input,
  Select,
  NothingToDisplay,
  SearchPostCode,
  RewardsBanner,
} from '../../components/index';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useResponsivity } from '../../helpers/helpers';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import Styled from './PageOverview.style';
import { Tooltip } from 'react-tooltip';
import store from 'store2';
interface PageoverviewProps {
  listLocation: any;
  listLocations: Function;
  deleteLocation: Function;
  editLocation: Function;
  wholesaler: any;
  location: any;
  getAddress: Function;
  clearAddress: Function;
}
export const PageOverview: React.FC<PageoverviewProps> = (props) => {
  const {
    listLocations,
    listLocation,
    deleteLocation,
    editLocation,
    wholesaler,
    location,
    getAddress,
    clearAddress,
  } = props;
  const { error, loading, done } = props.listLocation.status;
  const data = _.get(listLocation, 'data.data');
  const PageSize = 5;
  const isMobile = useResponsivity();
  const navigate = useNavigate();
  const [t] = useTranslation('common');
  const [currentPage, setCurrentPage] = React.useState(1);
  const [popup, setPopup] = React.useState(false);
  const [isId, setId] = React.useState();
  const [content, setContent] = React.useState('');

  const succesAddLocation = _.get(location, 'editLocation.data.message');
  const errorAddLocation = _.get(location, 'editLocation.data.errors');

  const renderErrors = () => {
    return _.map(errorAddLocation, (el, index) => {
      return <li key={index}>{el}</li>;
    });
  };

  React.useEffect(() => {
    listLocations();
  }, [listLocations]);

  const locationNumber = _.get(listLocation, 'data.total');
  const dataWholesale = _.get(wholesaler, 'data.data');
  const propertyId = _.get(listLocation, 'propertyId');
  const newData =
    data && data.map((v, index) => ({ ...v, position: index + 1 }));

  const getRoom = data && data.find((x) => x.id === parseInt(propertyId));
  const currentTableData = React.useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return newData && newData.slice(firstPageIndex, lastPageIndex);
  }, [PageSize, currentPage, data]);

  if (error) {
    return (
      <Wrap>
        <ErrorDisplay />
      </Wrap>
    );
  }
  if (loading && !done) {
    return (
      <Wrap>
        <SkeletonLoader />
      </Wrap>
    );
  }

  const heatedOptions = [
    { value: 'gas', label: 'Gas' },
    { value: 'electric', label: 'Electric' },
    { value: 'coal', label: 'Coal' },
    { value: 'renewable', label: 'Renewable' },
    { value: 'oil', label: 'Oil' },
    { value: "i-don't-know", label: "I don't know" },
  ];
  const findElement = _.find(currentTableData, function (o) {
    return o.id == isId;
  });

  const renderAddressSelect = () => {
    const addresses = _.get(location, 'address.data.data.items');
    if (addresses && addresses === null) {
      return [];
    }

    return (
      addresses &&
      addresses.map((address) => {
        return {
          value: address.summaryline,
          label: address.summaryline,
        };
      })
    );
  };

  const getSpid = _.get(findElement, 'spid');
  const getWholesaler = _.get(findElement, 'wholesaler_id');
  const getMale = _.get(findElement, 'number_of_male_employee');
  const getFemale = _.get(findElement, 'number_of_female_employee');
  const getHeatedWith = _.get(findElement, 'water_heated_with');
  const getName = _.get(findElement, 'property_name');
  const getPostcode = _.get(findElement, 'postcode');
  const getSavedAddress = _.get(findElement, 'address');
  const renderContent = () => {
    if (content === 'delete') {
      return (
        <div style={{ textAlign: 'center' }}>
          <Typography
            size="3"
            text="Are you sure you want to delete your property?"
          />
          <Typography size="p" text="All your data will be lost" />
          <Styled.ButtonWrap>
            <Button
              className="delete-yes"
              onClick={() => {
                deleteLocation(isId);
                setPopup(!popup);
                setTimeout(() => {
                  listLocations();
                }, 1000);
              }}
            >
              Yes
            </Button>{' '}
            <Button>No</Button>
          </Styled.ButtonWrap>
        </div>
      );
    } else if (content === 'edit') {
      return (
        <>
          <Formik
            initialValues={{
              spid: getSpid,
              wholesaler_id: getWholesaler,
              number_of_male_employee: getMale,
              number_of_female_employee: getFemale,
              water_heated_with: getHeatedWith,
              property_name: getName,
              postcode: getPostcode,
              address: getSavedAddress,
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              editLocation(isId, values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <Row>
                  <Col md={6} sm={12} xs={12}>
                    <SearchPostCode
                      handleBlur={handleBlur}
                      onSearch={() => getAddress(values.postcode)}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      disabled={false}
                      loading={false}
                      placeholder="Enter your postcode"
                      label="Postcode"
                      value={values.postcode}
                      name="postcode"
                      clearAddress={() => clearAddress()}
                    />
                  </Col>
                  <Col md={6} sm={12} xs={12}>
                    <Select
                      id="address"
                      name="address"
                      value={values.address}
                      type="text"
                      label="Address"
                      placeholder="Address*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={renderAddressSelect()}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <Input
                      id="property_name"
                      name="property_name"
                      label="Property name"
                      value={values.property_name}
                      type="text"
                      placeholder="Property name*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        errors.property_name && touched.property_name
                          ? errors.property_name
                          : undefined
                      }
                    />
                  </Col>

                  <Col xs={12} sm={12} md={6}>
                    <Select
                      onChange={handleChange}
                      style={{ marginBottom: '16px' }}
                      onBlur={handleBlur}
                      label="How is the water heated with?"
                      options={heatedOptions}
                      value={values.water_heated_with}
                      name="water_heated_with"
                      placeholder="How is the water heated at your premises?*"
                    />
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <Input
                      id="spid"
                      name="spid"
                      value={values.spid}
                      type="text"
                      label="SPID"
                      placeholder="SPID*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <Input
                      id="number_of_male_employee"
                      name="number_of_male_employee"
                      label="Number of male employees"
                      value={values.number_of_male_employee}
                      type="text"
                      placeholder="Number of male employees*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <Input
                      id="number_of_female_employee"
                      name="number_of_female_employee"
                      label="Number of female employees"
                      value={values.number_of_female_employee}
                      type="text"
                      placeholder="Number of female employees*"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button disabled={isSubmitting} type="submit">
                      Update location
                    </Button>
                  </Col>
                  {errorAddLocation ? (
                    <Col md={12} sm={12} xs={12}>
                      <Notifications type="ERROR">
                        {renderErrors()}
                      </Notifications>
                    </Col>
                  ) : null}
                  {succesAddLocation ? (
                    <Col md={12} sm={12} xs={12}>
                      <Notifications type="SUCCESS">
                        {succesAddLocation}
                      </Notifications>
                    </Col>
                  ) : null}
                </Row>
              </form>
            )}
          </Formik>
        </>
      );
    }
  };

  const tableItems = () => {
    return _.map(currentTableData, (el, index) => {
      return (
        <tr key={index}>
          <td width="25%" className="item">
            <div className="table-name">
              <Avatar>{el.position}</Avatar>
              <p>
                Name:
                <br />
                <span>{el.property_name}</span>
              </p>
            </div>
          </td>
          <td>
            <div className="table-name">
              <p>
                Male employees:
                <br />
                <span>{el.number_of_male_employee}</span>
              </p>
            </div>
          </td>
          <td>
            <div className="table-name">
              <p>
                Female employees:
                <br />
                <span>{el.number_of_female_employee}</span>
              </p>
            </div>
          </td>
          <td>
            <div className="table-name">
              <p>
                Water heated with:
                <br />
                <span>{el.water_heated_with}</span>
              </p>
            </div>
          </td>
          <td>
            <div className="table-name text-right">
              <p>
                Rooms:
                <br />
                <span>
                  {el.rooms.length}
                  <span
                    style={{
                      marginLeft: '8px',
                      textDecoration: 'underline',
                      fontSize: '14px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      navigate(`/locations/${el.id}`);
                      store('property', el.id);
                    }}
                  >
                    view
                  </span>
                  <span
                    style={{
                      marginLeft: '8px',
                      textDecoration: 'underline',
                      fontSize: '14px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      navigate(`/${el.id}/add-room`);
                      store('property', el.id);
                    }}
                  >
                    add
                  </span>
                </span>
              </p>
            </div>
          </td>
          <td className="text-right item">
            <div className="table-name text-right">
              <Styled.MaskItems
                data-tooltip-id="edit-tooltip"
                data-tooltip-content="Edit location"
                data-tooltip-place="top"
                className="edit"
                onClick={() => {
                  setPopup(!popup);
                  setId(el.id);
                  setContent('edit');
                  getAddress(el.postcode);
                }}
                style={{ marginRight: '8px', cursor: 'pointer' }}
              >
                <Icon icon="check" size={17} />
              </Styled.MaskItems>
              <Styled.MaskItems
                data-tooltip-id="delete-tooltip"
                data-tooltip-content="Delete location"
                data-tooltip-place="top"
                className="delete"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setPopup(!popup);
                  setId(el.id);
                  setContent('delete');
                }}
              >
                <Icon icon="trash" size={17} />
              </Styled.MaskItems>
            </div>
          </td>
        </tr>
      );
    });
  };
  const banners = [
    {
      title: t('portal.home.myuse_banner_title'),
      theme: 'darkTheme',
      description:
        'See how your business uses water and where savings can be made',
      isDesktop: !isMobile,
      bgPosition: !isMobile ? '-80px' : '0',
      onButtonClick: () => {
        navigate('/use');
      },
      buttonText: t('portal.home.myuse_banner_buttonText'),
      imageUrl:
        'https://cdn.getwaterfit.co.uk/static/files/images/develop/component/my-use.webp',
    },
    {
      title: t('portal.home.easywins_banner_title'),
      theme: 'darkTheme',
      description:
        'Choose simple actions to help your business reduce water use',
      isDesktop: !isMobile,
      bgPosition: !isMobile ? '-20px' : '0',
      onButtonClick: () => {
        navigate('/easy-wins');
      },
      buttonText: t('portal.home.easywins_banner_buttonText'),
      imageUrl:
        'https://cdn.getwaterfit.co.uk/static/files/images/develop/component/challenges.webp',
    },
    {
      title: 'Offers',
      theme: 'darkTheme',
      description: 'Promotional offers for all to see.',
      isDesktop: !isMobile,
      bgPosition: !isMobile ? '-150px' : '0',
      onButtonClick: () => {
        navigate('/offers');
      },
      buttonText: 'Take me there',
      imageUrl:
        'https://cdn.getwaterfit.co.uk/static/files/images/develop/component/book-va.webp',
    },
    {
      title: 'Ask me',
      theme: 'darkTheme',
      description: 'Get in touch to ask anything about GetWaterFit',
      isDesktop: !isMobile,
      bgPosition: !isMobile ? '-80px' : '0',
      onButtonClick: () => {
        navigate('/ask-me');
      },
      buttonText: 'Contact us',
      imageUrl:
        'https://cdn.getwaterfit.co.uk/static/files/images/develop/component/ask-me.webp',
    },
    {
      title: t('portal.customer_service.video_library2'),
      theme: 'darkTheme',
      description: 'Watch our short explanatory videos',
      isDesktop: !isMobile,
      bgPosition: !isMobile ? '-80px' : '0',
      onButtonClick: () => {
        navigate('/video-library');
      },
      buttonText: t('portal.customer_service.video_library_button'),
      imageUrl:
        'https://cdn.getwaterfit.co.uk/static/files/images/develop/component/video-library.webp',
    },
  ];
  return (
    <Wrap>
      {locationNumber === 0 ? (
        <NothingToDisplay
          location="/add-location"
          title="To start, please add a location"
          subtitle="Please add a location in order to continue"
          buttonText="Add location(s)"
        />
      ) : (
        <>
          <Row>
            <Col md={12} xs={12} sm={12}>
              <RewardsBanner />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={4}>
              <Box
                icon="office"
                location="/add-location"
                title="Location(s)"
                buttonText="Add location"
                value={locationNumber}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: '16px' }}>
            <Col xs={12} sm={12} md={12}>
              <Styled.TableWrap style={{ overflowX: 'hidden' }}>
                <Row>
                  <Col xs={12} sm={12} md={12}>
                    <Typography
                      size="3"
                      text="Location(s)"
                      style={{ padding: '0 1.25rem' }}
                    />
                  </Col>

                  <Col
                    md={12}
                    sm={12}
                    xs={12}
                    style={isMobile ? { overflowX: 'scroll' } : null}
                  >
                    <Styled.Table
                      style={{
                        borderCollapse: 'collapse',
                        width: '100%',
                        minWidth: '600px',
                      }}
                    >
                      <tbody>{tableItems()}</tbody>
                    </Styled.Table>
                    <Popup
                      isDesktop={!isMobile}
                      open={popup}
                      handleClick={() => {
                        setPopup(!popup);
                        setId(undefined);
                        setContent('');
                      }}
                    >
                      {renderContent()}
                    </Popup>
                  </Col>
                </Row>
                <Styled.PaginationWrap>
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={data && data.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </Styled.PaginationWrap>
              </Styled.TableWrap>
            </Col>
          </Row>
        </>
      )}

      <Row>
        {banners.map((bannerPoops, index) => {
          return (
            <Col xs={12} sm={12} md={6} key={`key${index}`}>
              <Tile {...bannerPoops} />
            </Col>
          );
        })}
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12}>
          <FooterInfo />
        </Col>
      </Row>
      <Tooltip id="edit-tooltip" />
      <Tooltip id="delete-tooltip" />
    </Wrap>
  );
};

export default PageOverview;
