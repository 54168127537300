import PageConsumption from './PageConsumption';
import { connect } from 'react-redux';
import { getUsage, getGeneralUse } from '../../actions/usage';
import { AppState } from '../../interfaces';
import { setMeta } from '../../actions';

const mapStateToProps = (state: AppState) => ({
  usage: state.usageReducer,
  listLocation: state.listLocationReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getUsage: (id) => dispatch(getUsage(id)),
  getGeneralUse: () => dispatch(getGeneralUse()),
  setMeta: (propertyId) => dispatch(setMeta(propertyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageConsumption);
