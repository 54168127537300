import * as React from 'react';
import CounterControl from '../CounterControl';
import Styled from './MultiCounterControl.style';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

interface MultiCounterControlProps {
  options: Array<any>;
  updateAnswerKey: Function;
  value: any;
  maxKey: string;
}
export const MultiCounterControl: React.FC<MultiCounterControlProps> = (
  props
) => {
  const valueIds = Object.keys(props.value);
  const [t] = useTranslation('common');
  const partialArrayItems = Array.from(props.options, (x) => x.key);

  const sum = _.reduce(
    partialArrayItems,
    (total, id) => {
      if (_.includes(valueIds, id.toString())) {
        return total + props.value[id];
      }
      return total;
    },
    0
  );
  const renderCounters = () => {
    return _.map(props.options, (el, index) => {
      const getMaxValue = props.maxKey !== undefined ? props.maxKey : el.max;

      const disabled =
        props.maxKey === undefined && typeof el.max === 'object' ? true : false;

      return (
        <Styled.Item key={`${index}_${el.key}`}>
          <div className="desc">{t(el.desc)} </div>
          <CounterControl
            answerKey={el.key}
            value={props.value[el.key]}
            max={disabled || !el.max ? 0 : getMaxValue}
            maxInGroup={sum >= getMaxValue ? true : false}
            min={el.min}
            defaultValue={el.defaultValue}
            updateAnswerKey={props.updateAnswerKey}
          />
        </Styled.Item>
      );
    });
  };

  return <div>{renderCounters()}</div>;
};

export default MultiCounterControl;
