/* eslint-disable no-case-declarations */
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { addGroup } from '../../helpers/helpers';
import { Row, Col } from 'react-styled-flexboxgrid';
import {
  CounterControl,
  RadioControl,
  SliderControl,
  MultiCounterControl,
} from '../index';
import Styled from './Questions.style';
import { getUrl } from '../../helpers/helpers';

interface QuestionsProps {
  data: any;
  updateAnswerKey: Function;
}

const Questions: React.FC<QuestionsProps> = (props) => {
  const { data, updateAnswerKey } = props;

  const [t] = useTranslation('common');
  const env = getUrl();

  const renderForm = () => {
    return _.map(data.data, (el, index) => {
      const controls = () => {
        return addGroup(el.controls).map((control) => {
          const { type, key } = control;
          switch (type) {
            case 'counter':
              return (
                <CounterControl
                  key={control.key}
                  answerKey={control.key}
                  value={data.answers[control.key]}
                  max={control.max}
                  min={control.min}
                  defaultValue={control.defaultValue}
                  updateAnswerKey={updateAnswerKey}
                />
              );
            case 'multi-counter':
              const length = control.options[0].max.length;
              const getLength = () => {
                if (length === 3) {
                  return (
                    (data.answers[control.options[0].max[0]] || 0) +
                    (data.answers[control.options[0].max[1]] || 0) +
                    (data.answers[control.options[0].max[2]] || 0)
                  );
                } else if (length === 5) {
                  return (
                    (data.answers[control.options[0].max[0]] || 0) +
                    (data.answers[control.options[0].max[1]] || 0) +
                    (data.answers[control.options[0].max[2]] || 0) +
                    (data.answers[control.options[0].max[3]] || 0) +
                    (data.answers[control.options[0].max[4]] || 0)
                  );
                } else {
                  return (
                    (data.answers[control.options[0].max[0]] || 0) +
                    (data.answers[control.options[0].max[1]] || 0) +
                    (data.answers[control.options[0].max[2]] || 0) +
                    (data.answers[control.options[0].max[3]] || 0)
                  );
                }
              };
              const maxInGroup = getLength();
              return (
                <MultiCounterControl
                  options={control.options}
                  key={control.key}
                  value={data.answers}
                  maxKey={maxInGroup || undefined}
                  updateAnswerKey={updateAnswerKey}
                />
              );
            case 'slider':
              return (
                <SliderControl
                  answerKey={control.key}
                  key={control.key}
                  value={data.answers[control.key]}
                  defaultValue={control.defaultValue}
                  help_image_url={control.help_image_url}
                  max={control.max}
                  min={control.min}
                  required={control.required}
                  updateAnswerKey={updateAnswerKey}
                  sliderIndicatorText={t(control.desc)}
                  validateRequiredKeys={control.validateRequiredKeys}
                />
              );
            case 'buttons':
              return (
                <RadioControl
                  text={t(control.text)}
                  options={control.options}
                  answerKey={control.key}
                  key={control.key}
                  value={data.answers[control.key]}
                  updateAnswerKey={updateAnswerKey}
                />
              );

            default:
              return <div key={key}>Controls for not exist</div>;
          }
        });
      };
      return (
        <Styled.ItemWrap key={`${el.text}-${index}`}>
          <Row>
            <Col md={12} sm={12} xs={12}>
              {env === 'localhost:3000' ||
              env === 'dev.business.getwaterfit.co.uk' ? (
                <span>{el.id}</span>
              ) : null}{' '}
              <Styled.Title>{t(el.text)}</Styled.Title>
            </Col>
          </Row>

          {controls()}
        </Styled.ItemWrap>
      );
    });
  };

  return <div>{renderForm()}</div>;
};

export default Questions;
