import React from 'react';
import Typography from '../Typography';
import * as _ from 'lodash';
import { useTranslation, Trans } from 'react-i18next';
import { Col, Row } from 'react-styled-flexboxgrid';

const FooterInfo: React.FC = () => {
  const [t] = useTranslation('common');

  const waterHeadedWithCoal = '£0.0413';
  const waterHeadedWithElectric = '£0.1636';
  const waterHeadedWithGas = '£0.0417';
  const waterHeadedWithOil = '£0.0481';
  const waterHeadedWithRenewable = '£0.0719';
  const link = 'http://www.energysavingtrust.org.uk/about-us/our-calculations';

  const text_key = t('portal.home.household_savings');

  return (
    <Row>
      <Col xs={12}>
        <>
          <p
            style={{
              fontSize: '12px',
              lineHeight: '18px',
              marginBottom: '0',
              color: 'rgb(11, 38, 65)',
              fontWeight: 'normal',
            }}
          >
            Savings will vary by business, for example due to size of business,
            employee water use and water saving behaviour, and strength of
            leaks. The figures presented are therefore estimates and are not
            guaranteed by either Save Water Save Money, your Water Retailer or
            your water Wholesaler. Financial values are based on the information
            submitted via GetWaterFit based on the cost of business water supply
            and sewerage at £2.90 per m3, and energy price of £0.50 per kWh,
            excluding standing charges.
          </p>
        </>
      </Col>
    </Row>
  );
};

export default FooterInfo;
