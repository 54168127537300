import { SupportTicketReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

const initialState: SupportTicketReducer = {
  status: {
    loading: false,
    error: false,
    done: false,
  },
  data: null,
};

const supportTicketReducer = createReducer(initialState, {
  SET_CUSTOMER_SERVICE_PENDING: (state) => {
    state.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  SET_CUSTOMER_SERVICE_FULFILLED: (state, action) => {
    state.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.data = action.payload.data.request;
  },

  SET_CUSTOMER_SERVICE_REJECTED: (state, action) => {
    state.status = {
      loading: false,
      error: true,
      done: false,
    };
    state.data = null;
  },
});

export default supportTicketReducer;
