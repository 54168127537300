import styled, { css, createGlobalStyle } from 'styled-components';
import { colors } from '../../assets/variables';

const GlobalStyle = createGlobalStyle`
select::-ms-expand {
    display: none;
}
`;

const resetForms = css`
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.2; /* 1 */
  margin: 0; /* 2 */

  ::placeholder {
    color: rgba(11, 38, 65, 0.7);
  }
`;

const Label = styled.label`
  font-size: 14px;
`;

const Select = styled.select`
  ${resetForms}
  width: 100%;
  height: 50px;
  transition: all 300ms;
  border: none;
  background-color: white;
  border: 1px solid ${colors.primary};
  vertical-align: middle;
  border-radius: 5px;
  padding: 8px 40px 8px 8px;
  outline: none;
  -webkit-appearance: none;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE0IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik02Ljk5OTkzIDYuMjk2OTVMMS44NDg3NyAxLjE0NTc5QzEuNjU0NTcgMC45NTE1OTEgMS4zMzk4NSAwLjk1MTU5MSAxLjE0NTY1IDEuMTQ1NzlDMC45NTE0NSAxLjMzOTk5IDAuOTUxNDUgMS42NTQ3MSAxLjE0NTY1IDEuODQ4OTFMNi45OTk5MyA3LjcwMzE5TDEyLjg1NDMgMS44NDg3N0MxMy4wNDg1IDEuNjU0NTcgMTMuMDQ4NiAxLjMzOTg1IDEyLjg1NDQgMS4xNDU2NUMxMi42NjAxIDAuOTUxNDUgMTIuMzQ1NCAwLjk1MTQ1IDEyLjE1MTIgMS4xNDU2NUw2Ljk5OTkzIDYuMjk2OTVaIiBzdHJva2U9IiMwQjI2NDEiLz4KPC9zdmc+Cg==');
  background-position: calc(100% - 10px) 50%;
  background-repeat: no-repeat;
  transition: 0.2s;

  ${(props) => {
    if (props.value === '') {
      return css`
        color: rgba(11, 38, 65, 0.7);
      `;
    }
    if (props.label) {
      return css`
        margin-top: 6px;
      `;
    }
  }}
`;

const FieldError = styled.div`
  font-size: 0.8em;
  color: ${colors.error};
  margin-top: 5px;
`;

export default {
  Select,
  Label,
  GlobalStyle,
  FieldError,
};
