import * as React from 'react';

const medium = (props: any) => {
  const { colorPrimary } = props;

  return (
    <>
      <path
        d="M82.537 11c4.098 0 7.43 3.333 7.43 7.431v65.106c0 4.098-3.332 7.431-7.43 7.431H17.43c-4.098 0-7.431-3.333-7.431-7.431V18.431C10 14.333 13.333 11 17.43 11h65.107zm0 2H17.43A5.438 5.438 0 0012 18.431v65.106a5.438 5.438 0 005.43 5.431h65.107a5.437 5.437 0 005.43-5.431V18.431A5.437 5.437 0 0082.538 13zm-44.28 14.138l11.286 36.47h.197l11.97-36.47h13.767V74.83h-9.428V52.256c0-.8.01-1.69.027-2.673l.032-1.398c.047-1.622.19-5.192.43-10.707h-.293L53.948 74.83h-9.265L33.2 37.413h-.293l.247 4.756c.24 4.856.364 8.285.371 10.287V74.83h-9.034V27.14h13.765z"
        fill={colorPrimary}
        fillRule="evenodd"
      />
    </>
  );
};
export default medium;
