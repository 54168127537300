import * as React from 'react';

const contract = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M76.298 13a5.834 5.834 0 015.823 5.604l.004.223v63.056a5.834 5.834 0 01-5.604 5.823l-.223.004h-52.47a5.834 5.834 0 01-5.824-5.604L18 81.883V18.827a5.834 5.834 0 015.604-5.823l.223-.004h52.471zm0 2h-52.47a3.832 3.832 0 00-3.823 3.63l-.005.197v63.056a3.832 3.832 0 003.63 3.822l.197.005h52.471a3.832 3.832 0 003.822-3.63l.005-.197V18.827a3.832 3.832 0 00-3.63-3.822L76.298 15z"
          fill={colorPrimary}
        />
        <path
          d="M27.812 27.5h25.506a1 1 0 100-2H27.812a1 1 0 000 2M27.812 35.021h25.506a1 1 0 100-2H27.812a1 1 0 000 2M27.812 42.542h25.506a1 1 0 100-2H27.812a1 1 0 000 2"
          fill={colorSecondary}
        />
        <path
          d="M71.526 55.241H27.812a1 1 0 100 2h43.714a1 1 0 100-2M71.526 61.544H27.812a1 1 0 100 2h43.714a1 1 0 100-2M71.526 67.846H27.812a1 1 0 100 2h43.714a1 1 0 100-2M71.526 74.148H27.812a1 1 0 100 2h43.714a1 1 0 100-2"
          fill={colorPrimary}
        />
      </g>
    </>
  );
};
export default contract;
