import * as React from 'react';

const translate = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M56.12 14H15.64C13.08 14 11 16.08 11 18.63v34.83c0 2.55 2.08 4.63 4.64 4.63h40.48c1.35 0 2.57-.58 3.42-1.51.76-.82 1.22-1.92 1.22-3.12V18.63c0-2.55-2.08-4.63-4.64-4.63"
          fill={colorSecondary}
        />
        <path
          d="M56.12 12c3.582 0 6.513 2.845 6.636 6.397l.004.233v21.39h21.39c3.582 0 6.504 2.855 6.626 6.407l.005.233v34.83a6.64 6.64 0 01-6.398 6.626l-.233.004H43.66c-3.573 0-6.502-2.855-6.625-6.398l-.004-.232v-21.4H15.64c-3.584 0-6.514-2.845-6.637-6.397L9 53.46V18.63c0-3.582 2.854-6.503 6.407-6.626L15.64 12h40.48zm28.03 30.02H62.76v6.01h4.67l10.93 32.03h-4.41l-3.43-10.08H59.16l-3.33 10.08h-4.27l6.93-20.42c.313-.117.614-.258.902-.42l.212-.125a6.54 6.54 0 01-1.114.545c-.73.3-1.53.45-2.37.45H39.03v21.4a4.64 4.64 0 004.42 4.625l.21.005h40.49a4.633 4.633 0 004.626-4.419l.005-.211V46.66a4.636 4.636 0 00-4.418-4.635l-.213-.005zm-19.28 9.66h-.1a113.123 113.123 0 01-1.254 4.635l-.355 1.165-3.141 9.27h9.65l-3.14-9.22c-.72-2.09-1.19-3.99-1.66-5.85zM56.12 14H15.64a4.642 4.642 0 00-4.635 4.419L11 18.63v34.83a4.641 4.641 0 004.428 4.625l.212.005h40.48c1.35 0 2.57-.58 3.42-1.51.76-.82 1.22-1.92 1.22-3.12V18.63a4.641 4.641 0 00-4.428-4.625L56.12 14zm-18.66 5.14L48.4 51.18h-4.42L40.55 41.1H29.19l-3.32 10.08h-4.28l10.88-32.04h4.99zM34.9 22.8h-.1c-.376 1.52-.79 3.072-1.254 4.63l-.356 1.17-3.14 9.27h9.65l-3.14-9.22c-.71-2.09-1.19-3.99-1.66-5.85z"
          fill={colorPrimary}
        />
      </g>
    </>
  );
};
export default translate;
