import styled from 'styled-components';
import { colors } from '../../assets/variables';
const BottomNav = styled.div`
  position: fixed;
  bottom: 0;
  height: 80px;
  background-color: #ffffff;
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px -4px 10px rgba(122, 122, 122, 0.75);
  a {
    text-decoration: none;
    color: ${colors.primary};
    filter: grayscale(100%);
    &:hover,
    &:focus,
    &:active {
      color: #174489;
      filter: grayscale(0%);
      background: transparent;
    }
    &.active {
      filter: grayscale(0%);
      color: #174489;
    }
  }
`;
const Text = styled.span`
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 1.2;
  text-align: center;
  max-width: 83px;
`;
export default { BottomNav, Text };
