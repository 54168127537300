import React from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import { LocalStorage } from 'ttl-localstorage';
import { Breadcrumbs, LitresNav } from '../index';
import * as _ from 'lodash';
import store from 'store2';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
interface DefaultLayoutProps {
  listLocation: any;
  listLocations: Function;
  general: any;
  getPreferences: Function;

  generalData: Function;
  getAvailableRewards: Function;
}

export const DefaultLayout: React.FC<DefaultLayoutProps> = (props) => {
  const {
    listLocation,
    listLocations,
    getPreferences,
    generalData,
    general,

    getAvailableRewards,
  } = props;
  const data = _.get(listLocation, 'data');
  const getFirstValue = _.get(data, '[0].id');
  const { done, loading } = listLocation.status;
  const location = useLocation();
  React.useEffect(() => {
    LocalStorage.get('swsmUser');
    listLocations();
    getPreferences();

    generalData();
    getAvailableRewards();
    store('property', getFirstValue);
  }, [listLocations]);

  if (done && !loading) {
    store('property', getFirstValue);
  }

  const genData = _.get(general, 'data');

  return (
    <div>
      <LitresNav data={genData} />
      <Breadcrumbs data={data} />
      <div style={{ background: '#f8fafc' }}>
        <Outlet />
        {location.pathname === '/myshop' ? (
          <Grid>
            <Row style={{ maxWidth: '760px' }}>
              <Col md={12} sm={12} xs={12}>
                <p style={{ fontSize: '15px' }}>
                  Neither <b>GetWaterFit</b> nor any partners associated with
                  <b> GetWaterFit</b> are involved in the actual transaction
                  between you the buyer and the seller. The contract for the
                  purchase and sale is directly between buyer and seller.{' '}
                  <Link
                    style={{ color: '#0b2641', fontWeight: 600 }}
                    target="_blank"
                    rel="noopener noreferrer"
                    to="/terms-and-conditions"
                  >
                    See Terms of use
                  </Link>
                  .
                </p>
              </Col>
            </Row>
          </Grid>
        ) : null}
      </div>
    </div>
  );
};

export default DefaultLayout;
