import * as React from 'react';

const network = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M50.107 19c9.368 0 17.027 7.361 17.55 16.6h18.035a1 1 0 011 1v33.421c3.122.483 5.522 3.182 5.522 6.438a6.53 6.53 0 01-6.522 6.522 6.53 6.53 0 01-6.522-6.522c0-3.256 2.4-5.955 5.522-6.438v-32.42H67.657c-.504 8.905-7.644 16.046-16.55 16.55v15.87c3.122.483 5.523 3.182 5.523 6.438a6.53 6.53 0 01-6.523 6.522 6.53 6.53 0 01-6.522-6.522c0-3.256 2.4-5.955 5.522-6.438v-15.87c-8.906-.504-16.046-7.645-16.55-16.55H15.522v32.42c3.122.483 5.523 3.182 5.523 6.438a6.53 6.53 0 01-6.523 6.522A6.53 6.53 0 018 76.46c0-3.256 2.4-5.955 5.522-6.438v-33.42a1 1 0 011-1h18.035C33.08 26.36 40.74 19 50.107 19zm35.585 52.936a4.527 4.527 0 00-4.522 4.523 4.526 4.526 0 004.522 4.522 4.526 4.526 0 004.522-4.522 4.527 4.527 0 00-4.522-4.523zm-35.585 0a4.527 4.527 0 00-4.522 4.523 4.526 4.526 0 004.522 4.522 4.527 4.527 0 004.523-4.522 4.527 4.527 0 00-4.523-4.523zm-35.585 0A4.527 4.527 0 0010 76.46a4.526 4.526 0 004.522 4.522 4.527 4.527 0 004.523-4.522 4.527 4.527 0 00-4.523-4.523zM50.107 21c-8.603 0-15.6 6.998-15.6 15.6 0 8.603 6.997 15.602 15.6 15.602S65.708 45.203 65.708 36.6c0-8.602-6.998-15.6-15.6-15.6z"
          fill={colorPrimary}
        />
        <path
          d="M50.107 52.202c-8.603 0-15.6-6.999-15.6-15.602 0-8.602 6.997-15.6 15.6-15.6s15.601 6.998 15.601 15.6c0 8.603-6.998 15.602-15.6 15.602"
          fill={colorSecondary}
        />
        <path
          d="M19.045 76.459a4.526 4.526 0 01-4.522 4.522A4.527 4.527 0 0110 76.459a4.527 4.527 0 014.523-4.522 4.526 4.526 0 014.522 4.522M54.63 76.459a4.526 4.526 0 01-4.522 4.522 4.527 4.527 0 01-4.523-4.522 4.527 4.527 0 014.523-4.522 4.526 4.526 0 014.522 4.522M85.692 80.981a4.527 4.527 0 01-4.522-4.522 4.527 4.527 0 014.522-4.523 4.527 4.527 0 014.522 4.523 4.526 4.526 0 01-4.522 4.522"
          fill="#D7F1FF"
        />
      </g>
    </>
  );
};
export default network;
