import * as _ from 'lodash';
import { ResetPasswordReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
import i18next from 'i18next';

export const initialState: ResetPasswordReducer = {
  resetRequest: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  resetUpdate: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
};

const resetPasswordReducer = createReducer(initialState, {
  RESET_PASSWORD_REPEAT: (state) => {
    state.resetRequest.status = {
      loading: false,
      error: false,
      done: false,
    };
  },

  RESET_PASSWORD_PENDING: (state) => {
    state.resetRequest.status = {
      loading: true,
      error: true,
      done: false,
    };
  },

  RESET_PASSWORD_FULFILLED: (state) => {
    state.resetRequest.status = {
      loading: false,
      error: false,
      done: true,
    };
  },

  RESET_PASSWORD_REJECTED: (state, action) => {
    state.resetRequest.status = {
      loading: false,
      error: _.get(
        action,
        'payload.data.message',
        i18next.t('portal.general.error_message')
      ),
      done: false,
    };
  },

  RESET_PASSWORD_UPDATE_PENDING: (state, action) => {
    state.resetUpdate.status = {
      loading: true,
      error: true,
      done: false,
    };
  },

  RESET_PASSWORD_UPDATE_FULFILLED: (state, action) => {
    state.resetUpdate.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.resetUpdate.data = action.payload.data;
  },

  RESET_PASSWORD_UPDATE_REJECTED: (state, action) => {
    state.resetUpdate.status = {
      loading: false,
      error: _.get(
        action,
        'payload.data.message',
        i18next.t('portal.general.error_message')
      ),
      done: false,
    };
  },
});

export default resetPasswordReducer;
