import * as React from 'react';

const romania = (props: any) => (
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g transform="translate(5.000000, 5.000000)" fillRule="nonzero">
      <circle id="Oval" fill="#FCD116" cx="45" cy="45" r="45" />
      <path
        d="M89.7301724,45.159202 C89.7301724,25.7021621 77.44974,9.11512885 60.2175032,2.72125044 L60.2175032,87.5973304 C77.44974,81.2032752 89.7301724,64.6162419 89.7301724,45.159202 Z"
        fill="#CE1126"
      />
      <path
        d="M0,45 C0,64.4570804 12.2806348,81.0441482 29.5127307,87.43804 L29.5127307,2.56196004 C12.2806348,8.95585176 0,25.5429196 0,45 Z"
        fill="#002B7F"
      />
    </g>
  </g>
);
export default romania;
