import * as React from 'react';

const leakingPipe = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M49.333 59.056c.38-.423 1.11-.423 1.488 0l.57.648c2.138 2.465 8.744 10.462 8.744 15.115 0 5.545-4.512 10.057-10.058 10.057-5.545 0-10.057-4.512-10.057-10.057 0-5.234 8.36-14.701 9.313-15.763zm.744 2.187l-.589.698c-2.88 3.464-7.468 9.66-7.468 12.878 0 4.443 3.615 8.057 8.057 8.057 4.443 0 8.058-3.614 8.058-8.057 0-3.45-5.267-10.315-8.058-13.576z"
          fill={colorPrimary}
        />
        <path
          d="M50.077 82.876c-4.442 0-8.057-3.614-8.057-8.057 0-3.447 5.267-10.314 8.057-13.576 2.791 3.26 8.058 10.126 8.058 13.576 0 4.443-3.615 8.057-8.058 8.057"
          fill={colorSecondary}
        />
        <path
          d="M16.175 16a1 1 0 01.993.883l.007.117v6.308h65.807V17a1 1 0 01.883-.993l.117-.007h8.175a1 1 0 01.993.883l.007.117v39.79a1 1 0 01-.883.992l-.117.007h-8.175a1 1 0 01-.993-.883l-.007-.117v-6.308H17.175v6.308a1 1 0 01-.883.993l-.117.007H8a1 1 0 01-.993-.883L7 56.789V17a1 1 0 01.883-.993L8 16h8.175zm74.982 2h-6.175v37.79h6.175V18zm-75.982 0H9v37.79h6.175V18zm67.804 7.308H62.724l-6.821 6.821 5.839 5.84c.36.36.389.927.083 1.32l-.083.094-9.098 9.098h30.335V25.308zm-23.083 0H17.175v23.173h32.64l9.806-9.805-5.839-5.84a1.001 1.001 0 01-.083-1.32l.083-.094 6.114-6.114z"
          fill={colorPrimary}
        />
        <path
          d="M91.157 18.001v37.79h-6.175V18h6.175zm-75.982 0v37.79H9V18h6.175z"
          fill="#D7F1FF"
        />
      </g>
    </>
  );
};
export default leakingPipe;
