import styled from 'styled-components';

const Wraper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const Row = styled.div`
  margin-bottom: 4px;
  display: flex;
  flex: 0 0 33.33%;

  align-items: flex-end;
  & > label {
    margin-bottom: 0px;
  }
  &:nth-child(3n + 1) > label {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-right: 4px;
  }
  &:nth-child(3n) > label {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-left: 4px;
  }
  &:last-child > label {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  & > img + label {
    min-width: 280px;
    @media screen and (max-width: 414px) {
      min-width: 200px;
    }
  }
  &:first-child > img + label {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &:last-child > img + label {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  @media screen and (max-width: 800px) {
    flex: 0 0 100%;
    & > label {
      border-radius: 10px;
    }
    &:nth-child(3n) > label {
      margin-left: 0px;
    }
    &:nth-child(3n + 1) > label {
      margin-right: 0px;
    }
  }
`;

const Image = styled.img`
  margin-top: 20px;
  height: 80px;
  width: 80px;
`;

export default {
  Wraper,
  Row,
  Image,
};
