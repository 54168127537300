import * as React from 'react';

const stopwatch = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M59.007 83.472v-3.855a1 1 0 10-2 0v3.857c-13.395-.494-24.234-11.151-25.015-24.472h3.863a1 1 0 100-2h-3.901c.243-13.82 11.3-25.041 25.053-25.55v3.858a1 1 0 102 0v-3.855C72.734 31.993 83.759 43.204 84 57.002h-3.84a1 1 0 000 2h3.802c-.78 13.3-11.59 23.947-24.956 24.47"
          fill="#D7F1FF"
        />
        <path
          d="M64.11 7a4.192 4.192 0 014.186 4.188 4.192 4.192 0 01-3.977 4.182l-.209.005h-1.307v6.65c0 .043-.02.079-.024.12C80.17 24.5 93.626 39.436 93.626 57.465c0 19.657-15.992 35.65-35.65 35.65-19.656 0-35.65-15.993-35.65-35.65 0-18.03 13.456-32.966 30.85-35.32l-.013-.058-.01-.061v-6.65h-1.309a4.193 4.193 0 01-4.187-4.187 4.194 4.194 0 013.979-4.183L51.846 7H64.11zm-6.133 16.813c-18.554 0-33.65 15.096-33.65 33.65 0 18.555 15.095 33.65 33.65 33.65 18.556 0 33.65-15.095 33.65-33.65 0-18.554-15.094-33.65-33.65-33.65zm2.826-8.438h-5.65v5.65h5.65v-5.65zM64.11 9H51.845a2.19 2.19 0 00-2.187 2.188 2.19 2.19 0 002.032 2.181l.156.006H64.11a2.189 2.189 0 00.155-4.37L64.11 9z"
          fill={colorPrimary}
        />
        <path
          d="M72.658 41.883a1 1 0 011.492 1.326l-.083.094-11.882 11.791c.4.703.647 1.504.647 2.37a4.83 4.83 0 01-4.824 4.824 4.776 4.776 0 01-2.43-.664l-.21-.13-4.494 4.375a.995.995 0 01-1.414-.019.999.999 0 01-.065-1.32l.085-.094 4.48-4.363a4.794 4.794 0 01-.777-2.61 4.83 4.83 0 014.825-4.824c.98 0 1.886.303 2.647.81l.204.143 11.799-11.709zM58.008 54.64a2.828 2.828 0 00-2.825 2.824 2.828 2.828 0 002.825 2.825 2.827 2.827 0 002.824-2.825 2.827 2.827 0 00-2.824-2.824zM15.284 63.843a1 1 0 01.117 1.993l-.117.007H7a1 1 0 01-.117-1.993L7 63.843h8.284zm4.142-6.841a1 1 0 01.117 1.993l-.117.007h-8.284a1 1 0 01-.117-1.993l.117-.007h8.284zm-4.142-6.552a1 1 0 01.117 1.993l-.117.007H7a1 1 0 01-.117-1.994L7 50.45h8.284z"
          fill={colorPrimary}
        />
        <path
          d="M57.515 29.432l.495-.003c15.445.02 28.003 12.588 28.003 28.035l-.01.349.032.124a.33.33 0 01.007.065c0 .087-.028.164-.05.244-.412 14.935-12.552 26.99-27.526 27.247l-.488.005c-15.458 0-28.034-12.576-28.034-28.034 0-15.305 12.326-27.783 27.57-28.032zm-.507 2.02c-13.617.504-24.59 11.507-25.043 25.136l-.01.414h3.9a1 1 0 01.116 1.993l-.116.007h-3.863c.774 13.187 11.405 23.764 24.615 24.454l.4.018v-3.857a1 1 0 011.994-.117l.007.117v3.855c13.233-.518 23.96-10.957 24.93-24.072l.026-.398H80.16a1 1 0 01-.117-1.994l.117-.006H84c-.24-13.66-11.048-24.785-24.582-25.528l-.411-.02v3.856a1 1 0 01-1.993.116l-.007-.116v-3.857z"
          fill={colorPrimary}
        />
        <path
          d="M57.977 60.288a2.828 2.828 0 01-2.823-2.825 2.827 2.827 0 012.823-2.824 2.826 2.826 0 012.825 2.824 2.826 2.826 0 01-2.825 2.825M49.688 11.188a2.19 2.19 0 012.188-2.189H64.14a2.19 2.19 0 012.188 2.188 2.19 2.19 0 01-2.189 2.188H51.876a2.189 2.189 0 01-2.188-2.188"
          fill={colorSecondary}
        />
      </g>
    </>
  );
};
export default stopwatch;
