import styled from 'styled-components';

const Wrap = styled.div`
  padding-top: 6px;
  background: rgb(248, 250, 252);
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  .line {
    margin: 0px 4px;
  }
  .link {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  span {
    &:last-child {
      color: #959595;
      cursor: auto;
      &:hover {
        text-decoration: none;
      }
    }
  }
`;
export default {
  Wrap,
  Container,
};
