import React from 'react';
import Styled from './Typography.style';

interface TypographyProps {
  size: '1' | '2' | '3' | '4' | '5' | '6' | 'p' | 'icon' | 'sectionSeparator';
  text?: any;
  subText?: string;
  weight?: number;
  style?: any;
  textAlign?: string;
  className?: string;
}
export class Typography extends React.Component<TypographyProps, any> {
  render() {
    const { size, text, className, weight, style, textAlign } = this.props;

    if (!text) {
      return null;
    }

    const renderTypography = (size) => {
      switch (size) {
        case '1':
          return (
            <Styled.HeadOne
              className={className}
              weight={weight}
              textAlign={textAlign}
              style={style}
            >
              {text}
            </Styled.HeadOne>
          );
        case '2':
          return (
            <Styled.HeadTwo
              className={className}
              weight={weight}
              textAlign={textAlign}
              style={style}
            >
              {text}
            </Styled.HeadTwo>
          );
        case '3':
          return (
            <Styled.HeadThree
              className={className}
              weight={weight}
              textAlign={textAlign}
              style={style}
            >
              {text}
            </Styled.HeadThree>
          );
        case '4':
          return (
            <Styled.HeadFour
              weight={weight}
              className={className}
              textAlign={textAlign}
              style={style}
            >
              {text}
            </Styled.HeadFour>
          );
        case '5':
          return (
            <Styled.HeadFive
              className={className}
              weight={weight}
              textAlign={textAlign}
              style={style}
            >
              {text}
            </Styled.HeadFive>
          );
        case '6':
          return (
            <Styled.HeadSix
              className={className}
              weight={weight}
              textAlign={textAlign}
              style={style}
            >
              {text}
            </Styled.HeadSix>
          );
        case 'p':
          return (
            <Styled.Paragraph
              className={className}
              weight={weight}
              textAlign={textAlign}
              style={style}
            >
              {text}
            </Styled.Paragraph>
          );
        case 'sectionSeparator':
          return (
            <Styled.SectionSeparator style={style}>
              {text}
            </Styled.SectionSeparator>
          );
        default:
          return null;
      }
    };

    return renderTypography(size);
  }
}
export default Typography;
