import styled from 'styled-components';

const Card = styled.div`
  background: white;
  border-radius: 8px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(48, 46, 56, 0.06) 0px 3px 10px 0px;
  border: 2px solid rgb(37, 179, 235);

  & > h4 {
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 600;
  }

  .image-popup {
    cursor: pointer;
    transition: all 200ms ease-in-out;
    &:hover {
      transition: all 200ms ease-in-out;
      opacity: 0.8;
    }
  }
`;

export default { Card };
