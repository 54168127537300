import React from 'react';
import Styled from './Marketplace.style';
import { Button, Popup, Icon, Typography } from '../../components';
import { useNavigate } from 'react-router-dom';

interface CardProps {
  title: string;
  description: string;
  image: string;
  buttonPath: string;
  buttonText: string;
  isDesktop: boolean;
  productUniqueness: string;
  companyName: string;
  id: number;
  businessCreds?: any;
  adId: number;
  isOffer?: boolean;
  optIn: Function;
  optedInData: any;
  getAvailableRewards: Function;
}
export const Card: React.FC<CardProps> = (props) => {
  const {
    title,
    description,
    image,
    productUniqueness,
    businessCreds,
    id,
    companyName,
    buttonPath,
    buttonText,
    isDesktop,

    adId,
    isOffer,
    optIn,
    optedInData,
    getAvailableRewards,
  } = props;
  const [showPopup, setPopup] = React.useState(false);
  const [bizId, setBizId] = React.useState(0);
  const navigate = useNavigate();

  const renderLeaves = () => {
    return (
      <div style={{ display: 'flex' }}>
        {props.businessCreds.water && (
          <Icon icon="leaf" colorPrimary="#86B049" size={18} />
        )}
        {props.businessCreds.carbon && (
          <Icon icon="leaf" colorPrimary="#86B049" size={18} />
        )}{' '}
        {props.businessCreds.energy && (
          <Icon icon="leaf" colorPrimary="#86B049" size={18} />
        )}{' '}
        {props.businessCreds.environment && (
          <Icon icon="leaf" colorPrimary="#86B049" size={18} />
        )}
      </div>
    );
  };

  const renderPopupContent = () => {
    return (
      <Styled.PopupContent>
        <Typography size="3" text={title} />
        <img src={image} alt={title} width={isDesktop ? '300px' : '100%'} />
        <Typography
          size="p"
          text={description}
          style={{
            marginBottom: '16px',
            width: isDesktop ? '500px' : '100%',
            textAlign: 'left',
            lineHeight: '1.5',
          }}
        />
        {productUniqueness && (
          <span
            style={{
              marginBottom: '16px',
              color: '#0b2641',
              width: isDesktop ? '500px' : '100%',
              textAlign: 'left',
              lineHeight: '1.5',
            }}
          >
            <b>Product Sustainability story</b>: {productUniqueness}
          </span>
        )}

        <Button
          onClick={() => {
            window.open(
              `${buttonPath}`,
              '_blank' // <- This is what makes it open in a new window.
            );
          }}
        >
          {buttonText}
        </Button>
      </Styled.PopupContent>
    );
  };

  return (
    <div
      style={{
        marginBottom: '32px',
        height: 'auto',
        display: 'flex',
        flex: 1,
        position: 'relative',
      }}
    >
      <Popup
        style={{ width: isDesktop ? '700px' : '100%' }}
        open={showPopup}
        handleClick={() => {
          setPopup(!showPopup);
          setBizId(id);
        }}
        isDesktop={isDesktop}
      >
        {renderPopupContent()}
      </Popup>
      <Styled.Card>
        <Styled.ImgWrap imageUrl={image} />
        <Styled.CopyWrap>
          {businessCreds ? (
            <Styled.CompanyName>
              <p
                style={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  margin: '0px',
                }}
                onClick={() => navigate(`/myshop/${id}`)}
              >
                {companyName}
              </p>
              <div
                style={{ display: 'flex' }}
                data-tooltip-id="company-tooltip"
                data-tooltip-content="See how we consider water, energy, carbon and our impact on the environment in what we do."
                data-tooltip-place="top"
              >
                {renderLeaves()}
                {/* <Tooltip id="company-tooltip" /> */}
              </div>
            </Styled.CompanyName>
          ) : null}
          <Typography
            size="4"
            text={title}
            style={{ minHeight: isDesktop ? '52px' : 'auto' }}
          />
          <Styled.ShortDescription
            style={{ minHeight: isDesktop ? '103px' : 'auto' }}
          >
            {description}
          </Styled.ShortDescription>
          {isOffer ? null : (
            <Styled.Button
              style={{ paddingLeft: '0px' }}
              onClick={() => {
                setPopup(!showPopup);
              }}
            >
              Read more
            </Styled.Button>
          )}
          {isOffer && optedInData.includes(adId) ? (
            <div style={{ marginTop: 'auto', fontWeight: 600 }}>
              Check your email for voucher code
            </div>
          ) : (
            <div style={{ marginTop: 'auto' }}>
              <Styled.Button
                onClick={() => {
                  if (isOffer) {
                    optIn(adId);
                    setTimeout(() => {
                      getAvailableRewards();
                    }, 500);
                  } else {
                    window.open(
                      `${buttonPath}`,
                      '_blank' // <- This is what makes it open in a new window.
                    );
                  }
                }}
                style={{ paddingLeft: '0px', marginRight: '16px' }}
              >
                {isOffer ? 'Accept Offer' : buttonText}
              </Styled.Button>
            </div>
          )}
        </Styled.CopyWrap>
      </Styled.Card>
    </div>
  );
};

export default Card;
