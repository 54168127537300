import * as React from 'react';

const latvia = () => (
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <circle fill="#F0F0F0" fillRule="nonzero" cx="50" cy="50" r="45" />
    <path
      d="M50,5 C29.2087695,5 11.7139648,19.1013477 6.55021484,38.2608008 L93.4499609,38.2608008 C88.2860352,19.1013477 70.7912305,5 50,5 Z"
      fill="#9E3039"
      fillRule="nonzero"
    />
    <path
      d="M50,95 C70.7912305,95 88.2860352,80.8986523 93.4497852,61.7391992 L6.55021484,61.7391992 C11.7139648,80.8984766 29.2087695,95 50,95 Z"
      fill="#9E3039"
      fillRule="nonzero"
    />
  </g>
);
export default latvia;
