import Landing from './Landing';
import { connect } from 'react-redux';
import { register } from '../../actions/index';
import { AppState } from '../../interfaces';

const mapStateToProps = (state: AppState) => ({
  registration: state.registerReducer,
});

const mapDispatchToProps = (dispatch) => ({
  register: ({ email, company, password, recaptchaResponse }) =>
    dispatch(register({ email, company, password, recaptchaResponse })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
