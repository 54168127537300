import * as React from 'react';
import { colors } from '../../assets/variables';
import Styled from './Button.style';

interface ButtonProps {
  full?: boolean;
  style?: Object;
  href?: string;
  disabled?: boolean;
  id?: string;
  colorSecondary?: string;
  colorPrimary?: string;
  active?: boolean;
  appearance?: 'DEFAULT' | 'OUTLINED' | 'TRANSPARENT' | string; // string here is because storybook
  onClick?: (e: any) => void;
  title?: string;
  type?: 'button' | 'submit';
  className?: string;
  children: any;
  isLoading?: boolean;
}

export const Button: React.FC<ButtonProps> = (props) => {
  const defaultProps = {
    colorPrimary: props.colorPrimary ? props.colorPrimary : colors.primary,
    colorSecondary: props.colorSecondary
      ? props.colorSecondary
      : colors.baseLight,
  };

  if (props.href) {
    const LinkAppearance = Styled.Default.withComponent('a');
    return (
      <LinkAppearance {...props} {...defaultProps} target="_blank">
        {props.children ? props.children : 'Link'}
      </LinkAppearance>
    );
  }
  if (props.isLoading) {
    const LoadingAppearance = Styled.Loading;
    return (
      <LoadingAppearance
        {...props}
        type={props.type || 'button'}
        {...defaultProps}
      >
        <Styled.Loader>
          <div />
          <div />
          <div />
          <div />
        </Styled.Loader>
      </LoadingAppearance>
    );
  }
  const ButtonAppearance = Styled.Default;
  return (
    <ButtonAppearance
      {...props}
      type={props.type || 'button'}
      {...defaultProps}
    >
      {props.children ? props.children : 'Button'}{' '}
    </ButtonAppearance>
  );
};
export default Button;
