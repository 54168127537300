import * as React from 'react';

const alert = (props: any) => {
  const { colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd" stroke="#092B4A" strokeWidth="2">
        <path
          d="M49.876 9c-1.536 0-2.939.72-3.941 2.259l-42.032 72.8a4.522 4.522 0 000 4.567c.767 1.329 2.092 2.183 3.926 2.284h84.065a4.52 4.52 0 003.955-2.284c.768-1.33.846-2.904.014-4.543l-42.032-72.8a4.55 4.55 0 00-1.668-1.676A4.557 4.557 0 0049.876 9z"
          fill="#FFF"
          strokeLinejoin="bevel"
        />
        <path
          d="M46.398 74.597a3.482 3.482 0 013.48-3.477 3.482 3.482 0 013.477 3.477c0 1.919-1.56 3.48-3.478 3.48-1.92 0-3.479-1.561-3.479-3.48zM49.876 32.403c-.841 0-1.604.342-2.157.894a3.043 3.043 0 00-.895 2.158v26.783c0 .841.342 1.604.895 2.157a3.043 3.043 0 002.157.895c.842 0 1.605-.342 2.158-.895a3.043 3.043 0 00.894-2.157V35.455c0-.842-.341-1.605-.894-2.158a3.043 3.043 0 00-2.158-.894z"
          fill={colorSecondary}
        />
      </g>
    </>
  );
};
export default alert;
