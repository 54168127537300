import styled from 'styled-components';
import { colors } from '../../assets/variables';
const Button = styled.button`
  padding: 8px;
  min-width: 80px;
  border-radius: 8px;
  background: ${colors.baseLight};
  color: ${colors.primary};
  border: 1px solid ${colors.primary};
  margin-right: 8px;
  margin-bottom: 8px;

  cursor: pointer;
  &.active {
    background: ${colors.primary};
    color: ${colors.baseLight};
    border: 1px solid ${colors.primary};
  }
  &:hover {
    background: ${colors.primary};
    color: ${colors.baseLight};
    border: 1px solid ${colors.primary};
  }
`;

export default { Button };
