/* eslint-disable no-prototype-builtins */
import * as React from 'react';

import iconAdmin from './icons/admin';
import iconAlert from './icons/alert';
import iconArrow from './icons/arrow';
import iconAmbulance from './icons/ambulance';
import iconBadge from './icons/badge';
import iconBasket from './icons/basket';
import iconBathTub from './icons/bathTub';
import iconBed from './icons/bed';
import iconBoiler from './icons/boiler';
import iconBrokenLink from './icons/brokenLink';
import iconBulb from './icons/bulb';
import iconCar from './icons/car';
import iconCart from './icons/cart';
import iconChart from './icons/chart';
import iconCheckRounded from './icons/checkRounded';
import iconChevronLeft from './icons/chevronLeft';
import iconChevronRight from './icons/chevronRight';
import iconCalendar from './icons/calendar';
import iconChevronUp from './icons/chevronUp';
import iconChevronDown from './icons/chevronDown';
import iconClock from './icons/clock';
import iconCoins from './icons/coins';
import iconCoinPlus from './icons/coinPlus';
import iconCoinsWhite from './icons/coinsWhite';
import iconContract from './icons/contract';
import iconDebug from './icons/debug';
import iconDishwasher from './icons/dishwasher';
import iconDroplet from './icons/droplet';
import iconDrops from './icons/drops';
import iconDRotate from './icons/dRotate';
import iconElectricalOutlet from './icons/electricalOutlet';
import iconEmployees from './icons/employees';
import iconEnergy from './icons/energy';
import iconEngineer from './icons/engineer';
import iconEntrance from './icons/entrance';
import iconEnvironment from './icons/environment';
import iconExit from './icons/exit';
import iconExternalLink from './icons/externalLink';
import iconFacebook from './icons/facebook';
import iconFaceSad from './icons/faceSad';
import iconFaceSmiley from './icons/faceSmiley';
import iconFaucet from './icons/faucet';
import iconFireHose from './icons/fireHose';
import iconFlowerWater from './icons/flowerWater';
import iconGarbageDisposal from './icons/garbageDisposal';
import iconGift from './icons/gift';
import iconGlassWater from './icons/glassWater';
import iconGlobe from './icons/globe';
import iconHand from './icons/hand';
import iconHandshake from './icons/handshake';
import iconHeart from './icons/heart';
import iconHidden from './icons/hidden';
import iconHome from './icons/home';
import iconHomeTemperature from './icons/homeTemperature';
import iconHomeWater from './icons/homeWater';
import iconHotTub from './icons/hotTub';
import iconInstagram from './icons/instagram';
import iconIrrigation from './icons/irrigation';
import iconKettle from './icons/kettle';
import iconLeakingPipe from './icons/leakingPipe';
import iconLifebouy from './icons/lifebouy';
import iconMail from './icons/mail';
import iconMan from './icons/man';
import iconMan2 from './icons/man2';
import iconMap from './icons/map';
import iconMaximiseLoads from './icons/maximiseLoads';
import iconMedium from './icons/medium';
import iconMegaphone from './icons/megaphone';
import iconMenu from './icons/menu';
import iconMeter from './icons/meter';
import iconMinus from './icons/minus';
import iconMouse from './icons/mouse';
import iconNetwork from './icons/network';
import iconOffice from './icons/office';
import iconOfficeTemp from './icons/officeTemp';
import iconPayment from './icons/payment';
import iconPen from './icons/pen';
import iconPerson from './icons/person';
import iconPin from './icons/pin';
import iconPlay from './icons/play';
import iconPlus from './icons/plus';
import iconPond from './icons/pond';
import iconPound from './icons/pound';
import iconPreasureWasher from './icons/preasureWasher';
import iconProduct from './icons/product';
import iconRainWaterTank from './icons/rainWaterTank';
import iconRefresh from './icons/refresh';
import iconResults from './icons/results';
import iconReturn from './icons/returnIcon';
import iconScale from './icons/scale';
import iconSettings from './icons/settings';
import iconShaving from './icons/shaving';
import iconShower from './icons/shower';
import iconSink from './icons/sink';
import iconSinkhole from './icons/sinkhole';
import iconStar from './icons/star';
import iconStopwatch from './icons/stopwatch';
import iconSwimmingPool from './icons/swimmingPool';
import iconTap from './icons/tap';
import iconTBrushing from './icons/tBrushing';
import iconThumbUp from './icons/thumbUp';
import iconTimes from './icons/times';
import iconToilet from './icons/toilet';
import iconTranslate from './icons/translate';
import iconTurf from './icons/turf';
import iconTwitter from './icons/twitter';
import iconTypeCounter from './icons/typeCounter';
import iconTypeInput from './icons/typeInput';
import iconTypeMultiple from './icons/typeMultiple';
import iconTypeSlider from './icons/typeSlider';
import iconUrinal from './icons/urinal';
import iconUsaMap from './icons/usaMap';
import iconUserSwitch from './icons/userSwitch';
import iconVan from './icons/van';
import iconVisible from './icons/visible';
import iconWallet from './icons/wallet';
import iconWashing from './icons/washing';
import iconWashingmachine from './icons/washingmachine';
import iconWaterbutt from './icons/waterbutt';
import iconWaterCoins from './icons/waterCoins';
import iconWaterEfficient from './icons/waterEfficient';
import iconWatering from './icons/watering';
import iconWorker from './icons/worker';
import iconWrench from './icons/wrench';
import iconYoutube from './icons/youtube';
import iconCo2 from './icons/co2';
import iconDownload from './icons/download';
import iconDownloadApp from './icons/downloadApp';
import iconOnlineSafety from './icons/onlineSafety';
import iconVirtualPlumber from './icons/virtualPlumber';
import iconTree from './icons/tree';
import iconVideoChat from './icons/videoChat';
import iconVideoChat2 from './icons/videoChat2';
import iconBlockedPipe from './icons/blockedPipe';
import iconKnowledge from './icons/knowledge';
import iconSnow from './icons/snow';
import iconUseWhite from './icons/useWhite';
import iconChartDown from './icons/chartDown';
import iconChartEqual from './icons/chartEqual';
import iconLock from './icons/lock';
import iconUnlock from './icons/unlock';
import iconTrash from './icons/trash';
import iconCheck from './icons/check';
import iconBell from './icons/bell';
import iconFrance from './flags/fr-FR';
import iconUk from './flags/en-GB';
import iconAustralia from './flags/en-AU';
import iconRomania from './flags/ro-RO';
import iconWales from './flags/cy-GB';
import iconLatvia from './flags/lv-LV';
import iconDenmark from './flags/da-DK';
import iconLeaf from './icons/leaf';

export const allIcons = {
  admin: iconAdmin,
  alert: iconAlert,
  arrow: iconArrow,
  ambulance: iconAmbulance,
  badge: iconBadge,
  basket: iconBasket,
  bathTub: iconBathTub,
  bed: iconBed,
  boiler: iconBoiler,
  brokenLink: iconBrokenLink,
  bulb: iconBulb,
  car: iconCar,
  cart: iconCart,
  chart: iconChart,
  chartEqual: iconChartEqual,
  chartDown: iconChartDown,
  checkRounded: iconCheckRounded,
  calendar: iconCalendar,
  chevronLeft: iconChevronLeft,
  chevronRight: iconChevronRight,
  chevronUp: iconChevronUp,
  chevronDown: iconChevronDown,
  clock: iconClock,
  coins: iconCoins,
  coinPlus: iconCoinPlus,
  coinsWhite: iconCoinsWhite,
  contract: iconContract,
  debug: iconDebug,
  dishwasher: iconDishwasher,
  droplet: iconDroplet,
  drops: iconDrops,
  dRotate: iconDRotate,
  electricalOutlet: iconElectricalOutlet,
  employees: iconEmployees,
  energy: iconEnergy,
  engineer: iconEngineer,
  entrance: iconEntrance,
  environment: iconEnvironment,
  exit: iconExit,
  externalLink: iconExternalLink,
  facebook: iconFacebook,
  faceSad: iconFaceSad,
  faceSmiley: iconFaceSmiley,
  faucet: iconFaucet,
  fireHose: iconFireHose,
  flowerWater: iconFlowerWater,
  garbageDisposal: iconGarbageDisposal,
  gift: iconGift,
  glassWater: iconGlassWater,
  globe: iconGlobe,
  hand: iconHand,
  handshake: iconHandshake,
  heart: iconHeart,
  hidden: iconHidden,
  home: iconHome,
  homeTemperature: iconHomeTemperature,
  homeWater: iconHomeWater,
  hotTub: iconHotTub,
  instagram: iconInstagram,
  irrigation: iconIrrigation,
  kettle: iconKettle,
  leakingPipe: iconLeakingPipe,
  lifebouy: iconLifebouy,
  mail: iconMail,
  man: iconMan,
  man2: iconMan2,
  map: iconMap,
  maximiseLoads: iconMaximiseLoads,
  medium: iconMedium,
  megaphone: iconMegaphone,
  menu: iconMenu,
  meter: iconMeter,
  minus: iconMinus,
  mouse: iconMouse,
  network: iconNetwork,
  office: iconOffice,
  officeTemp: iconOfficeTemp,
  payment: iconPayment,
  pen: iconPen,
  person: iconPerson,
  pin: iconPin,
  play: iconPlay,
  plus: iconPlus,
  pond: iconPond,
  pound: iconPound,
  preasureWasher: iconPreasureWasher,
  product: iconProduct,
  rainWaterTank: iconRainWaterTank,
  refresh: iconRefresh,
  results: iconResults,
  return: iconReturn,
  scale: iconScale,
  settings: iconSettings,
  shaving: iconShaving,
  shower: iconShower,
  sink: iconSink,
  sinkhole: iconSinkhole,
  star: iconStar,
  stopwatch: iconStopwatch,
  swimmingPool: iconSwimmingPool,
  tap: iconTap,
  tBrushing: iconTBrushing,
  thumbUp: iconThumbUp,
  times: iconTimes,
  toilet: iconToilet,
  translate: iconTranslate,
  turf: iconTurf,
  twitter: iconTwitter,
  typeCounter: iconTypeCounter,
  typeInput: iconTypeInput,
  typeMultiple: iconTypeMultiple,
  typeSlider: iconTypeSlider,
  urinal: iconUrinal,
  usaMap: iconUsaMap,
  userSwitch: iconUserSwitch,
  van: iconVan,
  visible: iconVisible,
  wallet: iconWallet,
  washing: iconWashing,
  washingmachine: iconWashingmachine,
  waterbutt: iconWaterbutt,
  waterCoins: iconWaterCoins,
  waterEfficient: iconWaterEfficient,
  watering: iconWatering,
  worker: iconWorker,
  wrench: iconWrench,
  youtube: iconYoutube,
  co2: iconCo2,
  download: iconDownload,
  downloadApp: iconDownloadApp,
  onlineSafety: iconOnlineSafety,
  virtualPlumber: iconVirtualPlumber,
  tree: iconTree,
  videoChat: iconVideoChat,
  videoChat2: iconVideoChat2,
  blockedPipe: iconBlockedPipe,
  knowledge: iconKnowledge,
  snow: iconSnow,
  useWhite: iconUseWhite,
  trash: iconTrash,
  check: iconCheck,
  bell: iconBell,
  frFR: iconFrance,
  enGB: iconUk,
  enAU: iconAustralia,
  roRO: iconRomania,
  cyGB: iconWales,
  lvLV: iconLatvia,
  daDK: iconDenmark,
  lock: iconLock,
  unlock: iconUnlock,
  leaf: iconLeaf,
};

const IconRoot = (props) => {
  const { icon } = props;
  const iconExists = allIcons.hasOwnProperty(icon);
  if (iconExists) {
    const SpecificIcon = allIcons[icon];
    return <SpecificIcon {...props} />;
  }
  const FallbackIcon = allIcons.times;
  return <FallbackIcon {...props} />;
};

export default IconRoot;
