import * as React from 'react';

const waterEfficient = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M48.112 10c15.883 0 30.405 9.57 36.748 24.012l.19.439 6.117-5.47-3.943 21.13-11.982-17.845 7.81 2.612C77.038 21.123 63.227 12 48.112 12 27.097 12 10 29.097 10 50.112s17.096 38.112 38.11 38.112a1 1 0 110 2c-22.116 0-40.11-17.994-40.11-40.112C8 27.994 25.995 10 48.11 10zm.554 12.323l1.193 1.823c4.06 6.282 16.876 26.807 16.876 35.41 0 10.269-8.355 18.624-18.624 18.624-10.269 0-18.623-8.355-18.623-18.623 0-10.004 17.33-36.127 18.068-37.234.247-.371.862-.371 1.11 0zm-.555 1.587l-1.297 2.017c-4.567 7.18-15.99 25.908-15.99 33.63 0 9.532 7.754 17.288 17.287 17.288s17.29-7.756 17.29-17.288c0-8.631-14.27-31.01-17.29-35.647zM59.67 58.823c.358 0 .654.286.666.646l.001.264c-.014 1.107-.27 5.375-3.259 8.464-2.076 2.144-4.996 3.23-8.679 3.23a.667.667 0 110-1.334c3.308 0 5.904-.95 7.72-2.825 3-3.1 2.884-7.708 2.883-7.755a.666.666 0 01.547-.679l.121-.011z"
          fill={colorPrimary}
        />
        <path
          d="M48.111 76.845c-9.532 0-17.288-7.756-17.288-17.288 0-8.63 14.27-31.01 17.288-35.647 3.02 4.638 17.29 27.016 17.29 35.647 0 9.532-7.757 17.288-17.29 17.288"
          fill={colorSecondary}
        />
      </g>
    </>
  );
};
export default waterEfficient;
