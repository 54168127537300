import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import {
  Landing,
  Login,
  ResetPassword,
  PageOverview,
  PageEasyWins,
  PageConsumption,
  VideoLibrary,
  Preferences,
  Rewards,
  AddLocation,
  ContactUs,
  AddRoom,
  FreeProducts,
  ViewLocation,
  EditRoom,
  KitchenTap,
  BathroomTap,
  Urinals,
  Toilets,
  Locations,
  Shower,
  SingleToilet,
  Kettle,
  Page404,
  About,
  Marketplace,
  CompanyPage,
} from '../../pages';
import WebFont from 'webfontloader';
import { Header, Footer, DefaultLayout, BottomNav } from '../index';
import routes from '../../routes';
import * as _ from 'lodash';
import { useLocation, Link } from 'react-router-dom';
import PrivateRoute from '../PrivateRoute';
import { useResponsivity, utmCheck } from '../../helpers/helpers';
import { isLoggedIn } from '../../helpers/auth';

const theme = {
  flexboxgrid: {
    outerMargin: 1,
    container: {
      sm: 46,
      md: 61,
      lg: 74,
    },
  },
};
const App: React.FC = () => {
  const location = useLocation();

  const isMobile = useResponsivity();
  React.useEffect(() => {
    WebFont.load({
      google: {
        families: ['Open Sans:300,400,500,600,700'],
      },
    });
    utmCheck();
  }, []);

  const builderRoutes = () => {
    return _.map(routes, (route, path) => {
      if (!route.authRequired) {
        return <Route key={path} path={path} element={route.template} />;
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      {location.pathname === '/' ? null : <Header />}
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/about" element={<About />} />
        {builderRoutes()}
        <Route
          element={
            <PrivateRoute>
              <DefaultLayout />
            </PrivateRoute>
          }
        >
          <Route path={'/portal'} element={<PageOverview />} />
          <Route path={'/easy-wins'} element={<PageEasyWins />} />
          <Route path={'/easy-wins/kitchen-tap'} element={<KitchenTap />} />
          <Route path={'/easy-wins/bathroom-tap'} element={<BathroomTap />} />
          <Route path={'/easy-wins/bathroom-shower'} element={<Shower />} />
          <Route path={'/easy-wins/urinals'} element={<Urinals />} />
          <Route path={'/easy-wins/dual-flush-toilets'} element={<Toilets />} />
          <Route path={'/easy-wins/toilets'} element={<Toilets />} />
          <Route path={'/easy-wins/kettle'} element={<Kettle />} />
          <Route
            path={'/easy-wins/single-flush-toilets'}
            element={<SingleToilet />}
          />
          <Route path={'/use'} element={<PageConsumption />} />
          <Route path={'/profile'} element={<Preferences />} />
          <Route path={'/video-library'} element={<VideoLibrary />} />
          <Route path={'/offers'} element={<Rewards />} />
          <Route path={'/:locationId/add-room'} element={<AddRoom />} />
          <Route
            path={'/:locationId/edit-room/:roomId'}
            element={<EditRoom />}
          />
          <Route path={'/locations'} element={<Locations />} />
          <Route path={'/locations/:locationId'} element={<ViewLocation />} />
          <Route path={'/add-location'} element={<AddLocation />} />
          <Route path={'/add-location'} element={<AddLocation />} />
          <Route path={'/ask-me'} element={<ContactUs />} />
          <Route path={'/free-products'} element={<FreeProducts />} />
          <Route path="/*" element={<Page404 />} />
        </Route>
      </Routes>
      {isMobile && isLoggedIn() ? <BottomNav /> : null}

      <Footer />
    </ThemeProvider>
  );
};

export default App;
