import styled from 'styled-components';
import { colors } from '../../assets/variables';

const Wrap = styled.div`
  background: white;
  border: 4px solid #0b2641;
  border-radius: 10px;
  padding: 16px;
  min-height: 300px;
  div.slider {
    border-bottom: 1px solid #e6e6e6;
  }
`;
const IconWrap = styled.div`
  display: flex;
  min-width: 150px;
  justify-content: flex-end;
  @media only screen and (max-width: 600px) {
    width: 100px;
  }
`;
const IconItem = styled.div`
  min-width: 50px;
  display: flex;
  justify-content: flex-end;
`;
const Item = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
`;
const TotalWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
  /* border-bottom: 1px solid #e6e6e6; */
  h5 {
    flex: 1;
    margin-bottom: 0px;
  }
`;
const ItemWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: 350px;
  div {
    justify-content: flex-end;
    display: flex;
  }
  @media only screen and (max-width: 600px) {
    width: 100px;
  }
`;
const Disclaimer = styled.div`
  display: flex;
  padding: 8px 0;
`;
export default {
  Wrap,
  IconWrap,
  IconItem,
  TotalWrap,
  Item,
  ItemWrap,
  Disclaimer,
};
