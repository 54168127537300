import { ContactFormReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

const initialState: ContactFormReducer = {
  status: {
    loading: false,
    error: false,
    done: false,
  },
  data: null,
};

const contactFormReducer = createReducer(initialState, {
  SET_CONTACT_FORM_PENDING: (state) => {
    state.status = {
      loading: true,
      error: false,
      done: false,
    };
  },

  SET_CONTACT_FORM_FULFILLED: (state, action) => {
    state.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.data = action.payload.data;
  },

  SET_CONTACT_FORM_REJECTED: (state, action) => {
    state.status = {
      loading: false,
      error: action.payload.data.error,
      done: false,
    };
    state.data = action.payload.data;
  },
});

export default contactFormReducer;
