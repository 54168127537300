import * as React from 'react';

const externalLink = (props: any) => {
  const { colorPrimary } = props;

  return (
    <>
      <path
        d="M46.229 13a1 1 0 01.116 1.993L46.23 15h-31.23v70.419h70.42V45.713a1 1 0 011.993-.117l.007.117v40.706a1 1 0 01-.883.993l-.117.007h-72.42a1 1 0 01-.992-.883L13 86.419v-72.42a1 1 0 01.883-.992L14 13h32.229zm35.858 3.167l-8.321 19.82-2.114-7.97-39.103 39.101a.997.997 0 01-1.414 0 .999.999 0 01-.083-1.32l.083-.094 39.103-39.102-7.97-2.113 19.819-8.322z"
        fill={colorPrimary}
        fillRule="evenodd"
      />
    </>
  );
};
export default externalLink;
