import { AppState } from '../../interfaces';
import { connect } from 'react-redux';
import PagePreferences from './Preferences';
import { setPreferences, deleteAccount } from '../../actions/preferences';
import { logOut } from '../../actions/index';

const mapStateToProps = (state: AppState) => ({
  page: state.preferencesReducer,
  deleteAccount: state.deleteAccountReducer,
});

const mapDispatchToProps = (dispatch) => ({
  setPreferences: (data, callBack) => dispatch(setPreferences(data, callBack)),
  deleteAccount: () => dispatch(deleteAccount()),

  logOut: () => dispatch(logOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PagePreferences);
