import axios from 'axios';
import { getMicroServiceAuthUrl } from '../helpers/helpers';

export const resetPasswordRequest = (email) => {
  return {
    type: 'RESET_PASSWORD',
    payload: axios.post(`${getMicroServiceAuthUrl('business-user')}reset`, {
      email,
    }),
  };
};

export const resetPasswordRequestRepeat = () => {
  return {
    type: 'RESET_PASSWORD_REPEAT',
  };
};

export const resetPasswordUpdate = (password, token) => {
  return (dispatch) => {
    const response = dispatch({
      type: 'RESET_PASSWORD_UPDATE',
      payload: axios.post(
        `${getMicroServiceAuthUrl('business-user')}reset/password`,
        {
          password,
          token,
        }
      ),
    });

    response
      .then((res) => {
        // history.push('/login');
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
