import styled from 'styled-components';
import { colors } from '../../../assets/variables';

const Wrap = styled.div`
  & > .paginationBttns {
    display: flex;
    width: auto;
    justify-content: flex-end;
    border-radius: 9999px;
    padding-inline-start: 0px;
    background-color: #e9eef5;
    color: #475569;
  }

  li {
    width: 2rem;
    height: 2rem;
    font-size: 14px;
    border-radius: 9999px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    & > a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
    }
    &:hover {
      background-color: #cbd5e1;
      transition-duration: 0.15s;
      transition-property: color, background-color, border-color,
        text-decoration-color, fill, stroke;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
    &.paginationDisabled {
      cursor: not-allowed;
      background-color: #e9eef5;
      color: #bfbebe;
    }
    &.paginationActive {
      color: white;
      background-color: ${colors.green};
      &:hover {
        background-color: ${colors.green};
      }
    }
  }
`;

const Credential = styled.div`
  border-radius: 10px;
  border: 4px solid ${colors.lightGreen};
  padding: 16px;
  background: ${colors.lightGreen};
  transition: all 0.3s cubic-bezier(0.25, 0.4, 0.45, 1.4);
  cursor: pointer;
  &:hover {
    scale: 1.1;
  }
  p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6; /* number of lines to show */
    line-clamp: 6;
    -webkit-box-orient: vertical;
  }
`;

const TitleWrap = styled.div`
  display: flex;

  h5 {
    flex: 1;
    min-height: 50px;
    padding-right: 16px;
  }
`;
const Description = styled.p`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 6;
  -webkit-box-orient: vertical;
`;
export default {
  Credential,
  TitleWrap,
  Wrap,
  Description,
};
