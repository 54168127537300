import styled, { css } from 'styled-components';
import { colors } from '../../assets/variables';
const Wrap = styled.div`
  position: relative;
  margin-bottom: 16px;
  ${(props) => {
    if (props.label) {
      return css`
        ${IconWrap} {
          top: 33px;
        }
      `;
    }
  }}
`;
const Input = styled.input`
  display: block;
  font-size: 16px;
  color: #4a4a4a;
  padding: 0.65em;
  border: 0 solid transparent;
  width: 100%;
  background-color: #fff;
  font-weight: 400;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'auto')};
  border: 1px solid black;
  line-height: inherit;
  min-height: 50px;
  letter-spacing: 1px;
  border-radius: 5px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ${(props) => {
    if (props.label) {
      return css`
        margin-top: 6px;
      `;
    }
  }};
`;
const Label = styled.label`
  font-size: 14px;
`;

const IconWrap = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  ${(props) => {
    if (props.onClick) {
      return css`
        cursor: pointer;
      `;
    }
  }}
`;

const FieldError = styled.div`
  font-size: 0.8em;
  color: ${colors.error};
`;

export default {
  Wrap,
  Input,
  IconWrap,
  FieldError,
  Label,
};
