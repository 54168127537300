import React from 'react';
import * as _ from 'lodash';
import { Wrap } from '../../components/index';
import Styled from './ResetPassword.style';
import { Row, Col } from 'react-styled-flexboxgrid';

import queryString from 'query-string';
import ResetPasswordRequest from './ResetPasswordRequest';
import ResetPasswordUpdate from './ResetPasswordUpdate';
import { useLocation } from 'react-router-dom';
interface ResetPasswordProps {
  resetPassword: any;
  resetPasswordUpdate: Function;
  resetPasswordRequest: Function;
  resetPasswordRequestRepeat: Function;
}

export const ResetPassword: React.FC<ResetPasswordProps> = (props) => {
  const {
    resetPasswordRequest,
    resetPasswordUpdate,
    resetPasswordRequestRepeat,
    resetPassword,
  } = props;
  const location = useLocation();
  const getString = location.search;
  const urlParams = queryString.parse(getString);
  const resetToken = urlParams.token;
  const { resetRequest, resetUpdate } = resetPassword;

  return (
    <Wrap>
      <Row style={{ marginTop: '128px' }}>
        <Col md={12} sm={12} xs={12}>
          <Styled.Card style={{ height: 'auto', marginBottom: 100 }}>
            {resetToken ? (
              <ResetPasswordUpdate
                resetUpdate={resetUpdate}
                resetPasswordUpdate={resetPasswordUpdate}
                resetToken={resetToken}
              />
            ) : (
              <ResetPasswordRequest
                resetRequest={resetRequest}
                resetPasswordRequestRepeat={resetPasswordRequestRepeat}
                resetPasswordRequest={resetPasswordRequest}
              />
            )}
          </Styled.Card>
        </Col>
      </Row>
    </Wrap>
  );
};

export default ResetPassword;
