import styled, { css } from 'styled-components';
import { colors } from '../../assets/variables';

const Wrap = styled.div`
  display: flex;
  font-family: 'Open Sans', sans-serif;
  flex-direction: column;
  color: ${colors.primary};
  background-color: #d7f1ff;
  margin-bottom: 16px;
  border-radius: 10px;
  overflow: hidden;
  min-height: 187px;
  width: 100%;
  ${(props) => {
    if (props.isDesktop) {
      return deskTopStyles;
    }
  }}
  ${(props) => {
    switch (props.theme) {
      case 'darkTheme':
        return css`
          color: ${colors.baseLight};
          background-color: ${colors.primary};
          ${Content} {
            padding: 2em;
          }
          ${Description} {
            min-height: 108px;
          }
          ${Button} {
            color: white;
          }
        `;
      case 'greenTheme':
        return css`
          color: ${colors.primary};
          background-color: ${colors.lightGreen};
          ${Content} {
            padding: 2em;
          }
          ${Description} {
            min-height: 108px;
          }
          ${Button} {
            color: ${colors.primary};
            text-decoration-color: ${colors.green};
          }
        `;
      default:
        return css`
          color: ${colors.primary};
          background-color: #d7f1ff;
        `;
    }
  }};
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em;
  flex-basis: 50%;
  font-family: 'Open Sans', sans-serif;
`;

const Title = styled.h4`
  font-size: 26px;
  font-weight: 600;
  margin: 0 0 10px;
  padding: 5px 0;
  font-family: 'Open Sans', sans-serif;
`;
const ImgWrap = styled.div`
  display: flex;
  flex: 1 0 auto;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position-x: ${(props) => props.bgPosition};
  margin: 0;
  height: 200px;
`;

const Description = styled.p`
  font-size: 18px;
  margin: 0 0 10px;
  min-height: 108px;
  line-height: 1.5;
  font-family: 'Open Sans', sans-serif;
`;
const Disclaimer = styled.p`
  font-size: 15px;
  line-height: 1.5;
  margin-top: 16px;
  margin-bottom: 0px;
  font-family: 'Open Sans', sans-serif;
`;

const Button = styled.button`
  text-decoration: underline;
  text-underline-offset: 8px;
  text-decoration-color: ${colors.action};
  text-decoration-thickness: 2px;
  border: 0;
  background: transparent;
  min-height: 35px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  margin-top: 10px;
  text-transform: none;
  ${(props) => {
    if (props.disabled) {
      return css`
        text-decoration-color: ${colors.darkGray};
      `;
    }
  }}
`;

const deskTopStyles = css`
  flex-direction: row;
  ${Title} {
    font-size: 26px;
    min-height: 62px;
    margin: 0 0 10px;
  }
  ${ImgWrap} {
    height: auto;
    margin: 0;
  }
  ${Content} {
    flex-basis: 60%;
    padding: 4em 2em;
  }
  ${Description} {
    font-size: 18px;
    min-height: 108px;
  }
`;

export default {
  Wrap,
  Title,
  Content,
  Description,
  Disclaimer,
  Button,
  ImgWrap,
};
