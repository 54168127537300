import * as React from 'react';

const chevronUp = (props: any) => {
  const { colorPrimary } = props;

  return (
    <>
      <path
        d="M24.03,75.9692391 L24.03,20.226413 C24.03,18.1248913 22.3271739,16.4220652 20.2256522,16.4220652 C18.1241304,16.4220652 16.4213043,18.1248913 16.4213043,20.226413 L16.4213043,75.9692391 L16.4213043,83.5779348 L24.03,83.5779348 L79.7743478,83.5779348 C81.8758696,83.5779348 83.5786957,81.8751087 83.5786957,79.773587 C83.5786957,77.6720652 81.8758696,75.9692391 79.7743478,75.9692391 L24.03,75.9692391 Z"
        id="Fill-1"
        fill={colorPrimary}
        transform="translate(50.000000, 50.000000) rotate(135.000000) translate(-50.000000, -50.000000) "
      />
    </>
  );
};
export default chevronUp;
