import React from 'react';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import { Link } from 'react-router-dom';
import Styled from './Header.style';
import { Navigation } from '../index';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
interface HeaderProps {
  rewards: any;
}

export const Header: React.FC<HeaderProps> = (props) => {
  const { rewards } = props;
  const location = useLocation();

  const getUrl = _.get(rewards, 'rewards.data.[0].user.business.logoURL');

  const url = getUrl
    ? getUrl
    : 'https://cdn.getwaterfit.co.uk/static/files/logos/www-color.svg';

  return (
    <Grid>
      <Row>
        <Col md={12} sm={12} xs={12}>
          <Styled.Header>
            <Link to="/">
              <img src={url} alt="GetWaterFit" style={{ maxHeight: '70px' }} />
            </Link>
            <Navigation />
          </Styled.Header>
        </Col>
      </Row>
    </Grid>
  );
};

export default Header;
