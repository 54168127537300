import * as React from 'react';

const megaphone = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M67.427 7.036c.388.097.701.38.84.75l.04.126 14.146 55.821a1.206 1.206 0 01-.875 1.47c-.183.046-.377.05-.566.006l-.14-.042-23.38-8.4-23.798 1.695 8.214 32.416a1.207 1.207 0 01-.756 1.433l-.118.036-8.921 2.26a1.208 1.208 0 01-1.433-.756l-.036-.119L22.37 61.09l-3.825.97a1.21 1.21 0 01-1.433-.757l-.036-.118-1.113-4.392-4.33 1.098a1.211 1.211 0 01-1.433-.757l-.036-.118-2.128-8.397a1.21 1.21 0 01.756-1.433l.119-.037 4.33-1.096-1.256-4.96a1.208 1.208 0 01.756-1.433l.119-.037 13.816-3.502 22.952-10.396L66.238 7.397a1.21 1.21 0 011.189-.36zM38.076 85.6l-6.577 1.667 1.192 4.7 6.577-1.667-1.192-4.7zm-2.738-10.805l-6.577 1.667 2.144 8.461 6.578-1.667-2.145-8.46zm-4.046-15.967l-6.577 1.667 3.452 13.623 6.577-1.667-3.452-13.623zM66.516 10.69L51.689 27.052l6.987 27.571 20.875 7.501L66.516 10.69zM26.199 38.736L14.626 41.67l4.498 17.748 11.573-2.933-4.498-17.748zm23.34-10.319L28.496 37.95l4.595 18.131 23.045-1.64-6.595-26.024zM13.837 48.395l-3.157.8 1.533 6.053 3.158-.8-1.534-6.053zm73.666-10.659a1 1 0 110 2h-5.37a1 1 0 110-2h5.37zm3.403-18.594a1 1 0 01-.251 1.392L80.12 27.84a.998.998 0 11-1.14-1.642l10.532-7.307a1 1 0 011.392.25z"
          fill={colorPrimary}
        />
        <path
          d="M35.337 74.795l2.145 8.461-6.577 1.667-2.145-8.461 6.577-1.667zM49.54 28.417l6.595 26.023-23.045 1.641-4.595-18.13 21.045-9.534z"
          fill={colorSecondary}
        />
      </g>
    </>
  );
};
export default megaphone;
