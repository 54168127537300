import ResetPassword from './ResetPassword';
import { connect } from 'react-redux';
import {
  resetPasswordRequest,
  resetPasswordUpdate,
  resetPasswordRequestRepeat,
} from '../../actions/resetPassword';
import { AppState } from '../../interfaces';

const mapStateToProps = (state: AppState) => ({
  resetPassword: state.resetPasswordReducer,
});

const mapDispatchToProps = (dispatch) => ({
  resetPasswordRequestRepeat: () => dispatch(resetPasswordRequestRepeat()),
  resetPasswordRequest: (email) => dispatch(resetPasswordRequest(email)),
  resetPasswordUpdate: (password, token) =>
    dispatch(resetPasswordUpdate(password, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
