import React from 'react';
import Styled from './ErrorDisplay.style';
import { Icon, Typography } from '../index';
import { useTranslation } from 'react-i18next';

interface ErrorDisplayProps {
  title?: string;
  description?: string;
  style?: any;
}

const ErrorDisplay: React.FC<ErrorDisplayProps> = (props) => {
  const { title, description } = props;
  const [t] = useTranslation('common');

  const text_keys = {
    working_on_it: t('portal.error_messages.working_on_it'),
    unavailable: t('portal.error_messages.unavailable'),
  };

  return (
    <Styled.Wrap>
      <Icon icon="alert" style={{ marginBottom: '24px' }} size={100} />
      {title ? (
        <Typography size="3" text={title} />
      ) : (
        <Typography size="3" text={text_keys.working_on_it} />
      )}
      {description ? (
        <Typography size="p" style={{ marginTop: '20px' }} text={description} />
      ) : (
        <Typography size="p" text={text_keys.unavailable} />
      )}
    </Styled.Wrap>
  );
};

export default ErrorDisplay;
