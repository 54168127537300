import * as React from 'react';

const payment = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M86.573 40.496a5.758 5.758 0 015.746 5.53l.004.22v30.439a5.758 5.758 0 01-5.53 5.746l-.22.004H27.054c-.134 0-.262-.004-.392-.013l-.198-.016h-.02c-2.805-.302-5.02-2.634-5.135-5.489l-.005-.232v-30.44a5.758 5.758 0 015.53-5.745l.22-.004h59.52zm-2.529 23.689h-14.54c-.578 0-1.061.445-1.123 1.008l-.007.123v10.26c0 .579.445 1.061 1.008 1.123l.122.007h14.54c.58 0 1.053-.444 1.114-1.008l.006-.122v-10.26a1.13 1.13 0 00-.997-1.124l-.123-.007zm-27.23-11.12h-27.51c-.63 0-1.13.51-1.13 1.13 0 .58.436 1.054 1.006 1.114l.124.007h27.51c.62 0 1.12-.5 1.12-1.12 0-.579-.435-1.062-.998-1.123l-.123-.007zm6.7-5.7h-34.21c-.63 0-1.13.5-1.13 1.13 0 .58.436 1.054 1.006 1.114l.124.007h34.21c.62 0 1.12-.5 1.12-1.12 0-.588-.435-1.063-.997-1.124l-.123-.006z"
          fill={colorSecondary}
        />
        <path
          d="M74.13 20.393l.114.232 8.18 17.621h4.15c4.326 0 7.864 3.455 7.996 7.752l.004.248v30.44c0 4.326-3.456 7.864-7.752 7.995l-.248.004h-59.52c-.21 0-.42-.01-.62-.029-.2.02-.4.03-.6.03a7.89 7.89 0 01-2.75-.49 7.912 7.912 0 01-4.377-3.867l-.133-.274-12.83-27.609c-1.823-3.921-.177-8.584 3.658-10.517l.233-.113 53.979-25.07c3.843-1.788 8.398-.252 10.398 3.42l.119.227zm12.444 20.103h-59.52a5.758 5.758 0 00-5.746 5.53l-.004.22v30.44c0 2.881 2.142 5.28 4.914 5.691l.226.03.315.02.295.008h59.52a5.758 5.758 0 005.745-5.53l.005-.22V46.246c0-3.17-2.58-5.75-5.75-5.75zm-2.53 23.69c.579 0 1.053.444 1.113 1.007l.007.123v10.26c0 .578-.436 1.061-.998 1.123l-.122.007h-14.54a1.136 1.136 0 01-1.123-1.008l-.007-.122v-10.26c0-.579.444-1.062 1.008-1.124l.122-.006h14.54zm-17.06-45.94c-.727 0-1.444.137-2.13.407l-.29.123-53.98 25.08a5.769 5.769 0 00-2.893 7.43l.093.21 11.27 24.25v-29.5c0-4.327 3.455-7.864 7.75-7.996l.25-.004h52.89l-7.74-16.67a5.717 5.717 0 00-3.24-2.98 5.713 5.713 0 00-1.98-.35zm15.94 48.189h-12.3v8.02h12.3v-8.02zm-26.11-13.369c.62 0 1.12.51 1.12 1.13 0 .579-.436 1.053-.998 1.114l-.122.006h-27.51c-.63 0-1.13-.5-1.13-1.12 0-.579.435-1.061 1.006-1.123l.124-.007h27.51zm6.7-5.7c.62 0 1.12.5 1.12 1.13 0 .579-.436 1.053-.998 1.113l-.122.007h-34.21c-.63 0-1.13-.5-1.13-1.12 0-.588.436-1.063 1.006-1.124l.124-.006h34.21z"
          fill={colorPrimary}
        />
      </g>
    </>
  );
};
export default payment;
