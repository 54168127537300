import * as React from 'react';

const instagram = (props: any) => {
  undefined;

  return (
    <>
      <path
        d="M85.352 0c7.972 0 14.477 6.402 14.645 14.335l.003.313v70.704c0 7.972-6.402 14.477-14.335 14.645l-.313.003H14.648C6.676 100 .171 93.598.003 85.665L0 85.352V14.648C0 6.676 6.402.171 14.335.003L14.648 0h70.704zm0 5.86H14.648c-4.76 0-8.648 3.803-8.785 8.53l-.004.258v70.704c0 4.76 3.804 8.648 8.53 8.785l.26.004h70.703c4.76 0 8.648-3.804 8.785-8.53l.004-.26V14.649c0-4.846-3.943-8.789-8.79-8.789zM49.5 23C64.112 23 76 34.888 76 49.5S64.112 76 49.5 76 23 64.112 23 49.5 34.888 23 49.5 23zm0 5.889c-11.365 0-20.611 9.246-20.611 20.611S38.135 70.111 49.5 70.111 70.111 60.865 70.111 49.5 60.865 28.889 49.5 28.889zM80 11c4.962 0 9 4.037 9 9s-4.038 9-9 9-9-4.037-9-9 4.038-9 9-9zm0 6c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3z"
        fill="#D7F1FF"
        fillRule="evenodd"
      />
    </>
  );
};
export default instagram;
