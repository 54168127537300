import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import * as _ from 'lodash';
import { Typography, Wrap, Loader, ErrorDisplay } from '../../components/index';
import Styled from './Rewards.style';
import { useTranslation } from 'react-i18next';
interface RewardsProps {
  rewards: any;
  optIn: Function;
  optOut: Function;
  getAvailableRewards: Function;
}
const Rewards: React.FC<RewardsProps> = (props) => {
  const { rewards, optIn, optOut, getAvailableRewards } = props;
  const [isSubmit, setSubmit] = React.useState('');
  const data = _.get(rewards, 'rewards.data');
  const [t] = useTranslation('common');

  const { loading, error } = rewards.rewards.status;
  const noOffers = t('portal.rewards_page.no_offer');
  const thankYou = t('portal.rewards_page.thank_you');
  const acceptOffer = t('portal.rewards_page.accept');
  const rejectOffer = t('portal.rewards_page.reject');
  const title = 'Offers';
  const description = t('portal.rewards_page.description');
  const optInDone = _.get(rewards, 'optIn.status.done');
  const optOutDone = _.get(rewards, 'optOut.status.done');

  React.useEffect(() => {
    getAvailableRewards();
  }, [getAvailableRewards]);
  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <ErrorDisplay />;
  }

  if (!data) {
    return (
      <Styled.SubmitWrap>
        <Styled.Title>{noOffers}</Styled.Title>
      </Styled.SubmitWrap>
    );
  }

  const renderRewardsBanner = () => {
    return _.map(data, (el, index) => {
      if (optInDone && el.id === isSubmit) {
        return (
          <Styled.SubmitWrap key={index}>
            <Styled.Title>{thankYou}</Styled.Title>
            <Styled.Description>{acceptOffer}</Styled.Description>
          </Styled.SubmitWrap>
        );
      } else if (optOutDone && el.id === isSubmit) {
        return (
          <Styled.SubmitWrap key={index}>
            <Styled.Title>{thankYou}</Styled.Title>
            <Styled.Description>{rejectOffer}</Styled.Description>
          </Styled.SubmitWrap>
        );
      } else if (el.optedIn === null && el.banner !== null) {
        return (
          <Col md={12} sm={12} xs={12} key={index}>
            <Styled.BannerWrap>
              <Styled.ImageWrap
                style={{ backgroundImage: `url('${el.banner.imageURL}')` }}
              />
              <Styled.TextWrap>
                <Styled.Title>{el.banner.title}</Styled.Title>
                <Styled.Description>{el.banner.description}</Styled.Description>
                <Styled.ButtonWrap>
                  <Styled.OptButton
                    onClick={() => {
                      optIn(el.id);
                      setTimeout(() => getAvailableRewards(), 3000);
                      setSubmit(el.id);
                    }}
                  >
                    Yes please
                  </Styled.OptButton>
                  <Styled.OptButton
                    onClick={() => {
                      optOut(el.id);
                      setTimeout(() => getAvailableRewards(), 3000);
                      setSubmit(el.id);
                    }}
                  >
                    No thanks
                  </Styled.OptButton>
                </Styled.ButtonWrap>
                <Styled.Disclaimer>
                  <i>
                    I agree for my email address to be passed to the
                    organisation promoting this offer in order to process my
                    request to redeem this offer.
                  </i>
                </Styled.Disclaimer>
              </Styled.TextWrap>
            </Styled.BannerWrap>
          </Col>
        );
      }
    });
  };
  const renderBann = () => {
    if (
      data.every((v) => v.optedIn === true) ||
      data.every((v) => v.optedIn === false)
    ) {
      return (
        <Col md={12} sm={12} xs={12} style={{ marginBottom: '32px' }}>
          <Styled.SubmitWrap>
            <h3>{noOffers}</h3>
          </Styled.SubmitWrap>
        </Col>
      );
    }
    return renderRewardsBanner();
  };
  return (
    <Wrap>
      <Row>
        <Col md={12} sm={12} xs={12}>
          <Typography
            size="1"
            weight={700}
            text={title}
            style={{ marginBottom: '16px', fontSize: '40px', color: '#0b2641' }}
          />
        </Col>
        <Col md={12} sm={12} xs={12}>
          <Typography
            size="p"
            style={{
              fontSize: '18px',
              marginBottom: '32px',
              fontWeight: 700,
              maxWidth: '760px',
            }}
            text={description}
          />
        </Col>
      </Row>

      {renderBann()}
    </Wrap>
  );
};
export default Rewards;
