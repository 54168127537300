import styled from 'styled-components';
import { colors } from '../../assets/variables';

const Box = styled.div`
  width: 100%;
  line-height: 1.4em;
  border-radius: 10px;
  border: 4px solid #d7f1ff;
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  flex-direction: column;
  height: auto;
  padding: 20px 20px;
  margin-bottom: 16px;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  button {
    margin: 0 8px;
    min-width: 120px;
    &.delete-yes {
      background-color: ${colors.action};
      color: ${colors.primary};
      border: 1px solid ${colors.action};

      &:hover {
        background-color: ${colors.baseLight};
        color: ${colors.primary};
        border: 1px solid ${colors.primary};
      }
    }
  }
`;
const PaginationWrap = styled.div`
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  display: flex;
  justify-content: flex-end;
`;
const MaskItems = styled.div`
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' fill='none'%3E%3Cpath fill='%23000' d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0z'/%3E%3C/svg%3E");
  color: #475569;
  background-color: #e2e8f0;
  height: 32px;
  width: 32px;
  mask-position: center;
  font-weight: 600;
  mask-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  mask-size: contain;
  &.delete {
    color: #ce5555;
    background-color: #f2b2b2;
  }
  &.edit {
    color: #63ce55;
    background-color: #b2f2bb;
  }
  &.view {
    color: #8e55ce;
    background-color: #d5b2f2;
  }
`;

const TableWrap = styled.div`
  background: white;
  border-radius: 8px;
  padding: 16px 0px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(48, 46, 56, 0.06) 0px 3px 10px 0px;
  .text-right {
    justify-content: right;
  }
`;

const Table = styled.table`
  tbody {
    tr {
      border-bottom: 1px solid rgb(226, 232, 240);
      td {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        padding-bottom: 0.75rem;
        padding-top: 0.75rem;

        .table-name {
          display: flex;
          align-items: center;
          p {
            margin-left: 8px;
            font-size: 14px;
            margin-top: 0px;
            margin-bottom: 0px;
            color: #94a3b8;
            span {
              font-size: 16px;
              font-weight: 600;
              color: ${colors.primary};
            }
          }
        }
      }
    }
  }
`;
const Popup = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(11, 38, 65, 0.16);
  border-radius: 10px;
  position: fixed;
  transform: scale(0);
  z-index: 99999;
  padding: 16px;
  margin: 16px;
  border-radius: 10px;
  width: 100%;
  &.open {
    position: relative;
    transition: transform 0.3s;
    transition-timing-function: ease-in-out;
    transform: scale(1);
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  @media screen and (max-height: 414px) {
    overflow-y: scroll;
    height: auto;
  }
`;
const PopupBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0);
  &.open {
    transform: scale(1);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(37, 179, 235, 0.6);
    right: 0;
    z-index: 99998;
  }
`;

const CloseWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 30px;
`;
const Close = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
`;
export default {
  Box,
  Table,
  TableWrap,
  MaskItems,
  ButtonWrap,
  PopupBackground,
  Popup,
  CloseWrap,
  Close,
  Container,
  PaginationWrap,
};
