import React from 'react';
import { Wrap, Task, EasyWin, Loader } from '../../components/index';
import * as _ from 'lodash';
import { useResponsivity } from '../../helpers/helpers';
import { Row, Col } from 'react-styled-flexboxgrid';
import { useTranslation } from 'react-i18next';
import store from 'store2';
interface UrinalsProps {
  challenges: any;
  listLocation: any;
  getChallengeCategory: Function;
  completeTask: any;
  completeChallenge: Function;
  generalData: Function;
}

const Urinals: React.FC<UrinalsProps> = (props) => {
  const {
    challenges,
    listLocation,
    getChallengeCategory,
    completeTask,
    completeChallenge,
    generalData,
  } = props;
  const getRoomId = store('property');
  const { error, loading, done } = _.get(completeTask, 'status');
  const loadingChallenges = _.get(challenges, 'status.loading');
  const data = _.get(challenges, 'data.challenges');
  const isMobile = useResponsivity();
  const [t] = useTranslation('common');

  React.useEffect(() => {
    if (getRoomId) {
      getChallengeCategory(getRoomId, 'bathroom-urinal');
    }
  }, [getRoomId]);

  const pageArray = [
    {
      id: 267,
      title: 'Urinals',
      poster: '',
      emailCopy:
        'Remember not to drop anything into the urinals which might cause a blockage.',
      easyWins: [
        {
          title: 'Introduction',
          theme: 'intro',
          size: 'full',
          text: 'Fitting a flush control to urinals significantly reduces water use, in particular during evenings and at weekends.',
        },
        {
          title: 'Easy Win Pro',
          theme: 'blue',
          size: 'half',
          icon: 'worker',
          text: 'Fit a control to the urinal cistern to ensure urinals flush at the correct frequency and only during working hours. A control avoids overnight and weekend flushing when staff are at home. Urinals without controls can waste as much as 200,000 litres every year.#Flush controls come in various forms – automatic, sensor, waterless – your plumber will recommend which is most suitable and will adjust the frequency and timings to minimise wasted water.',
        },
        {
          title: 'Easy Win Pro',
          theme: 'blue',
          size: 'half',
          icon: 'worker',
          text: 'Urinals with a strong leak could be wasting as much as 150,000 litres every year per cistern. Even small leaks waste water – and if unfixed, can turn into much stronger leaks over time.',
        },
      ],
    },
  ];

  const getChallenges = () => {
    return _.map(data, (el, index) => {
      const title = _.get(el, 'title');
      const description = _.get(el, 'description');
      const buttonText = _.get(el, 'buttonText');
      const type = _.get(el, 'internalType');
      const buttonPath = _.get(el, 'buttonPath');
      const energySaving = _.get(el, 'energy_saving');
      const carbonSaving = _.get(el, 'carbon_saving');
      const waterSaving = _.get(el, 'water_saving');
      return (
        <Col xs={12} sm={12} md={6} key={index}>
          <Task
            titleWidth="230px"
            isDesktop={!isMobile}
            icon={_.get(el, 'icon')}
            id={_.get(el, 'id')}
            title={`${t(title)}`}
            description={`${t(description)}`}
            buttonText={`${t(buttonText)}`}
            buttonPath={buttonPath}
            popup={type === 'direct-redirect' ? false : true}
            renderComplete={() => {
              completeChallenge(getRoomId, _.get(el, 'id'));
              setTimeout(() => {
                getChallengeCategory(getRoomId, 'bathroom-urinal');
                generalData();
              }, 1000);
            }}
            isLoading={loading}
            completed={done}
            energySaving={energySaving}
            carbonSaving={carbonSaving}
            waterSaving={waterSaving}
          />
        </Col>
      );
    });
  };
  if (loadingChallenges) {
    return (
      <Wrap>
        <Loader />
      </Wrap>
    );
  }
  return (
    <Wrap>
      <EasyWin data={pageArray}>{getChallenges()}</EasyWin>
    </Wrap>
  );
};

export default Urinals;
