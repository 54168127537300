import * as React from 'react';

const chartDown = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(2.000000, 21.215000)"
        fill={colorPrimary}
        fillRule="nonzero"
      >
        <path d="M0.87,7.41 L1.41,8.04 L42.21,54.99 C43.59,56.58 45.66,57.57 47.97,57.57 C50.28,57.57 52.35,56.55 53.73,54.99 L94.5,8.13 L95.19,7.35 C95.7,6.6 96,5.7 96,4.74 C96,2.13 93.78,0 91.02,0 L91.02,0 L4.98,0 L4.98,0 C2.22,0 0,2.13 0,4.74 C0,5.73 0.33,6.66 0.87,7.41 Z"></path>
      </g>
    </g>
  );
};
export default chartDown;
