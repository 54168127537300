import * as React from 'react';

const heart = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M28.27 15.001c-5.478 0-10.018 2.098-13.519 6.253-3.606 4.282-5.482 9.701-5.733 16.568-.202 5.522 1.257 11.01 4.461 16.778 2.934 5.282 7.151 10.301 13.272 15.795 6.529 5.86 13.882 10.948 22.481 15.555.291.157.339.131.489.051 8.417-4.538 15.593-9.47 21.936-15.077 5.347-4.729 9.235-9.052 12.233-13.604 4.326-6.569 6.331-12.942 6.13-19.483-.173-5.658-1.519-10.36-4.114-14.376-3.9-6.037-9.567-8.87-16.828-8.407-5.105.322-9.623 2.47-13.816 6.57-1.223 1.196-2.324 2.494-3.49 3.867-.53.625-1.064 1.254-1.611 1.875a.946.946 0 01-.79.338.997.997 0 01-.76-.399c-.211-.282-.423-.575-.639-.872-.464-.64-.942-1.3-1.458-1.91-4.954-5.875-10.088-8.87-16.157-9.424a22.545 22.545 0 00-2.087-.098"
          fill={colorSecondary}
        />
        <path
          d="M30.539 13.106c6.627.607 12.189 3.825 17.504 10.126.519.615.985 1.25 1.434 1.871l.771-.906c1.143-1.347 2.326-2.742 3.616-4.003 4.486-4.386 9.562-6.788 15.089-7.136 8.034-.505 14.309 2.627 18.632 9.317 2.799 4.33 4.249 9.367 4.435 15.401.214 6.966-1.899 13.72-6.46 20.644-3.098 4.704-7.095 9.153-12.579 14.002-6.459 5.711-13.757 10.728-22.311 15.34a2.362 2.362 0 01-1.133.303c-.508 0-.932-.18-1.249-.35-8.743-4.686-16.224-9.863-22.872-15.832-6.292-5.646-10.641-10.829-13.685-16.313-3.387-6.095-4.928-11.925-4.711-17.82.268-7.33 2.297-13.148 6.202-17.785 4.384-5.202 10.218-7.506 17.317-6.859zm-2.269 1.895c-5.478 0-10.018 2.098-13.519 6.253-3.606 4.282-5.482 9.701-5.733 16.568-.202 5.522 1.257 11.01 4.461 16.778 2.934 5.282 7.151 10.301 13.272 15.795 6.529 5.86 13.882 10.948 22.481 15.555.255.137.323.135.437.078l.052-.027c8.417-4.538 15.593-9.47 21.936-15.077 5.347-4.729 9.235-9.052 12.233-13.604 4.326-6.569 6.331-12.942 6.13-19.483-.173-5.658-1.519-10.36-4.114-14.376-3.9-6.037-9.567-8.87-16.828-8.407-5.105.322-9.623 2.47-13.816 6.57-1.02.997-1.954 2.064-2.912 3.187l-.578.68c-.53.625-1.064 1.254-1.611 1.875a.946.946 0 01-.79.338.997.997 0 01-.76-.399l-.639-.872c-.464-.64-.942-1.3-1.458-1.91-4.954-5.875-10.088-8.87-16.157-9.424a22.545 22.545 0 00-2.087-.098z"
          fill={colorPrimary}
        />
      </g>
    </>
  );
};
export default heart;
