import React, { Children } from 'react';
import Styled from './Avatar.style';

interface AvatarProps {
  children: any;
  style?: React.CSSProperties;
  theme?: string;
}

export const Avatar: React.FC<AvatarProps> = (props) => {
  const { children, style, theme } = props;

  return (
    <Styled.Mask className={theme ? theme : ''} style={style}>
      {children}
    </Styled.Mask>
  );
};

export default Avatar;
