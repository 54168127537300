import * as React from 'react';

const electricalOutlet = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M82.595 12a5.998 5.998 0 015.987 5.767l.004.225v64.602a5.998 5.998 0 01-5.767 5.988l-.224.004H17.992a6 6 0 01-5.988-5.768L12 82.594V17.992a6 6 0 015.768-5.988l.224-.004h64.603zm0 2H17.992a3.997 3.997 0 00-3.987 3.793l-.005.199v64.602a3.997 3.997 0 003.793 3.987l.199.005h64.603a3.996 3.996 0 003.986-3.793l.005-.2V17.993A3.996 3.996 0 0082.595 14zm-42.46 45.164a1 1 0 01.993.884l.007.116v7.475a1 1 0 01-.883.994l-.117.006H23.234a1 1 0 01-.993-.883l-.007-.117v-7.475a1 1 0 01.883-.993l.117-.007h16.901zm37.218 0a1 1 0 01.993.884l.007.116v7.475a1 1 0 01-.883.994l-.117.006H60.451a1 1 0 01-.993-.883l-.007-.117v-7.475a1 1 0 01.883-.993l.117-.007h16.902zm-38.218 2h-14.9v5.475h14.9v-5.476zm37.218 0H61.452v5.475h14.901v-5.476zM54.031 29.63a1 1 0 01.993.884l.007.116v16.901a1 1 0 01-.883.994l-.117.006h-7.475a1 1 0 01-.993-.883l-.007-.117v-16.9a1 1 0 01.883-.994l.117-.007h7.475zm-1 2h-5.476v14.901h5.476v-14.9zm27.963-13.355a1 1 0 01.993.884l.007.116V30.63a1 1 0 01-.883.994l-.117.006H69.639a1 1 0 01-.993-.883l-.007-.117V19.275a1 1 0 01.883-.993l.117-.007h11.355zm-53.511 3.057a.999.999 0 011.497 1.32l-.084.094-6.918 6.917a.997.997 0 01-1.414 0 1 1 0 01-.083-1.32l.083-.094 6.919-6.917zm52.511-1.057h-9.355v9.355h9.355v-9.355zm-55.746-2.177a.999.999 0 011.497 1.32l-.083.094-6.917 6.918a.997.997 0 01-1.414 0 1 1 0 01-.083-1.32l.083-.095 6.917-6.917z"
          fill={colorPrimary}
        />
        <path
          d="M39.135 61.163v5.476h-14.9v-5.476h14.9zm36.954 0v5.476h-14.9v-5.476h14.9zM53.031 31.63v14.901h-5.476v-14.9h5.476z"
          fill={colorSecondary}
        />
      </g>
    </>
  );
};
export default electricalOutlet;
