import axios from 'axios';
import { getMicroServiceUrl } from '../helpers/helpers';

export const generalData = () => ({
  type: 'GET_GENERAL',
  payload: axios.get(`${getMicroServiceUrl('gamification', '2.0')}general`),
});

export const getGeneralProperty = (id) => ({
  type: 'GET_GENERAL_PROPERTY',
  payload: axios.get(
    `${getMicroServiceUrl('gamification', '2.0')}general/orgPropertyId/${id}`
  ),
});
