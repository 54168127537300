import React from 'react';
import { Wrap, Task, EasyWin, Loader } from '../../components/index';
import * as _ from 'lodash';
import { useResponsivity } from '../../helpers/helpers';
import { Col } from 'react-styled-flexboxgrid';
import { useTranslation } from 'react-i18next';
import store from 'store2';
interface BathroomTapProps {
  challenges: any;
  listLocation: any;
  getChallengeCategory: Function;
  completeChallenge: Function;
  completeTask: any;
  generalData: Function;
}

const BathroomTap: React.FC<BathroomTapProps> = (props) => {
  const {
    challenges,
    listLocation,
    getChallengeCategory,
    completeChallenge,
    completeTask,
    generalData,
  } = props;
  const getRoomId = store('property');

  const data = _.get(challenges, 'data.challenges');
  const isMobile = useResponsivity();
  const [t] = useTranslation('common');
  const loadingChallenges = _.get(challenges, 'status.loading');

  const { error, loading, done } = _.get(completeTask, 'status');
  React.useEffect(() => {
    getChallengeCategory(getRoomId, 'bathroom-tap');
  }, [getRoomId]);

  const pageArray = [
    {
      id: 265,
      title: 'Washroom basin tap',
      poster:
        'https://cdn.getwaterfit.co.uk/static/files/images/gwf-business/posters/cheetah.png',
      emailCopy:
        'Remember to turn off the tap while washing your hands. Don’t be a chipper cheetah and leave the tap running.',
      easyWins: [
        {
          title: 'Introduction',
          theme: 'intro',
          size: 'full',
          text: 'Washroom basin taps can be huge water wasters, with high flow rates and taps left running when not in use – both of which can significantly impact energy bills.',
        },
        {
          title: 'Easy Win',
          theme: 'red',
          icon: null,
          size: 'half',
          buttonText: 'Download now',
          buttonPath:
            'https://cdn.getwaterfit.co.uk/static/files/images/gwf-business/posters/cheetah.pdf',
          text: 'Print off the poster and place beside the washbasin taps to remind staff to turn off the tap while washing hands. This poster will help reduce cold & hot water use in your business by as much as 30%.',
        },
        {
          title: 'Easy Win',
          theme: 'red',
          size: 'half',
          icon: null,
          buttonText: 'See video',
          buttonPath: '/video-library',
          text: 'Fitting tap inserts lets your business control the amount of water flowing from your taps. Tap inserts reduce water flow by as much as 70% or more, with little noticeable difference – except to business water & energy bills. And they’re easy to fit – you don’t need a plumber.',
        },
        {
          theme: 'tooltip',
          icon: 'knowledge',
          size: 'full',
          text: 'To measure the flow of water, use a measuring jug and fill for 5 seconds. Multiply the amount of water in the jug by 12 in order to understand the how much water runs out of the tap in one minute. For example, if you have 1 litre in your jug after 5 seconds, then the flow rate of the tap will be 1l x 12 = 12 litres per minute.',
        },
        {
          title: 'Easy Win',
          theme: 'red',
          size: 'half',
          icon: 'worker',
          text: 'Make sure your washroom percussion taps operate for 10-15 seconds each time they are pressed. Percussion taps that run for longer often pour out water when staff have already finished washing their hands. Remember that wasted hot water will impact energy bills, too.#Your Facilities Manager or Plumber will be able to adjust the timing, if required.',
        },
        {
          title: 'Easy Win Pro',
          theme: 'blue',
          size: 'half',
          icon: 'worker',
          text: 'Reduce cold & hot water use by switching to percussion or sensor taps.#Percussion taps produce a timed flow of water i.e. for 10-15 seconds before the tap turns off automatically. Sensor taps only operate when a staff member is directly in front of the wash basin, often when their hands are directly beneath the tap spout end.#Unlike pillar and mixer taps, which require the user to manually turn off the tap, percussion and sensor taps provide only the required amount of water for washing hands. They also minimise the risk of taps being left on overnight or at weekends.',
        },
      ],
    },
  ];

  const getChallenges = () => {
    return _.map(data, (el, index) => {
      const title = _.get(el, 'title');
      const description = _.get(el, 'description');
      const buttonText = _.get(el, 'buttonText');
      const type = _.get(el, 'internalType');
      const buttonPath = _.get(el, 'buttonPath');
      const energySaving = _.get(el, 'energy_saving');
      const carbonSaving = _.get(el, 'carbon_saving');
      const waterSaving = _.get(el, 'water_saving');

      return (
        <Col xs={12} sm={12} md={6} key={index}>
          <Task
            isDesktop={!isMobile}
            titleWidth="230px"
            icon={_.get(el, 'icon')}
            id={_.get(el, 'id')}
            title={`${t(title)}`}
            description={`${t(description)}`}
            buttonText={`${t(buttonText)}`}
            buttonPath={buttonPath}
            popup={type === 'direct-redirect' ? false : true}
            renderComplete={() => {
              completeChallenge(getRoomId, _.get(el, 'id'));
              setTimeout(() => {
                getChallengeCategory(getRoomId, 'bathroom-tap');
              }, 1000);
            }}
            isLoading={loading}
            completed={done}
            energySaving={energySaving}
            carbonSaving={carbonSaving}
            waterSaving={waterSaving}
          />
        </Col>
      );
    });
  };

  if (loadingChallenges) {
    return (
      <Wrap>
        <Loader />
      </Wrap>
    );
  }

  return (
    <Wrap>
      <EasyWin data={pageArray}>{getChallenges()}</EasyWin>
    </Wrap>
  );
};

export default BathroomTap;
