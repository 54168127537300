import * as React from 'react';

const australia = () => (
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g transform="translate(5.000000, 5.000000)" fillRule="nonzero">
      <path
        d="M90,45.0438586 C90,69.8722926 69.8526562,90 45,90 C20.1473437,90 0,69.8722926 0,45.0438586 C0,45.0543952 45,0.0928098466 45,0.0877171587 C69.8526562,0.0877171587 90,20.2154246 90,45.0438586 Z"
        fill="#0052B4"
      />
      <g fill="#F0F0F0">
        <path d="M45,0.0877171587 C44.9975391,0.0877171587 44.9949023,0.0878927687 44.9924414,0.0878927687 L45,0.0877171587 Z" />
        <path d="M44.8795898,45.0438586 L45,45.0438586 C45,45.0031171 45,44.9641317 45,44.9235658 C44.9599219,44.9637805 44.9198437,45.0038195 44.8795898,45.0438586 Z" />
        <path d="M45.0001758,23.5432327 C45.0001758,15.6328835 45.0001758,10.4494053 45.0001758,0.0878927687 L44.9924414,0.0878927687 C20.143125,0.091931797 0,20.2177075 0,45.0438586 L23.4782227,45.0438586 L23.4782227,31.8358847 L36.699082,45.0438586 L44.8797656,45.0438586 C44.9200195,45.0038195 44.9600977,44.9637805 45.0001758,44.9235658 C45.0001758,41.8948214 45.0001758,39.1923602 45.0001758,36.7513822 L31.7791406,23.5432327 L45.0001758,23.5432327 Z" />
      </g>
      <g transform="translate(5.869512, 5.951508)" fill="#D80027">
        <path d="M16.8967969,1.24778358e-15 C9.85869141,4.00373074 4.00763672,9.84908278 4.99600361e-15,16.8803287 L4.99600361e-15,39.0923503 L11.7391992,39.0923503 L11.7391992,11.7279334 L11.7391992,11.7277578 L39.1304883,11.7277578 C39.1304883,8.02888588 39.1304883,4.50509708 39.1304883,1.24778358e-15 L16.8967969,1.24778358e-15 Z" />
        <path d="M39.1304883,33.5639742 L23.1426563,17.591822 L17.6088867,17.591822 C17.6088867,17.591822 17.6088867,17.591822 17.6088867,17.591822 L39.1304883,39.0923503 L39.1304883,39.0923503 C39.1304883,39.0923503 39.1304883,35.2807369 39.1304883,33.5639742 Z" />
      </g>
      <g transform="translate(17.105625, 19.633805)" fill="#F0F0F0">
        <polygon points="10.0341211 33.2285592 12.5038477 38.3876277 18.0808594 37.0998801 15.5833594 42.2456022 20.0682422 45.7987179 14.4841992 47.0560849 14.4998437 52.7746466 10.0341211 49.1969456 5.56857422 52.7746466 5.58421875 47.0560849 -4.99600361e-15 45.7987179 4.48505859 42.2456022 1.98720703 37.0998801 7.56457031 38.3876277" />
        <polygon points="50.2685156 43.0016029 51.5033789 45.5811372 54.2919727 44.9371755 53.0430469 47.5100366 55.2856641 49.2866822 52.4935547 49.9153658 52.5012891 52.7746466 50.2685156 50.9857083 48.0357422 52.7746466 48.0434766 49.9153658 45.251543 49.2866822 47.4939844 47.5100366 46.2452344 44.9371755 49.0336523 45.5811372" />
        <polygon points="38.7810352 15.6370104 40.0157227 18.2167202 42.8043164 17.572583 41.5555664 20.145444 43.7980078 21.9220897 41.0060742 22.5507732 41.0136328 25.4100541 38.7810352 23.6211157 36.5482617 25.4100541 36.5558203 22.5507732 33.7638867 21.9220897 36.0063281 20.145444 34.7575781 17.572583 37.5461719 18.2167202" />
        <polygon points="50.2685156 4.99113433e-15 51.5033789 2.57970983 54.2919727 1.93574823 53.0432227 4.50860928 55.2854883 6.2850793 52.4935547 6.91393845 52.5012891 9.77321929 50.2685156 7.98428096 48.0357422 9.77321929 48.0434766 6.91393845 45.251543 6.2850793 47.4938086 4.50860928 46.2452344 1.93574823 49.0336523 2.57970983" />
        <polygon points="60.3028125 11.7277578 61.5375 14.3074676 64.3260938 13.6633304 63.0773438 16.2361914 65.3197852 18.0128371 62.5276758 18.6416962 62.5354102 21.5008015 60.3028125 19.7120387 58.0700391 21.5008015 58.0775977 18.6416962 55.2856641 18.0128371 57.5281055 16.2361914 56.2793555 13.6633304 59.0677734 14.3074676" />
        <polygon points="53.1277734 25.4100541 54.0989648 28.3964765 57.2422852 28.3964765 54.6992578 30.2423124 55.6708008 33.2285592 53.1277734 31.3828989 50.5849219 33.2285592 51.5561133 30.2423124 49.0132617 28.3964765 52.1564062 28.3964765" />
      </g>
    </g>
  </g>
);
export default australia;
