import styled from 'styled-components';
import { colors } from '../../assets/variables';
const Card = styled.div`
  border: 4px solid ${colors.tertiary};
  border-radius: 10px;
  padding: 32px;
`;
const Download = styled.a`
  font-family: 'Open Sans', sans-serif;
  transition: all 300ms;
  font-size: 16px;
  font-weight: 600;
  color: ${colors.primary};
  background-color: ${colors.baseLight};
  line-height: 1.15;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 14px 20px;
  text-decoration: none;
  border-radius: 10px;
  border: 2px solid ${colors.primary};
  text-align: left;
  cursor: pointer;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  text-transform: uppercase;
  margin: 0;
  display: flex;
  :hover,
  :focus {
    color: ${colors.action};
    border: 2px solid ${colors.action};
    transition: all 300ms;
    svg {
      path {
        transition: all 300ms;
        fill: ${colors.action};
      }
    }
  }
`;
export default { Card, Download };
