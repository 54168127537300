import styled from 'styled-components';

const Textarea = styled.textarea`
  border-radius: 8px;
  width: 100%;
  padding: 16px;
  font-family: 'Open Sans';
  border-color: black;
`;
const ErrorInline = styled.div`
  font-size: 12px;
  padding: 5px 0 0;
  color: #ad0000;
`;
export default {
  Textarea,
  ErrorInline,
};
