import React from 'react';
import Styled from './BottomNav.style';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useResponsivity } from '../../helpers/helpers';
import _ from 'lodash';
import { Icon } from '../index';
export const BottomNav: React.FC = () => {
  const [t] = useTranslation('common');
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const path = location.pathname;
  const isMobile = useResponsivity();
  const nav = [
    { path: '/portal', name: t('portal.header.menu_home'), icon: 'home' },
    {
      path: '/use',
      name: 'Use',
      icon: 'waterCoins',
    },
    {
      path: '/easy-wins',
      name: t('portal.header.menu_easywins'),
      icon: 'heart',
    },
    { path: '/profile', name: t('portal.header.menu_myProfile'), icon: 'man' },
  ];
  const renderNav = () => {
    return _.map(nav, (el, index) => {
      return (
        <Link
          key={index}
          to={el.path}
          className={path === el.path ? 'active' : ''}
          style={{
            fontSize: !isMobile ? '16px' : '20px',
            display: 'flex',
            flexDirection: 'row',
            textDecoration: 'none',
            alignItems: 'center',
          }}
        >
          <Styled.Text onClick={() => (isMobile ? setOpen(!open) : null)}>
            <Icon icon={el.icon} size={33} />
            {el.name}
          </Styled.Text>
        </Link>
      );
    });
  };
  return <Styled.BottomNav>{renderNav()}</Styled.BottomNav>;
};

export default BottomNav;
