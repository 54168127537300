import * as React from 'react';

const arrow = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M54.74 15.755c-2.31 2.12-3.299 3.588-3.309 4.906-.009 1.337.973 2.84 3.286 5.025 3.566 3.37 7.063 6.919 10.444 10.35 1.434 1.457 2.868 2.912 4.31 4.359.296.297.577.609.94 1.013l.651.72a1 1 0 01-.739 1.676l-16.163.005c-13.585.01-27.632.017-41.448-.05h-.08c-3.985 0-4.889 1.518-5.23 2.842-.526 2.04-.532 3.86-.018 5.723.9 2.352 2.5 3.337 5.453 3.293 13.792-.061 27.812-.053 41.37-.045l16.275.004a1.002 1.002 0 01.74 1.674l-.632.699a25.5 25.5 0 01-1.02 1.1c-2.45 2.448-4.902 4.894-7.355 7.339-3.014 3.006-6.028 6.01-9.038 9.02-2.317 2.319-2.33 4.107-.05 6.598 2.151 2.352 3.556 3.318 4.846 3.337h.038c1.316 0 2.872-.998 5.036-3.228 6.625-6.826 13.481-13.641 20.111-20.232 2.911-2.893 5.822-5.786 8.723-8.69 2.553-2.554 2.557-4.451.012-6.996A72584.986 72584.986 0 0061.5 15.8c-1.196-1.195-2.254-1.792-3.335-1.792-1.067 0-2.158.582-3.425 1.746z"
          fill={colorSecondary}
        />
        <path
          d="M62.913 14.387a79183.62 79183.62 0 0130.395 30.395c3.345 3.347 3.341 6.469-.012 9.826l-4.36 4.35-9.37 9.321a1421.79 1421.79 0 00-12.593 12.675l-2.49 2.554c-2.514 2.591-4.41 3.754-6.269 3.83l-.27.005c-2.225-.033-4.125-1.617-6.293-3.987-3.016-3.294-2.98-6.27.112-9.362l9.04-9.022 3.679-3.668 3.733-3.728-27.693-.01c-7.66 0-15.39.006-23.073.033l-4.667.018c-3.672 0-5.961-1.388-7.18-4.362l-.12-.308-.012-.041c-.625-2.231-.627-4.393-.005-6.804.722-2.802 3.022-4.26 6.842-4.339l.414-.003c12.277.058 24.739.058 36.895.052l18.436-.008-2.16-2.178-2.156-2.184c-2.888-2.932-5.86-5.949-8.88-8.858l-1.513-1.445c-2.755-2.604-3.925-4.545-3.91-6.492.012-1.954 1.158-3.798 3.953-6.365 3.35-3.076 6.376-3.043 9.527.105zm-4.749-.378c-1.067 0-2.158.582-3.425 1.746-2.309 2.12-3.298 3.588-3.308 4.906-.009 1.287.902 2.729 3.035 4.785l.251.24c3.057 2.888 6.063 5.908 8.988 8.874l1.456 1.477c1.434 1.456 2.868 2.91 4.31 4.358.119.119.235.24.354.368l1.237 1.366a1 1 0 01-.739 1.675l-29.876.011c-7.676 0-15.423-.008-23.122-.036l-4.693-.02c-3.985 0-4.889 1.518-5.23 2.842-.526 2.04-.532 3.86-.018 5.723.871 2.276 2.397 3.272 5.171 3.294l.282-.001c9.195-.04 18.49-.05 27.684-.05l29.961.009a1.002 1.002 0 01.74 1.674l-1.06 1.175c-.203.223-.394.426-.592.624l-7.355 7.339c-3.014 3.006-6.028 6.01-9.038 9.02-2.256 2.258-2.329 4.012-.225 6.403l.175.195c2.074 2.268 3.455 3.247 4.707 3.331l.177.006c1.266 0 2.753-.923 4.79-2.976l.246-.252c4.969-5.12 10.068-10.233 15.103-15.25l5.008-4.982c2.911-2.893 5.822-5.786 8.723-8.69 2.553-2.554 2.557-4.451.012-6.996A72584.986 72584.986 0 0061.5 15.8c-1.196-1.195-2.254-1.792-3.335-1.792z"
          fill={colorPrimary}
        />
      </g>
    </>
  );
};
export default arrow;
