import * as React from 'react';

const waterbutt = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M21 20.976a3.329 3.329 0 013.325-3.325h52.154a3.33 3.33 0 013.326 3.325 3.33 3.33 0 01-3.326 3.325H24.325A3.329 3.329 0 0121 20.976"
          fill="#D7F1FF"
        />
        <path fill={colorSecondary} d="M70.85 83.59H29.038l-4.475-57.27h51.682z" />
        <path
          d="M68.049 7c5.03 0 9.17 3.871 9.606 8.791 2.372.538 4.15 2.653 4.15 5.184 0 2.225-1.379 4.118-3.32 4.911l-.21.081-5.483 58.213 4.13 3.947a3.477 3.477 0 01.84 3.873c-.524 1.307-1.693 2.141-3.081 2.214l-.2.005H25.85a3.489 3.489 0 01-3.244-2.129 3.484 3.484 0 01.52-3.663l.142-.16 3.803-4.059-1.366-17.472H21v3.438a1 1 0 01-1.993.117L19 70.174v-4.438a1 1 0 01.883-.993l.117-.007h5.549L22.52 25.964C20.474 25.221 19 23.276 19 20.975c0-2.53 1.779-4.647 4.15-5.184.429-4.83 4.427-8.65 9.333-8.787L32.757 7h35.292zm3.302 78.57H28.535l-3.807 4.064a1.498 1.498 0 00-.287 1.661c.231.535.698.87 1.265.918l.144.006h48.63c.65 0 1.184-.36 1.426-.964a1.484 1.484 0 00-.262-1.575l-.102-.107-4.191-4.004zm4.884-59.27H24.553l4.474 57.27h41.815l5.393-57.27zM65.468 61.96a1 1 0 01-.256 1.39c-1.915 1.32-9.437 2.113-16.663 2.113l-.8-.003c-6.385-.052-12.364-.736-13.812-2.239a1 1 0 011.346-1.473l.093.085c2.13 2.206 25.467 2.101 28.7-.129a1 1 0 011.392.256zm0-12.404a1 1 0 01-.256 1.39c-1.878 1.295-9.155 2.083-16.254 2.112l-.41.001c-6.678 0-13.102-.677-14.611-2.242a1 1 0 011.346-1.473l.093.085c2.105 2.181 24.92 2.104 28.578-.051l.123-.078a.998.998 0 011.39.256zm0-12.604a1 1 0 01-.256 1.39c-1.915 1.32-9.437 2.113-16.663 2.113l-.8-.004c-6.385-.052-12.364-.736-13.812-2.238a1 1 0 011.346-1.473l.093.085c2.13 2.206 25.467 2.1 28.7-.13a1 1 0 011.392.257zM76.48 17.65H24.325A3.329 3.329 0 0021 20.975c0 1.771 1.39 3.222 3.136 3.32l.189.005H76.48a3.328 3.328 0 003.325-3.325 3.329 3.329 0 00-3.325-3.325zM68.049 9H32.757c-3.796 0-6.954 2.777-7.55 6.407l-.035.243h50.462C75.143 11.902 71.929 9 68.049 9z"
          fill={colorPrimary}
        />
      </g>
    </>
  );
};
export default waterbutt;
