import React, { useCallback } from 'react';
import {
  Typography,
  Wrap,
  NothingToDisplay,
  ErrorDisplay,
  Loader,
  Select,
  Search,
} from '../../components/index';
import { Row, Col } from 'react-styled-flexboxgrid';
import Card from './Card';
import * as _ from 'lodash';
import { useResponsivity } from '../../helpers/helpers';
import ReactPaginate from 'react-paginate';
import Styled from './Marketplace.style';
interface MarketplaceProps {
  marketplace: any;
  listBanners: Function;
  search: any;
  getSearchData: Function;
  adAnalytics: Function;
  optIn: Function;
  getAvailableRewards: Function;
  optedInData: any;
}

export const Marketplace: React.FC<MarketplaceProps> = (props) => {
  const {
    marketplace,
    optIn,
    getAvailableRewards,
    listBanners,
    search,
    getSearchData,
    adAnalytics,
    optedInData,
  } = props;
  const data = _.get(marketplace, 'data');
  const { error, loading, done } = _.get(marketplace, 'status');
  const dataLength = _.get(data, 'length');
  const isMobile = useResponsivity();
  const PageSize = 6;
  const [currentPage, setCurrentPage] = React.useState(1);
  const optedData = _.get(optedInData, 'data.bannerIds');
  const loadingSearch = _.get(search, 'status.loading');
  const errorSearch = _.get(search, 'status.error');
  const doneSearch = _.get(search, 'status.done');
  const dataSearch = _.get(search, 'data.hits');
  const resultData = _.get(search, 'data.total');

  const [message, setMessage] = React.useState('');

  const [pageNumber, setPageNumber] = React.useState(0);
  const ItemsPerPage = 6;
  const pagesVisited = pageNumber * ItemsPerPage;
  const setData = (message) => {
    setMessage(message);
  };

  const [savedMessage, setSavedMessage] = React.useState('');
  const setSavedData = (message) => {
    setSavedMessage(message);
  };

  const renderData = dataSearch && message !== '' ? dataSearch : data;
  const pageCount = Math.ceil(
    resultData
      ? resultData / ItemsPerPage
      : renderData && renderData.length / ItemsPerPage
  );
  const [isSorting, setSorting] = React.useState('new');
  const sortedZA = _.orderBy(
    renderData,
    [(user) => user.title.toLowerCase()],
    ['desc']
  );
  const sortedAZ = _.orderBy(
    renderData,
    [(user) => user.title.toLowerCase()],
    ['asc']
  );
  const sortedNew = _.orderBy(renderData, [(user) => user.createdAt], ['desc']);
  const sortedOld = _.orderBy(renderData, [(user) => user.createdAt], ['asc']);

  const renderSorted = useCallback(() => {
    switch (isSorting) {
      case 'za':
        return sortedZA;
      case 'az':
        return sortedAZ;
      case 'new':
        return sortedNew;
      case 'old':
        return sortedOld;
      default:
        return renderData;
    }
  }, [isSorting, renderData, sortedAZ, sortedNew, sortedOld, sortedZA]);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    if (message) {
      getSearchData({
        type: 'B2C',
        term: message,
        start: ItemsPerPage * selected,
        size: ItemsPerPage,
        sortField:
          isSorting === 'new' || isSorting === 'old' ? 'createdAt' : 'title',
        sortOrder: isSorting === 'new' || isSorting === 'za' ? 'desc' : 'asc',
      });
    }
  };

  const displayUsers =
    renderSorted() &&
    renderSorted().slice(pagesVisited, pagesVisited + ItemsPerPage);
  React.useEffect(() => {
    listBanners();
  }, [listBanners]);

  const sorting = [
    { label: 'Newest to oldest', value: 'new' },
    { label: 'Oldest to newest', value: 'old' },
    { label: 'Alphabetical (A - Z)', value: 'az' },
    { label: 'Alphabetical (Z - A)', value: 'za' },
  ];

  if (error) {
    return (
      <Wrap>
        <ErrorDisplay />
      </Wrap>
    );
  }
  if (loading) {
    return (
      <Wrap>
        <Loader type="leaf" />
      </Wrap>
    );
  }
  const handleRoomType = (e) => {
    if (e.currentTarget !== undefined) {
      setSorting(e.currentTarget.value);
    }
  };

  const renderNoBanners = () => {
    return (
      <Col md={12} sm={12} xs={12}>
        <NothingToDisplay
          title="There are no available offers for you right now"
          subtitle="Please come back later"
        />
      </Col>
    );
  };
  const renderErrorSearch = () => {
    return (
      <Col md={12} sm={12} xs={12}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '4px solid #d7f1ff',
            padding: '16px',
            borderRadius: '6px',
          }}
        >
          <Typography size="3" text="Opps..." />
          <p style={{ fontWeight: 500 }}>{errorSearch}</p>
          <Typography
            size="p"
            style={{ fontSize: '15px' }}
            text="Please try a different search term."
          />
        </div>
      </Col>
    );
  };
  const renderNoResults = () => {
    return (
      <Col md={12} sm={12} xs={12}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '4px solid #d7f1ff',
            padding: '16px',
            borderRadius: '6px',
          }}
        >
          <Typography size="3" text="Hmm..." />
          <p>
            We couldn&apos;t find any matches for &ldquo;<b>{savedMessage}</b>
            &rdquo;
          </p>
          <Typography
            size="p"
            style={{ fontSize: '15px' }}
            text="Double check your search for any typos or spelling errors - or try a different search term."
          />
        </div>
      </Col>
    );
  };

  const renderBanner = () => {
    return _.map(dataSearch ? dataSearch : displayUsers, (el, index) => {
      return (
        <Col md={4} sm={6} xs={12} style={{ display: 'flex' }}>
          <Card
            key={index}
            title={el.title}
            description={el.description}
            buttonPath={el.buttonPath}
            buttonText={el.buttonText}
            productUniqueness={el.productUniqueness}
            image={el.imageURL}
            companyName={el.companyName}
            isDesktop={!isMobile}
            businessCreds={el.businessCredentials}
            id={el.businessId}
            isOffer={el.isOffer}
            optIn={optIn}
            optedInData={optedData}
            adId={el.id}
            getAvailableRewards={getAvailableRewards}
          />
        </Col>
      );
    });
  };

  const renderTypeCard = () => {
    if (loadingSearch && !doneSearch) {
      return (
        <Col md={12} xs={12} sm={12}>
          <Loader type="leaf" />
        </Col>
      );
    } else if (errorSearch) {
      return renderErrorSearch();
    } else if (resultData === 0 && message !== '') {
      return renderNoResults();
    } else if (done && dataLength === 0) {
      return renderNoBanners();
    } else {
      return renderBanner();
    }
  };

  const title = (
    <svg width="161px" height="41px" viewBox="0 0 161 41">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(0.199219, 0.609375)" fillRule="nonzero">
          <g fill="#0B2641">
            <path d="M12.0898438,30.390625 L5.21484375,7.98828125 L5.0390625,7.98828125 C5.28645833,12.5455729 5.41015625,15.5859375 5.41015625,17.109375 L5.41015625,30.390625 L0,30.390625 L0,1.8359375 L8.2421875,1.8359375 L15,23.671875 L15.1171875,23.671875 L22.2851562,1.8359375 L30.5273438,1.8359375 L30.5273438,30.390625 L24.8828125,30.390625 L24.8828125,16.875 C24.8828125,16.2369792 24.8925781,15.5013021 24.9121094,14.6679688 C24.9316406,13.8346354 25.0195312,11.6210938 25.1757812,8.02734375 L25,8.02734375 L17.6367188,30.390625 L12.0898438,30.390625 Z"></path>
            <path d="M34.1210938,8.5546875 L40.6445312,8.5546875 L44.765625,20.8398438 C45.1171875,21.9075521 45.3580729,23.1705729 45.4882812,24.6289062 L45.6054688,24.6289062 C45.7486979,23.2877604 46.0286458,22.0247396 46.4453125,20.8398438 L50.4882812,8.5546875 L56.875,8.5546875 L47.6367188,33.1835938 C46.7903646,35.4622396 45.5826823,37.1679688 44.0136719,38.3007812 C42.4446615,39.4335938 40.6119792,40 38.515625,40 C37.4869792,40 36.4778646,39.8893229 35.4882812,39.6679688 L35.4882812,34.9414062 C36.2044271,35.1106771 36.9856771,35.1953125 37.8320312,35.1953125 C38.8867188,35.1953125 39.8079427,34.8730469 40.5957031,34.2285156 C41.3834635,33.5839844 41.9986979,32.6106771 42.4414062,31.3085938 L42.7929688,30.234375 L34.1210938,8.5546875 Z"></path>
            <path d="M77.3242188,22.4609375 C77.3242188,25.0390625 76.3964844,27.0703125 74.5410156,28.5546875 C72.6855469,30.0390625 70.1041667,30.78125 66.796875,30.78125 C63.75,30.78125 61.0546875,30.2083333 58.7109375,29.0625 L58.7109375,23.4375 C60.6380208,24.296875 62.2688802,24.9023438 63.6035156,25.2539062 C64.938151,25.6054688 66.1588542,25.78125 67.265625,25.78125 C68.59375,25.78125 69.6126302,25.5273438 70.3222656,25.0195312 C71.031901,24.5117188 71.3867188,23.7565104 71.3867188,22.7539062 C71.3867188,22.1940104 71.2304688,21.6959635 70.9179688,21.2597656 C70.6054688,20.8235677 70.1464844,20.4036458 69.5410156,20 C68.9355469,19.5963542 67.7018229,18.9518229 65.8398438,18.0664062 C64.0950521,17.2460938 62.7864583,16.4583333 61.9140625,15.703125 C61.0416667,14.9479167 60.3450521,14.0690104 59.8242188,13.0664062 C59.3033854,12.0638021 59.0429688,10.8919271 59.0429688,9.55078125 C59.0429688,7.02473958 59.8990885,5.0390625 61.6113281,3.59375 C63.3235677,2.1484375 65.6901042,1.42578125 68.7109375,1.42578125 C70.1953125,1.42578125 71.6113281,1.6015625 72.9589844,1.953125 C74.3066406,2.3046875 75.7161458,2.79947917 77.1875,3.4375 L75.234375,8.14453125 C73.7109375,7.51953125 72.4511719,7.08333333 71.4550781,6.8359375 C70.4589844,6.58854167 69.4791667,6.46484375 68.515625,6.46484375 C67.3697917,6.46484375 66.4908854,6.73177083 65.8789062,7.265625 C65.2669271,7.79947917 64.9609375,8.49609375 64.9609375,9.35546875 C64.9609375,9.88932292 65.0846354,10.3548177 65.3320312,10.7519531 C65.5794271,11.1490885 65.9733073,11.5332031 66.5136719,11.9042969 C67.0540365,12.2753906 68.3333333,12.9427083 70.3515625,13.90625 C73.0208333,15.1822917 74.8502604,16.4615885 75.8398438,17.7441406 C76.8294271,19.0266927 77.3242188,20.5989583 77.3242188,22.4609375 Z"></path>
            <path d="M102.1875,30.390625 L96.2304688,30.390625 L96.2304688,17.6367188 C96.2304688,14.4856771 95.0585938,12.9101562 92.7148438,12.9101562 C91.0481771,12.9101562 89.84375,13.4765625 89.1015625,14.609375 C88.359375,15.7421875 87.9882812,17.578125 87.9882812,20.1171875 L87.9882812,30.390625 L82.03125,30.390625 L82.03125,0 L87.9882812,0 L87.9882812,6.19140625 C87.9882812,6.67317708 87.9427083,7.80598958 87.8515625,9.58984375 L87.7148438,11.3476562 L88.0273438,11.3476562 C89.3554688,9.21223958 91.4648438,8.14453125 94.3554688,8.14453125 C96.9205729,8.14453125 98.8671875,8.83463542 100.195312,10.2148438 C101.523438,11.5950521 102.1875,13.5742188 102.1875,16.1523438 L102.1875,30.390625 Z"></path>
            <path d="M149.261719,29.78125 C146.696615,29.78125 144.684896,28.8502604 143.226562,26.9882812 L142.914062,26.9882812 C143.122396,28.8111979 143.226562,29.8658854 143.226562,30.1523438 L143.226562,39 L137.269531,39 L137.269531,7.5546875 L142.113281,7.5546875 L142.953125,10.3867188 L143.226562,10.3867188 C144.619792,8.22526042 146.683594,7.14453125 149.417969,7.14453125 C151.996094,7.14453125 154.014323,8.140625 155.472656,10.1328125 C156.93099,12.125 157.660156,14.8919271 157.660156,18.4335938 C157.660156,20.7643229 157.318359,22.7890625 156.634766,24.5078125 C155.951172,26.2265625 154.977865,27.5351562 153.714844,28.4335938 C152.451823,29.3320312 150.967448,29.78125 149.261719,29.78125 Z M147.503906,11.9101562 C146.032552,11.9101562 144.958333,12.3626302 144.28125,13.2675781 C143.604167,14.172526 143.252604,15.6666667 143.226562,17.75 L143.226562,18.3945312 C143.226562,20.7382812 143.57487,22.4179688 144.271484,23.4335938 C144.968099,24.4492188 146.071615,24.9570312 147.582031,24.9570312 C150.251302,24.9570312 151.585938,22.7565104 151.585938,18.3554688 C151.585938,16.2070312 151.257161,14.5957031 150.599609,13.5214844 C149.942057,12.4472656 148.910156,11.9101562 147.503906,11.9101562 Z"></path>
          </g>
          <path
            d="M133.368728,7.167955 L133.199459,5.390625 L131.506764,5.95485674 C128.605788,6.96277864 125.507942,7.27208 122.464951,6.85762752 C119.267374,6.36140161 115.99451,6.80235625 113.042281,8.1271489 C109.862383,9.4811836 107.584635,12.353588 106.990895,15.7583831 C106.611984,17.9231832 106.801542,20.1492707 107.541021,22.2188366 C108.017168,21.1440987 108.573569,20.1067411 109.205505,19.1155621 C110.638936,17.0065185 112.625141,15.3326401 114.946562,14.2772749 C117.182953,13.2561921 119.627411,12.7730977 122.084094,12.8666955 L122.084094,12.8666955 C119.745384,13.2242763 117.506764,14.0649597 115.510794,15.3352093 C114.548038,15.9401352 113.666365,16.6653819 112.887117,17.4933958 C112.141856,18.3210159 111.484748,19.2239481 110.926411,20.1876023 C109.877687,22.2019249 109.131471,24.3597732 108.711802,26.5916325 C108.259448,28.8306941 108.013914,31.1066039 107.9783,33.390625 L110.799459,33.390625 C110.932052,31.3422287 111.210168,29.3058568 111.631701,27.2969222 C113.588785,28.3537294 115.783533,28.8927053 118.00752,28.8626653 C120.081744,28.8612402 122.134628,28.4439483 124.044799,27.6354612 C134.779308,23.0510784 133.368728,7.8027157 133.368728,7.167955 Z"
            fill="#86B049"
          ></path>
        </g>
      </g>
    </svg>
  );

  const renderPagination = () => {
    return (
      <ReactPaginate
        previousLabel={'<'}
        nextLabel={'>'}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={'paginationBttns'}
        previousLinkClassName={'previousBttn'}
        nextLinkClassName={'nextBttn'}
        disabledClassName={'paginationDisabled'}
        activeClassName={'paginationActive'}
        forcePage={pageNumber}
      />
    );
  };

  return (
    <Wrap>
      <Styled.Wrap>
        <Row>
          <Col md={12} sm={12} xs={12} style={{ margin: '16px 0px' }}>
            {title}
          </Col>
          <Col md={12} sm={12} xs={12}>
            <Typography
              size="p"
              style={{
                fontSize: '18px',
                fontWeight: 700,
                maxWidth: '760px',
                marginBottom: '32px',
              }}
              text="Sustainable everyday products and services which consider water, energy, carbon and impact on the environment."
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '32px' }}>
          <Col md={4} xs={12} sm={12}>
            <Search
              setData={setData}
              getSearchData={() => {
                getSearchData({
                  type: 'B2B',
                  term: message,
                  start: 0,
                  size: PageSize,
                });
                setCurrentPage(1);
                setSorting('');
              }}
              result={resultData}
              setPreviousData={setSavedData}
            />
          </Col>
          <Col md={3} xs={12} sm={12}>
            <Select
              borderColor="#86B049"
              options={sorting}
              placeholder="Sort by"
              onChange={(e) => handleRoomType(e)}
              value={isSorting}
              name="category"
            />
          </Col>
        </Row>
        {renderPagination()}
        <Row>{renderTypeCard()}</Row>
        {renderPagination()}
      </Styled.Wrap>
    </Wrap>
  );
};

export default Marketplace;
