import React from 'react';
import * as _ from 'lodash';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Cell,
  Tooltip,
  LabelList,
} from 'recharts';
import {
  themeToColor,
  CustomBarLegend,
} from '../../helpers/customChartElements';
import { ChartData } from '../../interfaces';
import { Icon } from '../index';
import Styled from './ChartBars.style';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { prettifyIfNumber } from '../../helpers/helpers';

interface ChartBars2Props {
  id: number;
  chartData: ChartData;
  title: string;
  theme?: string;
  displayMoney?: string;
}

const ChartBars: React.FC<ChartBars2Props> = (props) => {
  const { chartData, theme = 'c1', displayMoney } = props;
  const [t] = useTranslation('common');
  const original = t('portal.my_use.chart_original');
  const current = t('portal.my_use.chart_current');
  const transformedData = chartData.dataSets.use?.data;
  // const transformedDataCost = chartData.dataSets.cost?.data;
  // const transformedDataEnergy = chartData.dataSets.energy?.data;
  // const transformedDataCarbon = chartData.dataSets.carbon?.data;
  const navigate = useNavigate();
  const newTransformedData = transformedData?.map((el) => {
    const o = Object.assign({}, el);
    o.difference = o.originalValue - o.value;
    return o;
  });
  const transformedDataCost = chartData.dataSets.cost?.data;
  const newTransformedDataCost = transformedDataCost?.map((el) => {
    const o = Object.assign({}, el);
    o.difference = o.originalValue - o.value;
    return o;
  });
  const transformedDataEnergy = chartData.dataSets.energy?.data;

  const newTransformedDataEnergy = transformedDataEnergy?.map((el) => {
    const o = Object.assign({}, el);
    o.difference = o.originalValue - o.value;
    return o;
  });

  const transformedDataCarbon = chartData.dataSets.carbon?.data;

  const newTransformedDataCarbon = transformedDataCarbon?.map((el) => {
    const o = Object.assign({}, el);
    o.difference = o.originalValue - o.value;
    return o;
  });

  const { chartTheme } = chartData;

  const xLabels =
    displayMoney === 'cost'
      ? chartData.dataSets.cost?.xLabel
      : chartData.dataSets.use?.xLabel;
  const yLabels =
    displayMoney === 'cost'
      ? chartData.dataSets.cost?.yLabel
      : chartData.dataSets.use?.yLabel;

  const barColors = themeToColor(chartTheme.split(','));

  const data =
    displayMoney === 'cost' ? newTransformedDataCost : newTransformedData;

  let energyData;

  const getChartData = () => {
    if (transformedDataEnergy) {
      return (energyData = newTransformedDataEnergy);
    }

    if (transformedDataCarbon) {
      return (energyData = newTransformedDataCarbon);
    }

    return (energyData = data);
  };
  const renderCustomizedLabel = (props) => {
    const { x, y, height, width, value } = props;
    const radius = 10;
    return (
      <text
        x={x + width / 2}
        y={y - radius}
        width={width}
        height={height}
        fill="#0F3E5D"
        style={{ fontWeight: 700 }}
        textAnchor="middle"
      >
        <tspan x={x + width / 2} dy="0em">
          {prettifyIfNumber(value)}
        </tspan>
      </text>
    );
  };
  const CustomTooltip = ({ active, payload }: any) => {
    const wrapStyle = {
      backgroundColor: '#fff',
      padding: '10px',
      border: '1px solid #c8ced3',
      borderRadius: '2px',
      boxShadow: '-7px 8px 16px rgba(0, 0, 0, 0.10)',
      minWidth: '180px',
      fontFamily: 'Open sans',
    };
    if (active && payload && payload.length) {
      return (
        <div style={wrapStyle}>
          <p>{t(`${payload[0].payload.label}`)}</p>
          <p>
            {original}:{' '}
            <b style={{ color: '#0b2641' }}>
              {prettifyIfNumber(payload[0].payload.originalValue)}
            </b>
          </p>
          <p>
            {current}:{' '}
            <b style={{ color: '#246b13' }}>
              {prettifyIfNumber(payload[0].payload.value)}
            </b>
          </p>
        </div>
      );
    }

    return null;
  };

  const getYlabels = () => {
    if (transformedDataEnergy) {
      const label = chartData.dataSets.energy?.yLabel;
      return label && t(label);
    }

    if (transformedDataCarbon) {
      const label = chartData.dataSets.carbon?.yLabel;
      return label && t(label);
    }

    return yLabels && t(yLabels);
  };

  const geXlabels = () => {
    if (transformedDataEnergy) {
      const label = chartData.dataSets.energy?.xLabel;

      return label && t(label);
    }

    if (transformedDataCarbon) {
      const label = chartData.dataSets.carbon?.xLabel;
      return label && t(label);
    }

    return xLabels && t(xLabels);
  };

  const renderButtons = () => {
    return _.map(chartData.links, (el, index) => {
      return (
        <Styled.ButtonComp key={index} onClick={() => navigate(el.link)}>
          <Icon icon={el.icon} size={40} />
          <p style={{ flex: 1, textAlign: 'left', marginLeft: '16px' }}>
            {t(el.text)}
          </p>
          <Icon icon="chevronRight" size={12} />
        </Styled.ButtonComp>
      );
    });
  };

  return (
    <Styled.Wrap>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <Styled.VerticalLine>
          <p
            style={{
              transform: 'rotate(270deg)',
              width: '70px',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0 0 26px 0',
            }}
          >
            {getYlabels()}
          </p>
        </Styled.VerticalLine>
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <ResponsiveContainer height={280} width="100%">
            <BarChart
              data={getChartData()}
              height={400}
              margin={{
                top: 50,
                right: 0,
                bottom: 0,
                left: 0,
              }}
              barCategoryGap={40}
            >
              <Tooltip
                content={<CustomTooltip />}
                cursor={{ fill: 'rgba(215,241,255,0.5)' }}
              />
              <Bar
                name="value"
                maxBarSize={80}
                barSize={80}
                radius={[4, 4, 0, 0]}
                dataKey="value"
                minPointSize={3}
                stackId="a"
              >
                <LabelList
                  dataKey="value"
                  content={renderCustomizedLabel}
                  position="top"
                />
                ((
                {getChartData()?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={_.get(barColors, index, theme)}
                  />
                ))}
              </Bar>
              <Bar
                name="difference"
                className="original-values"
                dataKey="difference"
                radius={[4, 4, 0, 0]}
                stackId="a"
              >
                ((
                {getChartData()?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={_.get(barColors, index, theme)}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
          <Styled.HorizontalLine>{geXlabels()}</Styled.HorizontalLine>
        </div>
      </div>
      {CustomBarLegend({
        data: energyData,
        color: barColors,
        original,
        current,
      })}
      <Styled.ButtonWrap>
        {chartData.links ? renderButtons() : null}
      </Styled.ButtonWrap>
    </Styled.Wrap>
  );
};

export default ChartBars;
