import * as React from 'react';

const worker = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M75.965 82.85a1.65 1.65 0 01-1.647 1.646h-48.67A1.65 1.65 0 0124 82.85V73.83a24.843 24.843 0 0118.808-24.143 15.857 15.857 0 007.175 1.721c2.576 0 5.003-.627 7.16-1.714 11.096 2.78 18.822 12.651 18.822 24.136v9.02z"
          fill={colorSecondary}
        />
        <path
          d="M49.956 13c9.308 0 17.115 6.566 19.018 15.313l.072.347h3.11c.55 0 1 .45 1 1 0 .51-.388.935-.884.993l-.116.007h-6.912a15.93 15.93 0 01.725 4.762c0 5.267-2.572 9.933-6.514 12.847 9.915 3.228 16.972 11.844 18.274 22.128l.05.43.144 2 .034.668.008.335v9.02a3.652 3.652 0 01-3.454 3.641l-.193.005h-48.67a3.652 3.652 0 01-3.643-3.453L22 82.849V73.83l.008-.335.035-.669.144-2A26.804 26.804 0 0140.52 48.278c-3.948-2.914-6.525-7.584-6.525-12.856 0-1.658.254-3.258.724-4.763h-6.964c-.55 0-1-.45-1-1 0-.51.388-.934.884-.992l.116-.007h3.12c.89-4.46 3.3-8.38 6.67-11.18 3.37-2.8 7.7-4.48 12.41-4.48zm-7.148 36.687a24.841 24.841 0 00-18.805 23.754l-.003.39v9.018c0 .86.663 1.569 1.506 1.641l.142.006h48.67a1.65 1.65 0 001.64-1.505l.007-.142V73.83c0-11.485-7.726-21.357-18.821-24.136a15.868 15.868 0 01-7.161 1.714c-2.582 0-5.014-.629-7.175-1.72zM63.134 30.66H36.83a13.92 13.92 0 00-.835 4.763c0 7.712 6.275 13.986 13.987 13.986 7.71 0 13.986-6.274 13.986-13.986 0-1.671-.295-3.275-.835-4.763zM48.956 15.03c-7.759.435-14.18 5.967-15.96 13.286l-.08.345h2.582c2.414-5.15 7.498-8.811 13.458-9.191v-4.44zm2 6.44v1.38c0 .56-.45 1-1 1a.996.996 0 01-.993-.881l-.007-.118v-1.377c-4.827.352-8.983 3.165-11.214 7.187h24.48c-2.239-4.036-6.416-6.855-11.266-7.19zm0-6.44v4.437c5.983.36 11.09 4.028 13.51 9.194h2.54c-1.67-7.49-8.17-13.19-16.05-13.63z"
          fill={colorPrimary}
        />
        <path
          d="M67.006 28.66h-34.09c1.66-7.49 8.16-13.19 16.04-13.63v7.82c0 .56.45 1 1 1s1-.44 1-1v-7.82c7.88.44 14.38 6.14 16.05 13.63"
          fill={colorSecondary}
        />
      </g>
    </>
  );
};
export default worker;
