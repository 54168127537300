import React from 'react';
import { Wrap, Task, EasyWin, Loader } from '../../components/index';
import * as _ from 'lodash';
import { useResponsivity } from '../../helpers/helpers';
import { Col } from 'react-styled-flexboxgrid';
import { useTranslation } from 'react-i18next';

interface KettleProps {
  challenges: any;
  listLocation: any;
  getChallengeCategory: Function;
  completeTask: any;
  completeChallenge: Function;
  generalData: Function;
}

const Kettle: React.FC<KettleProps> = (props) => {
  const { challenges } = props;

  const isMobile = useResponsivity();
  const [t] = useTranslation('common');

  const loadingChallenges = _.get(challenges, 'status.loading');

  const pageArray = [
    {
      id: 268,
      title: 'Kettles',
      poster:
        'https://cdn.getwaterfit.co.uk/static/files/images/gwf-business/posters/penguin.png',
      emailCopy: 'Remember to wash up using a bowl, or pop a plug in the sink.',
      easyWins: [
        {
          title: 'Introduction',
          theme: 'intro',
          size: 'full',
          text: 'Over-filling the kettle wastes both water and energy.',
        },
        {
          title: 'Easy Win',
          theme: 'red',
          size: 'half',
          icon: null,
          buttonText: 'Download now',
          buttonPath:
            'https://cdn.getwaterfit.co.uk/static/files/images/gwf-business/posters/penguin.pdf',
          text: 'Print off the poster and place within each kitchen to encourage staff to boil only what they need. This poster will reduce water and energy use in your business.',
        },
        {
          title: 'Easy Win',
          theme: 'red',
          size: 'half',
          icon: null,
          text: 'The easiest way to ensure only the required amount of water is boiled is to use a kettle which features measurable cup markings down the side.',
        },
        {
          title: 'Easy Win Pro',
          theme: 'blue',
          size: 'full',
          icon: 'worker',
          text: 'The best way to ensure staff only use the water they need is to install a boiling water tap.#This provides boiling water on demand. There are plenty of brands available, so shop around and talk to your Facilities Manager or Plumber to ensure it can be fitted. Since a boiling water tap saves both water and energy, talk to the manufacturer to determine when you can expect to get your money back through savings.',
        },
      ],
    },
  ];

  if (loadingChallenges) {
    return (
      <Wrap>
        <Loader />
      </Wrap>
    );
  }

  return (
    <Wrap>
      <EasyWin data={pageArray} />
    </Wrap>
  );
};

export default Kettle;
