import React from 'react';
import Styled from './Search.style';
import { useTypewriter } from '../../helpers/formatters';
interface SearchProps {
  search: any;
  getSearchData: Function;
  [anyProp: string]: any;
  placeholder?: string;
  onChange: Function;
  value: string | undefined;
  result?: string;
  setData: Function;
  clearSearchData: Function;
  setPreviousData: Function;
}
const Search: React.FC<SearchProps> = (props) => {
  const {
    getSearchData,
    setPreviousData,
    placeholder,
    result,
    setData,
    clearSearchData,
  } = props;
  const [isValue, setValue] = React.useState('');
  const inputElement = React.useRef<HTMLInputElement | null>(null);
  const words = [
    'Water efficiency products...',
    'Energy saver...',
    'Carbon neutral...',
    'Environment friendly...',
  ];
  const text = useTypewriter(words);
  const handleSet = (e) => {
    setValue(e.target.value);
    setData(e.target.value);
    if (isValue === '') {
      clearSearchData();
    }
  };
  const searchIcon = () => (
    <svg width="100%" height="100%" viewBox="0 0 100 100">
      <path
        fill={'#000'}
        fillRule="evenodd"
        d="M65.25 57l34.58 34.57-8.49 8.49-34.61-34.62a36 36 0 1 1 8.52-8.45zM36 62a26 26 0 1 0 0-52 26 26 0 0 0 0 52z"
      />
    </svg>
  );
  return (
    <Styled.Wrap>
      <div>
        <Styled.InputWrap>
          <Styled.Input
            placeholder={placeholder ? placeholder : text}
            value={isValue}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && isValue !== '') {
                getSearchData();
                setPreviousData(isValue);
              }
            }}
            onChange={handleSet}
          />
          {isValue === '' ? null : (
            <Styled.Close
              onClick={() => {
                setValue('');
                setData('');
                clearSearchData();
              }}
            >
              x
            </Styled.Close>
          )}
        </Styled.InputWrap>
        <Styled.Button
          ref={inputElement}
          onClick={(e) => {
            e.preventDefault();
            if (isValue !== '') {
              getSearchData();
            }
            setPreviousData(isValue);
          }}
        >
          {searchIcon()}
        </Styled.Button>
      </div>
      {result && isValue ? (
        <p style={{ marginTop: '0px', fontSize: '14px' }}>
          <i>
            Results:
            <b>{result}</b>
          </i>
        </p>
      ) : null}
    </Styled.Wrap>
  );
};
export default Search;
