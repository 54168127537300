import React from 'react';
import * as _ from 'lodash';
import Styled from './Preferences.style';
import {
  Wrap,
  NothingToDisplay,
  Loader,
  ErrorDisplay,
} from '../../components/index';
import { useTranslation } from 'react-i18next';
import FormUserDetails from './FormUserDetails';
import { useResponsivity } from '../../helpers/helpers';

interface PreferencesProps {
  deleteAccount: Function;
  logOut: Function;
  page: any;
  setPreferences: Function;
}

const Preferences: React.FC<PreferencesProps> = (props) => {
  const { deleteAccount, logOut, page, setPreferences } = props;
  const { loading, error, done } = props.page.userData.status;

  const [t] = useTranslation('common');
  const isMobile = useResponsivity();

  const doneUpdate = _.get(props, 'page.updateData.status.done');
  const errorUpdate = _.get(props, 'page.updateData.status.error.msg');

  if (error && !loading)
    return (
      <div>
        <ErrorDisplay />
      </div>
    );
  if (loading && !done)
    return (
      <div style={{ position: 'relative', height: '100px' }}>
        <Loader />
      </div>
    );

  return (
    <Wrap>
      <Styled.Wrap isDesktop={!isMobile}>
        <FormUserDetails
          data={page.userData}
          deleteAccount={deleteAccount}
          logOut={logOut}
          setPreferences={setPreferences}
          doneMessage={doneUpdate}
          erroMessage={errorUpdate}
        />
      </Styled.Wrap>
    </Wrap>
  );
};

export default Preferences;
