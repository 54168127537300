import axios from 'axios';
import * as _ from 'lodash';
import { getMicroServiceUrl } from '../helpers/helpers';

export const setCustomerService = (data, onSuccess, onFailed) => {
  const ticket = JSON.stringify({
    recaptchaResponse: data.recaptchaResponse,
    zendeskRequest: {
      request: {
        subject: data.subject,
        comment: {
          body: `message: ${data.freeText}`,
        },
        custom_fields: [
          { id: 1900005085913, value: data.name },
          { id: 1900005085933, value: data.phone },
          { id: 360029604918, value: data.email },
          { id: 1900005086093, value: data.freeText },
        ],
      },
    },
  });

  return (dispatch) => {
    const response = dispatch({
      type: 'SET_CONTACT_FORM',
      payload: axios.post(
        `${getMicroServiceUrl('business-user')}contact`,
        ticket,
        {
          headers: {
            Authorization: 'Basic',
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      ),
    });
    response
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onFailed();
      });
  };
};
