import Login from './Login';
import { connect } from 'react-redux';
import { logIn } from '../../actions/index';
import { AppState } from '../../interfaces';

const mapStateToProps = (state: AppState) => ({
  auth: state.authReducer,
});

const mapDispatchToProps = (dispatch) => ({
  logIn: ({ email, password }) => dispatch(logIn({ email, password })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
