import React from 'react';
import Styled from './Navigation.style';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '../index';
import { useResponsivity } from '../../helpers/helpers';
import { isLoggedIn } from '../../helpers/auth';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

interface NavigationProps {
  logOut: Function;
}

const Navigation: React.FC<NavigationProps> = (props) => {
  const { logOut } = props;
  const [open, setOpen] = React.useState(false);
  const auth = isLoggedIn();

  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname;

  const [t] = useTranslation('common');
  const nav = [
    { path: '/portal', name: t('portal.header.menu_home') },
    { path: '/use', name: 'Use' },
    { path: '/easy-wins', name: t('portal.header.menu_easywins') },
    { path: '/offers', name: 'Offers' },
    { path: '/profile', name: t('portal.header.menu_myProfile') },
  ];

  const isMobile = useResponsivity();

  const renderNav = () => {
    return _.map(nav, (el, index) => {
      return (
        <Link
          key={index}
          to={el.path}
          className={path === el.path ? 'active' : ''}
          style={{
            fontSize: !isMobile ? '16px' : '20px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Styled.Text onClick={() => (isMobile ? setOpen(!open) : null)}>
            {el.name}
          </Styled.Text>
        </Link>
      );
    });
  };

  const renderSignIn = () => {
    return (
      <Link
        to="/login"
        style={{
          fontSize: !isMobile ? '20px' : '16px',
          display: 'flex',
          flexDirection: 'row',
          marginLeft: 'auto',
          alignItems: 'center',
          padding: '0px',
          color: '#0b2641',
          textDecoration: 'none',
        }}
      >
        {!isMobile ? (
          <Styled.Text>{t('portal.header.sign_in')}</Styled.Text>
        ) : null}{' '}
        <Icon icon="man" size={!isMobile ? 35 : 30} />
      </Link>
    );
  };

  const renderStyleNav = () => {
    return isMobile ? (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          textTransform: 'none',
          cursor: 'pointer',
          fontSize: !isMobile ? '16px' : '20px',
        }}
        onClick={(e) => {
          e.preventDefault();
          setOpen(false);
          logOut();
          setTimeout(() => {
            navigate('/login');
          }, 1000);
        }}
      >
        <Styled.Text style={{ margin: '0px', fontSize: '18px' }}>
          {t('portal.header.menu_signout')}
        </Styled.Text>
      </div>
    ) : (
      <>
        {renderNav()}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            textTransform: 'none',
            cursor: 'pointer',
            fontSize: !isMobile ? '16px' : '20px',
          }}
          onClick={(e) => {
            e.preventDefault();
            setOpen(false);
            logOut();
            setTimeout(() => {
              navigate('/login');
            }, 1000);
          }}
        >
          <Styled.Text>{t('portal.header.menu_signout')}</Styled.Text>
        </div>
      </>
    );
  };

  return (
    <Styled.Menu isDesktop={!isMobile}>
      {auth ? <>{renderStyleNav()} </> : renderSignIn()}
    </Styled.Menu>
  );
};

export default Navigation;
