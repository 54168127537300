import * as React from 'react';

const garbageDisposal = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M58.327 11c.55 0 1.002.416 1.06.95l.006.117v3.942H86.19a1 1 0 01.994.884l.006.116V31.44a1 1 0 01-.883.994l-.117.007h-3.737l-6.546 57.264c-.057.501-.454.887-.945.94l-.115.005H24.441c-.51 0-.94-.358-1.044-.846l-.018-.115-5.657-57.248H14a1 1 0 01-.993-.884L13 31.441V17.01a1 1 0 01.884-.994L14 16.01h26.795v-3.944c0-.55.417-1.001.951-1.06l.116-.005h16.465zm21.971 21.507H69.734l-5.613 56.012h9.775l6.402-56.013zm-51.03 0h-9.396l5.535 56.012h7.52l-3.66-56.013zm38.321 0H51.161v56.012h10.815l5.613-56.013zm-18.56 0H31.404l3.66 56.012h13.963V32.506zM41.532 18.01H15v12.43h3.381c.07-.02.137-.043.207-.056l.107-.01h62.8c.071 0 .14.016.208.036l.101.03h3.386V18.01H58.656a1.42 1.42 0 01-.215.057l-.114.01H41.862a.83.83 0 01-.222-.035l-.107-.033zm15.727-4.878H42.928v2.812H57.26v-2.812z"
          fill={colorPrimary}
        />
        <path
          d="M85.19 30.44h-3.386c-.1-.03-.202-.066-.31-.066H18.695c-.109 0-.21.035-.314.067h-3.38V18.01h26.532c.105.034.213.066.33.066h16.464c.116 0 .223-.032.33-.067H85.19V30.44z"
          fill={colorSecondary}
        />
      </g>
    </>
  );
};
export default garbageDisposal;
