import * as React from 'react';

const pen = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M55.876 13.757c5.828-7.574 16.733-8.997 24.31-3.169a17.223 17.223 0 016.62 11.498 17.218 17.218 0 01-3.19 12.465l-.26.346-42.67 55.466a.998.998 0 01-.337.273l-.062.023-.065.02a.964.964 0 01-.274.061l-.057.013c-.056 0-.11-.017-.167-.027-.037-.006-.075-.007-.112-.018a1.076 1.076 0 01-.225-.093l-.105-.069-24.01-18.47 2.36 19.824 8.71-1.235a.999.999 0 01.396 1.957l-.115.023-9.723 1.38a1.003 1.003 0 01-1.11-.74l-.024-.133-2.76-23.19a.995.995 0 01.024-.353l.015-.048.018-.047a.91.91 0 01.114-.232l.03-.058 42.67-55.467zm14.69 12.563L29.113 80.201l10.595 8.15 41.451-53.88-10.595-8.15zM56.85 15.77l-41.45 53.88 12.128 9.331 41.451-53.88-12.129-9.332zm22.114-3.597c-6.338-4.875-15.3-4-20.615 1.776l-.239.266 24.23 18.64a15.205 15.205 0 002.48-10.51 15.23 15.23 0 00-5.856-10.172z"
          fill={colorPrimary}
        />
        <path
          d="M78.966 12.173a15.236 15.236 0 015.856 10.172 15.206 15.206 0 01-2.481 10.51l-24.23-18.64c5.283-6.015 14.42-6.992 20.855-2.042"
          fill={colorSecondary}
        />
        <path fill="#D7F1FF" d="M15.401 69.666l41.45-53.881 12.13 9.33-41.452 53.882z" />
      </g>
    </>
  );
};
export default pen;
