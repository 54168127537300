import * as React from 'react';

const hotTub = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M70.39 42.062a1 1 0 011.247-.667C86.783 45.989 96.19 54.992 96.19 64.89c0 14.639-20.68 26.549-46.096 26.549C24.678 91.44 4 79.53 4 64.89c0-9.588 8.973-18.454 23.416-23.14a1 1 0 11.617 1.904C14.443 48.06 6 56.199 6 64.89 6 78.427 25.782 89.44 50.095 89.44c24.314 0 44.096-11.013 44.096-24.55 0-8.983-8.865-17.254-23.134-21.581a1 1 0 01-.667-1.247zm12.464 9.006a1.002 1.002 0 011.4.203c2.019 2.706 3.043 5.593 3.043 8.582 0 11.86-16.688 21.51-37.202 21.51-20.513 0-37.201-9.65-37.201-21.51 0-1.447.246-2.892.73-4.296a1.002 1.002 0 011.892.655 11.107 11.107 0 00-.622 3.64c0 10.759 15.79 19.51 35.2 19.51 19.41 0 35.203-8.751 35.203-19.51 0-2.547-.89-5.032-2.646-7.384a1 1 0 01.203-1.4zM70.794 9a6.265 6.265 0 016.254 6.027l.004.229v3.155a1 1 0 01-1.993.116l-.007-.116v-3.155A4.262 4.262 0 0070.794 11a4.261 4.261 0 00-4.252 4.05l-.005.206V47.09a1 1 0 01-1.993.117l-.007-.117v-3.38H44.221v3.38a1 1 0 01-1.993.117l-.007-.117V15.257A6.264 6.264 0 0148.479 9a6.263 6.263 0 016.252 6.027l.004.229v3.155a1 1 0 01-1.993.116l-.007-.116v-3.155A4.26 4.26 0 0048.479 11a4.262 4.262 0 00-4.253 4.05l-.005.206v6.249h20.316v-6.25a6.263 6.263 0 016.257-6.255zm-6.257 27.974H44.221v4.735h20.316v-4.735zm0-6.734H44.221v4.734h20.316V30.24zm0-6.735H44.221v4.734h20.316v-4.734z"
          fill={colorPrimary}
        />
        <path
          d="M39.418 31.804a1 1 0 011 1c0 1.788-1.013 2.797-1.979 3.576l-.773.608c-.802.646-1.248 1.142-1.248 2.03 0 .888.446 1.383 1.248 2.029l.773.608c.966.779 1.979 1.788 1.979 3.576s-1.013 2.797-1.979 3.574l-.773.609c-.802.645-1.248 1.14-1.248 2.027 0 .949.512 1.448 1.424 2.165l.459.357c1.004.792 2.117 1.811 2.117 3.682 0 1.787-1.013 2.796-1.979 3.573l-.773.608c-.744.6-1.182 1.068-1.241 1.842l-.007.184a1 1 0 11-2 0c0-1.787 1.013-2.796 1.979-3.573l.773-.608c.802-.645 1.248-1.14 1.248-2.026 0-.886-.446-1.38-1.248-2.026l-.773-.607c-.966-.776-1.979-1.785-1.979-3.571 0-1.787 1.013-2.796 1.979-3.574l.773-.608c.802-.646 1.248-1.14 1.248-2.028 0-.888-.446-1.384-1.248-2.029l-.773-.608c-.966-.779-1.979-1.788-1.979-3.576s1.013-2.798 1.979-3.576l.773-.609c.802-.646 1.248-1.141 1.248-2.03a1 1 0 011-1zm-5.806 0a1 1 0 011 1c0 1.788-1.013 2.797-1.979 3.576l-.773.608c-.801.646-1.248 1.142-1.248 2.03 0 .951.513 1.452 1.425 2.17l.596.467c.966.779 1.98 1.788 1.98 3.576s-1.014 2.797-1.98 3.574l-.773.609c-.801.645-1.248 1.14-1.248 2.027 0 .949.513 1.448 1.425 2.165l.458.357c1.005.792 2.117 1.811 2.117 3.682 0 1.787-1.013 2.796-1.979 3.573l-.773.608c-.801.645-1.248 1.14-1.248 2.026a1 1 0 11-2 0c0-1.787 1.013-2.796 1.98-3.573l.772-.608c.802-.645 1.248-1.14 1.248-2.026 0-.886-.446-1.38-1.248-2.026l-.635-.498c-1.005-.791-2.117-1.81-2.117-3.68 0-1.787 1.013-2.796 1.98-3.574l.772-.608c.802-.646 1.248-1.14 1.248-2.028 0-.888-.446-1.384-1.248-2.029l-.635-.498c-1.005-.794-2.117-1.813-2.117-3.686 0-1.788 1.013-2.798 1.98-3.576l.772-.609c.802-.646 1.248-1.141 1.248-2.03a1 1 0 011-1z"
          fill={colorSecondary}
        />
      </g>
    </>
  );
};
export default hotTub;
