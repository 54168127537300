import styled from 'styled-components';

const Item = styled.div`
  display: flex;
  align-items: center;
  .desc {
    min-width: 200px;
    @media screen and (max-width: 600px) {
      min-width: 150px;
    }
  }
`;

export default { Item };
