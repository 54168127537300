import React from 'react';
import { Wrap, Product, Typography } from '../../components/index';
import * as _ from 'lodash';
import { Row, Col } from 'react-styled-flexboxgrid';
import { useResponsivity } from '../../helpers/helpers';
import { useTranslation } from 'react-i18next';

const FreeProducts: React.FC = () => {
  const isMobile = useResponsivity();
  const [t] = useTranslation('common');
  const data = {
    success: true,
    data: {
      items: [
        {
          id: 30,
          hasFreeProducts: true,
          name: 'Sutton and East Surrey Water',
          slug: 'suttoneastsurrey',
          active: true,
          groups: [
            {
              name: 'Choose either of these products',
              limit: 1,
              products: [
                {
                  id: 3520,
                  metatype: 'SHOWERREGULATOR',
                  metadescription:
                    'The Flowpoint Eco shower head is a single setting chrome shower head that will can reduce the amount of hot water you use in the',
                  title: 'Regulated shower head',
                  description:
                    '\u003Cp\u003ERegulated to deliver a constant seven litres of water per minute, with as little as 0.2 bar pressure.\u003C/p\u003E\r\n\u003Cp\u003EUnfortunately, it\u0027s not suitable for electric, multijet, digital or Aqualisa showers, or lower pressure or gravity-fed systems with less than 0.5 bar pressure.\u003C/p\u003E',
                  freePackDescription:
                    'Regulated to deliver a constant seven litres of water per minute, with as little as 0.2 bar pressure.\r\nUnfortunately, it\u0027s not suitable for electric, multijet, digital or Aqualisa showers, or lower pressure or gravity-fed systems with less than 0.5 bar pressure.',
                  maxperhousehold: 1,
                  state: 'ACTIVE',
                  limitedOffer: false,
                  images: {
                    path: '/product-images/3520/flowpoint-eco-shower-head-suttoneastsurrey-water-3520--1-master.jpeg',
                  },
                  imagesList: [
                    {
                      path: '/product-images/3520/flowpoint-eco-shower-head-suttoneastsurrey-water-3520--1-master.jpeg',
                    },
                  ],
                },
                {
                  id: 3005,
                  metatype: 'SHOWERREGULATOR',
                  metadescription:
                    'This ShowerSave regulator regulates your shower to just 8 litres per minute',
                  title: 'Shower regulator',
                  description:
                    '\u003Cp\u003EFits to the base of your shower hose and is regulated to deliver a constant eight litres of water per minute.\u003C/p\u003E\r\n\u003Cp\u003EUnfortunately, it\ufffds not suitable for electric, multijet, digital or Aqualisa showers, or lower pressure or gravity fed systems with less than 0.5 bar pressure.\u003C/p\u003E',
                  freePackDescription:
                    'Fits to the base of your shower hose and is regulated to deliver a constant eight litres of water per minute.\r\nUnfortunately, it\ufffds not suitable for electric, multijet, digital or Aqualisa showers, or lower pressure or gravity fed systems with less than 0.5 bar pressure.',
                  maxperhousehold: 1,
                  state: 'ACTIVE',
                  limitedOffer: false,
                  images: {
                    path: '/product-images/3005/shower-save-regulator-3005--master.jpeg',
                  },
                  imagesList: [
                    {
                      path: '/product-images/3005/shower-save-regulator-3005--master.jpeg',
                    },
                  ],
                },
              ],
            },
            {
              name: 'Choose any of these products',
              limit: 20,
              products: [
                {
                  id: 7079,
                  metatype: 'STOPTAPTAG',
                  metadescription: 'Stop Tap Tag',
                  title: 'Stop Tap Tag',
                  description:
                    'Use this identifying tag to hang on your Stop Tap within your home allowing you to also record details of a plumber in case of emergency.',
                  freePackDescription:
                    'Use this identifying tag to hang on your Stop Tap within your home allowing you to also record details of a plumber in case of emergency.',
                  maxperhousehold: 1,
                  state: 'ACTIVE',
                  limitedOffer: false,
                  images: {
                    path: '/product-images/7079/product-swsm7079-7079--master.png',
                  },
                  imagesList: [
                    {
                      path: '/product-images/7079/product-swsm7079-7079--master.png',
                    },
                  ],
                },
                {
                  id: 7109,
                  metatype: 'BUBBLESTREAM',
                  metadescription: 'Kitchen Stream',
                  title: 'Kitchen Stream',
                  description:
                    '\u003Cp\u003ESave water on your kitchen tap with this 360 degree swivel tap aerator providing multi-directional spray, with a 6.8 litre per minute flow regulator. Suitable to fit most standard kitchen mixer taps with a screwed outlet female housing of 22mm or male housing of 24mm.\u003C/p\u003E',
                  freePackDescription:
                    'Save water on your kitchen tap with this 360 degree swivel tap aerator providing multi-directional spray, with a 6.8 litre per minute flow regulator. Suitable to fit most standard kitchen mixer taps with a screwed outlet female housing of 22mm or male housing of 24mm.',
                  maxperhousehold: 1,
                  state: 'ACTIVE',
                  limitedOffer: false,
                  images: {
                    path: '/product-images/7109/product-swsm7109-7109--master.jpeg',
                  },
                  imagesList: [
                    {
                      path: '/product-images/7109/product-swsm7109-7109--master.jpeg',
                    },
                  ],
                },
                {
                  id: 3032,
                  metatype: 'TAPINSERTS',
                  metadescription:
                    'This Free Tap Aerator Inserts Kit  from Sutton and East Surrey comes with two sets of tap aerators and a specially made fitting tool to help you remove the end of your taps without damaging the chrome housing.',
                  title: 'Regulated tap insert twin pack',
                  description:
                    '\u003Cp\u003ESuitable to fit most basin taps with a screwed outlet housing and two flat edges. A fitting tool is provided to unscrew and retain the original housing. Once fitted, the tap aerators will regulate the flow of water to 5 litres per minute to help save water and money. Two aerators are provided to fit on both hot and cold outlets where required.\u003C/p\u003E',
                  freePackDescription:
                    'Suitable to fit most basin taps with a screwed outlet housing and two flat edges. A fitting tool is provided to unscrew and retain the original housing. Once fitted, the tap aerators will regulate the flow of water to 5 litres per minute to help save water and money. Two aerators are provided to fit on both hot and cold outlets where required.',
                  maxperhousehold: 1,
                  state: 'ACTIVE',
                  limitedOffer: false,
                  images: {
                    path: '/product-images/3032/tap-insert-pack-3032--1-master.jpeg',
                  },
                  imagesList: [
                    {
                      path: '/product-images/3032/tap-insert-pack-3032--1-master.jpeg',
                    },
                  ],
                },
                {
                  id: 3321,
                  metatype: 'SHOWERTIMER',
                  metadescription:
                    'The 4 minute shower timer with a monkey card is designed to help your family cut their energy and water bills, by helping you not take too long a shower. Showers are the biggest users of water in UK homes, accounting for a quarter of all water used. Even if you\ufffdre not on a water meter heating all that water takes a lot of energy and costs a lot in fuel bills - \ufffd218 a year for the average UK household.',
                  title: 'Four-minute shower timer',
                  description:
                    '\u003Cp\u003EThis timer is designed to help you and your family reduce the time you spend in the shower and save energy and water in the process. As it contains small parts, which area potential choking hazard, this product isn\u0027t suitable for children under three.\u003C/p\u003E',
                  freePackDescription:
                    'This timer is designed to help you and your family reduce the time you spend in the shower and save energy and water in the process. As it contains small parts, which area potential choking hazard, this product isn\u0027t suitable for children under three.',
                  maxperhousehold: 1,
                  state: 'ACTIVE',
                  limitedOffer: false,
                  images: {
                    path: '/product-images/3321/shower-timer-sutton-east-surrey-monkey-card-3321-15131-master.jpeg',
                  },
                  imagesList: [
                    {
                      path: '/product-images/3321/shower-timer-sutton-east-surrey-monkey-card-3321-15131-master.jpeg',
                    },
                  ],
                },
                {
                  id: 6057,
                  metatype: 'BUFFALOOBAG',
                  metadescription:
                    'Save between 1- 2 litres of water every time you flush',
                  title: 'Buffaloo cistern bag',
                  description:
                    '\u003Cp\u003EPop this into your single lever toilet cistern to save one to two litres every time you flush. Not suitable for dual flush toilets.\u003C/p\u003E',
                  freePackDescription:
                    'Pop this into your single lever toilet cistern to save one to two litres every time you flush. Not suitable for dual flush toilets.',
                  maxperhousehold: 3,
                  state: 'ACTIVE',
                  limitedOffer: false,
                  images: {
                    path: '/product-images/6057/buffaloo-cistern-bag-6057-15203-master.png',
                  },
                  imagesList: [
                    {
                      path: '/product-images/6057/buffaloo-cistern-bag-6057-15203-master.png',
                    },
                  ],
                },
                {
                  id: 3175,
                  metatype: 'LEAKYLOO',
                  metadescription:
                    'Did you know that a leaking toilet can waste as much as 400 litres of water every day? But worry not, if you suspect that your toilet is leaking, LeakyLoo is here to help. This clever sugar paper like strip will dissolve on contact with water to indicate a leak, and once you know, you can call a plumber to fix the leak and save litres of water as a result.',
                  title: 'LeakyLoo detection strips (including instructions)',
                  description:
                    '\u003Cp\u003EApply one of these strips to your toilet pan to see if your push button toilet has a leak. They\u0027re made from biodegradable water-soluble paper which flushes away.Instructions for use are included with your strips.\u003C/p\u003E',
                  freePackDescription:
                    'Apply one of these strips to your toilet pan to see if your push button toilet has a leak. They\u0027re made from biodegradable water-soluble paper which flushes away.Instructions for use are included with your strips.',
                  maxperhousehold: 1,
                  state: 'ACTIVE',
                  limitedOffer: false,
                  images: {
                    path: '/product-images/3175/leaky-loo-3175--master.jpeg',
                  },
                  imagesList: [
                    {
                      path: '/product-images/3175/leaky-loo-3175--master.jpeg',
                    },
                    {
                      path: '/product-images/3175/leaky-loo-3175--1-master.jpeg',
                    },
                  ],
                },
                {
                  id: 6081,
                  metatype: 'LEAKYLOO',
                  metadescription:
                    'Additional LeakyLoo Toilet Leak Detection Strips',
                  title: 'Extra LeakyLoo detection strips (no instruction)',
                  description:
                    '\u003Cp\u003EMore biodegradable strips to detect leaks in your push button toilet.Instructions not included.\u003C/p\u003E',
                  freePackDescription:
                    'More biodegradable strips to detect leaks in your push button toilet.Instructions not included.',
                  maxperhousehold: 3,
                  state: 'ACTIVE',
                  limitedOffer: false,
                  images: {
                    path: '/product-images/6081/product-swsm6081-6081--1-master.jpeg',
                  },
                  imagesList: [
                    {
                      path: '/product-images/6081/product-swsm6081-6081--1-master.jpeg',
                    },
                    {
                      path: '/product-images/6081/product-swsm6081-6081--2-master.jpeg',
                    },
                  ],
                },
                {
                  id: 3850,
                  metatype: 'LEAFLET-30-A',
                  metadescription:
                    'This self-audit leaflet for Sutton and East Surrey  will help you better understand how much water you use around the home, and what opportunities there are to reduce your usage just by changing your behaviour a little.',
                  title: 'Water saving leaflet',
                  description: '-',
                  freePackDescription: '-',
                  maxperhousehold: 1,
                  state: 'ACTIVE',
                  limitedOffer: false,
                  images: {
                    path: '/product-images/3850/water-audit-leaflet-water-3850-14926-master.jpeg',
                  },
                  imagesList: [
                    {
                      path: '/product-images/3850/water-audit-leaflet-water-3850-14926-master.jpeg',
                    },
                  ],
                },
                {
                  id: 3229,
                  metatype: 'GEL',
                  metadescription:
                    'Reduces watering by up to 4 times, improves plant quality, flower production and productivity of dry soils. Add water gel to soil before planting to aid water retention in hanging baskets, containers and pots.',
                  title: 'Plant water-saving gel',
                  description:
                    '\u003Cp\u003EBy adding this gel to the soil before planting your hanging baskets, containers and pots, you\u0027ll increase the amount of water it retains and reduce the amount of watering it needs.\u003C/p\u003E',
                  freePackDescription:
                    'By adding this gel to the soil before planting your hanging baskets, containers and pots, you\u0027ll increase the amount of water it retains and reduce the amount of watering it needs.',
                  maxperhousehold: 1,
                  state: 'ACTIVE',
                  limitedOffer: false,
                  images: {
                    path: '/product-images/3229/water-saving-gel-sutton-east-surrey-water-3229-5161-master.png',
                  },
                  imagesList: [
                    {
                      path: '/product-images/3229/water-saving-gel-sutton-east-surrey-water-3229-5161-master.png',
                    },
                    {
                      path: '/product-images/3229/water-saving-gel-sutton-east-surrey-water-3229--master.jpeg',
                    },
                  ],
                },
                {
                  id: 3883,
                  metatype: 'SINKPLUG',
                  metadescription: 'Universal Silicone Sink Plug in Blue',
                  title: 'Universal sink plug',
                  description:
                    '\u003Cp\u003EThis universal silicone sink plug effectively prevents leaks by creating a water-tight seal around the plug holes in your kitchen sink sand bathroom basins.\u003C/p\u003E',
                  freePackDescription:
                    'This universal silicone sink plug effectively prevents leaks by creating a water-tight seal around the plug holes in your kitchen sink sand bathroom basins.',
                  maxperhousehold: 1,
                  state: 'ACTIVE',
                  limitedOffer: false,
                  images: {
                    path: '/product-images/3883/sink-plug-3883-15215-1-master.png',
                  },
                  imagesList: [
                    {
                      path: '/product-images/3883/sink-plug-3883-15215-1-master.png',
                    },
                  ],
                },
              ],
            },
          ],
          product_blacklist: [
            'HOSETRIGGER',
            'TOOTHYTIMER',
            '100LWATERBUTT_UU',
            '200LWATERBUTT_UU',
            '100LWATERBUTT_SWW',
            '200LWATERBUTT_SWW',
            '100LWATERBUTT',
            'WESSEXWASTE',
          ],
        },
      ],
    },
  };

  const renderProducts = (groups) => {
    if (groups.length > 0) {
      return _.map(groups, (group, groupIndex) => {
        const elements = _.map(group.products, (el, index) => {
          const image = `https://cdn.savewatersavemoney.co.uk/cache/product_page${_.get(
            el,
            'images.path'
          )}`;

          return (
            <Product
              id={el.id}
              key={index}
              description={el.freePackDescription}
              maxInGroup={true}
              maxQuantity={12}
              count={1}
              image={image}
              title={el.title}
              addToOrder={() => void 0}
              removeFromOrder={() => void 0}
            />
          );
        });

        return (
          <div key={groupIndex} style={{ marginBottom: '16px' }}>
            <Col xs={12} sm={12} md={12}>
              <Typography
                size="3"
                style={{
                  fontSize: !isMobile ? '22px' : '20px',
                  marginBottom: '16px',
                }}
                text={group.name}
              />
            </Col>
            <Row>{elements}</Row>
          </div>
        );
      });
    }
  };
  const pageTitle = t('portal.free_products.page_title');
  const pageDisclaimer = t('portal.free_products.page_disclaimer');
  return (
    <Wrap>
      <Typography
        weight={700}
        size="1"
        text={pageTitle}
        style={{
          marginBottom: '16px',
          fontSize: !isMobile ? '40px' : '28px',
          color: '#0b2641',
        }}
      />
      {renderProducts(data.data.items[0].groups)}
    </Wrap>
  );
};

export default FreeProducts;
