import React from 'react';
import Styled from './Breadcrumbs.style';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import { Icon } from '../index';
import { useParams } from 'react-router-dom';
import * as _ from 'lodash';
import { capitalizeFirstLetter } from '../../helpers/helpers';
interface BreadcrumbsProps {
  data: any;
}
export const Breadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
  const data = _.get(props, 'data.data');

  const location = useLocation();
  const navigate = useNavigate();
  const paths = location.pathname.substring(1);
  const getPaths = paths.split('/');
  const { roomId, locationId } = useParams();
  const findLocation = _.find(data, function (o) {
    return o.id == locationId;
  });
  const getRooms = _.get(findLocation, 'rooms');
  const findRoom = _.find(getRooms, function (o) {
    return o.id == roomId;
  });

  const propertyName = _.get(findLocation, 'property_name');
  const roomName = _.get(findRoom, 'name');

  const renderString = () => {
    if (getPaths.length === 1 && getPaths[0] === 'portal') {
      return null;
    } else if (locationId || roomId) {
      return (
        <Styled.Container>
          <div
            onClick={() => navigate('/portal')}
            style={{ cursor: 'pointer' }}
          >
            <Icon icon="home" size={26} />
          </div>
          {locationId ? (
            <>
              <span className="line">/</span>
              <span
                className="link"
                onClick={() => {
                  if (!roomId) {
                    return null;
                  } else {
                    navigate(`/locations/${findLocation.id}`);
                  }
                }}
                style={{ marginRight: '4px' }}
              >
                {capitalizeFirstLetter(propertyName)}
              </span>
            </>
          ) : null}
          {roomId ? (
            <>
              <span className="line">/</span>
              <span className="link" style={{ marginRight: '4px' }}>
                {roomName
                  ? capitalizeFirstLetter(roomName)
                  : 'Sample room name'}
              </span>
            </>
          ) : null}
        </Styled.Container>
      );
    } else if (getPaths.length >= 1) {
      return (
        <Styled.Container>
          <div
            onClick={() => navigate('/portal')}
            style={{ cursor: 'pointer' }}
          >
            <Icon icon="home" size={26} />
          </div>
          {getPaths[0] ? (
            <>
              <span className="line">{'>'}</span>
              <span
                className="link"
                onClick={() => {
                  if (getPaths[1]) {
                    navigate(`/${getPaths[0]}`);
                  } else {
                    return null;
                  }
                }}
                style={{ marginRight: '4px' }}
              >
                {capitalizeFirstLetter(getPaths[0].replace(/-/g, ' '))}
              </span>
            </>
          ) : null}
          {getPaths[1] ? (
            <>
              <span className="line">{'>'}</span>
              <span className="link">
                {capitalizeFirstLetter(getPaths[1].replace(/-/g, ' '))}
              </span>
            </>
          ) : null}
          {getPaths[2] ? (
            <>
              <span className="line">{'>'}</span>
              <span className="link">
                {capitalizeFirstLetter(getPaths[2].replace(/-/g, ' '))}
              </span>
            </>
          ) : null}
        </Styled.Container>
      );
    }
  };
  return (
    <Styled.Wrap>
      <Grid>
        <Row>
          <Col md={12} xs={12} sm={12}>
            {renderString()}
          </Col>
        </Row>
      </Grid>
    </Styled.Wrap>
  );
};

export default Breadcrumbs;
