import styled from 'styled-components';
import { colors } from '../../assets/variables';
const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 50px;
  div {
    display: flex;
    flex-direction: row;
  }
`;
const Input = styled.input`
  padding: 16px 24px 16px 16px;
  width: 100%;
  border: 1px solid ${colors.green};
  line-height: inherit;
  max-height: 50px;
  letter-spacing: 1px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  display: block;
  border-right: 0px;
  font-size: 16px;
  color: #4a4a4a;

  &:focus-visible {
    background: #e2e8f0;
    outline: 0px;
  }
`;
const Button = styled.button`
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  width: 50px;
  border: 1px solid ${colors.green};
  min-height: 50px;
  min-width: 50px;
  max-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  background: ${colors.green};
  &:hover {
    background: rgba(134, 176, 73, 0.8);
    transition: all 0.3s ease;
  }
  svg {
    width: 21px;
    height: 21px;
    path {
      fill: white;
    }
  }
`;
const InputWrap = styled.div`
  width: 100%;
  position: relative;
`;
const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;
export default {
  Input,
  Wrap,
  Button,
  InputWrap,
  Close,
};
