import axios from 'axios';
import { getMicroserviceGWF } from '../helpers/helpers';
export const getSearchData = (data) => {
  return {
    type: 'GET_SEARCH_DATA',
    payload: axios.post(
      `${getMicroserviceGWF('sponsor-portal')}public/search/marketplace`,
      data
    ),
  };
};
export const clearSearchData = () => {
  return {
    type: 'CLEAR_SEARCH_DATA',
  };
};
