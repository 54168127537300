import * as React from 'react';

const download = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(24.001967, 6.500000)">
        <polygon
          fill="#FFFFFF"
          points="33.4485384 45.4083886 33.4485384 28.5523365 17.890396 28.5523365 17.890396 45.4083886 8.53034915 45.4083886 25.6694672 75.0925694 42.8064885 45.4083886"
        ></polygon>
        <path
          d="M12.1632383,47.5051733 L25.6686285,70.8990001 L39.1740187,47.5051733 L33.4476997,47.5051733 C32.2902745,47.5051733 31.350915,46.5679105 31.350915,45.4083886 L31.350915,30.6491212 L19.986342,30.6491212 L19.986342,45.4083886 C19.986342,46.5679105 19.0490792,47.5051733 17.8895573,47.5051733 L12.1632383,47.5051733 Z M25.6686285,77.1893541 C24.9200764,77.1893541 24.2281374,76.790965 23.8528129,76.1409618 L6.7136949,46.4567809 C6.34046722,45.8067777 6.34046722,45.0099995 6.7136949,44.3599962 C7.08901936,43.709993 7.7809583,43.3116039 8.53160722,43.3116039 L15.7927726,43.3116039 L15.7927726,28.5523365 C15.7927726,27.3928145 16.7321321,26.4555518 17.8895573,26.4555518 L33.4476997,26.4555518 C34.6072216,26.4555518 35.5444844,27.3928145 35.5444844,28.5523365 L35.5444844,43.3116039 L42.8056498,43.3116039 C43.5562987,43.3116039 44.2482376,43.709993 44.6214653,44.3599962 C44.9967898,45.0099995 44.9967898,45.8067777 44.6214653,46.4567809 L27.4865408,76.1409618 C27.1112164,76.790965 26.4192774,77.1893541 25.6686285,77.1893541 L25.6686285,77.1893541 Z"
          fill={colorPrimary}
        ></path>
        <path
          d="M49.899282,87 L2.09678469,87 C0.937262756,87 0,86.0627372 0,84.9032153 C0,83.7436934 0.937262756,82.8064306 2.09678469,82.8064306 L49.899282,82.8064306 C51.058804,82.8064306 51.9960667,83.7436934 51.9960667,84.9032153 C51.9960667,86.0627372 51.058804,87 49.899282,87"
          fill={colorPrimary}
        ></path>
        <path
          d="M33.0493106,20.9370242 L18.9463368,20.9370242 C17.7889116,20.9370242 16.8495521,19.9997614 16.8495521,18.8402395 C16.8495521,17.6807175 17.7889116,16.7434548 18.9463368,16.7434548 L33.0493106,16.7434548 C34.2088325,16.7434548 35.1460953,17.6807175 35.1460953,18.8402395 C35.1460953,19.9997614 34.2088325,20.9370242 33.0493106,20.9370242"
          fill={colorPrimary}
        ></path>
        <path
          d="M33.0493106,12.5662403 L18.9463368,12.5662403 C17.7889116,12.5662403 16.8495521,11.6289776 16.8495521,10.4694556 C16.8495521,9.3099337 17.7889116,8.37267094 18.9463368,8.37267094 L33.0493106,8.37267094 C34.2088325,8.37267094 35.1460953,9.3099337 35.1460953,10.4694556 C35.1460953,11.6289776 34.2088325,12.5662403 33.0493106,12.5662403"
          fill={colorPrimary}
        ></path>
        <path
          d="M33.0493106,4.19356938 L18.9463368,4.19356938 C17.7889116,4.19356938 16.8495521,3.25630662 16.8495521,2.09678469 C16.8495521,0.937262756 17.7889116,0 18.9463368,0 L33.0493106,0 C34.2088325,0 35.1460953,0.937262756 35.1460953,2.09678469 C35.1460953,3.25630662 34.2088325,4.19356938 33.0493106,4.19356938"
          fill={colorPrimary}
        ></path>
      </g>
    </g>
  );
};
export default download;
