import axios from 'axios';
import { getMicroServiceUrl } from '../helpers/helpers';

export const addRoom = (id, room, roomName, data) => ({
  type: 'ADD_ROOM',
  payload: axios.post(
    `${getMicroServiceUrl('business-user')}add_room/${id}`,
    { type: room, name: roomName, data },
    data
  ),
});

export const deleteRoom = (id) => ({
  type: 'DELETE_ROOM',
  payload: axios.delete(
    `${getMicroServiceUrl('business-user')}delete_room/${id}`
  ),
});

export const editRoom = (id, room, roomName, data) => ({
  type: 'EDIT_ROOM',
  payload: axios.post(
    `${getMicroServiceUrl('business-user')}edit_room/${id}`,
    { type: room, name: roomName, data },
    data
  ),
});
