import React from 'react';
import Styled from './Wrap.style';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
interface WrapProps {
  children: any;
}

export const Wrap: React.FC<WrapProps> = (props) => {
  return (
    <Grid>
      <Row>
        <Col md={12} sm={12} xs={12}>
          <Styled.Wrap>{props.children}</Styled.Wrap>
        </Col>
      </Row>
    </Grid>
  );
};

export default Wrap;
