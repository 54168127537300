import styled, { css, keyframes } from 'styled-components';
import { colors } from '../../assets/variables';

const spinnerBounce = keyframes`
  0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
`;

const Loader = styled.div`
  z-index: 9999;
  display: inline-block;
  border-radius: 50%;

  ${(props) => {
    const { color, size } = props;
    return css`
      border: ${size * 0.12}em solid;
      font-size: 1em;
      border-color: ${color} ${color} ${color} transparent;
      width: ${size}em;
      height: ${size}em;
    `;
  }};

  ${(props) => {
    const { inline, size } = props;
    if (inline === false) {
      return css`
        position: absolute;
        left: calc(50% - ${size / 2}em);
        top: calc(50% - ${size / 2}em);
      `;
    }
  }};

  animation: rotating 1.5s linear infinite;
  &:before,
  &:after {
    content: '';
    display: block;
  }

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const LeafLoad = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  div {
    display: inline-block;
    animation: ${spinnerBounce} 1.4s infinite ease-in-out both;

    &:nth-child(1) {
      animation-delay: -0.32s;
    }
    &:nth-child(2) {
      animation-delay: -0.16s;
    }
  }
`;

const LoaderDot = styled.div`
  div {
    background-color: ${colors.primary};
    border-radius: 100%;
    display: inline-block;
    animation: ${spinnerBounce} 1.4s infinite ease-in-out both;
    ${(props) => {
      const { color, size } = props;
      return css`
        font-size: 1em;
        background-color: ${color};
        width: ${size}em;
        height: ${size}em;
      `;
    }};
    &:nth-child(1) {
      animation-delay: -0.32s;
    }
    &:nth-child(2) {
      animation-delay: -0.16s;
    }
  }
`;

export default { Loader, LoaderDot, LeafLoad };
