import axios from 'axios';
import {
  getMicroServiceUrl,
  getMicroServiceAuthUrl,
  getUtmTags,
} from '../helpers/helpers';
import * as _ from 'lodash';

export const logOut = () => {
  return (dispatch) => {
    return axios
      .get(`${getMicroServiceAuthUrl('business-user')}logout`)
      .then((resp) => {
        localStorage.removeItem('swsmUser');
        localStorage.removeItem('property');
        dispatch(clearUserdata(resp));
        window.location.reload();
      })
      .catch((error) => {
        localStorage.removeItem('swsmUser');
        localStorage.removeItem('property');
      });
  };
};

export function logIn({ email, password }) {
  const utm_tags = getUtmTags();
  return (dispatch) => {
    dispatch(logInPending());
    return axios
      .post(`${getMicroServiceAuthUrl('business-user')}login`, {
        email,
        password,
        utm_tags,
      })
      .then((resp) => {
        dispatch(logInFulfilled(resp));
      })
      .catch((error) => {
        dispatch(logInRejected(error));
      });
  };
}

export const logInPending = () => {
  return {
    type: 'LOG_IN_PENDING',
  };
};
export const logInFulfilled = (resp) => {
  return {
    type: 'LOG_IN_FULFILLED',
    payload: resp,
  };
};
export const logInRejected = (resp) => {
  return {
    type: 'LOG_IN_REJECTED',
    payload: resp,
  };
};

export const clearUserdata = (resp) => {
  return {
    type: 'CLEAR_USERDATA',
    payload: resp,
  };
};

export const refreshAccessToken = () => {
  return {
    type: 'REFRESH_ACCESS_TOKEN',
    payload: axios.post(`${getMicroServiceAuthUrl('user')}token`),
  };
};

export const register = ({ email, company, password, recaptchaResponse }) => {
  const utm_tags = getUtmTags();

  return (dispatch) => {
    dispatch(registerPending());
    return axios
      .post(`${getMicroServiceAuthUrl('business-user')}register`, {
        email,
        company,
        recaptchaResponse,
        password,
        utm_tags,
      })
      .then((resp) => {
        dispatch(registerFulfilled(resp));
        window.location.reload();
      })
      .catch((error) => {
        dispatch(registerRejected(error));
      });
  };
};

export const registerPending = () => {
  return {
    type: 'REGISTER_PENDING',
  };
};
export const registerFulfilled = (resp) => {
  return {
    type: 'REGISTER_FULFILLED',
    payload: resp,
  };
};
export const registerRejected = (resp) => {
  return {
    type: 'REGISTER_REJECTED',
    payload: resp,
  };
};

export const setCustomerService = (data, onSuccess, onFailed) => {
  const email = 'getwaterfit1@savewatersavemoney.com';
  const token = 'lmjIkWRdvG0vUOs5soX86t4HxuTadqy52cemmoS1';
  const auth = email + '/token:' + token;

  const ticket = JSON.stringify({
    request: {
      subject: data.subject,
      comment: {
        body: `message: ${data.freeText}`,
      },
      custom_fields: [
        { id: 1900005085913, value: data.name },
        { id: 1900005085933, value: data.phone },
        { id: 360029604918, value: data.email },
        { id: 1900005188153, value: data.query },
        { id: 360029815997, value: data.query2 },
        { id: 1900005084813, value: data.waterCompany },
        { id: 360029604898, value: data.orderNumber },
        { id: 1900005086093, value: data.freeText },
      ],
    },
  });

  return (dispatch) => {
    const response = dispatch({
      type: 'SET_CUSTOMER_SERVICE',
      payload: axios.post(
        'https://savewatersavemoney.zendesk.com/api/v2/requests.json',
        ticket,
        {
          headers: {
            Authorization: 'Basic ' + btoa(auth),
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      ),
    });
    response
      .then((data) => {
        onSuccess();
      })
      .catch((err) => {
        onFailed();
      });
  };
};

export const getWholesaleList = () => {
  return {
    type: 'GET_WHOLESALER_LIST',
    payload: axios.get(
      `${getMicroServiceUrl('business-user')}wholesaler_detail`
    ),
  };
};

export const setMeta = (propertyId) => {
  return {
    type: 'SET_META',
    propertyId,
  };
};
