import * as React from 'react';

const mail = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path fill={colorSecondary} d="M86.875 25.142l-38.85 29.296L9.177 25.142z" />
        <path
          d="M90.052 23c.429 0 .811.275.948.683.044.13.05.265.039.396l.007.032.006.032v52.285a1 1 0 01-.883.993l-.117.007H6a1 1 0 01-.993-.884L5 76.428V24.143l.013-.064a.974.974 0 01.039-.396 1 1 0 01.832-.677L6 23h84.052zm-1 3.007L48.628 56.49a1.003 1.003 0 01-1.094.072l-.111-.072L7 26.007v49.42h82.052v-49.42zm-2.177-.864H9.177l38.848 29.295 38.85-29.295z"
          fill={colorPrimary}
        />
      </g>
    </>
  );
};
export default mail;
