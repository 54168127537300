import * as React from 'react';
import Styled from './SearchIcon.style';
import Loader from '../Loader';

interface SearchIconProps {
  loading: boolean;
  size?: number;
  color?: string;
}

const searchIcon = (color) => (
  <svg width="100%" height="100%" viewBox="0 0 100 100">
    <path
      fill={color}
      fillRule="evenodd"
      d="M65.25 57l34.58 34.57-8.49 8.49-34.61-34.62a36 36 0 1 1 8.52-8.45zM36 62a26 26 0 1 0 0-52 26 26 0 0 0 0 52z"
    />
  </svg>
);

export const SearchIcon: React.FC<SearchIconProps> = (props) => {
  const { loading, size = 2, color = '#fff' } = props;
  return (
    <Styled.Icon size={size}>
      {loading ? (
        <Loader type="circle" color={color} size={size} inline />
      ) : (
        searchIcon(color)
      )}
    </Styled.Icon>
  );
};

export default SearchIcon;
