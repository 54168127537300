import store from 'store2';
import * as _ from 'lodash';
import { RegisterReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
import i18next from 'i18next';

export const initialState: RegisterReducer | any = {
  status: {
    loading: false,
    error: null,
    done: false,
  },
  data: null,
};

const registerReducer = createReducer(initialState, {
  REGISTER_PENDING: (state) => {
    state.status = {
      loading: true,
      error: null,
      done: false,
    };
  },

  REGISTER_FULFILLED: (state, action) => {
    state.status = {
      loading: false,
      error: null,
      done: true,
    };
    state.data = action.payload.data;

    store('swsmUser', state);
  },

  REGISTER_REJECTED: (state, action) => {
    state.status = {
      loading: false,
      error: action.payload.data.errors[0],
      done: false,
    };
  },
});

export default registerReducer;
