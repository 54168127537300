import React from 'react';
import Styled from './Loader.style';
import { Icon } from '../../components';
interface LoaderProps {
  color?: string;
  size?: number;
  inline?: boolean;
  type?: 'circle' | 'dots' | 'leaf';
}
const Loader: React.FC<LoaderProps> = (props) => {
  const { size = 1, color = '#006bdf', inline = false, type } = props;
  const loaderType = (type) => {
    switch (type) {
      case 'dots':
        return (
          <Styled.LoaderDot type={type} size={size} color={color} {...props}>
            <div />
            <div />
            <div />
          </Styled.LoaderDot>
        );
      case 'leaf':
        return (
          <Styled.LeafLoad type={type} color={color} {...props}>
            <div>
              <Icon icon="leaf" size={20} colorPrimary="#86B049" />
            </div>
            <div>
              <Icon icon="leaf" size={20} colorPrimary="#86B049" />
            </div>
            <div>
              <Icon icon="leaf" size={20} colorPrimary="#86B049" />
            </div>
          </Styled.LeafLoad>
        );
      default:
        return (
          <Styled.Loader
            type={type}
            size={size}
            color={color}
            inline={inline}
            {...props}
          />
        );
    }
  };

  return loaderType(type);
};

export default Loader;
