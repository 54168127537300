import React from 'react';
import * as _ from 'lodash';
import Styled from './RewardsBanner.style';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../index';
interface RewardsBannerProps {
  rewards?: any;
}
const RewardsBanner: React.FC<RewardsBannerProps> = (props) => {
  const { rewards } = props;
  const navigate = useNavigate();
  const getRewardBanner = _.get(rewards, 'rewards.data.[0].banner');
  const getOptedIn = _.get(rewards, 'rewards.data[0].optedIn');
  const data = _.get(rewards, 'rewards.data.length');
  const loading = _.get(rewards, 'rewards.status.loading');
  if (loading) {
    return <Loader />;
  }

  const renderRewardBanner = () => {
    if (data === 0 || getRewardBanner === null || getOptedIn !== null) {
      return null;
    }
    return (
      <div style={{ marginTop: '32px' }}>
        <Styled.BannerWrap>
          <Styled.ImageWrap
            style={{
              backgroundImage: `url('${_.get(getRewardBanner, 'imageURL')}')`,
            }}
          />
          <Styled.TextWrap>
            <Styled.Title>{_.get(getRewardBanner, 'title')}</Styled.Title>
            <Styled.Description>
              {_.get(getRewardBanner, 'description')}
            </Styled.Description>
            <Styled.ButtonWrap>
              <Styled.OptButton onClick={() => navigate('/offers')}>
                {_.get(getRewardBanner, 'buttonText')}
              </Styled.OptButton>
            </Styled.ButtonWrap>
          </Styled.TextWrap>
        </Styled.BannerWrap>
      </div>
    );
  };
  return renderRewardBanner();
};
export default RewardsBanner;
