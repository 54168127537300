import * as React from 'react';

const shaving = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M54.204 22.371v-6.377a2.151 2.151 0 00-2.148-2.149H18.908a2.151 2.151 0 00-2.148 2.15v6.376a2.15 2.15 0 002.148 2.148h33.148a2.15 2.15 0 002.148-2.148"
          fill={colorPrimary}
        />
        <path
          d="M18.76 22.391v-6.378c0-.082.066-.148.148-.148h33.148c.082 0 .148.066.148.148v6.378a.148.148 0 01-.148.148H18.908a.148.148 0 01-.148-.148"
          fill={colorSecondary}
        />
        <path d="M49.378 17.973H21.586a1 1 0 100 2h27.792a1 1 0 100-2" fill={colorPrimary} />
        <path
          d="M57.042 10a2.8 2.8 0 012.79 2.626l.006.17V25.61a2.8 2.8 0 01-2.626 2.79l-.17.006h-6.664v4.816c0 .045-.02.084-.025.127a1.01 1.01 0 01-.041.204c-.024.066-.059.123-.095.182l-.029.059-.033.058c-.07.086-7.06 8.75-8.31 13.245-1.155 4.145-.482 27.473.035 39.229.001 3.106-2.49 5.597-5.552 5.597a5.56 5.56 0 01-5.553-5.553l.173-3.31c.421-8.526 1.358-30.907-.134-36.055-1.337-4.621-8.427-13.053-8.499-13.138-.03-.035-.04-.08-.064-.117-.039-.061-.078-.12-.104-.188-.024-.067-.031-.134-.041-.203l-.016-.068-.011-.07v-4.815h-7.283a2.8 2.8 0 01-2.79-2.626l-.006-.17V12.797a2.8 2.8 0 012.626-2.792l.17-.005h42.246zm-9.717 24.22H25.182l.423.538c2.291 2.93 6.109 8.161 7.131 11.689 1.936 6.683.117 38.623.037 39.98.002 1.9 1.596 3.495 3.555 3.495a3.557 3.557 0 003.553-3.553l-.18-4.49c-.35-9.502-.989-30.988.216-35.32.961-3.455 4.748-8.814 6.994-11.793l.414-.545zm1.053-5.815H24.079v3.816h24.299v-3.816zM57.042 12H14.796a.798.798 0 00-.79.697l-.006.1v12.812c0 .405.304.74.696.79l.1.006h42.246c.404 0 .74-.305.79-.697l.006-.1V12.798a.799.799 0 00-.696-.791l-.1-.006z"
          fill={colorPrimary}
        />
        <path
          d="M39.892 46.56c-1.525 5.483-.097 38.452-.036 39.809a3.557 3.557 0 01-3.553 3.553c-1.96 0-3.553-1.594-3.555-3.495.08-1.357 1.899-33.297-.037-39.98-1.088-3.755-5.344-9.441-7.554-12.226H47.3c-2.16 2.825-6.385 8.66-7.41 12.339"
          fill="#D7F1FF"
        />
        <path
          d="M76.991 38.498c3.71 0 7.095 1.715 9.287 4.707 2.123 2.896 2.77 6.497 1.798 9.932l-.103.343-5.66 17.94v14.568a5.94 5.94 0 01-5.711 5.93l-.223.003h-9.793a5.94 5.94 0 01-5.928-5.71l-.004-.223V71.43l-6.089-17.663c-1.229-3.564-.679-7.369 1.51-10.439 2.121-2.974 5.394-4.718 9.025-4.824l.352-.006h11.54zm3.321 41.678H62.654v5.812a3.938 3.938 0 003.73 3.928l.202.005h9.793c2.1 0 3.823-1.655 3.928-3.73l.005-.203v-5.812zm0-7.913H62.654v5.913h17.658v-5.913z"
          fill={colorPrimary}
        />
        <path
          d="M86.065 52.878L80.58 70.263H62.367l-5.91-17.148a9.406 9.406 0 011.247-8.626 9.387 9.387 0 016.748-3.933v16.691a1 1 0 102 0V40.498h3.768v7.874a1 1 0 002 0v-7.874h3.771v15.428a1 1 0 102 0v-15.37a9.406 9.406 0 016.675 3.831 9.428 9.428 0 011.4 8.491"
          fill={colorSecondary}
        />
      </g>
    </>
  );
};
export default shaving;
