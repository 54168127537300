import * as React from 'react';
import * as _ from 'lodash';
import { Tooltip } from 'react-tooltip';
import Styled from './Task.style';
import { useNavigate } from 'react-router-dom';
import { Icon, Popup, Button, ButtonClean } from '../index';
interface TaskProps {
  id: number;
  icon: string;
  title?: string;
  description?: string;
  isDesktop: boolean;
  buttonText?: string;
  buttonPath?: string;
  renderComplete?: Function;
  completed?: boolean;
  popup: boolean;
  isLoading?: boolean;
  waterSaving?: string;
  energySaving?: string;
  carbonSaving?: string;
  titleWidth: string;
}
export const Task: React.FC<TaskProps> = (props) => {
  const {
    id,
    popup,
    icon,
    title,
    description,
    isDesktop,
    buttonText,
    buttonPath,
    renderComplete,
    completed,
    isLoading,
    carbonSaving,
    energySaving,
    waterSaving,
    titleWidth,
  } = props;

  const [showPopup, setPopup] = React.useState(false);
  const navigate = useNavigate();

  const renderPopupContent = () => {
    return (
      <>
        <Icon
          size={100}
          icon={icon}
          style={{
            marginBottom: '8px',
          }}
        />
        {completed ? (
          <>
            {' '}
            <Styled.PopupTitle>Congratulations</Styled.PopupTitle>
            <Styled.Description>You have saved</Styled.Description>
            <Styled.CtaWrap>
              <Button full onClick={() => setPopup(!popup)}>
                Close
              </Button>
            </Styled.CtaWrap>
          </>
        ) : (
          <>
            <Styled.PopupTitle>{title}</Styled.PopupTitle>
            <Styled.Description>{description}</Styled.Description>
            <div style={{ marginBottom: '6px' }}>Monthly savings:</div>
            <Styled.Savings>
              <Styled.SavingItem
                data-tooltip-id="water-tooltip"
                data-tooltip-content="Litres saved"
                data-tooltip-place="top"
              >
                <b>{waterSaving}</b>
                <Icon icon="droplet" style={{ marginLeft: '6px' }} size={28} />
              </Styled.SavingItem>
              <Styled.SavingItem
                data-tooltip-id="energy-tooltip"
                data-tooltip-content="kWh saved"
                data-tooltip-place="top"
              >
                <b>{energySaving}</b>
                <Icon icon="energy" style={{ marginLeft: '6px' }} size={28} />
              </Styled.SavingItem>
              <Styled.SavingItem
                data-tooltip-id="co2-tooltip"
                data-tooltip-content="Kg/m3 CO2"
                data-tooltip-place="top"
              >
                <b>{carbonSaving}</b>
                <Icon icon="co2" style={{ marginLeft: '6px' }} size={28} />
              </Styled.SavingItem>
            </Styled.Savings>
            <Styled.CtaWrap>
              <Button
                full
                isLoading={isLoading}
                onClick={() => {
                  renderComplete ? renderComplete() : navigate(`${buttonPath}`);
                }}
              >
                {buttonText}
              </Button>
            </Styled.CtaWrap>
            <Tooltip id="water-tooltip" />
            <Tooltip id="energy-tooltip" />
            <Tooltip id="co2-tooltip" />
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Styled.Wrap isDesktop={isDesktop} id={id}>
        <Popup
          open={showPopup}
          handleClick={() => setPopup(!showPopup)}
          isDesktop={isDesktop}
        >
          {renderPopupContent()}
        </Popup>

        <ButtonClean
          style={{ width: '100%', textAlign: 'left', fontSize: '18px' }}
          onClick={() => {
            popup ? setPopup(!showPopup) : navigate(`${buttonPath}`);
          }}
        >
          <Styled.TextWrap>
            <Styled.Icon>
              <Icon size={40} icon={icon} />
            </Styled.Icon>
            <Styled.TitleWrap>
              <Styled.Title
                style={{
                  fontSize: isDesktop ? '18px' : '14px',
                  maxWidth: titleWidth,
                }}
              >
                {title}
              </Styled.Title>
            </Styled.TitleWrap>
            <Styled.ArrowWrap>
              <Icon size={30} icon="chevronRight" colorPrimary="#ffffff" />
            </Styled.ArrowWrap>
          </Styled.TextWrap>
        </ButtonClean>
      </Styled.Wrap>
    </>
  );
};

export default Task;
