import * as React from 'react';

const minus = (props: any) => {
  const { colorPrimary } = props;

  return (
    <>
      <path
        d="M88.763 53H10.5a2.5 2.5 0 110-5h78.263a2.5 2.5 0 110 5"
        fill={colorPrimary}
        fillRule="evenodd"
      />
    </>
  );
};
export default minus;
