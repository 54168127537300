import * as React from 'react';

const homeWater = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path fill="#D7F1FF" d="M45.55 88.25h17.487V57.53H45.55z" />
        <path
          d="M54.423 15.837h.12l.117.015a.996.996 0 01.437.193l.094.083 39 39c.36.36.388.927.083 1.32l-.084.094-5.788 5.788a.997.997 0 01-1.311.089l-.103-.09-6.94-6.94v27.306a6.645 6.645 0 015.512 5.304l.043.25h1.081a1 1 0 01.117 1.994l-.117.007h-2.08a1 1 0 01-1-1c0-.098.03-.187.055-.275-.146-2.428-2.148-4.364-4.61-4.364a4.644 4.644 0 00-4.64 4.639 1 1 0 01-.884.993l-.117.007H15.924l-.07.006-.034-.006H7a1 1 0 01-.116-1.994L7 88.25h7.78a6.648 6.648 0 015.68-6.504 6.646 6.646 0 016.595-7.406c.48 0 .958.055 1.424.159l.347.087V67.89a1 1 0 011.994-.117l.006.117v20.36H43.55V56.53a1 1 0 01.884-.994l.116-.006h19.487a1 1 0 01.994.883l.006.117v31.72h7.455a6.645 6.645 0 015.306-5.512l.25-.043V53.39L54.481 29.822 21.973 62.33a1 1 0 01-1.32.083l-.094-.083-5.788-5.788a.999.999 0 01-.083-1.32l.083-.094 39-39a1 1 0 01.534-.277l.118-.014zM93.7 88.25a1 1 0 01.116 1.993l-.116.007h-2.408a1 1 0 01-.117-1.994l.117-.006H93.7zM63.037 57.53H45.55v30.72h17.487V57.53zM27.054 76.34a4.644 4.644 0 00-4.42 6.049.998.998 0 01-.878 1.299h-.17l-.17-.01a4.64 4.64 0 00-4.625 4.356l-.008.216h12.043V76.692a4.663 4.663 0 00-1.772-.352zm23.577-4.156a3.16 3.16 0 013.157 3.156 3.16 3.16 0 01-3.157 3.157 3.16 3.16 0 01-3.157-3.157 3.16 3.16 0 013.157-3.156zm0 2a1.157 1.157 0 000 2.313 1.157 1.157 0 000-2.313zm3.85-55.938L16.891 55.835l4.374 4.374L53.774 27.7a.997.997 0 011.312-.09l.102.09 32.507 32.508 4.374-4.374-37.589-37.59zm-34.648-6.929l.69.786c2.252 2.612 8.624 10.408 8.624 14.977 0 5.545-4.512 10.057-10.058 10.057-5.545 0-10.057-4.512-10.057-10.057 0-5.234 8.36-14.7 9.313-15.763.38-.423 1.11-.423 1.488 0zm-.744 2.187l-.386.456c-2.861 3.41-7.67 9.826-7.67 13.12 0 4.443 3.614 8.057 8.056 8.057 4.443 0 8.058-3.614 8.058-8.057 0-3.45-5.267-10.315-8.058-13.576z"
          fill={colorPrimary}
        />
        <path
          d="M54.48 18.246l37.59 37.589-4.375 4.374L55.188 27.7a.999.999 0 00-1.414 0L21.266 60.209l-4.374-4.374 37.588-37.59zm-35.39-4.742l.385.456c2.863 3.409 7.672 9.824 7.672 13.12 0 4.443-3.615 8.057-8.058 8.057-4.442 0-8.057-3.614-8.057-8.057 0-3.447 5.267-10.314 8.057-13.576z"
          fill={colorSecondary}
        />
      </g>
    </>
  );
};
export default homeWater;
