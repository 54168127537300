import * as React from 'react';

const hand = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <path
        d="M84.672 39.084c-7.108-.023-14.218-.006-21.326-.006H37.149c.56-1.374 1.024-2.45 1.44-3.544 1.956-5.126 4.16-10.176 5.773-15.407 1.682-5.45-1.422-9.857-6.161-9.026-1.662.291-3.55 1.51-4.571 2.877-7.295 9.758-14.411 19.65-21.54 29.531-2.045 2.835-3.096 6.034-3.08 9.571.027 6.134-.052 12.268.025 18.4.113 8.913 7.206 16.312 16.124 16.495 9.754.2 19.516.141 29.27.011 5.953-.08 9.746-4.918 8.77-10.625-.114-.67.422-1.519.815-2.193.99-1.704 2.42-3.238 3.036-5.057.896-2.649-.56-4.94-2.185-6.975 2.93-3.463 3.167-7.064.663-10.977 6.629 0 12.893.02 19.158-.007 4.205-.018 6.932-2.554 6.959-6.388.026-3.9-2.827-6.665-6.973-6.68z"
        stroke={colorPrimary}
        strokeWidth="2"
        fill={colorSecondary}
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
};
export default hand;
