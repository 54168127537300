import React from 'react';
import { Wrap, Task, EasyWin, Loader } from '../../components/index';
import * as _ from 'lodash';
import { useResponsivity } from '../../helpers/helpers';
import { Col } from 'react-styled-flexboxgrid';
import { useTranslation } from 'react-i18next';
import store from 'store2';
interface KitchenTapProps {
  challenges: any;
  listLocation: any;
  getChallengeCategory: Function;
  completeTask: any;
  completeChallenge: Function;
  generalData: Function;
}

const KitchenTap: React.FC<KitchenTapProps> = (props) => {
  const {
    challenges,
    listLocation,
    getChallengeCategory,
    completeTask,
    completeChallenge,
    generalData,
  } = props;
  const getRoomId = store('property');

  const data = _.get(challenges, 'data.challenges');
  const isMobile = useResponsivity();
  const [t] = useTranslation('common');
  const { error, loading, done } = _.get(completeTask, 'status');
  const loadingChallenges = _.get(challenges, 'status.loading');
  React.useEffect(() => {
    if (getRoomId) {
      getChallengeCategory(getRoomId, 'kitchen-tap');
    }
  }, [getRoomId]);

  const pageArray = [
    {
      id: 268,
      title: 'Kitchen taps',
      poster:
        'https://cdn.getwaterfit.co.uk/static/files/images/gwf-business/posters/gecko.png',
      emailCopy:
        'Remember to wash up wisely using a bowl or popping a plug in the sink ideally at the end of the day, rather than individually under a running tap.',
      easyWins: [
        {
          title: 'Introduction',
          theme: 'intro',
          size: 'full',
          text: 'Reduce water by using a bowl or putting the plug in when washing up – rather than under a running tap. Remember, wasted hot water adds to your business energy bills.',
        },
        {
          title: 'Easy Win',
          theme: 'red',
          size: 'half',
          icon: null,
          buttonText: 'Download now',
          buttonPath:
            'https://cdn.getwaterfit.co.uk/static/files/images/gwf-business/posters/gecko.pdf',
          text: 'Print off the poster and place within each kitchen to encourage staff to boil only what they need. This poster will reduce water and energy use in your business.',
        },
        {
          title: 'Easy-to-fit Win',
          theme: 'red',
          size: 'half',
          icon: null,
          buttonText: 'See video',
          buttonPath: '/video-library',
          text: 'Fitting a Kitchen Tap Aerator typically reduces water flow by around half with little noticeable difference – except to water & energy bills. Several products feature changeable flow patterns and a swivel to allow 360º rotation. They’re also easy to fit – you don’t need a plumber.',
        },
        {
          theme: 'tooltip',
          icon: 'knowledge',
          size: 'full',
          text: 'To measure the flow of water, use a measuring jug and fill for 5 seconds. Multiply the amount of water in the jug by 12 in order to understand the how much water runs out of the tap in one minute. For example, if you have 1 litre in your jug after 5 seconds, then the flow rate of the tap will be 1l x 12 = 12 litres per minute. Find out when you’ll get your money back on a Kitchen Tap Aerator with our simple Return on Investment calculator.',
        },
      ],
    },
  ];

  const getChallenges = () => {
    return _.map(data, (el, index) => {
      const title = _.get(el, 'title');
      const description = _.get(el, 'description');
      const buttonText = _.get(el, 'buttonText');
      const type = _.get(el, 'internalType');
      const buttonPath = _.get(el, 'buttonPath');
      const energySaving = _.get(el, 'energy_saving');
      const carbonSaving = _.get(el, 'carbon_saving');
      const waterSaving = _.get(el, 'water_saving');
      return (
        <Col xs={12} sm={12} md={6} key={index}>
          <Task
            isDesktop={!isMobile}
            titleWidth="230px"
            icon={_.get(el, 'icon')}
            id={_.get(el, 'id')}
            title={`${t(title)}`}
            description={`${t(description)}`}
            buttonText={`${t(buttonText)}`}
            buttonPath={buttonPath}
            popup={type === 'direct-redirect' ? false : true}
            renderComplete={() => {
              completeChallenge(getRoomId, _.get(el, 'id'));
              setTimeout(() => {
                getChallengeCategory(getRoomId, 'kitchen-tap');
              }, 1000);
            }}
            isLoading={loading}
            completed={done}
            energySaving={energySaving}
            carbonSaving={carbonSaving}
            waterSaving={waterSaving}
          />
        </Col>
      );
    });
  };

  if (loadingChallenges) {
    return (
      <Wrap>
        <Loader />
      </Wrap>
    );
  }

  return (
    <Wrap>
      <EasyWin data={pageArray}>{getChallenges()}</EasyWin>
    </Wrap>
  );
};

export default KitchenTap;
