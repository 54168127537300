import * as React from 'react';

const hidden = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M51.222 32.222c-10.039 0-18.206 8.167-18.206 18.206 0 10.038 8.167 18.205 18.206 18.205 10.038 0 18.205-8.167 18.205-18.205 0-10.04-8.167-18.206-18.205-18.206"
          fill={colorSecondary}
        />
        <path
          d="M51.222 30.22c11.142 0 20.205 9.065 20.205 20.207 0 11.142-9.063 20.205-20.205 20.205-11.142 0-20.206-9.063-20.206-20.205 0-11.142 9.064-20.206 20.206-20.206zm0 2c-10.039 0-18.206 8.168-18.206 18.207 0 10.038 8.167 18.205 18.206 18.205 10.038 0 18.205-8.167 18.205-18.205 0-10.04-8.167-18.206-18.205-18.206z"
          fill={colorPrimary}
        />
        <path
          d="M51.222 43.923a6.51 6.51 0 016.503 6.504 6.51 6.51 0 01-6.503 6.503 6.51 6.51 0 01-6.504-6.503 6.512 6.512 0 016.504-6.504zm0 2a4.51 4.51 0 00-4.504 4.504 4.509 4.509 0 004.504 4.503 4.508 4.508 0 004.503-4.503 4.509 4.509 0 00-4.503-4.504z"
          fill={colorPrimary}
        />
        <path
          d="M81.942 18.293a.999.999 0 011.497 1.32l-.083.094L69.57 33.493l.777.438C81.58 40.34 91.82 49.091 92.49 49.67a1 1 0 010 1.515l-.701.595c-4.332 3.634-26.771 21.877-40.568 21.877-4.615 0-10.346-2.047-16.103-4.903l-.54-.27L20.502 82.56a.993.993 0 01-.707.293 1 1 0 01-.79-1.613l.083-.094 13.63-13.63-.787-.424C20.13 60.676 9.088 51.786 8.369 51.204a1.003 1.003 0 010-1.554l1.07-.851c5.292-4.158 28.302-21.603 41.783-21.603 4.683 0 10.36 2.106 15.988 5.026l.527.276 14.205-14.205zM68.088 34.974L36.08 66.984c5.484 2.728 10.882 4.672 15.142 4.672 12.73 0 34.39-17.358 39.061-21.229l-1.412-1.154c-3.835-3.097-11.915-9.334-20.316-14.039l-.467-.26zm-16.866-5.778c-12.729 0-35.608 17.32-40.61 21.231l1.043.808c3.906 3 12.903 9.641 22.079 14.54l.474.251 32.04-32.039c-5.362-2.79-10.708-4.79-15.026-4.79z"
          fill={colorPrimary}
        />
      </g>
    </>
  );
};
export default hidden;
