import * as React from 'react';

const gift = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <>
      <g fill="none" fillRule="evenodd">
        <path
          d="M60.378 12.011c3.442-.128 5.974.834 7.115 2.704.926 1.517.836 3.494-.248 5.427-1.006 1.792-3 3.74-5.985 5.183l-.395.186H88.31c.52 0 .937.388.993.883l.007.117v11.02c0 .51-.38.935-.882.993l-.118.007h-3.35v49.19c0 .51-.388.935-.884.993l-.116.007H15.35c-.51 0-.935-.389-.993-.884l-.007-.117V38.53H11a.996.996 0 01-.993-.883L10 37.53V26.51c0-.511.38-.936.882-.994l.118-.006h27.445c-3.204-1.46-5.33-3.5-6.38-5.37-1.084-1.931-1.174-3.91-.249-5.425 1.142-1.872 3.681-2.827 7.116-2.704 3.78.14 6.635 1.412 8.488 3.781 1.447 1.852 2.036 4.072 2.235 6.027.2-1.956.787-4.175 2.235-6.027 1.853-2.37 4.71-3.641 8.488-3.781zm22.582 51.54H55.48v23.17h27.48v-23.17zm-39.13 0H16.35v23.17h27.48v-23.17zm9.65-25.02h-7.65v17.72H16.35v5.3h29.48v25.17h7.65V61.55h29.48v-5.3H53.48V38.53zm29.48 0H55.48v15.72h27.48V38.53zm-39.13 0H16.35v15.72h27.48V38.53zm43.48-11.02H12v9.02h75.31v-9.02zM38.31 14c-2.4 0-4.09.616-4.787 1.757-.546.893-.444 2.104.287 3.405 1.706 3.04 6.607 6.107 13.813 6.334l.036-.333c.179-1.847.219-5.544-1.82-8.146-1.474-1.88-3.822-2.892-6.98-3.008-.187-.006-.37-.01-.549-.01zM61 14c-.179 0-.36.003-.548.01-3.163.116-5.514 1.13-6.987 3.015-2.173 2.78-1.984 6.776-1.78 8.471 7.206-.227 12.11-3.294 13.815-6.334.73-1.302.832-2.512.286-3.405C65.09 14.617 63.4 14 61 14z"
          fill={colorPrimary}
        />
        <path
          fill={colorSecondary}
          d="M82.943 56.25v5.301H53.464v25.17h-7.65v-25.17h-29.48v-5.3h29.48V38.53h7.65v17.72z"
        />
      </g>
    </>
  );
};
export default gift;
