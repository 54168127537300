import React from 'react';
import * as _ from 'lodash';
import store from 'store2';
export function getVariable(scope: string, name: string, fallback: any = '') {
  return _.get(window, `swsm.${scope}.${name}`, fallback);
}

export const scrollToTop = () => {
  if (window.scrollTo) {
    window.scrollTo({ top: 0 });
  }
};

export const capitalizeFirstLetter = (string: any): any => {
  if (typeof string === 'string' || string instanceof String) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string;
};

export function getUrl() {
  return window.location.host;
}
export function getMicroServiceUrl(name: string, version = '1.0') {
  const env = getUrl();
  if (env === 'localhost:3000' || env === 'dev.business.getwaterfit.co.uk') {
    return `https://api.dev.business.getwaterfit.co.uk/${name}/api/${version}/`;
  } else if (env === 'stage.business.getwaterfit.co.uk') {
    return `https://api.stage.business.getwaterfit.co.uk/${name}/api/${version}/`;
  }
  return `https://api.business.getwaterfit.co.uk/${name}/api/${version}/`;
}

export function getMicroserviceGWF(name: string, version = '1.0') {
  const env = getUrl();
  if (env === 'localhost:3000' || env === 'dev.business.getwaterfit.co.uk') {
    return `https://api.dev.getwaterfit.co.uk/${name}/api/${version}/`;
  } else if (env === 'stage.business.getwaterfit.co.uk') {
    return `https://api.stage.getwaterfit.co.uk/${name}/api/${version}/`;
  }
  return `https://api.getwaterfit.co.uk/${name}/api/${version}/`;
}

export function getMicroServiceAuthUrl(name: string, version = '1.0') {
  const env = getUrl();

  if (env === 'localhost:3000' || env === 'dev.business.getwaterfit.co.uk') {
    return `https://api.dev.business.getwaterfit.co.uk/${name}/auth/${version}/`;
  } else if (env === 'stage.business.getwaterfit.co.uk') {
    return `https://api.stage.business.getwaterfit.co.uk/${name}/auth/${version}/`;
  }

  return `https://api.business.getwaterfit.co.uk/${name}/auth/${version}/`;
}

export const footerItems = [
  {
    socialMedia: [
      { url: 'https://www.facebook.com/LetsGWF/', name: 'facebook' },
      { url: 'https://twitter.com/LetsGetWaterFit', name: 'twitter' },
    ],
    navElements: [
      {
        location: 'footer',
        path: '/about',
        pathId: '/about',
        title: 'About us',
        name: 'About us',
      },
      {
        location: 'footer',
        path: '/faq',
        pathId: '/faq',
        title: 'FAQs',
        name: 'portal.footer.faq',
      },

      {
        location: 'footer',
        path: '/terms-and-conditions',
        pathId: '/terms-and-conditions',
        title: 'Terms of use',
        name: 'portal.footer.terms',
      },
      {
        location: 'footer',
        path: '/privacy',
        pathId: '/privacy',
        title: 'Privacy policy',
        name: 'portal.footer.privacy',
      },
      {
        location: 'footer',
        path: '/cookies',
        pathId: '/cookies',
        title: 'Cookie policy',
        name: 'portal.footer.cookie',
      },
    ],
  },
];

export const useResponsivity = () => {
  const [isOnMobile, setIsOnMobile] = React.useState(true);
  React.useEffect(() => {
    /*Check the screen size on every render, prevent possible bugs by
        checking the screen size and setting the isOnMobile when necessary.
        */
    function handleResize() {
      if (window.innerWidth >= 800) {
        setIsOnMobile(false);
      } else {
        setIsOnMobile(true);
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);

    //Cleanup the useEffect when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return isOnMobile;
};

export const getControlKeys = (singleStep) => {
  const { controls } = singleStep;
  if (controls && controls.length > 0) {
    const keys: Array<string> = [];
    controls.forEach((control) => {
      if (control.key) {
        keys.push(control.key);
      }
    });
    return keys;
  }
  return [];
};

export const addGroup = (data) => {
  return _.map(data, (el, index) => {
    const num = parseInt(index, 1);
    const previousSame = _.get(data, `[${num - 1}].type`) === el.type;
    const nextSame = _.get(data, `[${index + 1}].type`) === el.type;
    return {
      ...el,
      grouped: (previousSame || nextSame) && {
        firstInGroup: nextSame && !previousSame,
        inGroup: previousSame || nextSame,
        lastInGroup: previousSame && !nextSame,
      },
    };
  });
};

export const numberFormatter = (
  number,
  fractionDigits = 0,
  thousandSeperator = ',',
  fractionSeperator = '.'
) => {
  if ((number !== 0 && !number) || !_.isFinite(number)) return number;
  const frDigits = _.isFinite(fractionDigits)
    ? Math.min(Math.max(fractionDigits, 0), 7)
    : 0;
  const num = number.toFixed(frDigits).toString();

  const parts = num.split('.');
  const digits = parts[0].split('').reverse();
  let sign = '';
  if (num < 0) {
    sign = digits.pop();
  }
  const final: any = [];
  let pos = 0;

  while (digits.length > 1) {
    final.push(digits.shift());
    pos++;
    if (pos % 3 === 0) {
      final.push(thousandSeperator);
    }
  }
  final.push(digits.shift());
  return `${sign}${final.reverse().join('')}${
    frDigits > 0 ? fractionSeperator : ''
  }${frDigits > 0 && parts[1] ? parts[1] : ''}`;
};

export const prettifyIfNumber = (
  stringOrNumber: string | number,
  digits = 0
): string | number => {
  if (typeof stringOrNumber === 'number') {
    return numberFormatter(stringOrNumber, digits);
  }
  return stringOrNumber;
};

//Function to extract UTM tags from URL;
export const getUtmtagsFromURL = () => {
  //gets current url and checks if it includes a utm parameter;
  const addr = window.location.href.toLowerCase();
  const hasUtmTags = addr.includes('utm_');

  //if url contains no utm data return undefined;
  if (!hasUtmTags) {
    return undefined;
  }

  //splits url into an array of utm tags and values
  const urlExtraParams = addr.split('?');
  const utmTags = urlExtraParams[1].split('&');

  //iterates over the utm array and creates a new object containing the utm tags as key and value
  // {utm_source: "buffer"}
  let tags = {};
  utmTags.forEach((tag) => {
    if (tag.includes('utm')) {
      const keyValue = tag.split('=');
      tags = { ...tags, [keyValue[0]]: keyValue[1] };
      return tags;
    }
  });

  return tags;
};

export const getUtmTags = () => {
  const utmStore = store('utm_tags');
  if (utmStore !== null) {
    return utmStore.utm_tags;
  } else if (getUtmtagsFromURL() !== undefined) {
    return getUtmtagsFromURL();
  }
  return null;
};

export const utmCheck = () => {
  const utm_tags =
    getUtmtagsFromURL() !== undefined ? getUtmtagsFromURL() : null;
  if (utm_tags !== null) {
    store('utm_tags', {
      utm_tags,
    });
  } else {
    return null;
  }
};
